import { BreadcrumbGroup, SpaceBetween } from "@cloudscape-design/components";
import { useGetProviderAffiliationReportQuery } from "../redux/api/reporting/reporting";
import ChangeTimeZone from "../components/Timezone";
import { useTranslation } from "react-i18next";

import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./ReportingToolBar";

export default function ProviderAffiliationReport() {
  const { data = [], isLoading } = useGetProviderAffiliationReportQuery();
  const { t } = useTranslation();
  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("reports.expiredDocuments.breadcrumb.text")}`,
            href: "/reports",
          },
          {
            text: `Provider affiliation`,
            href: "#",
          },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          columns={[
            {
              field: "name",
              headerName: "Affiliation Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.facility?.name ?? "-",
            },
            {
              field: "affiliationStatus",
              headerName: "Affiliation Status",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.affiliationStatus?.name ?? "-",
            },
            {
              field: "affiliationType",
              headerName: "Affiliation Type",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.affiliationType?.name ?? "-",
            },
            {
              field: "lastName",
              headerName: "Provider_LastName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.lastName ?? "-",
            },
            {
              field: "firstname",
              headerName: "Provider_FirstName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.firstName ?? "-",
            },
            {
              field: "npi",
              headerName: "NPI_Number",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.npiNumber ?? "-",
            },
            {
              field: "provideremail",
              headerName: "Provider_Email",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.email ?? "-",
            },
            {
              field: "licenseType",
              headerName: "Provider_License_Type",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.licenseType?.name ?? "-",
            },
            {
              field: "identificationNumber",
              headerName: "IdentificationNumber",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.identificationNumber ?? "-",
            },
            {
              field: "primaryfacility",
              headerName: "Primary Facility",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.facility?.name ?? "-",
            },
            {
              field: "startDate",
              headerName: "Start Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.startDate) ?? "-",
            },
            {
              field: "endDate",
              headerName: "End Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.endDate) ?? "-",
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.email ?? "-",
            },
          ]}
          rows={data}
          getRowId={(row) => row?.provider?.id + "_" + row?.id}
          loading={isLoading}
          slots={{ toolbar: ToolBar }}
          disableRowSelectionOnClick={true}
          disableColumnMenu
          density="compact"
        />
      </div>
    </SpaceBetween>
  );
}

const ToolBar = () => <CustomToolbar fileName="payer_enrollments_report" />;
