import { useGetAllProviderSafeDocumentsQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Divider } from "@mui/material";
import {
  Box,
  Button,
  FormField,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import FormSection from "../../components/FormSection";
import { concat } from "lodash";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useTranslation } from "react-i18next";

type selectedDocumentProps = {
  selectedDocument: OptionDefinition;
  setSelectedDocument: React.Dispatch<React.SetStateAction<{}>>;
};

const DocumentSection = ({
  providerId,
  selectedDocument,
  setSelectedDocument,
}: {
  providerId?: string;
} & selectedDocumentProps) => {
  const {
    data: allDocuments = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllProviderSafeDocumentsQuery(
    providerId ? { providerId } : skipToken,
  );
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <FormSection
        columns={2}
        header={
          <Box fontSize="heading-s" variant="h5">
            {t("documentSection.header.label")}
          </Box>
        }
      >
        <FormField stretch={false}>
          <Select
            selectedOption={selectedDocument}
            onChange={({ detail }) =>
              setSelectedDocument(detail.selectedOption)
            }
            filteringType="auto"
            options={concat(
              [{ label: "None - no document selected ", value: "-1" }],
              allDocuments.map((doc) => {
                return {
                  label: doc.name,
                  value: "" + doc.id,
                };
              }),
            )}
          />
        </FormField>
        <FormField stretch={false}>
          <SpaceBetween size="m" direction="horizontal">
            {isFetching || isLoading ? (
              <Spinner />
            ) : (
              <Button
                iconName="refresh"
                variant="icon"
                formAction="none"
                onClick={() => {
                  refetch();
                }}
              />
            )}
            <Button
              href={!!providerId ? PATH_DASHBOARD.safe.add(providerId) : "#"}
              iconAlign="right"
              iconName="external"
              target="_blank"
              formAction="none"
            >
              {t("documentSection.header.actions.createNewDocument")}
            </Button>
          </SpaceBetween>
        </FormField>
      </FormSection>
    </>
  );
};
export default DocumentSection;
