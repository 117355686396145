import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import { useGetAllProviderEnrollmentsQuery } from "../redux/api/provider/provider";

export type FilterType = {
  filterPayer: string;
  providerFilterOptions: OptionDefinition[];
  facilityFilterOptions: OptionDefinition[];
  statusFilterOptions: OptionDefinition[];
};

export default function useProviderEnrollmentFilter() {
  const { providerId } = useParams();
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProviderEnrollmentsQuery(
    providerId ? { providerId } : skipToken,
  );

  const [filter, setFilter] = useState<FilterType>({
    filterPayer: "",
    providerFilterOptions: [],
    facilityFilterOptions: [],
    statusFilterOptions: [],
  });

  const [filteredPayerEnrollments, setFilteredPayerEnrollments] =
    useState<PayerEnrollment[]>(data);

  useEffect(() => {
    setFilteredPayerEnrollments(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_payerenrollments = [...data];

    if (filter.filterPayer) {
      temp_filtered_payerenrollments = temp_filtered_payerenrollments.filter(
        (payerenrollment) =>
          payerenrollment?.payer?.name
            ?.toLowerCase()
            .includes(filter?.filterPayer?.toLowerCase()),
      );
    }
    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option?.value + "")
      .filter((value) => !!value);

    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_payerenrollments = temp_filtered_payerenrollments.filter(
        (payerenrollment) =>
          selectedProviderId.indexOf(payerenrollment?.provider?.id + "") >= 0,
      );
    }

    const selectedFaciltiesId = filter.facilityFilterOptions
      .map((option) => option?.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesId && selectedFaciltiesId.length > 0) {
      temp_filtered_payerenrollments = temp_filtered_payerenrollments.filter(
        (payerenrollment) =>
          selectedFaciltiesId.indexOf(payerenrollment?.facility?.id + "") >= 0,
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option?.value + "")
      .filter((value) => !!value);
    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_payerenrollments = temp_filtered_payerenrollments.filter(
        (payerenrollment) =>
          selectedStatus.indexOf(payerenrollment?.status?.id + "") >= 0,
      );
    }
    setFilteredPayerEnrollments(temp_filtered_payerenrollments);
  }, [filter]);

  return {
    filteredPayerEnrollments,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
