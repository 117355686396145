import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import "./index.css";
import { connect } from "react-redux";
import { setModalName } from "../redux/UI/actions";
import { Provider } from "../redux/api/provider/types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProviderFilters from "../components/filter/provider-filters";
import useFilter from "../hooks/useFilter";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import {
  useActiveProviderMutation,
  useDeleteProviderMutation,
  useGetAllProvidersQuery,
  useInActiveProviderMutation,
} from "../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { TableEmptyState } from "../common/common-components";
import { useLocalStorage } from "../common/localStorage";
import {
  PROVIDER_TABLE_DEFAULT_PREFERENCES,
  PROVIDER_CONTENT_DISPLAY_OPTIONS,
  TablePreferences,
} from "../common/table-config";
import LoadingScreen from "../components/LoadingScreen";
import {
  ADD_MANAGEPROVIDER_MODAL,
  MANAGEPROVIDER_EDIT_MODAL,
} from "../helpers/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ManageProviderTable({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { providerId } = useParams();
  const [selectedItem, setSelectedItem] = useState<Provider>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteManageProvider] = useDeleteProviderMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const [activeProvider] = useActiveProviderMutation();
  const [inactiveProvider] = useInActiveProviderMutation();

  const {
    filteredProviders,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useFilter();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Provider-Table-Preferences",
    PROVIDER_TABLE_DEFAULT_PREFERENCES,
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredProviders,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName="Providers"
            action={() => {
              openModalName(ADD_MANAGEPROVIDER_MODAL);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  useEffect(() => {
    const temp = filteredProviders.find(
      (provider) => "" + provider.id === "" + providerId,
    );
    if (!!temp) setSelectedItem(temp);
    else {
      if (filteredProviders.at(0)?.id)
        navigate(`/manageprovider/${filteredProviders.at(0)?.id}`);
    }
  }, [providerId, filteredProviders, navigate]);

  const tableProps: TableProps<Provider> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredProviders.length}/${providers.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  disabled={!selectedItem}
                  variant="normal"
                  onClick={() => {
                    navigate(`/safe?providerId=${providerId}`);
                  }}
                >
                  {t("providers.table.header.actions.gotoSafe")}
                </Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  onItemClick={async ({ detail }) => {
                    if (detail.id === "edit") {
                      openModalName(MANAGEPROVIDER_EDIT_MODAL);
                    }
                    if (detail.id === "status") {
                      if (selectedItem?.isActive === false) {
                        await notifyWrapper({
                          promise: activeProvider({
                            providerId: selectedItem?.id,
                          }),
                          resourceName: "Provider",
                          actionName: "Active",
                        });
                      }
                      if (selectedItem?.isActive === true) {
                        await notifyWrapper({
                          promise: inactiveProvider({
                            providerId: selectedItem?.id,
                          }),
                          resourceName: "Provider",
                          actionName: "Inactive",
                        });
                      }
                    }
                  }}
                  items={[
                    { text: "Edit", id: "edit", disabled: false },
                    {
                      text:
                        selectedItem?.isActive === false
                          ? `${t("providers.table.header.actions.active")}`
                          : `${t("providers.table.header.actions.inactive")}`,
                      id: "status",
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  disabled={!hasPermission}
                  variant="primary"
                  onClick={() => {
                    openModalName(ADD_MANAGEPROVIDER_MODAL);
                  }}
                >
                  {t("providers.table.header.actions.addProvider")}
                </Button>
                {/* <ButtonDropdown
                  disabled={!hasPermission}
                  items={[
                    {
                      text: `${t("providers.table.header.actions.importFromCsv")}`,
                      id: "import_csv",
                    },
                  ]}
                  mainAction={{
                    text: `${t("providers.table.header.actions.addProvider")}`,
                    onClick: () => {
                      openModalName(ADD_MANAGEPROVIDER_MODAL);
                    },
                    disabled: !hasPermission,
                  }}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "import_csv") {
                      navigate("/manageprovider/addCsvUpload");
                    }
                  }}
                  variant="primary"
                /> */}
              </span>
            </div>
          }
        >
          {t("providers.table.header.label")}
        </Header>
      ),
      filter: <ProviderFilters filter={filter} setFilter={setFilter} />,
      //Begin Collectionpreferences properties
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PROVIDER_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      //End Collectionpreferences properties
      variant: "container",
      selectionType: "single",
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: (selectionChangeDetail) => {
        const temp_providerId =
          selectionChangeDetail.detail.selectedItems.at(0)?.id;
        if (temp_providerId) navigate(`/manageprovider/${temp_providerId}`);
      },
      onRowClick: (onRowClickDetail) => {
        const temp = onRowClickDetail.detail.item.id;
        if (temp) navigate(`/manageprovider/${temp}`);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "fullname",
          header: <div>{t("providers.table.header.fields.fullName")}</div>,
          sortingField: "firstName",
          cell: (item) => (
            <Link href={`${item.id}/profile`}>
              {item.firstName} {item.lastName}
            </Link>
          ),
        },
        {
          id: "lastName",
          header: <div>{t("providers.table.header.fields.lastName")}</div>,
          sortingField: "lastName",
          cell: (item) => <span>{item.lastName}</span>,
        },
        {
          id: "firstName",
          header: <div>{t("providers.table.header.fields.firstName")}</div>,
          sortingField: "firstName",
          cell: (item) => <span>{item.firstName}</span>,
        },
        {
          id: "npiNumber",
          header: <div>{t("providers.table.header.fields.npiNumber")}</div>,
          sortingField: "npiNumber",
          cell: (item) => <span>{item?.npiNumber}</span>,
        },
        {
          id: "email",
          header: <div>{t("providers.table.header.fields.email")}</div>,
          sortingField: "email",
          cell: (item) => <span>{item.email}</span>,
        },
        {
          id: "facilityGroup",
          header: (
            <div>{t("providers.table.header.fields.facilityGroupName")}</div>
          ),
          cell: (item) => <span>{item?.facilityGroup?.name}</span>,
          sortingField: "facilityGroup.name",
          sortingComparator: (a, b) =>
            (a.facilityGroup?.name ?? "").localeCompare(
              b.facilityGroup?.name ?? "",
            ),
        },
        {
          id: "facility",
          header: <div>{t("providers.table.header.fields.facilityName")}</div>,
          cell: (item) => <span>{item?.facility?.name}</span>,
          sortingField: "facility.name",
          sortingComparator: (a, b) =>
            (a.facility?.name ?? "").localeCompare(b.facility?.name ?? ""),
        },
        {
          id: "licenseType",
          header: <div>{t("providers.table.header.fields.licenseType")}</div>,
          cell: (item) => <span>{item?.licenseType?.name}</span>,
          sortingField: "licenseType.name",
          sortingComparator: (a, b) =>
            (a.licenseType?.name ?? "").localeCompare(
              b.licenseType?.name ?? "",
            ),
        },
        {
          id: "status",
          header: <div>Status</div>,
          cell: (item) => (
            <StatusIndicator
              type={item?.isActive === true ? "success" : "stopped"}
              colorOverride={item?.isActive === true ? "green" : "red"}
            >
              {item?.isActive === true ? "Active" : "Inactive"}
            </StatusIndicator>
          ),
        },
      ],
    };
  }, [filteredProviders, selectedItem, tablePreferences, items, isLoading]);
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id)
            await notifyWrapper({
              promise: deleteManageProvider({ providerId: selectedItem?.id }),
              resourceName: "Provider",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providers.table.header.delete.header.label")}
        content={
          <>
            <Box>{t("providers.table.header.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">
              {selectedItem?.firstName + " " + selectedItem?.lastName}
            </Box>
          </>
        }
        description={t(
          "providers.table.header.delete.header.content.description",
        )}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageProviderTable);
