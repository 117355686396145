import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import AssoicatedFacilityTable from "./associated-facility-table";
import { Facility } from "../../redux/api/facility/types";
import {
  useGetFacilityGroupQuery,
  useUpdateFacilityGroupMutation,
} from "../../redux/api/facilitygroup/facilityGroup";

type FormInputProps = {
  id: string;
  name: string;
  description: string;
};
export default function EditFacilityGroup() {
  const methods = useForm<FormInputProps>();
  const [associatedFacilities, setAssociatedFacilities] = useState<Facility[]>(
    [],
  );
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: facilitygroup,
    fulfilledTimeStamp,
    isLoading,
  } = useGetFacilityGroupQuery(
    !!id
      ? {
          id: id,
        }
      : skipToken,
  );
  const [updateFacilityGroup] = useUpdateFacilityGroupMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.edit") ?? false),
  );
  const onSubmit = async (data: FormInputProps) => {
    if (!!id) {
      await notifyWrapper({
        promise: updateFacilityGroup({
          id: id,
          name: data?.name,
          description: data?.description,
        }),
        resourceName: "facilitygroup",
        actionName: "update",
      });
    }
    navigate(-1);
  };
  useEffect(() => {
    if (facilitygroup) {
      methods.setValue("name", facilitygroup?.name);
      methods.setValue("description", facilitygroup?.description);
      if (facilitygroup?.facilities) {
        setAssociatedFacilities(facilitygroup?.facilities);
      }
    }
  }, [fulfilledTimeStamp, methods, facilitygroup]);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("facilityGroup.editFacilityGroup.header.breadcrumb.text")}`,
                href: `/facilitygroup`,
              },
              {
                text: methods.getValues("name"),
                href: "#",
              },
            ]}
          />
        }
        content={
          <SpaceBetween size="m">
            <Container
              header={
                <Header>
                  {t("facilityGroup.editFacilityGroup.header.label")}
                </Header>
              }
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate("/facilitygroup");
                          }}
                          formAction="none"
                        >
                          {t(
                            "facilityGroup.editFacilityGroup.header.actions.cancel",
                          )}
                        </Button>
                        <Button
                          formAction="submit"
                          variant="primary"
                          disabled={!hasPermission}
                        >
                          {t(
                            "facilityGroup.editFacilityGroup.header.actions.save",
                          )}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={2}>
                      <RHFTextField
                        label="Name"
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />

                      <RHFTextField
                        label="Description"
                        name="description"
                        stretch={false}
                      />
                    </FormSection>
                  </Form>
                </form>
              </FormProvider>
            </Container>
            <AssoicatedFacilityTable
              associatedFacilities={associatedFacilities}
              setAssociatedFacilities={setAssociatedFacilities}
            />
          </SpaceBetween>
        }
      />
    </>
  );
}
