import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import {
  PAYER_GROUP_CONTENT_DISPLAY_OPTIONS,
  PAYER_GROUP_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import StyledRouterLink from "../components/styled-route-link";
import useFacilityGroupFilter from "../hooks/useFacilityGroupFilter";
import { FacilityGroup } from "../../src/redux/api/facilitygroup/types";
import { useDeleteFacilityGroupMutation } from "../../src/redux/api/facilitygroup/facilityGroup";
import FacilityGroupFilter from "../components/filter/facilitygroup-filters";

export default function FacilityGroupTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deleteFacilityGroup] = useDeleteFacilityGroupMutation();
  const [selectedItem, setSelectedItem] = useState<FacilityGroup>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Payer-Group-Table-Preferences",
    PAYER_GROUP_TABLE_DEFAULT_PREFERENCES,
  );
  const {
    filteredFacilityGroup,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useFacilityGroupFilter();
  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilityGroup,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("facilityGroup.table.header.label")}
            action={() => {
              navigate(`addnew`);
            }}
          />
        ),
      },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.delete") ?? false),
  );

  const tableProps: TableProps<FacilityGroup> = {
    header: (
      <Header
        variant="h2"
        counter={`(${filteredFacilityGroup.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
            </span>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectedItem}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission
                      ? `${t("facilityGroup.table.header.actions.facilityEdit")}`
                      : `${t("facilityGroup.table.header.actions.facilityView")}`,
                  },
                  {
                    id: "delete_form",
                    text: `${t("facilityGroup.table.header.actions.facilityDelete")}`,
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") {
                    if (selectedItem) OpenDeleteModal(true);
                  } else if (id === "edit_form") {
                    navigate(`/facilitygroup/${selectedItem?.id}`);
                  }
                }}
              >
                {t("facilityGroup.table.header.actions.facilityActions")}
              </ButtonDropdown>
            </span>

            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  navigate("addnew");
                }}
                variant="primary"
              >
                {t("facilityGroup.table.header.actions.addFacility")}
              </Button>
            </span>
          </div>
        }
      >
        {t("facilityGroup.table.header.label")}
      </Header>
    ),
    items: items,
    filter: <FacilityGroupFilter filter={filter} setFilter={setFilter} />,
    preferences: (
      <TablePreferences
        title="Facility Group Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={PAYER_GROUP_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    pagination: <Pagination {...paginationProps} />,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    loadingText: "Loading...",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    selectedItems: selectedItem ? [selectedItem] : [],
    onSelectionChange: ({ detail }) => {
      if (detail.selectedItems && detail.selectedItems.at(0))
        setSelectedItem(detail.selectedItems.at(0));
    },
    onRowClick: ({ detail }) => {
      if (detail.item) setSelectedItem(detail.item);
    },
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>{t("facilityGroup.table.header.fields.id")}</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: <div>Name</div>,
        cell: (item) => (
          <StyledRouterLink
            className={hasPermission ? "edit_link" : "normal"}
            to={hasPermission ? `/facilitygroup/${item.id}` : `#`}
            label={item?.name}
          />
        ),
        sortingField: "name",
      },
      {
        id: "description",
        header: <span>Description</span>,
        cell: (item) => <div>{item?.description ?? "-"}</div>,
      },
    ],
  };
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deleteFacilityGroup({
                id: selectedItem?.id,
              }),
              resourceName: "FacilityGroup",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("facilityGroup.deleteFacilityGroup.header.label")}
        content={
          <>
            <Box>
              {t("facilityGroup.deleteFacilityGroup.header.content.label")}
            </Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t(
          "facilityGroup.deleteFacilityGroup.header.content.description",
        )}
      />
    </div>
  );
}
