import { Header, Table } from "@cloudscape-design/components";
import { MemberType } from "../redux/api/manageadmin/types";
import { useTranslation } from "react-i18next";

export function AssociatedAdmins({
  admins,
}: {
  admins: readonly MemberType[];
}) {
  const { t } = useTranslation();
  return (
    <Table
      header={
        <Header counter={`(${admins.length})`}>
          {t(
            "rolesandpermissions.roles.roleDetails.associatedAdmins.table.header.label",
          )}
        </Header>
      }
      items={admins}
      columnDefinitions={[
        {
          id: "id",
          header: (
            <div>
              {t(
                "rolesandpermissions.roles.roleDetails.associatedAdmins.table.header.fields.id",
              )}
            </div>
          ),
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: (
            <div>
              {t(
                "rolesandpermissions.roles.roleDetails.associatedAdmins.table.header.fields.memberName",
              )}
            </div>
          ),
          cell: (item) => <span>{item?.firstName + " " + item?.lastName}</span>,
        },
        {
          id: "email",
          header: (
            <div>
              {t(
                "rolesandpermissions.roles.roleDetails.associatedAdmins.table.header.fields.email",
              )}
            </div>
          ),
          cell: (item) => <span>{item.email}</span>,
        },
      ]}
      visibleColumns={["email", "name"]}
    />
  );
}
