import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useGetAllFacilityTypesQuery } from "../../redux/api/facility/facility";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useFacilityFilter";
import { USStatesList } from "../../helpers/constants";

interface FacilityFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function FacilityFilters({
  filter,
  setFilter,
  countText,
}: FacilityFiltersProps) {
  const { data: facilityTypes = [] } = useGetAllFacilityTypesQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Name",
    value: "name",
  });
  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.facilityFilterOptions &&
      filter.facilityFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Facilities = ${join(
            filter.facilityFilterOptions.map(
              (facilityFilterOption) => facilityFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Name", value: "name" },
            { label: "Type", value: "type" },
            { label: "Tax ID", value: "taxId" },
            { label: "CLIA ID", value: "cliaId" },
            { label: "State", value: "state" },
          ]}
        />
        <div>
          {selectedProperty.value === "name" && (
            <TextFilter
              filteringPlaceholder="Find Name"
              filteringText={filter.filterName}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterName: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "type" && (
            <Multiselect
              hideTokens
              selectedOptions={filter.facilityFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={facilityTypes.map((type) => ({
                label: type.name,
                value: type.id + "",
              }))}
              placeholder="Choose Type"
            />
          )}
          {selectedProperty.value === "taxId" && (
            <TextFilter
              filteringPlaceholder="Find Tax ID"
              filteringText={filter.filterTaxId}
              onChange={({ detail }) => {
                setFilter({ ...filter, filterTaxId: detail.filteringText });
              }}
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "cliaId" && (
            <TextFilter
              filteringPlaceholder="Find Clia ID"
              filteringText={filter.filterCliaId}
              onChange={({ detail }) => {
                setFilter({ ...filter, filterCliaId: detail.filteringText });
              }}
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "state" && (
            <Multiselect
              hideTokens
              selectedOptions={filter.facilityFilterStateOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityFilterStateOptions: [...detail.selectedOptions],
                });
              }}
              options={USStatesList.map((state) => ({
                value: state.value,
              }))}
              placeholder="Choose State"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Facilities")
          ) {
            setFilter({
              ...filter,
              facilityFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
