import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useGetPayerQuery,
  useUpdatePayerMutation,
} from "../../redux/api/payers/payers";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import RHFSelect from "../../components/RHF/RHFSelect";
import { USStatesList } from "../../helpers/constants";
import AssoicatedNetworksTable from "./associated-network-table";
import { PayerGroup } from "../../redux/api/payergroup/types";
import RHFPhoneNumber from "../../components/RHF/RHFPhoneNumber";

type FormInputProps = {
  id: string;
  name: string;
  website: string;
  payerId: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
  contactName: string;
  contactEmail: string;
  contactFax: string;
  contactPhone: string;
};
export default function EditPayer() {
  const methods = useForm<FormInputProps>();
  const [associatedNetworks, setAssociatedNetworks] = useState<PayerGroup[]>(
    [],
  );

  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: payer,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPayerQuery(
    !!id
      ? {
          payerId: id,
        }
      : skipToken,
  );
  const [updatePayer] = useUpdatePayerMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const onSubmit = async (data: FormInputProps) => {
    if (!!id) {
      await notifyWrapper({
        promise: updatePayer({
          id: id,
          name: data?.name,
          payerId: data?.payerId,
          website: data?.website,
          payerAddress: {
            addressLane1: data?.addressLane1,
            addressLane2: data?.addressLane2,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipcode,
            contactEmail: data?.contactEmail,
            contactFax: data?.contactFax,
            contactName: data?.contactName,
            contactPhone: contactNumber ? contactNumber : "",
          },
        }),
        resourceName: "payer",
        actionName: "update",
      });
    }
    navigate(-1);
  };
  useEffect(() => {
    if (payer) {
      methods.setValue("name", payer?.name);
      methods.setValue("payerId", payer?.payerId);
      methods.setValue("website", payer?.website);
      methods.setValue("addressLane1", payer?.payerAddress?.addressLane1);
      methods.setValue("addressLane2", payer?.payerAddress?.addressLane2);
      methods.setValue("state", payer?.payerAddress?.state);
      methods.setValue("city", payer?.payerAddress?.city);
      methods.setValue("zipcode", payer?.payerAddress?.zipcode);
      methods.setValue("contactName", payer?.payerAddress?.contactName);
      methods.setValue("contactEmail", payer?.payerAddress?.contactEmail);
      methods.setValue("contactFax", payer?.payerAddress?.contactFax);
      setContactNumber(
        payer?.payerAddress?.contactPhone
          ? payer?.payerAddress?.contactPhone
          : "",
      );
      if (!!payer?.payerGroup) setAssociatedNetworks(payer?.payerGroup);
    }
  }, [fulfilledTimeStamp, methods, payer]);

  const [contactNumber, setContactNumber] = useState("");
  const handlePracticeContactNumberChange = (newValue: string) => {
    setContactNumber(newValue);
  };

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("payers.editPayer.header.breadcrumb.text")}`,
                href: `/payers`,
              },

              {
                text: `${t("payers.editPayer.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <SpaceBetween size="m">
            <Container
              header={<Header>{t("payers.editPayer.header.label")}</Header>}
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate("/payers");
                          }}
                          formAction="none"
                        >
                          {t("payers.editPayer.header.actions.cancel")}
                        </Button>
                        <Button
                          formAction="submit"
                          variant="primary"
                          disabled={!hasPermission}
                        >
                          {t("payers.editPayer.header.actions.submit")}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={1}>
                      <RHFTextField
                        label={t("payers.editPayer.header.fields.name")}
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label={t("payers.editPayer.header.fields.payerId")}
                        name="payerId"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.editPayer.header.fields.website")}
                        name="website"
                        stretch={false}
                      />
                      <ExpandableSection
                        headerText={t("payers.editPayer.header.headerText")}
                      >
                        <FormSection header="" columns={2}>
                          <RHFTextField
                            label={t(
                              "payers.editPayer.header.fields.addressLane1",
                            )}
                            name="addressLane1"
                            stretch={false}
                          />
                          <RHFTextField
                            label={t(
                              "payers.editPayer.header.fields.addressLane2",
                            )}
                            name="addressLane2"
                            stretch={false}
                          />
                          <RHFTextField
                            label={t("payers.editPayer.header.fields.city")}
                            name="city"
                            stretch={false}
                          />
                          <RHFSelect
                            name="state"
                            label={t("payers.editPayer.header.fields.state")}
                            options={USStatesList.map((state) => ({
                              value: state.value,
                            }))}
                            stretch={false}
                          />
                          <RHFTextField
                            label={t("payers.editPayer.header.fields.zipcode")}
                            name="zipcode"
                            stretch={false}
                          />
                          <RHFTextField
                            label={t(
                              "payers.editPayer.header.fields.contactName",
                            )}
                            name="contactName"
                            stretch={false}
                          />
                          <RHFTextField
                            label={t(
                              "payers.editPayer.header.fields.contactEmail",
                            )}
                            name="contactEmail"
                            stretch={false}
                          />
                          <RHFTextField
                            label={t(
                              "payers.editPayer.header.fields.contactFax",
                            )}
                            name="contactFax"
                            stretch={false}
                          />
                          <RHFPhoneNumber
                            label={t(
                              "payers.editPayer.header.fields.contactPhone",
                            )}
                            name="contactPhone"
                          />
                        </FormSection>
                      </ExpandableSection>
                    </FormSection>
                  </Form>
                </form>
              </FormProvider>
            </Container>
            <AssoicatedNetworksTable
              associatedNetworks={associatedNetworks}
              setAssociatedNetworks={setAssociatedNetworks}
            />
          </SpaceBetween>
        }
      />
    </>
  );
}
