import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { setModalName } from "../../../redux/UI/actions";
import FormSection from "../../../components/FormSection";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { alertReminderOptions } from "../../../helpers/constants";
import RHFSelect from "../../../components/RHF/RHFSelect";
import InnerAppLayout from "../../../components/InnerAppLayout";
import AttachmentViewer from "../../../components/PdftronViewerWrappers/AttachmentViewer";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import { useEffect, useState } from "react";
import {
  useGetDocumentPsvQuery,
  useUpdatePSVMutation,
} from "../../../redux/api/document/document";
import { useParams } from "react-router";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}
type FormInputType = {
  sourceType: string;
  primarySource: string;
  status: string;
  alertDays: string;
  expiration: string;
  file: File;
};

function EditPsv({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<FormInputType>();
  const [searchParams] = useSearchParams();

  const { documentId } = useParams();
  const { data: psv } = useGetDocumentPsvQuery(
    !!documentId && !!searchParams.has("psv")
      ? {
          documentId: documentId,
          psvId: searchParams.get("psv") ?? "",
        }
      : skipToken,
  );

  const [uploadFile, setUploadFile] = useState<File>();
  const [updatePsv] = useUpdatePSVMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const onSubmit = async (data: FormInputType) => {
    if (!psv) return;
    setModalName("");
    await notifyWrapper({
      promise: updatePsv({
        documentId,
        id: psv?.id,
        sourceType: data?.sourceType,
        primarySource: data?.primarySource,
        status: data?.status,
        alertDays: data?.alertDays,
        expiration: data?.expiration,
        file: uploadFile,
      }),
      resourceName: "psv",
      actionName: "update",
    });
  };

  useEffect(() => {
    if (psv) {
      methods.setValue("sourceType", psv?.sourceType);
      methods.setValue("primarySource", psv?.primarySource);
      methods.setValue("status", psv?.status);
      methods.setValue("expiration", psv?.expiration);
      methods.setValue("alertDays", psv?.alertDays);
    }
  }, [psv]);

  return (
    <Modal
      visible={true}
      size="max"
      header={
        <Header variant="h2">
          {t("providerSafe.content.details.psv.editPsv.header.label")}
        </Header>
      }
      onDismiss={() => {
        setModalName("");
      }}
    >
      <InnerAppLayout
        rightPanel={
          <AttachmentViewer
            hideHeader
            maxHeight
            attachment={psv?.attachment}
            file={uploadFile}
            onFileChange={(uploadFile?: File) => {
              setUploadFile(uploadFile);
            }}
          />
        }
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <SpaceBetween direction="vertical" size="s">
                <FormSection columns={2}>
                  <RHFTextField
                    label={t(
                      "providerSafe.content.details.psv.editPsv.header.fields.sourceType",
                    )}
                    name="sourceType"
                    stretch
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField
                    label={t(
                      "providerSafe.content.details.psv.editPsv.header.fields.primarySource",
                    )}
                    name="primarySource"
                    stretch
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField
                    label={t(
                      "providerSafe.content.details.psv.editPsv.header.fields.status",
                    )}
                    name="status"
                    stretch
                  />
                  <RHFDatePicker
                    label={t(
                      "providerSafe.content.details.psv.editPsv.header.fields.expirationDate",
                    )}
                    name={"expiration"}
                  />
                  <RHFSelect
                    label={t(
                      "providerSafe.content.details.psv.editPsv.header.fields.alertDays",
                    )}
                    name={"alertDays"}
                    options={alertReminderOptions}
                  />
                </FormSection>

                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      formAction="none"
                      onClick={() => {
                        setModalName("");
                      }}
                    >
                      {t(
                        "providerSafe.content.details.psv.editPsv.header.actions.cancel",
                      )}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button formAction="submit" variant="primary">
                      {t(
                        "providerSafe.content.details.psv.editPsv.header.actions.update",
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </SpaceBetween>
            </form>
          </FormProvider>
        }
      />
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditPsv);
