import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { PayerEnrollmentStatus } from "../../redux/api/payerenrollment/types";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useSaveAllEnrollmentStatusMutation,
} from "../../redux/api/payerenrollment/payerenrollment";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function PayerEnrollmentStatusModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } =
    useGetAllPayerEnrollmentStatusQuery();
  const { t } = useTranslation();
  const [status, setStatus] = useState<PayerEnrollmentStatus[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllPayerEnrollmentStatus] = useSaveAllEnrollmentStatusMutation();
  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...status].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllPayerEnrollmentStatus(tmp),
      resourceName: "Payer Enrollment Status",
      actionName: "Save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setStatus(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={
        <Header>
          {t("payerenrollments.payerEnrollmentStatus.header.label")}
        </Header>
      }
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t(
                "payerenrollments.payerEnrollmentStatus.header.actions.cancel",
              )}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              {t("payerenrollments.payerEnrollmentStatus.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>

          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setStatus([...status, { id: "", name: "", deleted: false }])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...status];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      deleted: true,
                    };
                    setStatus(tmp);
                  }}
                  items={status.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "payerenrollments.payerEnrollmentStatus.header.actions.addEnrollmentStatus",
                  )}
                  definition={[
                    {
                      label: `${t("payerenrollments.payerEnrollmentStatus.header.fields.status.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...status];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              deleted: item.deleted,
                            };
                            setStatus(tmp);
                          }}
                          placeholder={t(
                            "payerenrollments.payerEnrollmentStatus.header.fields.status.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(PayerEnrollmentStatusModal);
