import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setModalName } from "../../../../redux/UI/actions";
import { BoardName } from "../../../../redux/api/provider/section/types";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllBoardNameQuery,
  useSaveAllBoardNameMutation,
} from "../../../../redux/api/boardName/board";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function ProviderBoardNameModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllBoardNameQuery();
  const { t } = useTranslation();
  const [boardName, setBoardName] = useState<BoardName[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllBoardName] = useSaveAllBoardNameMutation();
  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...boardName].filter((item) => !!item.name);

    await notifyWrapper({
      promise: saveAllBoardName(tmp),
      resourceName: "Board Name",
      actionName: "Save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setBoardName(tmpItems);
  }, [data, fulfilledTimeStamp]);
  return (
    <Modal
      header={
        <Header>
          {t("providerProfiles.affiliations.boardName.header.label")}
        </Header>
      }
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t(
                "providerProfiles.affiliations.boardName.header.actions.cancel",
              )}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              {t("providerProfiles.affiliations.boardName.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setBoardName([...boardName, { name: "" }])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...boardName];
                    const item = { ...tmp[itemIndex] };
                    if (!!item.name) {
                      tmp[itemIndex] = {
                        id: item.id,
                        name: item.name,
                        deleted: true,
                      };
                      setBoardName(tmp);
                    }
                  }}
                  items={boardName.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "providerProfiles.affiliations.boardName.header.actions.addBoardName",
                  )}
                  definition={[
                    {
                      label: `${t("providerProfiles.affiliations.boardName.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...boardName];
                            tmp[itemIndex] = {
                              name: changeDetails.detail.value,
                            };
                            setBoardName(tmp);
                          }}
                          placeholder={t(
                            "providerProfiles.affiliations.boardName.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ProviderBoardNameModal);
