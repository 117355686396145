import LoadingScreen from "../../components/LoadingScreen";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";

import AssociatedPayersModals from "./associated-payers-model";
import { useDeleteAssociatedPayerMutation } from "../../redux/api/payergroup/payergroup";
import PayerFilters from "../../components/filter/payer-filters";
import { Payers } from "../../redux/api/payers/types";
import usePayerFilter from "../../hooks/usePayerFilter";

export default function AssoicatedPayersTable({
  associatedPayers,
  setAssociatedPayers,
}: {
  associatedPayers: Payers[];
  setAssociatedPayers: Dispatch<SetStateAction<Payers[]>>;
}) {
  const [isAssociatedPayersModalOpen, setAssociatedPayersModalOpen] =
    useState<boolean>(false);
  const { id } = useParams();

  const { t } = useTranslation();
  const { filteredPayers, filter, isLoading, isFetching, setFilter } =
    usePayerFilter(associatedPayers);
  const [deleteAssociatedPayer] = useDeleteAssociatedPayerMutation();

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayers,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Associated Payers"}
            action={() => {
              setAssociatedPayersModalOpen(true);
            }}
          />
        ),
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedPayers, setSelectedPayers] = useState<Payers[]>([]);

  const tableProps: TableProps<Payers> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredPayers?.length})`}
          actions={
            <SpaceBetween size="l" direction="horizontal">
              <Button
                onClick={async () => {
                  try {
                    if (!id) return;
                    const payerToRemove =
                      selectedPayers?.map(
                        (selectedPayer) => selectedPayer?.id + "",
                      ) || [];

                    await deleteAssociatedPayer({
                      payerGroupID: id,
                      deleteassociatedPayer: payerToRemove,
                    });

                    const temp = associatedPayers.filter(
                      (payer) => !payerToRemove.includes(payer?.id + ""),
                    );
                    setAssociatedPayers(temp);
                    setSelectedPayers([]);
                  } catch (error) {
                    console.error("Error deleting payer:", error);
                    // Handle error (e.g., show a message to the user)
                  }
                }}
              >
                Remove
              </Button>

              <Button
                onClick={() => {
                  setAssociatedPayersModalOpen(true);
                }}
                variant="primary"
                data-test="add_payer"
              >
                Associate Payer
              </Button>
            </SpaceBetween>
          }
        >
          Associated Payers
        </Header>
      ),
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <PayerFilters filter={filter} setFilter={setFilter} />,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: true,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedPayers(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedPayers;
        if (!detail.item) return;
        if (temp?.includes(detail.item))
          setSelectedPayers(temp.filter((item) => item !== detail.item));
        else setSelectedPayers([detail.item, ...temp]);
      },
      selectedItems: selectedPayers,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "payerId", visible: true },
        { id: "website", visible: true },
        { id: "state", visible: true },
      ],
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("payers.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>{t("payers.table.header.fields.name")}</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },

        {
          id: "payerId",
          header: <div>{t("payers.table.header.fields.payerId")}</div>,
          cell: (item) => <div>{item?.payerId ?? "-"}</div>,
        },
        {
          id: "website",
          header: "Website",
          cell: (item) => <Link href="#">{item.website || "-"}</Link>,
          sortingField: "website",
          isRowHeader: true,
        },
        {
          id: "state",
          header: <div>{t("payers.table.header.fields.state")}</div>,
          cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
        },
      ],
    };
  }, [items, selectedPayers]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <AssociatedPayersModals
        associatedPayers={associatedPayers}
        isAssociatedPayersModalOpen={isAssociatedPayersModalOpen}
        setAssociatedPayers={setAssociatedPayers}
        setAssociatedPayersModalOpen={setAssociatedPayersModalOpen}
      />
    </div>
  );
}
