import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import StyledRouterLink from "../components/styled-route-link";
import {
  SAFECONTENT_CONTENT_DISPLAY_OPTIONS,
  SAFECONTENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import ChangeTimeZone from "../components/Timezone";
import { useGetProviderQuery } from "../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useDeleteDocumentMutation,
  useShareDocumentMutation,
} from "../redux/api/document/document";
import KeyValue from "../components/KeyValue";
import { Document } from "../redux/api/document/types";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import useProviderSafeFilter from "../hooks/useProviderSafeFilter";
import ProviderSafeFilters from "../components/filter/providersafe-filters";
import { usePageIndex } from "../hooks/usePageIndex";
import ShareDocumentModal from "./document/modals/share-document-modal";
import { openInNewTab } from "../helpers/utils";
import { HOST_API, getFileUrl } from "../config";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

export default function ProviderSafeContent() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const providerId = useMemo(() => {
    return searchParams.get("providerId") ?? "";
  }, [searchParams]);

  const { t } = useTranslation();

  const { data: provider } = useGetProviderQuery(
    !!providerId ? { providerId } : skipToken,
  );

  const {
    filteredProviderSafe,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
    fulfilledTimeStamp,
  } = useProviderSafeFilter();

  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [openShareDocModal, setOpenShareDocModal] = useState<boolean>(false);
  const [shareDocument] = useShareDocumentMutation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-SafeContent-Table-Preferences",
    SAFECONTENT_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(
    filteredProviderSafe,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("providerSafe.content.table.header.resourceName")}
            action={() => {
              navigate(`/safe/${providerId}/documents/addnew`);
            }}
          />
        ),
      },
      sorting: {
        defaultState: {
          isDescending: true,
          sortingColumn: {
            sortingField: "createdDate",
          },
        },
      },
      selection: { keepSelection: true },
      pagination: {
        pageSize: tablePreferences.pageSize,
        defaultPage: Number(searchParams.get("safePageIndex")) ?? undefined,
      },
    },
  );
  usePageIndex(String(paginationProps.currentPageIndex), {
    queryParamName: "safePageIndex",
  });

  const tableProps: TableProps<Document> = useMemo(() => {
    return {
      items,
      variant: "container",
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Safe Table Column Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={SAFECONTENT_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      filter: <ProviderSafeFilters filter={filter} setFilter={setFilter} />,
      ...collectionProps,
      selectionType: "multi",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectedItems: selectedDocuments,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedDocuments(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: (onRowClickDetail) => {
        const index = selectedDocuments?.findIndex(
          (selectedDocument) =>
            selectedDocument?.id + "" === onRowClickDetail.detail.item?.id + "",
        );
        if (index === -1) {
          setSelectedDocuments([
            ...selectedDocuments,
            onRowClickDetail.detail.item,
          ]);
        } else {
          let temp = [...selectedDocuments];
          temp.splice(index, 1);
          setSelectedDocuments([...temp]);
        }
      },
      trackBy: (item) => item.id,
      columnDefinitions: [
        {
          id: "documentName",
          header: (
            <div>
              {t("providerSafe.content.table.header.fields.documentName")}
            </div>
          ),
          sortingField: "name",
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={
                hasPermission
                  ? `/safe/${providerId}/documents/${item.id}/edit`
                  : `#`
              }
              label={item?.name}
            />
          ),
        },
        {
          id: "categoryName",
          header: (
            <div>
              {t("providerSafe.content.table.header.fields.categoryName")}
            </div>
          ),
          cell: (item) => <span>{item?.category?.name}</span>,
          sortingField: "category.name",
          sortingComparator: (a, b) =>
            (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
        },
        {
          id: "fileName",
          header: (
            <div>{t("providerSafe.content.table.header.fields.fileName")}</div>
          ),
          cell: (item) => <span>{item?.attachment?.name}</span>,
          sortingField: "attachment.name",
          sortingComparator: (a, b) =>
            (a?.attachment?.name ?? "").localeCompare(
              b?.attachment?.name ?? "",
            ),
        },
        {
          id: "expiration",
          header: (
            <div>
              {t("providerSafe.content.table.header.fields.expiration")}
            </div>
          ),
          cell: (item) => <span>{ChangeTimeZone(item.expirationDate)}</span>,
          sortingField: "expirationDate",
        },
        {
          id: "alertDays",
          header: (
            <div>{t("providerSafe.content.table.header.fields.alertDays")}</div>
          ),
          sortingField: "alertDays",
          cell: (item) => (
            <span>{item.alertDays > 0 ? `${item.alertDays} days` : ""}</span>
          ),
        },
        {
          id: "uploaded",
          header: (
            <div>{t("providerSafe.content.table.header.fields.uploaded")}</div>
          ),
          sortingField: "createdDate",
          cell: (item) => <span>{ChangeTimeZone(item.createdDate)}</span>,
        },
        {
          id: "notes",
          header: (
            <div>{t("providerSafe.content.table.header.fields.notes")}</div>
          ),
          sortingField: "notes",
          cell: (item) => <span>{item.notes}</span>,
        },
      ],
      header: (
        <Header
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>

              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission
                        ? `${t("providerSafe.content.table.header.actions.edit")}`
                        : `${t("providerSafe.content.table.header.actions.view")}`,
                      disabled:
                        !!selectedDocuments && selectedDocuments?.length !== 1,
                    },
                    {
                      id: "share",
                      text: "Share",
                      disabled:
                        !!selectedDocuments && selectedDocuments?.length !== 1,
                    },
                    ...(!!organization?.organizationServiceLimits?.psv
                      ? [
                          {
                            id: "psv",
                            text: "PSV",
                            disabled:
                              !!selectedDocuments &&
                              selectedDocuments?.length !== 1,
                          },
                        ]
                      : []),
                    {
                      id: "delete_form",
                      text: `${t("providerSafe.content.table.header.actions.delete")}`,
                      disabled:
                        !hasDeletePermission ||
                        (!!selectedDocuments &&
                          selectedDocuments?.length !== 1),
                    },
                    {
                      id: "download",
                      text: "Download",
                      items: [
                        {
                          id: "downlaod_selected",
                          text: "Download selected",
                          disabled:
                            !!selectedDocuments &&
                            selectedDocuments?.length < 1,
                        },
                        { id: "download_all", text: "Download all" },
                      ],
                    },
                  ]}
                  expandableGroups={true}
                  onItemClick={(itemClickDetails) => {
                    if (
                      itemClickDetails.detail.id === "delete_form" &&
                      !!providerId &&
                      !!selectedDocuments &&
                      selectedDocuments?.length === 1
                    ) {
                      OpenDeleteModal(true);
                    }
                    if (
                      itemClickDetails.detail.id === "share" &&
                      !!providerId &&
                      !!selectedDocuments &&
                      selectedDocuments?.length === 1
                    ) {
                      setOpenShareDocModal(true);
                    }
                    if (
                      itemClickDetails.detail.id === "download_all" &&
                      !!providerId
                    ) {
                      openInNewTab({
                        downloadUrl: `${HOST_API}/api/provider/${providerId}/document/all/download`,
                      });
                    }

                    if (
                      itemClickDetails.detail.id === "downlaod_selected" &&
                      !!providerId &&
                      !!selectedDocuments &&
                      selectedDocuments?.length !== 0
                    ) {
                      if (selectedDocuments?.length === 1) {
                        const documentKey =
                          selectedDocuments?.at(0)?.attachment?.key;
                        if (!!documentKey)
                          openInNewTab({
                            downloadUrl: getFileUrl(documentKey),
                          });
                      } else {
                        const documentIds = selectedDocuments
                          .map((selectedDocument) => selectedDocument?.id)
                          .join(",");
                        openInNewTab({
                          downloadUrl: `${HOST_API}/api/provider/${providerId}/document/download?documentIds=${documentIds}`,
                        });
                      }
                    }
                    if (
                      itemClickDetails.detail.id === "delete_form" &&
                      !!providerId &&
                      !!selectedDocuments &&
                      selectedDocuments?.length === 1
                    ) {
                      OpenDeleteModal(true);
                    }
                    if (
                      itemClickDetails.detail.id === "edit_form" &&
                      !!providerId &&
                      !!selectedDocuments &&
                      selectedDocuments?.length === 1
                    ) {
                      navigate(
                        `/safe/${providerId}/documents/${selectedDocuments?.at(0)?.id}/edit`,
                      );
                    }
                    if (
                      itemClickDetails.detail.id === "psv" &&
                      !!providerId &&
                      !!selectedDocuments &&
                      selectedDocuments?.length === 1
                    ) {
                      navigate(
                        `/safe/${providerId}/documents/${selectedDocuments?.at(0)?.id}`,
                      );
                    }
                  }}
                >
                  {t("providerSafe.content.table.header.actions.safeActions")}
                </ButtonDropdown>
              </span>
              <span>
                <ButtonDropdown
                  disabled={!hasPermission}
                  items={[
                    {
                      text: "Add more than one document",
                      id: "bulk_upload",
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    if (
                      itemClickDetails.detail.id === "bulk_upload" &&
                      !!providerId
                    ) {
                      navigate(`/safe/${providerId}/documents/bulkupload`);
                    }
                  }}
                  mainAction={{
                    text: t(
                      "providerSafe.content.table.header.actions.addNewDocument",
                    ),
                    onClick: () => {
                      navigate(`/safe/${providerId}/documents/addnew`);
                    },
                  }}
                  variant="primary"
                />
              </span>
            </div>
          }
          counter={`(${filteredProviderSafe?.length})`}
        >
          {provider?.firstName} {provider?.lastName}
        </Header>
      ),
    };
  }, [selectedDocuments, items, fulfilledTimeStamp, tablePreferences]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (
            !!providerId &&
            !!selectedDocuments &&
            selectedDocuments?.length === 1
          ) {
            const id = selectedDocuments?.at(0)?.id;
            if (!!id)
              await notifyWrapper({
                promise: deleteDocument({
                  providerId: providerId,
                  documentId: id,
                }),
                resourceName: "document",
                actionName: "delete",
              });
          }
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerSafe.content.delete.header.label")}
        content={
          <KeyValue
            label={t("providerSafe.content.delete.header.content.label")}
            value={
              <Header variant="h3">{selectedDocuments.at(0)?.name}</Header>
            }
          ></KeyValue>
        }
        description={t(
          "providerSafe.content.delete.header.content.description",
        )}
      />
      <ShareDocumentModal
        visible={
          openShareDocModal &&
          !!selectedDocuments &&
          selectedDocuments.length === 1
        }
        selectedDocument={selectedDocuments.at(0)}
        shareDocument={async (email: string) => {
          const id = selectedDocuments.at(0)?.id;
          if (!!providerId && !!id && !!email) {
            setOpenShareDocModal(false);
            await notifyWrapper({
              promise: shareDocument({
                providerId,
                documentId: id,
                email,
              }),
              resourceName: "document",
              actionName: "share",
            });
          }
        }}
        closeModal={() => {
          setOpenShareDocModal(false);
        }}
      />
    </div>
  );
}
