import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import {
  Button,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  Link,
  Checkbox,
} from "@cloudscape-design/components";
import "./login.css";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import { PATH_AUTH } from "../../routes/paths";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

type FormInputType = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  return (
    <div className="container">
      <div className="centered-element">
        <SpaceBetween direction="vertical" size="xxl">
          <img
            alt=""
            src="/RD_Horizozntal_Color_Logo.svg"
            style={{
              height: 45,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SpaceBetween direction="vertical" size="xxs">
              <div className="signup" style={{ width: 500, margin: 15 }}>
                <Container
                  className="signupContainer"
                  header={
                    <Header
                      className="signupheader"
                      variant="h1"
                      description={
                        error && (
                          <StatusIndicator type="error">
                            {error}
                          </StatusIndicator>
                        )
                      }
                    >
                      {t("login.header.label")}
                    </Header>
                  }
                >
                  <FormProvider {...methods}>
                    <form
                      className="signupform"
                      onSubmit={methods.handleSubmit(
                        async (data: FormInputType) => {
                          try {
                            login(data.email, data.password).catch((error) => {
                              // console.error(error);
                              setError(error);
                              Sentry.captureException(error);
                            });
                          } catch (error) {
                            // console.error(error);
                            Sentry.captureException(error);
                            setError("Error: ");
                          }
                        },
                      )}
                    >
                      <SpaceBetween direction="vertical" size="l">
                        <RHFTextField
                          testid="email"
                          label={t("login.header.fields.email")}
                          name="email"
                          stretch
                          rules={{
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email",
                            },
                          }}
                        />
                        <RHFTextField
                          testid="password"
                          type={showPassword ? "text" : "password"}
                          label={t("login.header.fields.password")}
                          name="password"
                          stretch
                          rules={{ required: "This field is required" }}
                        />
                        <Checkbox
                          data-testid="showpassword"
                          onChange={({ detail }) =>
                            setShowPassword(detail.checked)
                          }
                          checked={showPassword}
                        >
                          {t("login.header.fields.showPassword")}
                        </Checkbox>
                        <ul
                          style={{
                            padding: 0,
                            margin: 0,
                            listStyle: "none",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            data-testid="forgotpassword"
                            href={PATH_AUTH.requestReset}
                          >
                            {t("login.header.actions.forgotPassword")}
                          </Link>
                          <Button variant="primary" data-testid="loginButton">
                            {t("login.header.actions.login")}
                          </Button>
                        </ul>
                      </SpaceBetween>
                    </form>
                  </FormProvider>
                </Container>
              </div>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </div>
    </div>
  );
}
