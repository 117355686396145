import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateInviteMutation } from "../../redux/api/manageadmin/invitation";
import { useGetAllRoleQuery } from "../../redux/api/role/role";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../../hooks/useIsLoading";
import { useNavigate, useParams } from "react-router-dom";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useState } from "react";
import { Facility } from "../../redux/api/facility/types";

import { PATH_DASHBOARD } from "../../routes/paths";
import AssoicatedFacilitiesTable from "./associated-facilities-table";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";

type FormInputType = {
  email: string;
  role: string;
};
export default function AddInvitation() {
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();
  const [createInvite, { isLoading: isCreating }] = useCreateInviteMutation();
  const { data: roles, isLoading: isRoleLoading } = useGetAllRoleQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const [associatedFacilities, setAssociatedFacilities] = useState<Facility[]>(
    [],
  );

  const { data: organization, isLoading: isOrgLoading } =
    useGetOrganizationDetailsQuery();

  useIsLoading(isCreating || isRoleLoading || isOrgLoading);
  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: createInvite({
        email: data.email,
        role: data.role,
        facilityIds:
          associatedFacilities?.map(
            (associatedFacility) => associatedFacility?.id + "",
          ) ?? [],
      }),
      resourceName: "Invite",
      actionName: "add",
    });
    navigate(-1);
  };

  return (
    <SpaceBetween size="s">
      <BreadcrumbGroup
        items={[
          { text: "Members", href: PATH_DASHBOARD.manageadmin.list },
          { text: "Add new", href: "#" },
        ]}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container
            header={
              <Header
                variant="h2"
                description={t("members.header.inviteModal.header.description")}
                actions={
                  <SpaceBetween size="s" direction="horizontal">
                    <Button
                      formAction="none"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t("members.header.inviteModal.header.actions.cancel")}
                    </Button>
                    <Button variant="primary">
                      {t("members.header.inviteModal.header.actions.invite")}
                    </Button>
                  </SpaceBetween>
                }
              >
                {t("members.header.inviteModal.header.label")}
              </Header>
            }
          >
            <SpaceBetween direction="vertical" size="s">
              <RHFTextField
                label={t("members.header.inviteModal.header.fields.email")}
                name="email"
                stretch={false}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email",
                  },
                }}
              />
              <RHFSelect
                label={t("members.header.inviteModal.header.fields.role")}
                name={"role"}
                stretch={false}
                rules={{ required: "This field is required" }}
                options={roles?.map((role) => {
                  return {
                    label: role.name,
                    value: role.id,
                    disabled: role.default,
                  };
                })}
              />
            </SpaceBetween>
          </Container>
        </form>
      </FormProvider>
      {!!organization?.settings?.enableFacilityBasedAccess && (
        <AssoicatedFacilitiesTable
          associatedFacilities={associatedFacilities}
          setAssociatedFacilities={setAssociatedFacilities}
        />
      )}
    </SpaceBetween>
  );
}
