import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Input,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetAllPayerQuery } from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";
import { useTranslation } from "react-i18next";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { usePageIndex } from "../hooks/usePageIndex";

interface PayerNavigationProps {
  navigateTo: (id: string) => string;
  onChange?: (selectedPayer?: Payers) => void;
}
export default function PayerNavigation({
  onChange,
  navigateTo,
}: PayerNavigationProps) {
  const { data = [] } = useGetAllPayerQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [preferences, setPreferences] = React.useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });
  const { id } = useParams();
  const { t } = useTranslation();
  const selectedItem = useMemo(() => {
    const temp = data.find((payer) => "" + payer.id === "" + id);
    if (temp) return temp;
    else {
      if (data.at(0)?.id) navigate(navigateTo(`${data.at(0)?.id}`));
      return undefined;
    }
  }, [id, data]);

  const [name, setName] = React.useState("");
  const [filteredPayer, setFilteredPayers] = useState<Payers[]>(data);
  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayer,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      selection: { keepSelection: true, trackBy: (item) => item.id },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
      pagination: {
        pageSize: preferences?.pageSize,
        defaultPage: Number(searchParams.get("pageIndex")) ?? undefined,
      },
    },
  );
  usePageIndex(String(paginationProps.currentPageIndex));

  useEffect(() => {
    let temp_filtered_payers = [...data];
    if (name) {
      temp_filtered_payers = temp_filtered_payers.filter((payer) =>
        payer.name.toLowerCase().includes(name.toLowerCase()),
      );
      setFilteredPayers(temp_filtered_payers);
    } else {
      setFilteredPayers(data);
    }
  }, [data, name, filteredPayer]);

  useEffect(() => {
    onChange && onChange(selectedItem);
  }, [selectedItem, onChange]);

  const tableProps: TableProps<Payers> = React.useMemo(() => {
    return {
      header: (
        <Header variant="h2">
          {t("payerenrollments.navigationTable.header.label")}
        </Header>
      ),
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      ...collectionProps,
      wrapLines: true,
      stripedRows: true,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: (selectionChangeDetail) => {
        const temp_payerId =
          selectionChangeDetail.detail.selectedItems.at(0)?.id;
        if (temp_payerId) navigate(navigateTo(`${temp_payerId}`));
      },
      onRowClick: (onRowClickDetail) => {
        const temp = onRowClickDetail.detail.item.id;
        if (temp) navigate(navigateTo(`${temp}`));
      },
      empty: <p>No results to display.</p>,
      trackBy: (item) => item.id,
      filter: (
        <Input
          onChange={({ detail }) => setName(detail.value)}
          value={name}
          placeholder="Find payer by name"
          type="search"
        />
      ),
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>{t("payerenrollments.navigationTable.header.fields.id")}</div>
          ),
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: (
            <div>
              {t("payerenrollments.navigationTable.header.fields.name")}
            </div>
          ),
          cell: (item) => <span>{item.name}</span>,
          sortingField: "name",
          sortingComparator: (a, b) =>
            (a?.name ?? "").localeCompare(b?.name ?? ""),
        },
      ],
      columnDisplay: [{ id: "name", visible: true }],
      items,
    };
  }, [id, selectedItem, items]);

  return <Table {...tableProps} />;
}
