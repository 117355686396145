import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Grid,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

import InnerAppLayout from "../../components/InnerAppLayout";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { TASK_STATUS, TASK_TYPE } from "../../helpers/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import FormSection from "../../components/FormSection";
import {
  useGetAllWorkflowQuery,
  useGetWorkflowQuery,
  useUpdateWorkflowTaskMutation,
} from "../../redux/api/workflow/workflow";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { Divider } from "antd";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { WorkFlowTaskRequest } from "../../redux/api/workflow/types";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { method } from "lodash";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditWorkFlowTask({ setModalName }: PropsFromDispatch) {
  const methods = useForm<WorkFlowTaskRequest>();
  const { workflowId, taskId } = useParams();

  const { data: workflow, fulfilledTimeStamp } = useGetWorkflowQuery(
    !!workflowId ? { id: workflowId } : skipToken,
  );
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [updateTask] = useUpdateWorkflowTaskMutation();

  useEffect(() => {
    if (workflow && workflow?.tasks) {
      const workflowtask = workflow?.tasks?.find(
        (task) => task?.id + "" === taskId + "",
      );
      if (!workflowtask) return;
      methods.setValue("name", workflowtask.name ?? "");
      methods.setValue("description", workflowtask.description ?? "");
      methods.setValue("taskStatusId", workflowtask.taskStatus?.id ?? "");
      methods.setValue("taskTypeId", workflowtask.taskType?.id ?? "");
      methods.setValue("adminId", workflowtask.admin?.id ?? "");
      methods.setValue("providerId", workflowtask.provider?.id ?? "");
      methods.setValue("facilityId", workflowtask.facility?.id ?? "");
      methods.setValue("workflowId", workflowId ?? "");
      methods.setValue("startDate", workflowtask.startDate ?? "");
      methods.setValue("dueDate", workflowtask.dueDate ?? "");
      methods.setValue("completedDate", workflowtask.completedDate ?? "");
    }
  }, [fulfilledTimeStamp, methods, workflow]);

  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  const onSubmit = async (data: WorkFlowTaskRequest) => {
    if (!!workflowId && !!taskId) {
      await notifyWrapper({
        promise: updateTask({ taskId: taskId, ...data }),
        resourceName: "task",
        actionName: "update",
      });
      navigate(-1);
    }
  };

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `Workflows`,
                href: `/workflows`,
              },
              {
                text: workflow?.name ?? "",
                href: `/workflows/${workflowId}`,
              },
              {
                text: `Add Task`,
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <Container
                header={
                  <div style={{ paddingBottom: "12px" }}>
                    <Header>Edit Task</Header>
                  </div>
                }
              >
                <Grid gridDefinition={[{ colspan: 9 }]}>
                  <FormSection columns={3}>
                    <RHFFloatingSelect
                      name="taskTypeId"
                      label="Task Type"
                      options={taskTypes.map((type) => ({
                        label: type.name,
                        value: "" + type.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: "Modify",
                        onClick: () => {
                          setModalName(TASK_TYPE);
                        },
                      }}
                    />
                    <RHFSelect
                      label={"Provider"}
                      name={"providerId"}
                      stretch={false}
                      filteringType="auto"
                      options={providers.map((provider) => ({
                        label: provider.firstName + " " + provider.lastName,
                        value: "" + provider.id,
                      }))}
                    />
                    <RHFTextField
                      label={"Task Name"}
                      name="name"
                      stretch={false}
                    />
                  </FormSection>
                </Grid>
                <Grid gridDefinition={[{ colspan: 9 }]}>
                  <FormSection columns={3}>
                    <RHFSelect
                      label={"Assign to"}
                      name={"adminId"}
                      stretch={false}
                      filteringType="auto"
                      options={members.map((member) => ({
                        label: member.firstName + " " + member.lastName,
                        value: "" + member.id,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="taskStatusId"
                      label="Task Status"
                      options={taskstatuses.map((status) => ({
                        label: status.name,
                        value: "" + status.id,
                      }))}
                      stretch={false}
                      action={{
                        label: "Modify",
                        onClick: () => {
                          setModalName(TASK_STATUS);
                        },
                      }}
                    />

                    <RHFSelect
                      label={"Facility"}
                      name={"facilityId"}
                      stretch={false}
                      filteringType="auto"
                      options={facilities.map((facility) => ({
                        label: facility.name,
                        value: "" + facility.id,
                      }))}
                    />
                    <RHFDatePicker
                      label={"Start date"}
                      name={"startDate"}
                      stretch={true}
                    />
                    <RHFDatePicker
                      label={"Due date"}
                      name={"dueDate"}
                      stretch={true}
                    />
                    <RHFDatePicker
                      label={"Completed date"}
                      name={"completedDate"}
                      stretch={true}
                    />
                  </FormSection>
                </Grid>
                <Divider />
                <Grid gridDefinition={[{ colspan: 9 }]}>
                  <ExpandableSection headerText="Notes">
                    <FormSection>
                      <RHFTextArea
                        label={"Notes"}
                        name="description"
                        stretch={true}
                      />
                    </FormSection>
                  </ExpandableSection>
                </Grid>
              </Container>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditWorkFlowTask);
