import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import { useFormContext, Controller } from "react-hook-form";
import FloatingSelect, { FloatingSelectProps } from "../FloatingSelect";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  defaultValue?: string;
  rules?: { required: string };
  testid?: string;
};

type Props = IProps &
  Omit<FloatingSelectProps, "onSelectionChange"> &
  FormFieldProps;

export default function RHFFloatingSelect({
  name,
  rules,
  label,
  errorText,
  defaultValue,
  description,
  stretch,
  options,
  action,
  enableFiltering,
  disabled = false,
  testid,
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <FormField
            label={
              <span>
                {label}{" "}
                {rules?.required && !!label && (
                  <span style={{ color: "#fc0a37" }}>*</span>
                )}
              </span>
            }
            /* info={
              description && (
                <span>
                  <i>{description}</i>
                </span>
              )
            } */
            description={<span>{description}</span>}
            errorText={errorText ?? fieldState.error?.message}
            stretch={stretch ?? true}
          >
            <FloatingSelect
              data-testid={testid}
              options={options} //{sortBy(options, (option) => option.label)}
              selectedOption={options?.find((option) => {
                if (typeof field.value === "object" && field.value !== null)
                  return (
                    option.value?.toString() === field.value.id?.toString()
                  );
                else {
                  return option.value?.toString() === field.value?.toString();
                }
              })}
              onSelectionChange={(changedOption) => {
                field.onChange(changedOption.value);
              }}
              enableFiltering={enableFiltering}
              disabled={disabled}
              action={action}
            />
          </FormField>
        );
      }}
      rules={rules}
    />
  );
}
