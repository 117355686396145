import { BreadcrumbGroup, Header } from "@cloudscape-design/components";
import { useSearchParams } from "react-router-dom";
import InnerAppLayout from "../components/InnerAppLayout";
import { useState } from "react";
import { Provider } from "../redux/api/provider/types";
import ProviderSafeContent from "./provider-safe-content";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";
import ProviderSafeNavigation from "./provider-Safe-Navigation";
import { useTranslation } from "react-i18next";

export const NoProviderSelected = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Header variant="h2">{t("providerSafe.noProvider")}</Header>
    </div>
  );
};
export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 10,
  },
}));

export default function ProviderSafeTable() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<Provider>();
  const [searchParams] = useSearchParams();

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={
            selectedItem
              ? [
                  { text: `${t("providerSafe.breadcrumb.text")}`, href: "#" },
                  {
                    text: selectedItem.firstName + " " + selectedItem.lastName,
                    href: "#",
                  },
                ]
              : [{ text: `${t("providerSafe.breadcrumb.text")}`, href: "#" }]
          }
        />
      }
      leftPanel={
        <ProviderSafeNavigation
          onChange={(selectedProvider) => {
            setSelectedItem(selectedProvider);
          }}
        />
      }
      content={
        !!searchParams.get("providerId") ? (
          <ProviderSafeContent />
        ) : (
          <NoProviderSelected />
        )
      }
    />
  );
}
