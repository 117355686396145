import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/usePayerEnrollmentFilter";
import { useGetAllPayerEnrollmentStatusQuery } from "../../redux/api/payerenrollment/payerenrollment";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";

interface PayerEnrollmentFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function ProviderEnrollentFilters({
  filter,
  setFilter,
  countText,
}: PayerEnrollmentFiltersProps) {
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: enrollmentStatus = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Payer",
    value: "payer",
  });
  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.facilityFilterOptions &&
      filter.facilityFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Facilities = ${join(
            filter.facilityFilterOptions.map(
              (facilityFilterOption) => facilityFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];
    if (
      !!filter.providerFilterOptions &&
      filter.providerFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Providers = ${join(
            filter.providerFilterOptions.map(
              (providerFilterOptions) => providerFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (!!filter.statusFilterOptions && filter.statusFilterOptions.length > 0)
      temp = [
        ...temp,
        {
          label: `Status = ${join(
            filter.statusFilterOptions.map(
              (statusFilterOption) => statusFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterPayer: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Payer", value: "payer" },
            { label: "Provider", value: "name" },
            { label: "Facility", value: "facility" },
            { label: "Status", value: "status" },
          ]}
        />
        <div>
          {selectedProperty.value === "payer" && (
            <TextFilter
              filteringPlaceholder="Find Payer"
              filteringText={filter.filterPayer}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterPayer: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "facility" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.facilityFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={facilities.map((facility) => ({
                label: facility.name,
                value: facility.id + "",
              }))}
              placeholder="Choose Facilites"
            />
          )}
          {selectedProperty.value === "name" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.providerFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  providerFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={providers.map((provider) => ({
                label: provider.firstName + " " + provider.lastName,
                value: provider.id + "",
              }))}
              placeholder="Choose Providers"
            />
          )}
          {selectedProperty.value === "status" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.statusFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  statusFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={enrollmentStatus.map((status) => ({
                label: status.name,
                value: status.id,
              }))}
              placeholder="Choose Status"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Facility")) {
            setFilter({
              ...filter,
              facilityFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Provider")
          ) {
            setFilter({
              ...filter,
              providerFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Status")
          ) {
            setFilter({
              ...filter,
              statusFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
