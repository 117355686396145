import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../components/LoadingScreen";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import FacilityFilters from "../../components/filter/facility-filters";
import { useAddFacilitesToFacilityGroupMutation } from "../../redux/api/facilitygroup/facilityGroup";
import useFacilityFilter from "../../hooks/useFacilityFilter";
import { Facility } from "../../redux/api/facility/types";
import * as Sentry from "@sentry/react";

export default function AssociatedFacilityGroupModals({
  isAssociatedFacilityModalOpen,
  setIsAssociatedFacilityModalOpen,
  associatedFacilities,
  setAssociatedFacilities,
}: {
  isAssociatedFacilityModalOpen: boolean;
  setIsAssociatedFacilityModalOpen: Dispatch<SetStateAction<boolean>>;
  associatedFacilities: Facility[];
  setAssociatedFacilities: Dispatch<SetStateAction<Facility[]>>;
}) {
  const { id } = useParams();
  const [addFacilitesToFacilityGroup] =
    useAddFacilitesToFacilityGroupMutation();
  const { t } = useTranslation();
  const { filteredFacilities, filter, isLoading, isFetching, setFilter } =
    useFacilityFilter();
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  const [selectedFacilityGroup, setSelectedFacilityGroup] = useState<
    Facility[]
  >([]);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <FacilityFilters filter={filter} setFilter={setFilter} />,
      variant: "borderless",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacilityGroup(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacilityGroup;
        if (
          !detail.item ||
          !!associatedFacilities
            ?.map((associatedFacility) => associatedFacility?.id + "")
            ?.includes(detail.item.id + "")
        )
          return;
        if (temp?.includes(detail.item))
          setSelectedFacilityGroup(temp.filter((item) => item !== detail.item));
        else setSelectedFacilityGroup([detail.item, ...temp]);
      },
      selectedItems: selectedFacilityGroup,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "id", visible: true },
        { id: "name", visible: true },
        { id: "description", visible: true },
        { id: "npiNumber", visible: true },
        { id: "facilityType", visible: true },
        { id: "taxId", visible: true },
        { id: "cliaId", visible: true },
        { id: "immunizationId", visible: true },
        { id: "taxonomyCode", visible: true },
        { id: "addressLane1", visible: true },
        { id: "addressLane2", visible: true },
        { id: "city", visible: true },
        { id: "state", visible: true },
        { id: "zipcode", visible: true },
        { id: "country", visible: true },
      ],
      isItemDisabled: (item) => {
        return associatedFacilities
          ?.map((associatedFacility) => associatedFacility?.id + "")
          ?.includes(item?.id + "");
      },
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("facilityGroup.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>{t("facilityGroup.table.header.fields.name")}</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "description",
          header: (
            <div>{t("facilityGroup.table.header.fields.description")}</div>
          ),
          cell: (item) => <div>{item?.description ?? "-"}</div>,
        },
        {
          id: "npiNumber",
          header: <div>{t("facilityGroup.table.header.fields.npiNumber")}</div>,
          cell: (item) => <div>{item?.npiNumber ?? "-"}</div>,
        },
        {
          id: "facilityType",
          header: (
            <div>{t("facilityGroup.table.header.fields.facilityType")}</div>
          ),
          cell: (item) => <div>{item?.facilityType.name ?? "-"}</div>,
        },
        {
          id: "taxId",
          header: <div>{t("facilityGroup.table.header.fields.taxId")}</div>,
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "cliaId",
          header: <div>{t("facilityGroup.table.header.fields.CLIAID")}</div>,
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "immunizationId",
          header: (
            <div>{t("facilityGroup.table.header.fields.immunizationId")}</div>
          ),
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "taxonomyCode",
          header: (
            <div>{t("facilityGroup.table.header.fields.taxonomyCode")}</div>
          ),
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "addressLane1",
          header: (
            <div>{t("facilityGroup.table.header.fields.addressLane1")}</div>
          ),
          cell: (item) => (
            <div>{item?.facilityAddress?.addressLane1 ?? "-"}</div>
          ),
        },
        {
          id: "addressLane2",
          header: (
            <div>{t("facilityGroup.table.header.fields.addressLane2")}</div>
          ),
          cell: (item) => (
            <div>{item?.facilityAddress?.addressLane2 ?? "-"}</div>
          ),
        },
        {
          id: "city",
          header: <div>{t("facilityGroup.table.header.fields.city")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.city ?? "-"}</div>,
        },
        {
          id: "state",
          header: <div>{t("facilityGroup.table.header.fields.state")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.state ?? "-"}</div>,
        },
        {
          id: "zipcode",
          header: <div>{t("facilityGroup.table.header.fields.zipcode")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.zipcode ?? "-"}</div>,
        },
        {
          id: "country",
          header: <div>{t("facilityGroup.table.header.fields.country")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.country ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedFacilityGroup]);
  return (
    <Modal
      visible={isAssociatedFacilityModalOpen}
      size="max"
      header={
        <Header counter={`(${filteredFacilities.length})`}>
          {t("facilityGroup.table.header.label")}
        </Header>
      }
      onDismiss={() => {
        setIsAssociatedFacilityModalOpen(false);
      }}
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={() => {
                setSelectedFacilityGroup([]);
                setIsAssociatedFacilityModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                try {
                  if (!!selectedFacilityGroup && !!id) {
                    const facilityIdGroups =
                      selectedFacilityGroup?.map(
                        (selectedFacilityItem) => selectedFacilityItem?.id + "",
                      ) || [];

                    await addFacilitesToFacilityGroup({
                      facilityGroupId: id,
                      facilityIds: facilityIdGroups,
                    });

                    setAssociatedFacilities([
                      ...associatedFacilities,
                      ...selectedFacilityGroup,
                    ]);
                    setSelectedFacilityGroup([]);
                    setIsAssociatedFacilityModalOpen(false);
                  }
                } catch (error) {
                  console.error("Error updating admin facilityGroups:", error);
                  // Handle error (e.g., show a message to the user)
                  Sentry.captureException(error);
                }
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div>
        <LoadingScreen isOpen={isLoading} />
        <Table {...tableProps} />
      </div>
    </Modal>
  );
}
