import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setModalName } from "../../../../redux/UI/actions";
import { AffiliationType } from "../../../../redux/api/provider/section/types";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllAffiliationTypeQuery,
  useSaveAllAffiliationTypeMutation,
} from "../../../../redux/api/affiliationType/affiliation";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function ProviderAffiliationTypeModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllAffiliationTypeQuery();
  const { t } = useTranslation();
  const [type, setType] = useState<AffiliationType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllAffiliationType] = useSaveAllAffiliationTypeMutation();
  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...type].filter((item) => !!item.name);

    await notifyWrapper({
      promise: saveAllAffiliationType(tmp),
      resourceName: "Affiliation Type",
      actionName: "Save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setType(tmpItems);
  }, [data, fulfilledTimeStamp]);
  return (
    <Modal
      header={
        <Header>
          {t("providerProfiles.affiliations.affiliationType.header.label")}
        </Header>
      }
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t(
                "providerProfiles.affiliations.affiliationType.header.actions.cancel",
              )}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              {t(
                "providerProfiles.affiliations.affiliationType.header.actions.save",
              )}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setType([
                      ...type,
                      { id: "", name: "", description: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...type];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      description: item.description,
                      deleted: true,
                    };
                    setType(tmp);
                  }}
                  items={type.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "providerProfiles.affiliations.affiliationType.header.actions.addAffiliationType",
                  )}
                  definition={[
                    {
                      label: `${t("providerProfiles.affiliations.affiliationType.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...type];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              description: item.description,
                              deleted: item.deleted,
                            };
                            setType(tmp);
                          }}
                          placeholder={t(
                            "providerProfiles.affiliations.affiliationType.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },
                    {
                      label: `${t("providerProfiles.affiliations.affiliationType.header.fields.description.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.description}
                          onChange={(changeDetails) => {
                            const tmp = [...type];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: item.name,
                              description: changeDetails.detail.value,
                              deleted: item.deleted,
                            };
                            setType(tmp);
                          }}
                          placeholder={t(
                            "providerProfiles.affiliations.affiliationType.header.fields.description.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ProviderAffiliationTypeModal);
