import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { CredentialPackage } from "../redux/api/credentialpackages/types";
import { useGetAllCredentialPackagesQuery } from "../redux/api/credentialpackages/credentialpackages";

export type FilterType = {
  filterPackageName: string;
  packageTypeFilterOptions: OptionDefinition[];
  providerFilterOptions: OptionDefinition[];
  statusFilterOptions: OptionDefinition[];
  credentialTemplateFilterOptions: OptionDefinition[];
};

export default function useCredentialPackagesFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllCredentialPackagesQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterPackageName: "",
    packageTypeFilterOptions: [],
    providerFilterOptions: [],
    statusFilterOptions: [],
    credentialTemplateFilterOptions: [],
  });

  const [filteredCredentialPackages, setFilteredCredentialPackages] =
    useState<CredentialPackage[]>(data);

  useEffect(() => {
    setFilteredCredentialPackages(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_packages = [...data];
    if (filter.filterPackageName) {
      temp_filtered_packages = temp_filtered_packages.filter(
        (credentialpackage) =>
          credentialpackage?.name
            .toLowerCase()
            .includes(filter.filterPackageName.toLowerCase()),
      );
    }

    const selectedPackageTypeId = filter.packageTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedPackageTypeId && selectedPackageTypeId.length > 0) {
      temp_filtered_packages = temp_filtered_packages.filter(
        (credentialpackage) =>
          selectedPackageTypeId.indexOf(
            credentialpackage.packageType?.id + "",
          ) >= 0,
      );
    }
    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_packages = temp_filtered_packages.filter(
        (credentialpackage) =>
          selectedProviderId.indexOf(credentialpackage.provider?.id + "") >= 0,
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_packages = temp_filtered_packages.filter(
        (credentialpackage) =>
          selectedStatus.indexOf(credentialpackage?.packageStatus?.id + "") >=
          0,
      );
    }

    const selectedCredentialTemplate = filter.credentialTemplateFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedCredentialTemplate && selectedCredentialTemplate.length > 0) {
      temp_filtered_packages = temp_filtered_packages.filter(
        (credentialpackage) =>
          selectedCredentialTemplate.indexOf(
            credentialpackage?.credentialTemplate?.id + "",
          ) >= 0,
      );
    }

    setFilteredCredentialPackages(temp_filtered_packages);
  }, [filter]);

  return {
    filteredCredentialPackages,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
