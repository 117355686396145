import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllPayerQuery } from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";

export type FilterType = {
  filterPayerName: string;
  filterPayerID: string;
  stateFilterOptions: OptionDefinition[];
};

export default function usePayerFilter(items?: Payers[]) {
  const { data = [], isFetching, isLoading, refetch } = useGetAllPayerQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterPayerName: "",
    filterPayerID: "",
    stateFilterOptions: [],
  });

  const [filteredPayers, setFilteredPayers] = useState<Payers[]>(items ?? data);

  useEffect(() => {
    setFilteredPayers(items ?? data);
  }, [data, items]);

  useEffect(() => {
    let temp_filtered_payers = !!items ? [...items] : [...data];
    if (filter.filterPayerName) {
      temp_filtered_payers = temp_filtered_payers.filter((payer) =>
        payer?.name
          .toLowerCase()
          .includes(filter.filterPayerName.toLowerCase()),
      );
    }
    if (filter.filterPayerID) {
      temp_filtered_payers = temp_filtered_payers.filter((payer) =>
        payer?.payerId
          .toLowerCase()
          .includes(filter.filterPayerID.toLowerCase()),
      );
    }
    const selectedState = filter.stateFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedState && selectedState.length > 0) {
      temp_filtered_payers = temp_filtered_payers.filter(
        (payer) => selectedState.indexOf(payer?.payerAddress?.state + "") >= 0,
      );
    }
    setFilteredPayers(temp_filtered_payers);
  }, [filter]);

  return {
    filteredPayers,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
