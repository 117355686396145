import { api } from "..";
import { MemberType, UpdateMemberTypes } from "./types";

const ManageAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllMembers: build.query<MemberType[], void>({
      query: () => "admin/all",
      providesTags: ["ManageAdmin"],
    }),
    getMember: build.query<MemberType, { activeId: string }>({
      query: ({ activeId }) => `admin/${activeId}`,
      providesTags: ["ManageAdmin"],
    }),
    deleteAdmin: build.mutation<
      void,
      {
        adminId: string;
      }
    >({
      query: ({ adminId }) => ({
        url: `admin/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageAdmin"],
    }),
    updateMember: build.mutation<
      void,
      Pick<MemberType, "id"> & Partial<UpdateMemberTypes>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `invite/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ManageAdmin", id: arg.id },
        { type: "ManageAdmin" },
      ],
    }),
    updateMemberRole: build.mutation<void, { id: string; role_ids: string }>({
      query: ({ id, ...patch }) => {
        return {
          url: `admin/${id}/role`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ManageAdmin", id: arg.id },
        { type: "ManageAdmin" },
      ],
    }),
    deleteAdminFacility: build.mutation<
      void,
      { manageadminId: number; faciltiesToRemove: string[] }
    >({
      query: ({ manageadminId, faciltiesToRemove }) => ({
        url: `admin/${manageadminId}/facility`,
        method: "DELETE",
        body: faciltiesToRemove, // Wrap faciltiesToRemove in an object
      }),
      invalidatesTags: ["ManageAdmin"],
    }),
    updateAdminFacility: build.mutation<
      void,
      { manageadminId: number; faciltiesToupdate: string[] }
    >({
      query: ({ manageadminId, faciltiesToupdate }) => ({
        url: `admin/${manageadminId}/facility`,
        method: "PUT",
        body: faciltiesToupdate, // Wrap faciltiesToRemove in an object
      }),
      invalidatesTags: ["ManageAdmin"],
    }),
  }),
});

export const {
  useUpdateMemberMutation,
  useGetAllMembersQuery,
  useGetMemberQuery,
  useDeleteAdminMutation,
  useUpdateMemberRoleMutation,
  useDeleteAdminFacilityMutation,
  useUpdateAdminFacilityMutation,
} = ManageAdminApi;
