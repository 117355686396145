import FacilityFilters from "../../components/filter/facility-filters";
import LoadingScreen from "../../components/LoadingScreen";
import useFacilityFilter from "../../hooks/useFacilityFilter";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Facility } from "../../redux/api/facility/types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import AssociatedFacilitiesModals from "./associated-facilities-modal";
import { useDeleteAdminFacilityMutation } from "../../redux/api/manageadmin/manageadmin";

export default function AssoicatedFacilitiesTable({
  associatedFacilities,
  setAssociatedFacilities,
}: {
  associatedFacilities: Facility[];
  setAssociatedFacilities: Dispatch<SetStateAction<Facility[]>>;
}) {
  const [isAssociatedFacilitiesModalOpen, setAssociatedFacilitiesModalOpen] =
    useState<boolean>(false);
  const { manageadminId } = useParams();

  const { t } = useTranslation();
  const [deleteAdminFacility] = useDeleteAdminFacilityMutation();
  const { filteredFacilities, filter, isLoading, isFetching, setFilter } =
    useFacilityFilter(associatedFacilities);
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Associated Facilities"}
            description="No facilities associated."
            actionLabel="Associate Facility"
            action={() => {
              setAssociatedFacilitiesModalOpen(true);
            }}
          />
        ),
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredFacilities.length})`}
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <Button
                onClick={async () => {
                  try {
                    const faciltiesToRemove =
                      selectedFacilities?.map((selectedFacility) =>
                        selectedFacility?.id.toString(),
                      ) || [];

                    const manageadminIdNum = parseInt(manageadminId || "", 10);

                    if (isNaN(manageadminIdNum)) {
                      throw new Error("Invalid manageadminId");
                    }

                    await deleteAdminFacility({
                      manageadminId: manageadminIdNum,
                      faciltiesToRemove,
                    });

                    const temp = associatedFacilities.filter(
                      (facility) =>
                        !faciltiesToRemove.includes(facility?.id.toString()),
                    );
                    setAssociatedFacilities(temp);
                    setSelectedFacilities([]);
                  } catch (error) {
                    console.error("Error deleting admin facility:", error);
                    // Handle error (e.g., show a message to the user)
                  }
                }}
              >
                Remove
              </Button>

              <Button
                onClick={() => {
                  setAssociatedFacilitiesModalOpen(true);
                }}
                variant="primary"
                data-test="add_facilify"
              >
                Associate Facility
              </Button>
            </SpaceBetween>
          }
        >
          Associated Facilities
        </Header>
      ),
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <FacilityFilters filter={filter} setFilter={setFilter} />,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: true,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacilities(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacilities;
        if (!detail.item) return;
        if (temp?.includes(detail.item))
          setSelectedFacilities(temp.filter((item) => item !== detail.item));
        else setSelectedFacilities([detail.item, ...temp]);
      },
      selectedItems: selectedFacilities,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "npiNumber", visible: true },
        { id: "facilityType", visible: true },
      ],
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("facility.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>{t("facility.table.header.fields.name")}</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "npiNumber",
          header: <div>{t("facility.table.header.fields.npiNumber")}</div>,
          cell: (item) => <span>{item.npiNumber}</span>,
        },
        {
          id: "facilityType",
          header: <div>{t("facility.table.header.fields.facilityType")}</div>,
          cell: (item) => <span>{item?.facilityType?.name}</span>,
        },
      ],
    };
  }, [items, selectedFacilities]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <AssociatedFacilitiesModals
        associatedFacilities={associatedFacilities}
        isAssociatedFacilitiesModalOpen={isAssociatedFacilitiesModalOpen}
        setAssociatedFacilities={setAssociatedFacilities}
        setAssociatedFacilitiesModalOpen={setAssociatedFacilitiesModalOpen}
      />
    </div>
  );
}
