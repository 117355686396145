import { api } from "..";
import { AllDocumentResponse } from "../document/types";
import { PayerEnrollment } from "../payerenrollment/types";
import { ProviderAffiliationWithProvider } from "../provider/section/types";
import { Provider } from "../provider/types";
import { ProviderDocuments } from "./types";

const reportingAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDocuments: build.query<AllDocumentResponse[], void>({
      query: () => `reporting/documents`,
      providesTags: ["Reporting"],
    }),
    getPayerEnrollmentsReport: build.query<PayerEnrollment[], void>({
      query: () => `reporting/payerenrollment`,
      providesTags: ["Reporting"],
    }),
    getProviderAffiliationReport: build.query<
      ProviderAffiliationWithProvider[],
      void
    >({
      query: () => `reporting/providersaffiliations`,
      providesTags: ["Reporting"],
    }),
    getAllExpiredDocuments: build.query<ProviderDocuments[], void>({
      query: () => `reporting/documents/expired`,
      providesTags: ["Reporting"],
    }),
    getAllExpiringDocuments: build.query<
      ProviderDocuments[],
      { days?: string }
    >({
      query: ({ days }) =>
        !!days
          ? `reporting/documents/expiring?days=${days}`
          : `reporting/documents/expiring`,
      providesTags: ["Reporting"],
    }),
    getAllProvidersReport: build.query<Provider[], void>({
      query: () => `reporting/providers/all`,
      providesTags: ["Reporting"],
    }),
  }),
});
export const {
  useGetPayerEnrollmentsReportQuery,
  useGetProviderAffiliationReportQuery,
  useGetAllDocumentsQuery,
  useGetAllExpiredDocumentsQuery,
  useGetAllExpiringDocumentsQuery,
  useGetAllProvidersReportQuery,
} = reportingAPI;
