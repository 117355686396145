import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllPayerEnrollmentsQuery } from "../redux/api/payerenrollment/payerenrollment";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";

export type FilterType = {
  filterPayer: string;
  providerFilterOptions: OptionDefinition[];
  facilityFilterOptions: OptionDefinition[];
  statusFilterOptions: OptionDefinition[];
};

export default function usePayerEnrollmentFilter() {
  const { id: payerId } = useParams();
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllPayerEnrollmentsQuery(payerId ? { payerId } : skipToken);

  const [filter, setFilter] = useState<FilterType>({
    filterPayer: "",
    providerFilterOptions: [],
    facilityFilterOptions: [],
    statusFilterOptions: [],
  });

  const [filteredPayers, setFilteredPayers] = useState<PayerEnrollment[]>(data);

  useEffect(() => {
    setFilteredPayers(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_payers = [...data];

    if (filter.filterPayer) {
      temp_filtered_payers = temp_filtered_payers.filter((payer) =>
        payer.payer.name
          ?.toLowerCase()
          .includes(filter.filterPayer.toLowerCase()),
      );
    }
    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_payers = temp_filtered_payers.filter(
        (payer) => selectedProviderId.indexOf(payer.provider?.id + "") >= 0,
      );
    }

    const selectedFaciltiesId = filter.facilityFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesId && selectedFaciltiesId.length > 0) {
      temp_filtered_payers = temp_filtered_payers.filter(
        (payer) => selectedFaciltiesId.indexOf(payer.facility?.id + "") >= 0,
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_payers = temp_filtered_payers.filter(
        (payer) => selectedStatus.indexOf(payer?.status?.id + "") >= 0,
      );
    }
    setFilteredPayers(temp_filtered_payers);
  }, [filter]);

  return {
    filteredPayers,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
