import { Divider } from "@mui/material";

import {
  Container,
  Box,
  Header,
  TextFilter,
  SpaceBetween,
  Select,
} from "@cloudscape-design/components";

import { FieldType } from "./types";
import CollapsibleList from "./section-collapsible-list";
import { SectionFilterType } from "../hooks/useSectionFilter";
import {
  DIGITALFORM_FIELDS_Type,
  PROVIDER_DIGITALFORM_FIELDS,
  STANDARD_DIGITALFORM_FIELDS,
} from "../helpers/df_constants";

interface TemplateDesignerLeftPanelProps {
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>, field: FieldType) => void;
}
interface FilterProps {
  filteredFields: DIGITALFORM_FIELDS_Type[];
  filter: SectionFilterType;
  setFilter: React.Dispatch<React.SetStateAction<SectionFilterType>>;
}

export default function TemplateDesignerTagsList({
  onDragStart,
  onDragEnd,
  filteredFields,
  filter,
  setFilter,
}: TemplateDesignerLeftPanelProps & FilterProps) {
  return (
    <Container
      header={
        <>
          <Box padding={{ top: "s", left: "s", right: "s", bottom: "m" }}>
            <SpaceBetween size={"s"}>
              <Header>Fields</Header>
              <TextFilter
                filteringText={filter.filterText}
                onChange={(changeDetails) => {
                  setFilter({
                    ...filter,
                    filterText: changeDetails.detail.filteringText,
                  });
                }}
                filteringPlaceholder={"search by field name"}
              ></TextFilter>
            </SpaceBetween>
          </Box>
          <Divider />
        </>
      }
      disableHeaderPaddings
    >
      <div
        style={{
          padding: 0,
          overflow: "auto",
          height: `calc(100vh - 250px)`,
        }}
      >
        <SpaceBetween size={"xxs"} direction="vertical">
          {filteredFields.map((field) => {
            return (
              <CollapsibleList
                field={field}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
              />
            );
          })}
        </SpaceBetween>
      </div>
    </Container>
  );
}
