// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import Header from "@cloudscape-design/components/header";
import { Box, ColumnLayout, Link } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";
import LoadingScreen from "../../../components/LoadingScreen";
import { useTranslation } from "react-i18next";

export const serviceOverview: WidgetConfig = {
  title: "Service overview",
  description: "Overview of all your resources",
  header: ServiceOverviewHeader,
  content: ServiceOverviewWidget,
};

function ServiceOverviewHeader() {
  const { t } = useTranslation();
  return (
    <Header variant="h2">{t("dashboard.serviceOverview.header.label")}</Header>
  );
}

function ServiceOverviewWidget() {
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isError } = useGetAllDashboardQuery();

  //const { overview } = dashboardData;
  return (
    <>
      <LoadingScreen isOpen={isLoading} />

      <ColumnLayout columns={4} variant="text-grid" minColumnWidth={170}>
        <div>
          <Box variant="awsui-key-label">
            {t("dashboard.serviceOverview.content.fields.activeProviders")}
          </Box>
          <Link variant="awsui-value-large" href="/manageprovider">
            {data?.overview.totalActiveProviders}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">
            {t("dashboard.serviceOverview.content.fields.documentsPerProvider")}
          </Box>
          <Link variant="awsui-value-large" href="/safe">
            {data?.overview.documentsPerProvider}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">
            {t("dashboard.serviceOverview.content.fields.documentsExpiring")}
          </Box>
          <Link variant="awsui-value-large" href="/expiringDocuments">
            {data?.overview.totalExpiringDocuments}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">
            {t("dashboard.serviceOverview.content.fields.documentsExpired")}
          </Box>
          <Link variant="awsui-value-large" href="/expiredDocuments">
            {data?.overview.totalExpiredDocuments}
          </Link>
        </div>
      </ColumnLayout>
    </>
  );
}
