import MUIButton, { ButtonProps } from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridSortedRowIdsSelector,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";

export default function CustomToolbar({ fileName }: { fileName: string }) {
  const apiRef = useGridApiContext();

  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps: ButtonProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer>
      <MUIButton
        {...buttonBaseProps}
        onClick={() =>
          handleExport({
            getRowsToExport: getUnfilteredRows,
            fileName: fileName,
          })
        }
      >
        Export to CSV
      </MUIButton>
    </GridToolbarContainer>
  );
}

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  "SaveAlt",
);

const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridSortedRowIdsSelector(apiRef);
