import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Icon,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteProviderEducationMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useState, useMemo } from "react";
import { ProviderEducationType } from "../../../../redux/api/provider/section/types";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import {
  EDUCATION_CONTENT_DISPLAY_OPTIONS,
  EDUCATION_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { TableEmptyState } from "../../../../common/common-components";
import { useLocalStorage } from "../../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import ChangeTimeZone from "../../../../components/Timezone";
import { AsYouType } from "libphonenumber-js";

export default function Education() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] = useState<ProviderEducationType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteProviderEducation] = useDeleteProviderEducationMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Education-Table-Preferences",
    EDUCATION_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerEducations ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("providerProfiles.education.table.header.label")}
            action={() => {
              navigate(`editeducation`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
    },
  );

  const tableProps: TableProps<ProviderEducationType> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>{t("providerProfiles.education.table.header.fields.id")}</div>
          ),
          cell: (item) => item.id || "-",
          sortingField: "name",
          isRowHeader: true,
        },
        {
          id: "name",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.name")}
            </div>
          ),
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editeducation/${item.id}` : `#`}
              label={item?.name}
            />
          ),
        },
        {
          id: "type",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.type")}
            </div>
          ),
          cell: (item) => item.type || "-",
          sortingField: "alt",
        },
        {
          id: "startDate",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.startDate")}
            </div>
          ),
          cell: (item) => <div> {ChangeTimeZone(item.startDate) ?? "-"} </div>,
        },
        {
          id: "endDate",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.endDate")}
            </div>
          ),
          cell: (item) => <div> {ChangeTimeZone(item.endDate) ?? "-"} </div>,
        },
        {
          id: "degree",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.degree")}
            </div>
          ),
          cell: (item) => <div> {item?.degree?.name ?? "-"} </div>,
        },
        {
          id: "email",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.email")}
            </div>
          ),
          cell: (item) => <div> {item.email ?? "-"} </div>,
        },
        {
          id: "phone",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.phone")}
            </div>
          ),
          cell: (item) => (
            <div> {new AsYouType("US").input(item?.phone ?? "") ?? "-"} </div>
          ),
        },
        {
          id: "fax",
          header: (
            <div>{t("providerProfiles.education.table.header.fields.fax")}</div>
          ),
          cell: (item) => <div> {item.fax ?? "-"} </div>,
        },
        {
          id: "addressLane1",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.addressLane1")}
            </div>
          ),
          cell: (item) => <div> {item.addressLane1 ?? "-"} </div>,
        },
        {
          id: "addressLane2",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.addressLane2")}
            </div>
          ),
          cell: (item) => <div> {item.addressLane2 ?? "-"} </div>,
        },
        {
          id: "city",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.city")}
            </div>
          ),
          cell: (item) => <div> {item.city ?? "-"} </div>,
        },
        {
          id: "state",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.state")}
            </div>
          ),
          cell: (item) => <div> {item.state ?? "-"} </div>,
        },
        {
          id: "zip",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.zipcode")}
            </div>
          ),
          cell: (item) => <div> {item.zip ?? "-"} </div>,
        },
        {
          id: "country",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.country")}
            </div>
          ),
          cell: (item) => <div> {item.country ?? "-"} </div>,
        },
        {
          id: "residencyDirector",
          header: (
            <div>
              {t(
                "providerProfiles.education.table.header.fields.residencyDirector",
              )}
            </div>
          ),
          cell: (item) => <div> {item.residencyDirector ?? "-"} </div>,
        },
        {
          id: "fellowshipDirector",
          header: (
            <div>
              {t(
                "providerProfiles.education.table.header.fields.fellowshipDirector",
              )}
            </div>
          ),
          cell: (item) => <div> {item.fellowshipDirector ?? "-"} </div>,
        },
        {
          id: "documentName",
          header: (
            <div>
              {t("providerProfiles.education.table.header.fields.documentName")}
            </div>
          ),
          cell: (item) =>
            item?.document?.name ? (
              <Link
                external
                variant="secondary"
                href={
                  hasPermission && item?.document?.id
                    ? `/safe/${providerId}/documents/${item?.document?.id}/edit`
                    : `#`
                }
              >
                {item.document?.name}
              </Link>
            ) : (
              <>-</>
            ),
        },
      ],
      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  {
                    text: `${t("providerProfiles.education.table.header.actions.edit")}`,
                    id: "edit",
                    disabled: false,
                  },
                  {
                    text: `${t("providerProfiles.education.table.header.actions.delete")}`,
                    id: "delete",
                    disabled: false,
                  },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editeducation/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                {t(
                  "providerProfiles.education.table.header.actions.educationActions",
                )}
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editeducation`);
                }}
              >
                {t("providerProfiles.education.table.header.actions.addNew")}
              </Button>
            </SpaceBetween>
          }
        >
          {t("providerProfiles.education.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Education Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={EDUCATION_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      stickyHeader: true,
      variant: "container",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
    };
  }, [tablePreferences, items, selectedItem, provider]);
  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderEducation({
                providerId: providerId,
                educationId: selectedItem.id.toString(),
              }),
              resourceName: "education",
              actionName: "delete",
            });

          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerProfiles.education.delete.header.label")}
        content={
          <>
            <Box>
              {t("providerProfiles.education.delete.header.content.label")}
            </Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t(
          "providerProfiles.education.delete.header.content.description",
        )}
      />
    </div>
  );
}
