import { useEffect, useState } from "react";
import { PayerGroup } from "../redux/api/payergroup/types";
import { useGetAllPayerGroupQuery } from "../redux/api/payergroup/payergroup";

export type FilterType = {
  filterPayerGroupName: string;
  filterPayerGroupID: string;
};

export default function usePayerGroupFilter(items?: PayerGroup[]) {
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useGetAllPayerGroupQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterPayerGroupName: "",
    filterPayerGroupID: "",
  });

  const [filteredPayerGroup, setFilteredPayerGroup] = useState<PayerGroup[]>(
    items ?? data,
  );

  useEffect(() => {
    setFilteredPayerGroup(items ?? data);
  }, [data, items]);

  useEffect(() => {
    let temp_filtered_payer_group = !!items ? [...items] : [...data];
    if (filter.filterPayerGroupName) {
      temp_filtered_payer_group = temp_filtered_payer_group.filter(
        (payergroup) =>
          payergroup?.name
            .toLowerCase()
            .includes(filter.filterPayerGroupName.toLowerCase()),
      );
    }
    if (filter.filterPayerGroupID) {
      temp_filtered_payer_group = temp_filtered_payer_group.filter(
        (payergroup) =>
          (payergroup?.groupCode + "")?.includes(
            filter.filterPayerGroupID + "",
          ),
      );
    }

    setFilteredPayerGroup(temp_filtered_payer_group);
  }, [filter]);

  return {
    filteredPayerGroup,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
