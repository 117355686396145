import { FormProvider, useForm } from "react-hook-form";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  FormField,
  Header,
  SpaceBetween,
  Tiles,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  useCreatePayerEnrollmentMutation,
  useGetAllPayerEnrollmentStatusQuery,
} from "../../redux/api/payerenrollment/payerenrollment";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  PAYER_ENROLLMENT_STATUS,
  alertReminderOptions,
} from "../../helpers/constants";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import WorkflowSection from "../../payers/modify/work-flow-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import RHFTextField from "../../components/RHF/RHFTextField";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddPayerEnrollment({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PayerEnrollmentRequest>();
  const { t } = useTranslation();
  const { id: payerId } = useParams();
  const [enrollmentType, setEnrollmentType] = useState<string>("provider");
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: allpayers = [] } = useGetAllPayerQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { data: facility = [] } = useGetFacilitiesQuery();

  const [selectedWorkflow, setselectedWorkflow] = useState<OptionDefinition>({
    label: "None - no workflow selected ",
    value: "-1",
  });

  const onSubmit = async (data: PayerEnrollmentRequest) => {
    if (!!payerId) {
      await notifyWrapper({
        promise: addPayerEnrollment({
          ...data,
          workflowId:
            selectedWorkflow.value !== "-1" ? selectedWorkflow.value : "",
        }),
        resourceName: "payerenrollment",
        actionName: "add",
      });
      navigate(-1);
    }
  };
  useEffect(() => {
    if (enrollmentType === "provider") {
      methods.setValue("facilityId", null);
    } else if (enrollmentType === "facility") {
      methods.setValue("providerId", null);
    }
  }, [enrollmentType]);

  useEffect(() => {
    if (payerId) {
      const temp = allpayers?.find((payer) => "" + payer?.id === payerId + "");
      if (temp) methods.setValue("payerId", temp?.id);
    }
  }, [payerId]);

  const [addPayerEnrollment] = useCreatePayerEnrollmentMutation();
  return (
    <div>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("payerenrollments.addPayerEnrollment.header.breadcrumb.text")}`,
              href: `/payerenrollment`,
            },

            {
              text: `${t("payerenrollments.addPayerEnrollment.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t("payerenrollments.addPayerEnrollment.header.label")}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "payerenrollments.addPayerEnrollment.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "payerenrollments.addPayerEnrollment.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="xl">
                    <FormField label="Enrollment Type" stretch={true}>
                      <Tiles
                        onChange={({ detail }) => {
                          setEnrollmentType(detail.value);
                        }}
                        columns={2}
                        value={enrollmentType}
                        items={[
                          { label: "Provider", value: "provider" },
                          { label: "Facility", value: "facility" },
                        ]}
                      />
                    </FormField>
                    <SpaceBetween size="m">
                      <FormSection columns={4}>
                        <RHFSelect
                          name="payerId"
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.payer",
                          )}
                          options={allpayers.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          stretch={false}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFSelect
                          name="payerGroupId"
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.payergroup",
                          )}
                          options={allpayers
                            ?.find((payer) => "" + payer?.id === payerId + "")
                            ?.payerGroup?.map((temp) => ({
                              label: temp.name,
                              value: "" + temp.id,
                            }))}
                          stretch={false}
                        />
                        {enrollmentType === "provider" && (
                          <RHFSelect
                            label={t(
                              "payerenrollments.addPayerEnrollment.header.fields.provider",
                            )}
                            rules={{ required: "This field is required" }}
                            name={"providerId"}
                            stretch={false}
                            filteringType="auto"
                            options={provider.map((temp) => ({
                              label: temp.firstName + " " + temp.lastName,
                              value: "" + temp.id,
                              tags: temp?.providerLicenses?.map(
                                (type) => type.licenseType,
                              ),
                            }))}
                          />
                        )}
                        <RHFSelect
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.facility",
                          )}
                          name={"facilityId"}
                          stretch={false}
                          filteringType="auto"
                          options={facility.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          rules={
                            enrollmentType === "facility"
                              ? {
                                  required: "This field is required",
                                }
                              : undefined
                          }
                        />
                        <RHFFloatingSelect
                          name="enrollmentStatusId"
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.status.label",
                          )}
                          options={status.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          stretch={false}
                          action={{
                            label: `${t("payerenrollments.addPayerEnrollment.header.fields.status.actions.modify")}`,
                            onClick: () => {
                              setModalName(PAYER_ENROLLMENT_STATUS);
                            },
                          }}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.submittedDate",
                          )}
                          name={"submittedDate"}
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.approvedDate",
                          )}
                          name="approvedDate"
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.effectiveDate",
                          )}
                          name="effectiveDate"
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.recredentialingDate",
                          )}
                          name={"recredentialingDate"}
                          stretch={false}
                        />
                        <RHFSelect
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.followupDate",
                          )}
                          name={"alertDays"}
                          stretch={false}
                          options={alertReminderOptions}
                        />
                        <RHFTextField
                          label={t(
                            "payerenrollments.addPayerEnrollment.header.fields.payerProviderId",
                          )}
                          name="payerProviderId"
                          stretch
                        />
                      </FormSection>
                      <WorkflowSection
                        selectedWorkflow={selectedWorkflow}
                        setselectedWorkflow={setselectedWorkflow}
                      />
                      <ExpandableSection
                        headerText={t(
                          "payerenrollments.addPayerEnrollment.header.headerText",
                        )}
                      >
                        <FormSection>
                          <RHFTextArea
                            label={t(
                              "payerenrollments.addPayerEnrollment.header.fields.description",
                            )}
                            name="description"
                            stretch={false}
                          />
                        </FormSection>
                      </ExpandableSection>
                    </SpaceBetween>
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddPayerEnrollment);
