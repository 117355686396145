import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useMemo } from "react";
import { ProviderLicenseType } from "../../../../redux/api/provider/section/types";
import {
  useDeleteProviderLicenseMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  LICENSE_CONTENT_DISPLAY_OPTIONS,
  LICENSE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { TableEmptyState } from "../../../../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import ChangeTimeZone from "../../../../components/Timezone";

export default function License() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] = useState<ProviderLicenseType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteProviderLicense] = useDeleteProviderLicenseMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-License-Table-Preferences",
    LICENSE_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerLicenses ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("providerProfiles.license.table.header.label")}
            action={() => {
              navigate(`editlicense`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
    },
  );

  const tableProps: TableProps<ProviderLicenseType> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>{t("providerProfiles.license.table.header.fields.id")}</div>
          ),
          cell: (item) => item.id || "-",
          sortingField: "id",
          isRowHeader: true,
        },
        {
          id: "licenseType",
          header: (
            <div>
              {t("providerProfiles.license.table.header.fields.licenseType")}
            </div>
          ),
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editlicense/${item.id}` : `#`}
              label={item?.licenseType}
            />
          ),
          sortingField: "alt",
        },
        {
          id: "licenseNumber",
          header: (
            <div>
              {t("providerProfiles.license.table.header.fields.licenseNumber")}
            </div>
          ),
          cell: (item) => item.licenseNumber || "-",
        },
        {
          id: "state",
          header: (
            <div>{t("providerProfiles.license.table.header.fields.state")}</div>
          ),
          cell: (item) => <div> {item.state ?? "-"} </div>,
        },
        {
          id: "startDate",
          header: (
            <div>
              {t("providerProfiles.license.table.header.fields.startDate")}
            </div>
          ),
          cell: (item) => <div> {ChangeTimeZone(item.startDate) ?? "-"} </div>,
        },
        {
          id: "expirationDate",
          header: (
            <div>
              {t("providerProfiles.license.table.header.fields.expirationDate")}
            </div>
          ),
          cell: (item) => <div> {ChangeTimeZone(item.endDate) ?? "-"} </div>,
        },
        {
          id: "documentName",
          header: (
            <div>
              {t("providerProfiles.license.table.header.fields.documentName")}
            </div>
          ),
          cell: (item) =>
            item?.document?.name ? (
              <Link
                external
                href={
                  hasPermission && item?.document?.id
                    ? `/safe/${providerId}/documents/${item?.document?.id}/edit`
                    : `#`
                }
              >
                {item.document?.name}
              </Link>
            ) : (
              <>-</>
            ),
        },
        {
          id: "notes",
          header: <div>Notes</div>,
          cell: (item) => <span>{item?.notes}</span>,
        },
      ],
      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  {
                    text: `${t("providerProfiles.license.table.header.actions.edit")}`,
                    id: "edit",
                    disabled: false,
                  },
                  {
                    text: `${t("providerProfiles.license.table.header.actions.delete")}`,
                    id: "delete",
                    disabled: false,
                  },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editlicense/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                {t(
                  "providerProfiles.license.table.header.actions.licenseActions",
                )}
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editlicense`);
                }}
              >
                {t("providerProfiles.license.table.header.actions.addNew")}
              </Button>
            </SpaceBetween>
          }
        >
          {t("providerProfiles.license.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="License Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={LICENSE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
    };
  }, [tablePreferences, items, selectedItem, provider]);

  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderLicense({
                providerId: providerId,
                licenseId: selectedItem.id.toString(),
              }),
              resourceName: "license",
              actionName: "delete",
            });

          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerProfiles.license.delete.header.label")}
        content={
          <>
            <Box>
              {t("providerProfiles.license.delete.header.content.label")}
            </Box>
            <Box variant="awsui-key-label">{selectedItem?.licenseNumber}</Box>
          </>
        }
        description={t(
          "providerProfiles.license.delete.header.content.description",
        )}
      />
    </div>
  );
}
