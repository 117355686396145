import { SetStateAction, useMemo } from "react";
import {
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
} from "../../redux/api/workflow/workflow";
import {
  WorkFlowTaskRequest,
  WorkflowRequest,
} from "../../redux/api/workflow/types";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { Divider } from "antd";
import ChangeTimeZone from "../../components/Timezone";

export default function ReviewWorkflow({
  setActiveStepIndex,
  workflowTasks,
}: {
  setActiveStepIndex: (value: SetStateAction<number>) => void;
  workflowTasks: WorkFlowTaskRequest[];
}) {
  const { data: workflowStatus = [] } = useGetAllWorkflowStatusQuery();
  const { data: workflowTemplates = [] } = useGetAllWorkflowTemplateQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const methods = useFormContext<WorkflowRequest>();

  const assignedTo = useMemo(() => {
    if (!!methods.getValues("adminId"))
      return members?.find(
        (member) => member?.id + "" === methods.getValues("adminId") + "",
      );
  }, [methods.getValues("adminId")]);

  const assignedProvider = useMemo(() => {
    if (!!methods.getValues("providerId"))
      return providers?.find(
        (provider) =>
          provider?.id + "" === methods.getValues("providerId") + "",
      );
  }, [methods.getValues("providerId")]);

  const assignedFacility = useMemo(() => {
    if (!!methods.getValues("facilityId"))
      return facilities?.find(
        (facility) =>
          facility?.id + "" === methods.getValues("facilityId") + "",
      );
  }, [methods.getValues("facilityId")]);

  return (
    <SpaceBetween size="m">
      <SpaceBetween size="xs">
        <Header
          variant="h3"
          actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}
        >
          Details
        </Header>
        <Container>
          <ColumnLayout columns={3} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Workflow Name</Box>
              <div>{methods.getValues("name")}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Workflow Status</Box>
              <div>
                {workflowStatus?.find(
                  (status) =>
                    status?.id + "" ===
                    methods.getValues("workflowStatusId") + "",
                )?.name ?? "-"}
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Workflow Template</Box>
              <div>
                {workflowTemplates?.find(
                  (template) =>
                    template?.id + "" ===
                    methods.getValues("workflowTemplateId") + "",
                )?.name ?? "-"}
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Notes</Box>
              <div>{methods.getValues("notes") ?? "-"}</div>
            </div>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
      <Divider style={{ padding: 0, margin: 0 }} />
      <SpaceBetween size="xs">
        <Header
          variant="h3"
          actions={<Button onClick={() => setActiveStepIndex(1)}>Edit</Button>}
        >
          Assigned To
        </Header>
        <Container>
          <ColumnLayout columns={3} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Assigned To</Box>
              <div>
                {!!assignedTo
                  ? assignedTo?.firstName + " " + assignedTo?.lastName
                  : "-"}
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Provider</Box>
              <div>
                {!!assignedProvider
                  ? assignedProvider?.firstName +
                    " " +
                    assignedProvider?.lastName
                  : "-"}
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Facility</Box>
              <div>{!!assignedFacility && assignedFacility?.name + " "}</div>
            </div>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
      <Divider style={{ padding: 0, margin: 0 }} />
      <SpaceBetween size="xs">
        <Header
          variant="h3"
          actions={<Button onClick={() => setActiveStepIndex(2)}>Edit</Button>}
        >
          Start and Due Date
        </Header>
        <Container>
          <ColumnLayout columns={2} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Start Date</Box>
              <div>{ChangeTimeZone(methods.getValues("startDate")) ?? "-"}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Due Date</Box>
              <div>{ChangeTimeZone(methods.getValues("dueDate")) ?? "-"}</div>
            </div>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
      <Divider style={{ padding: 0, margin: 0 }} />
      <SpaceBetween size="xs">
        <Header
          variant="h3"
          actions={<Button onClick={() => setActiveStepIndex(3)}>Edit</Button>}
        >
          Tasks
        </Header>
        <Table
          columnDefinitions={[
            {
              id: "tasktype",
              header: "Task Type",
              cell: (item) =>
                taskTypes?.find(
                  (taskType) =>
                    taskType.id?.toString() === item?.taskTypeId?.toString(),
                )?.name || "-",
            },
            {
              id: "name",
              header: "Name",
              cell: (item) => item.name || "-",
            },
            {
              id: "taskstatus",
              header: "Task Status",
              cell: (item) =>
                taskstatuses?.find(
                  (taskstatus) =>
                    taskstatus.id?.toString() ===
                    item?.taskStatusId?.toString(),
                )?.name || "-",
            },
            {
              id: "assignedto",
              header: "Assign to",
              cell: (item) =>
                !item?.adminId
                  ? "-"
                  : members?.find(
                      (member) =>
                        member.id?.toString() === item?.adminId?.toString(),
                    )?.firstName ||
                    "" +
                      " " +
                      members?.find(
                        (member) =>
                          member.id?.toString() === item?.adminId?.toString(),
                      )?.lastName ||
                    "",
            },
            {
              id: "provider",
              header: "Provider",
              cell: (item) =>
                !item?.providerId
                  ? "-"
                  : (providers?.find(
                      (provider) =>
                        provider.id?.toString() ===
                        item?.providerId?.toString(),
                    )?.firstName ?? "") +
                    " " +
                    (providers?.find(
                      (provider) =>
                        provider.id?.toString() ===
                        item?.providerId?.toString(),
                    )?.lastName ?? ""),
            },
            {
              id: "startDate",
              header: "Start Date",
              cell: (item) => ChangeTimeZone(item.startDate) || "-",
            },
            {
              id: "dueDate",
              header: "Due Date",
              cell: (item) => ChangeTimeZone(item.dueDate) || "-",
            },
          ]}
          items={workflowTasks}
          loadingText="Loading resources"
          sortingDisabled
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button>Create resource</Button>
              </SpaceBetween>
            </Box>
          }
        />
      </SpaceBetween>
    </SpaceBetween>
  );
}
