import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";
import { useSaveFaciltyGroupMutation } from "../../redux/api/facilitygroup/facilityGroup";

type FormInputProps = {
  name: string;
  description: string;
};

export default function AddFacilityGroup() {
  const methods = useForm<FormInputProps>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addFaciltyGroup] = useSaveFaciltyGroupMutation();
  const onSubmit = async (data: FormInputProps) => {
    await notifyWrapper({
      promise: addFaciltyGroup({
        name: data?.name,
        description: data?.description,
      }),
      resourceName: "facilitygroup",
      actionName: "add",
      callback: (id) => {
        if (!!id) navigate(`/facilitygroup/${id}`);
      },
    });
  };

  return (
    <>
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("facilityGroup.addFacilityGroup.header.breadcrumb.text")}`,
                href: `/facilitygroup`,
              },

              {
                text: `${t("facilityGroup.addFacilityGroup.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <SpaceBetween size="m">
            <Container
              header={
                <SpaceBetween size="xxs">
                  <Header>
                    {t("facilityGroup.addFacilityGroup.header.label")}
                  </Header>
                </SpaceBetween>
              }
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate("/facilitygroup");
                          }}
                          formAction="none"
                        >
                          {t(
                            "facilityGroup.addFacilityGroup.header.actions.cancel",
                          )}
                        </Button>
                        <Button formAction="submit" variant="primary">
                          Next
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={2}>
                      <RHFTextField
                        label="Name"
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />

                      <RHFTextField
                        label="Description"
                        name="description"
                        stretch={false}
                      />
                    </FormSection>
                  </Form>
                </form>
              </FormProvider>
            </Container>
          </SpaceBetween>
        }
      />
    </>
  );
}
