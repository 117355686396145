import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { Task } from "../redux/api/task/types";
import { useGetMyTasksQuery } from "../redux/api/task";

export type FilterType = {
  filtertaskName: string;
  taskTypeFilterOptions: OptionDefinition[];
  providerFilterOptions: OptionDefinition[];
  statusFilterOptions: OptionDefinition[];
  workflowFilterOptions: OptionDefinition[];
};

export default function useMyTaskFilter() {
  const { data = [], isLoading, isFetching, refetch } = useGetMyTasksQuery();

  const [filter, setFilter] = useState<FilterType>({
    filtertaskName: "",
    taskTypeFilterOptions: [],
    providerFilterOptions: [],
    statusFilterOptions: [],
    workflowFilterOptions: [],
  });

  const [filteredTask, setFilteredTask] = useState<Task[]>(data);

  useEffect(() => {
    setFilteredTask(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_tasks = [...data];
    if (filter.filtertaskName) {
      temp_filtered_tasks = temp_filtered_tasks.filter((task) =>
        task?.name
          ?.toLowerCase()
          .includes(filter?.filtertaskName?.toLowerCase()),
      );
    }
    const selectedTaskTypeId = filter.taskTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedTaskTypeId && selectedTaskTypeId.length > 0) {
      temp_filtered_tasks = temp_filtered_tasks.filter(
        (task) => selectedTaskTypeId.indexOf(task.taskType?.id + "") >= 0,
      );
    }

    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_tasks = temp_filtered_tasks.filter(
        (task) => selectedProviderId.indexOf(task?.provider?.id + "") >= 0,
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_tasks = temp_filtered_tasks.filter(
        (task) => selectedStatus.indexOf(task?.taskStatus?.id + "") >= 0,
      );
    }

    const selectedWorkflowId = filter.workflowFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedWorkflowId && selectedWorkflowId.length > 0) {
      temp_filtered_tasks = temp_filtered_tasks.filter(
        (task) => selectedWorkflowId.indexOf(task?.workflow?.id + "") >= 0,
      );
    }
    setFilteredTask(temp_filtered_tasks);
  }, [filter]);

  return {
    filteredTask,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
