import {
  Box,
  ColumnLayout,
  SpaceBetween,
  Header,
  BreadcrumbGroup,
  Button,
  Link,
  ExpandableSection,
} from "@cloudscape-design/components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useDeleteDocumentMutation,
  useGetDocumentQuery,
} from "../redux/api/document/document";
import { useGetProviderQuery } from "../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import ChangeTimeZone from "../components/Timezone";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useMemo, useState } from "react";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import KeyValue from "../components/KeyValue";
import { useTranslation } from "react-i18next";
import PsvTable from "./psv/psv-table";

export default function ProviderSafeDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { providerId, documentId } = useParams();
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );
  const { data: document } = useGetDocumentQuery(
    documentId && providerId ? { documentId, providerId } : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteDocument] = useDeleteDocumentMutation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `Providers`,
            href: `/manageprovider/${providerId}`,
          },
          {
            text: displayName,
            href: `/safe/${providerId}`,
          },
          {
            text: document?.name ?? "",
            href: `/safe/${providerId}/documents/${documentId}`,
          },
          {
            text: `versions`,
            href: "#",
          },
        ]}
      />
      <ExpandableSection
        variant="container"
        headerText={document?.name}
        headerActions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              disabled={!hasDeletePermission}
              onClick={() => {
                OpenDeleteModal(true);
              }}
            >
              {t("providerSafe.content.details.header.actions.delete")}
            </Button>
            <Button
              onClick={() => {
                navigate(`/safe/${providerId}/documents/${documentId}/edit`);
              }}
              variant="primary"
            >
              {hasPermission
                ? `${t("providerSafe.content.details.header.actions.edit")}`
                : `${t("providerSafe.content.details.header.actions.view")}`}
            </Button>
          </SpaceBetween>
        }
      >
        <ColumnLayout columns={4} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">
              {t("providerSafe.content.details.header.fields.documentName")}
            </Box>
            <Link
              href={`/safe/${provider?.id}/documents/${document?.id}/edit?preview=true`}
            >
              {document?.name || "-"}
            </Link>
          </div>
          <div>
            <Box variant="awsui-key-label">
              {t("providerSafe.content.details.header.fields.category")}
            </Box>
            <div>{document?.category?.name}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">
              {t("providerSafe.content.details.header.fields.expirationDate")}
            </Box>
            <div>{ChangeTimeZone(document?.expirationDate) ?? "-"}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">
              {t("providerSafe.content.details.header.fields.uploaded")}
            </Box>
            <div>{ChangeTimeZone(document?.createdDate) ?? "-"}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">
              {t("providerSafe.content.details.header.fields.fileName")}
            </Box>
            <Link href="#">{document?.name || "-"}</Link>
          </div>
        </ColumnLayout>
      </ExpandableSection>
      <PsvTable />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (document?.id && providerId && documentId) {
            await notifyWrapper({
              promise: deleteDocument({
                providerId: providerId,
                documentId: documentId,
              }),
              resourceName: "document",
              actionName: "delete",
            });
          }
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerSafe.content.delete.header.label")}
        content={
          <KeyValue
            label={t("providerSafe.content.delete.header.content.label")}
            value={<Header variant="h3">{document?.name}</Header>}
          ></KeyValue>
        }
        description={t(
          "providerSafe.content.delete.header.content.description",
        )}
      />
    </SpaceBetween>
  );
}
