import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import {
  AppLayout,
  Checkbox,
  ColumnLayout,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Link,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import MUIButtonCustomized from "../../components/MuiButtonCustomized";
import { getIntivtationDetails } from "../../context/JWTContext";
import useIsLoading from "../../hooks/useIsLoading";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import RHFCheckBox from "../../components/RHF/RHFCheckBox";
import { useTranslation } from "react-i18next";

type FormInputType = {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  agree: boolean;
};

function SignUpForm() {
  const { t } = useTranslation();
  const { register } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>();

  const methods = useForm<FormInputType>();
  useIsLoading(isLoading);

  useEffect(() => {
    if (!!searchParams.get("token")) {
      const email = searchParams.get("email");
      const role = searchParams.get("role");
      setIsLoading(true);
      if (!!role) {
        setRole(role);
      }
      if (!!email) {
        setEmail(email);
      }
      setIsLoading(false);
    }
  }, [!!searchParams.get("token")]);

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const onSubmit = async (data: FormInputType) => {
    const invite_hash = searchParams.get("token");
    setIsLoading(true);
    if (invite_hash) {
      try {
        register({
          queryparams: searchParams.toString(),
          body: {
            password: data.password,
            frist_name: data.firstName,
            last_name: data.lastName,
            email: email,
            roleId: searchParams.get("roleId") ?? "",
            isOwner: searchParams.get("isOwner") === "true",
            invite_hash: invite_hash,
          },
        })
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            setError(error?.response?.data?.message ?? "Error: ");
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        setError("Error: ");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="centered-element">
        <SpaceBetween direction="vertical" size="xl">
          <img src="/RD_Horizozntal_Color_Logo.svg" style={{ height: 80 }} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SpaceBetween direction="vertical" size="xxs">
              <div className="signup" style={{ width: 500, margin: 5 }}>
                <Container
                  header={
                    <Header
                      variant="h2"
                      description={
                        <SpaceBetween size={"s"} direction="vertical">
                          <div>
                            {t("signup.header.description.line1")}
                            {t("signup.header.description.line2")}
                            {t("signup.header.description.line3")}
                          </div>
                          {error && (
                            <StatusIndicator type="error">
                              {error}
                            </StatusIndicator>
                          )}
                        </SpaceBetween>
                      }
                    >
                      {t("signup.header.label")}
                    </Header>
                  }
                >
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <SpaceBetween direction="vertical" size="l">
                        <ColumnLayout columns={2}>
                          <RHFTextField
                            label={t("signup.header.fields.firstName")}
                            name="firstName"
                            stretch
                            rules={{ required: "This field is required" }}
                          />

                          <RHFTextField
                            label={t("signup.header.fields.lastName")}
                            name="lastName"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                        </ColumnLayout>

                        {!!role && (
                          <FormField label={t("signup.header.fields.role")}>
                            <Input value={role} readOnly />
                          </FormField>
                        )}

                        <FormField label={t("signup.header.fields.email")}>
                          <Input
                            value={email}
                            readOnly={!!searchParams.has("token")}
                            onChange={(event) => setEmail(event.detail.value)}
                          />
                        </FormField>
                        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                          <RHFTextField
                            type={showPassword ? "text" : "password"}
                            label={t("signup.header.fields.password")}
                            name="password"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                          <div style={{ marginTop: "32px" }}>
                            <Checkbox
                              onChange={({ detail }) =>
                                setShowPassword(detail.checked)
                              }
                              checked={showPassword}
                            >
                              {t("signup.header.fields.showPassword")}
                            </Checkbox>
                          </div>
                        </Grid>

                        <RHFTextField
                          type={showPassword ? "text" : "password"}
                          label={t("signup.header.fields.confirmPassword")}
                          name="confirmPassword"
                          stretch
                          rules={{
                            required: "This field is required",
                            validate: (value: any, formValues: FieldValues) => {
                              return (
                                value === (formValues.password ?? "") ||
                                "Password must match"
                              );
                            },
                          }}
                        />
                        <RHFCheckBox
                          label={
                            <span>
                              {t("signup.header.fields.agree.line1")}{" "}
                              <a href="#">
                                {t("signup.header.fields.agree.line2")}{" "}
                              </a>
                              {t("signup.header.fields.agree.and")}{" "}
                              <a href="#">
                                {t("signup.header.fields.agree.line3")}
                              </a>
                              .
                            </span>
                          }
                          name="agree"
                          rules={{ required: "This field is required" }}
                        />
                        <MUIButtonCustomized
                          type="submit"
                          label="Create account"
                        />
                        <span>
                          {t("signup.header.actions.login.description")}{" "}
                          <Link href="/login">
                            {t("signup.header.actions.login.label")}
                          </Link>
                        </span>
                      </SpaceBetween>
                    </form>
                  </FormProvider>
                </Container>
              </div>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </div>
    </div>
  );
}

export default SignUpForm;
