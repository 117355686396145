import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useMemo } from "react";
import { ProviderThirdPartyLoginType } from "../../../../redux/api/provider/section/types";
import {
  useDeleteProviderThirdPartyLoginMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import {
  THIRDPARTYLOGIN_CONTENT_DISPLAY_OPTIONS,
  THIRDPARTYLOGIN_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";

export default function ThirdPartyLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] =
    useState<ProviderThirdPartyLoginType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deleteProviderThirdPartyLogin] =
    useDeleteProviderThirdPartyLoginMutation();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-ThirdPartyLogin-Table-Preferences",
    THIRDPARTYLOGIN_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerThirdPartyLogins ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t(
              "providerProfiles.thirdPartyLogin.table.header.label",
            )}
            action={() => {
              navigate(`editthirdpartylogin`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
    },
  );

  const tableProps: TableProps<ProviderThirdPartyLoginType> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>
              {t("providerProfiles.thirdPartyLogin.table.header.fields.id")}
            </div>
          ),
          cell: (item) => item.id || "-",
          isRowHeader: true,
        },
        {
          id: "name",
          header: (
            <div>
              {t("providerProfiles.thirdPartyLogin.table.header.fields.name")}
            </div>
          ),
          cell: (item) => item.name || "-",
          isRowHeader: true,
        },
        {
          id: "accountNo",
          header: (
            <div>
              {t(
                "providerProfiles.thirdPartyLogin.table.header.fields.accountNo",
              )}
            </div>
          ),
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editthirdpartylogin/${item.id}` : `#`}
              label={item?.accountNo}
            />
          ),
        },
        {
          id: "websiteUrl",
          header: (
            <div>
              {t(
                "providerProfiles.thirdPartyLogin.table.header.fields.websiteUrl",
              )}
            </div>
          ),
          cell: (item) => (
            <Link external href={item?.websiteUrl} variant="primary">
              {item?.websiteUrl}
            </Link>
          ),
        },
        {
          id: "username",
          header: (
            <div>
              {t(
                "providerProfiles.thirdPartyLogin.table.header.fields.username",
              )}
            </div>
          ),
          cell: (item) => item.username ?? "-",
        },
        {
          id: "password",
          header: (
            <div>
              {t(
                "providerProfiles.thirdPartyLogin.table.header.fields.password",
              )}
            </div>
          ),
          cell: (item) => (
            <div>{"*".repeat(item?.password?.length) ?? "-"}</div>
          ),
        },
      ],

      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  {
                    text: `${t("providerProfiles.thirdPartyLogin.table.header.actions.edit")}`,
                    id: "edit",
                    disabled: false,
                  },
                  {
                    text: `${t("providerProfiles.thirdPartyLogin.table.header.actions.delete")}`,
                    id: "delete",
                    disabled: false,
                  },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editthirdpartylogin/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                {t(
                  "providerProfiles.thirdPartyLogin.table.header.actions.thirdPartyLoginActions",
                )}
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editthirdpartylogin`);
                }}
              >
                {t(
                  "providerProfiles.thirdPartyLogin.table.header.actions.addNew",
                )}
              </Button>
            </SpaceBetween>
          }
        >
          {t("providerProfiles.thirdPartyLogin.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Third Party Login Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={THIRDPARTYLOGIN_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
    };
  }, [tablePreferences, items, selectedItem, provider]);

  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderThirdPartyLogin({
                providerId: providerId,
                thirdPartyLoginId: selectedItem.id.toString(),
              }),
              resourceName: "third party login",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerProfiles.thirdPartyLogin.delete.header.label")}
        content={
          <>
            <Box>
              {t(
                "providerProfiles.thirdPartyLogin.delete.header.content.label",
              )}
            </Box>
            <Box variant="awsui-key-label">{selectedItem?.username}</Box>
          </>
        }
        description={t(
          "providerProfiles.thirdPartyLogin.delete.header.content.description",
        )}
      />
    </div>
  );
}
