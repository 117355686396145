import {
  Box,
  Button,
  Header,
  Modal,
  Multiselect,
  SpaceBetween,
} from "@cloudscape-design/components";
import { setModalName } from "../../redux/UI/actions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetRoleQuery,
  useAddRoleProvidersMutation,
} from "../../redux/api/role/role";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { connect } from "react-redux";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { async } from "q";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddAssociatedProvidersModal({ setModalName }: PropsFromDispatch) {
  const { id: roleId } = useParams();

  const { data: role, isLoading } = useGetRoleQuery({
    id: roleId ?? "",
  });
  const { data: provider } = useGetAllProvidersQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addRoleProviders] = useAddRoleProvidersMutation();
  const [selectedOptions, setSelectedOptions] = useState<OptionDefinition[]>(
    role?.providers.map((provider) => {
      return {
        label: provider.firstName + " " + provider?.lastName,
        value: provider.id,
      };
    }) ?? [],
  );

  useEffect(() => {
    setSelectedOptions(
      role?.providers.map((provider) => {
        return {
          label: provider.firstName + " " + provider?.lastName,
          value: provider.id,
        };
      }) ?? [],
    );
  }, [role]);

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Add provider to role: {role?.name}</Header>}
      onDismiss={() => {
        setModalName("");
      }}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setModalName("")}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                if (selectedOptions && selectedOptions.length > 0 && role?.id) {
                  await notifyWrapper({
                    promise: addRoleProviders({
                      roleId: role.id,
                      provider_ids: selectedOptions.map(
                        (provider) => provider.value ?? "",
                      ),
                    }),
                    resourceName: "role provider",
                    actionName: "add",
                  });
                }

                setModalName("");
              }}
              variant="primary"
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Multiselect
        selectedOptions={selectedOptions}
        onChange={({ detail }) =>
          setSelectedOptions([...detail.selectedOptions])
        }
        deselectAriaLabel={(e) => `Remove ${e.label}`}
        options={
          provider?.map((provider) => {
            return {
              label: provider.firstName + " " + provider?.lastName,
              value: provider.id,
            };
          }) ?? []
        }
        placeholder="Choose options"
        selectedAriaLabel="Selected"
      />
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddAssociatedProvidersModal);
