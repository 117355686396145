import {
  Table,
  TableProps,
  Box,
  Header,
  Button,
  ButtonDropdown,
  Pagination,
  Input,
} from "@cloudscape-design/components";
import { useState, useMemo, useEffect } from "react";

import { useNavigate } from "react-router";

import { useCollection } from "@cloudscape-design/collection-hooks";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { TableEmptyState } from "../../common/common-components";
import { PATH_DASHBOARD } from "../../routes/paths";
import StyledRouterLink from "../../components/styled-route-link";
import LoadingScreen from "../../components/LoadingScreen";
import { WorkFlowTemplate } from "../../redux/api/workflow/types";
import {
  useDeleteWorkflowTemplateMutation,
  useGetAllWorkflowTemplateQuery,
} from "../../redux/api/workflow/workflow";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import {
  TablePreferences,
  WORKFLOWTEMPLATE_CONTENT_DISPLAY_OPTIONS,
  WORKFLOWTEMPLATE_TABLE_DEFAULT_PREFERENCES,
} from "../../common/table-config";
import { useLocalStorage } from "../../common/localStorage";

export default function WorkflowsTemplate() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllWorkflowTemplateQuery();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deleteTemplate] = useDeleteWorkflowTemplateMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Workflow-Template-Table-Preferences",
    WORKFLOWTEMPLATE_TABLE_DEFAULT_PREFERENCES,
  );
  const [name, setName] = useState("");
  const [filteredTemplate, setFilteredTemplate] =
    useState<WorkFlowTemplate[]>(data);
  useEffect(() => {
    if (!data || !data?.length) return;
    let temp_filtered_template = [...data];
    if (name) {
      temp_filtered_template = temp_filtered_template.filter((template) =>
        template.name.toLowerCase().includes(name.toLowerCase()),
      );
      setFilteredTemplate(temp_filtered_template);
    } else {
      setFilteredTemplate(data);
    }
  }, [data, name, filteredTemplate]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredTemplate,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Workflow Template"}
            action={() => {
              navigate(`add`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedWorkflowTemplate, setSelectedWorkflowTemplate] =
    useState<WorkFlowTemplate>();

  const tableProps: TableProps<WorkFlowTemplate> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredTemplate.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedWorkflowTemplate}
                  items={[
                    {
                      id: "edit_form",
                      text: "Edit",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedWorkflowTemplate) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedWorkflowTemplate?.id)
                        navigate(
                          PATH_DASHBOARD.manage.workflowTemplates.edit(
                            selectedWorkflowTemplate?.id,
                          ),
                        );
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate(`add`);
                  }}
                  variant="primary"
                  data-test="add_workflow_template"
                >
                  Add Workflow Template
                </Button>
              </span>
            </div>
          }
        >
          Workflow Templates
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Workflow Template Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={WORKFLOWTEMPLATE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      filter: (
        <Input
          onChange={({ detail }) => setName(detail.value)}
          value={name}
          placeholder="Find template by name"
          type="search"
        />
      ),
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedWorkflowTemplate(
            selectionChangeDetail.detail.selectedItems.at(0),
          );
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedWorkflowTemplate(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedWorkflowTemplate
        ? [selectedWorkflowTemplate]
        : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>Workflow Template ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => (
            <StyledRouterLink
              className={"edit_link"}
              to={`/workflowtemplate/${item.id}`}
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
      ],
    };
  }, [items, selectedWorkflowTemplate]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!selectedWorkflowTemplate?.id)
            await notifyWrapper({
              promise: deleteTemplate({ id: selectedWorkflowTemplate?.id }),
              resourceName: "Workflow Template",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Template"}
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">
              {selectedWorkflowTemplate?.name}
            </Box>
          </>
        }
        description="Are you sure you want to delete this template?"
      />
    </div>
  );
}
