import { BreadcrumbGroup, SpaceBetween } from "@cloudscape-design/components";
import { useGetPayerEnrollmentsReportQuery } from "../redux/api/reporting/reporting";
import ChangeTimeZone from "../components/Timezone";
import { useTranslation } from "react-i18next";

import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./ReportingToolBar";
import moment from "moment";

export default function PayerEnrollmentReport() {
  const { data = [], isLoading } = useGetPayerEnrollmentsReportQuery();
  const { t } = useTranslation();
  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("reports.expiredDocuments.breadcrumb.text")}`,
            href: "/reports",
          },
          {
            text: `Payer enrollment`,
            href: "#",
          },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          columns={[
            {
              field: "payername",
              headerName: "Payer_name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.payer?.name ?? "-",
            },
            {
              field: "payerid",
              headerName: "Payer_id",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.payer?.payerId ?? "-",
            },
            {
              field: "network",
              headerName: "Network",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.payerGroup?.name ?? "-",
            },
            {
              field: "networkid",
              headerName: "Network_id",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.payerGroup?.id ?? "-",
            },
            {
              field: "payerproviderid",
              headerName: "Provider ID",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.payerProviderId ?? "-",
            },
            {
              field: "status",
              headerName: "Status",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.status?.name ?? "-",
            },
            {
              field: "submittedDate",
              headerName: "Submitted_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.submittedDate) ?? "-",
            },
            {
              field: "approvedDate",
              headerName: "Approved_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.approvedDate) ?? "-",
            },
            {
              field: "effectiveDate",
              headerName: "Effective_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.effectiveDate) ?? "-",
            },
            {
              field: "recredentialingDate",
              headerName: "Recredentialing_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.recredentialingDate) ?? "-",
              sortComparator: (a: any, b: any) => {
                const momentDate1 = moment(ChangeTimeZone(a));
                const momentDate2 = moment(ChangeTimeZone(b));

                if (momentDate1.isValid() && momentDate2.isValid()) {
                  const year1 = momentDate1.year();
                  const year2 = momentDate2.year();
                  const month1 = momentDate1.month();
                  const month2 = momentDate2.month();

                  if (year1 !== year2) {
                    return year1 - year2;
                  }
                  return month1 - month2;
                }
                return 0;
              },
            },
            {
              field: "lastName",
              headerName: "Provider_LastName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.lastName ?? "-",
            },
            {
              field: "firstname",
              headerName: "Provider_FirstName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.firstName ?? "-",
            },
            {
              field: "npi",
              headerName: "NPI_Number",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.npiNumber ?? "-",
            },
            {
              field: "email",
              headerName: "Provider_Email",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.email ?? "-",
            },
            {
              field: "licenseType",
              headerName: "Provider_License_Type",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.licenseType?.name ?? "-",
            },
            {
              field: "identificationNumber",
              headerName: "IdentificationNumber",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.identificationNumber ?? "-",
            },
            {
              field: "facility",
              headerName: "Provider_Facility_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.facility?.name ?? "-",
            },
            {
              field: "enrollment_facility",
              headerName: "Enrollment_Facility_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.facility?.name ?? "-",
            },
          ]}
          rows={data}
          getRowId={(row) => row?.provider?.id + "_" + row?.id}
          loading={isLoading}
          slots={{ toolbar: ToolBar }}
          disableRowSelectionOnClick={true}
          disableColumnMenu
          density="compact"
        />
      </div>
    </SpaceBetween>
  );
}

const ToolBar = () => <CustomToolbar fileName="payer_enrollments_report" />;
