import { Select } from "@cloudscape-design/components";
import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import { OptionsFilteringType } from "@cloudscape-design/components/internal/components/dropdown/interfaces";
import {
  OptionDefinition,
  OptionGroup,
} from "@cloudscape-design/components/internal/components/option/interfaces";
import { SelectProps as AwsSelectProps } from "@cloudscape-design/components";
import { useFormContext, Controller } from "react-hook-form";
import { ReactNode } from "react";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  defaultValue?: string;
  rules?: { required: string };
  disabled?: boolean;
  groupOptions?: boolean;
  testid?: string;
  css?: string;
  hideAsterisk?: boolean;
  expandToViewport?: boolean;
};

type SelectProps = {
  options?: OptionDefinition[];
  optionGroups?: OptionGroup[];
  filteringType?: OptionsFilteringType;
  triggerVariant?: AwsSelectProps.TriggerVariant | undefined;
  empty?: ReactNode;
  // disabled?:AwsSelectProps.disabled | false;
};

type Props = IProps & SelectProps & FormFieldProps;

export default function RHFSelect({
  name,
  css,
  rules,
  label,
  errorText,
  expandToViewport = true,
  defaultValue,
  description,
  stretch,
  options,
  optionGroups,
  disabled = false,
  groupOptions = false,
  filteringType = "none",
  testid,
  triggerVariant,
  hideAsterisk = false,
  empty,
  ...formfieldProps
}: // disabled
Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <FormField
            info={formfieldProps.info}
            label={
              <span>
                {label}{" "}
                {rules?.required && !!label && !hideAsterisk && (
                  <span style={{ color: "#fc0a37" }}>*</span>
                )}
              </span>
            }
            description={
              description && (
                <span>
                  <i>{description}</i>
                </span>
              )
            }
            errorText={errorText ?? fieldState.error?.message}
            stretch={stretch ?? true}
          >
            <Select
              data-testid={testid}
              expandToViewport={expandToViewport}
              triggerVariant={triggerVariant}
              empty={empty}
              selectedOption={
                optionGroups
                  ? optionGroups
                      ?.map((group) => group.options)
                      .flat()
                      .find((option) => {
                        return (
                          option.value?.toString() === field.value?.toString()
                        );
                      }) ?? null
                  : options?.find((option) => {
                      if (
                        typeof field.value === "object" &&
                        field.value !== null
                      ) {
                        return !!field.value.id
                          ? option.value?.toString() ===
                              field.value.id?.toString()
                          : option.value?.toString() ===
                              field.value.value?.toString();
                      } else {
                        return (
                          option.value?.toString() === field.value?.toString()
                        );
                      }
                    }) ?? null
              }
              onChange={({ detail }) => {
                field.onChange(detail.selectedOption.value);
              }}
              options={options ?? optionGroups}
              disabled={disabled}
              filteringType={filteringType}
            />
          </FormField>
        );
      }}
      rules={rules}
    />
  );
}
