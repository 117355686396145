import { FormProvider, useForm } from "react-hook-form";
import { ProviderWorkHistoryType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderWorkHistoryMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useTranslation } from "react-i18next";
import { Countries, USStatesList } from "../../../../helpers/constants";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";

export default function EditWorkHistory() {
  const { t } = useTranslation();
  const methods = useForm<ProviderWorkHistoryType>();
  const { providerId, workhistoryId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderWorkHistoryType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderWorkHistory({
          providerId: providerId,
          ...data,
          documentId:
            selectedDocument.value !== "-1" ? selectedDocument.value : "",
        }),
        resourceName: "work history",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderWorkHistory] = useAddProviderWorkHistoryMutation();
  const [type] = methods.watch(["type"]);

  useEffect(() => {
    methods.clearErrors("endDate");
    methods.clearErrors("gapExplanation");
  }, [type]);

  useEffect(() => {
    if (provider && provider.providerWorkHistories) {
      const workHistory = provider.providerWorkHistories.find(
        (workHistory) => workHistory?.id + "" === workhistoryId + "",
      );

      if (workHistory) {
        methods.setValue("id", workHistory.id);
        methods.setValue("type", workHistory.type);
        methods.setValue("name", workHistory.name);
        methods.setValue("startDate", workHistory.startDate);
        methods.setValue("endDate", workHistory.endDate);
        methods.setValue("positionHeld", workHistory.positionHeld);
        methods.setValue("gapExplanation", workHistory.gapExplanation);
        methods.setValue("referenceName", workHistory.referenceName);
        methods.setValue("referenceEmail", workHistory.referenceEmail);
        methods.setValue("referencePhone", workHistory.referencePhone);
        methods.setValue("referenceFax", workHistory.referenceFax);
        methods.setValue("addressLane1", workHistory.addressLane1);
        methods.setValue("addressLane2", workHistory.addressLane2);
        methods.setValue("city", workHistory.city);
        methods.setValue("state", workHistory.state);
        methods.setValue("zip", workHistory.zip);
        methods.setValue("country", workHistory.country);
        workHistory?.document &&
          setSelectedDocument({
            label: workHistory?.document?.name,
            value: workHistory?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, workhistoryId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.workHistory.editWorkHistory.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=workhistory`,
            },
            {
              text: `${t("providerProfiles.workHistory.editWorkHistory.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t("providerProfiles.workHistory.editWorkHistory.header.label")}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.workHistory.editWorkHistory.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.workHistory.editWorkHistory.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFSelect
                        name="type"
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.type",
                        )}
                        options={["Current", "Previous", "Gap"].map((type) => ({
                          value: type,
                        }))}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.name",
                        )}
                        name="name"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.startDate",
                        )}
                        name="startDate"
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.endDate",
                        )}
                        name="endDate"
                        rules={
                          type === "Previous"
                            ? { required: "This field is required" }
                            : { required: false }
                        }
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.positionHeld",
                        )}
                        name="positionHeld"
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.gapExplanation",
                        )}
                        name="gapExplanation"
                        rules={
                          type === "Gap"
                            ? { required: "This field is required" }
                            : { required: false }
                        }
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.referenceName",
                        )}
                        name="referenceName"
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.referenceEmail",
                        )}
                        name="referenceEmail"
                        rules={{
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email",
                          },
                        }}
                      />

                      <RHFPhoneNumber
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.referencePhone",
                        )}
                        name="referencePhone"
                      />

                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.referenceFax",
                        )}
                        name="referenceFax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.addressLane1",
                        )}
                        name="addressLane1"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.addressLane2",
                        )}
                        name="addressLane2"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.city",
                        )}
                        name="city"
                        stretch
                      />
                      <RHFSelect
                        name="state"
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.state",
                        )}
                        options={USStatesList.map((state) => ({
                          value: state.value,
                        }))}
                        stretch
                      />
                      <RHFSelect
                        name="country"
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.country",
                        )}
                        options={Countries.map((country) => ({
                          value: country.value,
                        }))}
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.workHistory.editWorkHistory.header.fields.zipcode",
                        )}
                        name="zip"
                        stretch
                      />
                    </FormSection>
                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
