import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../common/localStorage";
import {
  PRIVILEGETYPE_CONTENT_DISPLAY_OPTIONS,
  PRIVILEGETYPE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import {
  useDeletePrivilegeTypeMutation,
  useGetAllPrivilegeTypesQuery,
} from "../../redux/api/privileges/privileges";
import { PrivilegeType } from "../../redux/api/privileges/types";

export default function PrivilegeTypeTable() {
  const { data = [] } = useGetAllPrivilegeTypesQuery();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState<PrivilegeType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deletePrivilegeType] = useDeletePrivilegeTypeMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-PrivilegeType-Table-Preferences",
    PRIVILEGETYPE_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
    },
    pagination: { pageSize: tablePreferences.pageSize },
  });
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("privileges.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("privileges.delete") ?? false),
  );
  useEffect(() => {
    const temp = data.find((type) => "" + type.id === "" + id);
    if (temp) setSelectedItem(temp);
  }, [id]);
  const tableProps: TableProps<PrivilegeType> = useMemo(() => {
    return {
      header: (
        <Header
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission ? "Edit" : "View",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "edit_form") {
                      navigate(`/privilegetype/${selectedItem?.id}`);
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate("add");
                  }}
                  variant="primary"
                >
                  Add Privilege Type
                </Button>
              </span>
            </div>
          }
        >
          Privilege Type
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Privilege Type Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PRIVILEGETYPE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      empty: <p>No results to display.</p>,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>id</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <div>{item.name}</div>,
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <span>{item.description}</span>,
        },
      ],
    };
  }, [selectedItem, data, tablePreferences, items]);
  return (
    <div style={{ paddingTop: "25px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            await notifyWrapper({
              promise: deletePrivilegeType({
                privilegeTypeId: selectedItem?.id,
              }),
              resourceName: "privilege type",
              actionName: "delete",
            });
          }
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete privilege Type"}
        content={
          <>
            <Box>Type</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this privilege type?"}
      />
    </div>
  );
}
