import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@cloudscape-design/global-styles/index.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import AuthProvider from "./context/JWTContext";
import { I18nProvider } from "@cloudscape-design/components/i18n";
import enMessages from "@cloudscape-design/components/i18n/messages/all.en";
import * as Sentry from "@sentry/react";
import "./i18n";

function getEnvironment() {
  const hostname = window.location.hostname;

  if (hostname === "dev.readydoc.app") {
    return "development";
  } else if (hostname === "qa.readydoc.app") {
    return "staging";
  } else if (hostname === "readydoc.app") {
    return "production";
  } else {
    return "local"; // Assuming any other hostname would be a local development environment
  }
}

Sentry.init({
  dsn: "https://389cd75b704f7d6824c0fe2af6853c53@o345027.ingest.us.sentry.io/4506825649225728",
  environment: getEnvironment(),
  release: "readydoc@" + process.env.REACT_APP_VERSION,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/*.readydoc\.app\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    "ResizeObserver loop completed with undelivered notifications.",
    "Invalid username or password",
    "Two instances of WebViewer were created on the same HTML element. Please create a new element for each instance of WebViewer",
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <I18nProvider locale="en" messages={[enMessages]}>
      <Provider store={store}>
        <AuthProvider>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </I18nProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
