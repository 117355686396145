import {
  Button,
  ColumnLayout,
  Container,
  Header,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import KeyValue from "../../../components/KeyValue";
import Exclusions, { NoExlusions } from "./exclusions";
import { useGetProviderQuery } from "../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import { MANAGEPROVIDER_EDIT_MODAL } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function Overview({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  // const search = useLocation().search;
  // const providerId = new URLSearchParams(search).get("providerId");
  const { providerId } = useParams();
  const { t } = useTranslation();
  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  return (
    <div>
      <div style={{ padding: 10 }}>
        <Container
          header={
            <Header
              actions={
                <Button
                  onClick={() => {
                    openModalName(MANAGEPROVIDER_EDIT_MODAL);
                  }}
                >
                  {t(
                    "providerProfiles.overview.generalInformation.header.actions.edit",
                  )}
                </Button>
              }
            >
              {t("providerProfiles.overview.generalInformation.header.label")}
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <KeyValue
              label={t(
                "providerProfiles.overview.generalInformation.header.fields.firstName",
              )}
              value={selectedProvider?.firstName}
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.generalInformation.header.fields.lastName",
              )}
              value={selectedProvider?.lastName}
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.generalInformation.header.fields.email",
              )}
              value={selectedProvider?.email}
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.generalInformation.header.fields.npiNumber",
              )}
              value={selectedProvider?.npiNumber}
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.generalInformation.header.fields.facility",
              )}
              value={selectedProvider?.facility?.name}
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.generalInformation.header.fields.licenseType",
              )}
              value={selectedProvider?.licenseType?.name}
            />
          </ColumnLayout>
        </Container>
      </div>
      <div style={{ padding: 10 }}>
        <Container
          header={
            <Header
              description={
                <i>
                  {t(
                    "providerProfiles.overview.documentsSummary.header.description",
                  )}
                </i>
              }
              actions={
                <Button
                  onClick={() => {
                    navigate(`/safe?providerId=${providerId}`);
                  }}
                >
                  {t(
                    "providerProfiles.overview.documentsSummary.header.actions.gotoSafe",
                  )}
                </Button>
              }
            >
              {t("providerProfiles.overview.documentsSummary.header.label")}
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <KeyValue
              label={t(
                "providerProfiles.overview.documentsSummary.header.fields.totalCount",
              )}
              value={
                <StatusIndicator type="info">
                  {selectedProvider?.summary.safe.total}
                </StatusIndicator>
              }
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.documentsSummary.header.fields.expiredCount",
              )}
              value={
                <StatusIndicator
                  type={
                    selectedProvider?.summary.safe.expired === 0
                      ? "success"
                      : "error"
                  }
                >
                  {selectedProvider?.summary.safe.expired}
                </StatusIndicator>
              }
            />
            <KeyValue
              label={t(
                "providerProfiles.overview.documentsSummary.header.fields.expiringCount",
              )}
              value={
                <StatusIndicator
                  type={
                    selectedProvider?.summary.safe.expiring === 0
                      ? "success"
                      : "warning"
                  }
                >
                  {selectedProvider?.summary.safe.expiring}
                </StatusIndicator>
              }
            />
          </ColumnLayout>
        </Container>
      </div>
      <div style={{ padding: 10 }}>
        {selectedProvider && selectedProvider?.npiNumber ? (
          <Exclusions
            key={selectedProvider?.npiNumber}
            npi_number={selectedProvider?.npiNumber}
          />
        ) : (
          <NoExlusions />
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(Overview);
