import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  WorkFlowTaskRequest,
  WorkflowRequest,
} from "../../redux/api/workflow/types";
import { useForm, useFormContext } from "react-hook-form";
import {
  Button,
  Container,
  FormField,
  Header,
  SpaceBetween,
  Select,
  Input,
} from "@cloudscape-design/components";
import { DatePicker, Divider, Flex } from "antd";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import dayjs from "dayjs";

export default function StepFour({
  workflowTasks,
  setWorkflowTasks,
}: {
  workflowTasks: WorkFlowTaskRequest[];
  setWorkflowTasks: Dispatch<SetStateAction<WorkFlowTaskRequest[]>>;
}) {
  const methods = useFormContext<WorkflowRequest>();
  const [
    providerId,
    workflowTemplateId,
    adminId,
    facilityId,
    startDate,
    dueDate,
  ] = methods.watch([
    "providerId",
    "workflowTemplateId",
    "adminId",
    "facilityId",
    "startDate",
    "dueDate",
  ]);

  return (
    <SpaceBetween size="xs">
      <Header>Add task</Header>
      <Container>
        <SpaceBetween size="s">
          {workflowTasks?.map((workflowTask, index) => {
            const updatedWorkflowTask = {
              ...workflowTask,
              adminId: !!workflowTask.adminId ? workflowTask.adminId : adminId,
              facilityId: !!workflowTask.facilityId
                ? workflowTask.facilityId
                : facilityId,
              providerId: !!workflowTask.providerId
                ? workflowTask.providerId
                : providerId,
              startDate: workflowTask?.startDate,
              dueDate: workflowTask?.dueDate,
            };
            return (
              <>
                <FlexTask
                  workflowTask={updatedWorkflowTask}
                  setWorkflowTask={(workflowTask) => {
                    const temp = [...workflowTasks];
                    temp[index] = workflowTask;
                    setWorkflowTasks([...temp]);
                  }}
                  removeWorkFlowTask={() => {
                    const temp = [...workflowTasks];
                    temp.splice(index, 1);
                    setWorkflowTasks(temp);
                  }}
                />
                <Divider
                  style={{
                    margin: 8,
                    borderBlockStartWidth: "2px",
                  }}
                />
              </>
            );
          })}
          <Flex wrap="wrap" gap="large" align="flex-end">
            <Button
              formAction="none"
              onClick={() => {
                setWorkflowTasks([
                  ...workflowTasks,
                  {
                    name: "",
                    description: "",
                    taskStatusId: "",
                    taskTypeId: "",
                    adminId: methods.getValues("adminId"),
                    providerId: methods.getValues("providerId"),
                    facilityId: methods.getValues("facilityId"),
                    workflowId: "",
                    startDate: methods.getValues("startDate"),
                    dueDate: methods.getValues("dueDate"),
                  },
                ]);
              }}
            >
              Add New Task
            </Button>
          </Flex>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}

function FlexTask({
  workflowTask,
  setWorkflowTask,
  removeWorkFlowTask,
}: {
  workflowTask: WorkFlowTaskRequest;
  setWorkflowTask: (workflowTask: WorkFlowTaskRequest) => void;
  removeWorkFlowTask: VoidFunction;
}) {
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  const providersOptions = useMemo(() => {
    return providers.map((provider) => ({
      label: provider.firstName + " " + provider.lastName,
      value: "" + provider.id,
    }));
  }, [providers]);

  const memberOptions = useMemo(() => {
    return members.map((member) => ({
      label: member.firstName + " " + member.lastName,
      value: "" + member.id,
    }));
  }, [members]);

  const facilitiesOptions = useMemo(() => {
    return facilities.map((facility) => ({
      label: facility.name,
      value: "" + facility.id,
    }));
  }, [facilities]);

  const taskTypeOptions = useMemo(() => {
    return taskTypes.map((type) => ({
      label: type.name,
      value: "" + type.id,
    }));
  }, [taskTypes]);

  const taskstatusOptions = useMemo(() => {
    return taskstatuses.map((status) => ({
      label: status.name,
      value: "" + status.id,
    }));
  }, [taskstatuses]);

  useEffect(() => {
    const selectedTaskTypeOption = taskTypeOptions?.find(
      (taskTypeOption) =>
        taskTypeOption.value + "" === workflowTask?.taskTypeId + "",
    );
    const selectedProviderOption = providersOptions?.find(
      (providersOption) =>
        providersOption.value + "" === workflowTask?.providerId + "",
    );
    if (!!selectedTaskTypeOption && !!selectedProviderOption) {
      const taskName =
        selectedProviderOption?.label + "_" + selectedTaskTypeOption?.label;
      setWorkflowTask({
        ...workflowTask,
        name: taskName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowTask?.providerId, workflowTask?.taskTypeId]);

  return (
    <Flex wrap="wrap" gap="large" align="flex-end">
      <div style={{ minWidth: "200px" }}>
        <FormField
          label={
            <span>
              Task Type <span style={{ color: "#fc0a37" }}>*</span>
            </span>
          }
        >
          <Select
            expandToViewport
            filteringType="auto"
            options={taskTypeOptions}
            placeholder="Task Type"
            onChange={({ detail }) => {
              setWorkflowTask({
                ...workflowTask,
                taskTypeId: detail.selectedOption.value ?? "",
              });
            }}
            selectedOption={
              taskTypeOptions?.find(
                (taskTypeOption) =>
                  taskTypeOption.value?.toString() ===
                  workflowTask?.taskTypeId?.toString(),
              ) ?? null
            }
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Provider</span>}>
          <Select
            expandToViewport
            filteringType="auto"
            options={providersOptions}
            placeholder="Select provider"
            onChange={({ detail }) => {
              setWorkflowTask({
                ...workflowTask,
                providerId: detail.selectedOption.value ?? "",
              });
            }}
            selectedOption={
              providersOptions?.find(
                (providersOption) =>
                  providersOption.value?.toString() ===
                  workflowTask?.providerId?.toString(),
              ) ?? null
            }
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Task Name</span>}>
          <Input
            onChange={({ detail }) => {
              setWorkflowTask({
                ...workflowTask,
                name: detail.value ?? "",
              });
            }}
            placeholder={"Task name"}
            value={workflowTask?.name}
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Task Status</span>}>
          <Select
            expandToViewport
            filteringType="auto"
            options={taskstatusOptions}
            placeholder="Task Status"
            onChange={({ detail }) => {
              setWorkflowTask({
                ...workflowTask,
                taskStatusId: detail.selectedOption.value ?? "",
              });
            }}
            selectedOption={
              taskstatusOptions?.find(
                (taskstatusOption) =>
                  taskstatusOption.value?.toString() ===
                  workflowTask?.taskStatusId?.toString(),
              ) ?? null
            }
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Assign to</span>}>
          <Select
            expandToViewport
            filteringType="auto"
            options={memberOptions}
            placeholder="Select user"
            onChange={({ detail }) => {
              setWorkflowTask({
                ...workflowTask,
                adminId: detail.selectedOption.value ?? "",
              });
            }}
            selectedOption={
              memberOptions?.find(
                (memberOption) =>
                  memberOption.value?.toString() ===
                  workflowTask?.adminId?.toString(),
              ) ?? null
            }
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Facility</span>}>
          <Select
            expandToViewport
            filteringType="auto"
            options={facilitiesOptions}
            placeholder="Select facility"
            onChange={({ detail }) => {
              setWorkflowTask({
                ...workflowTask,
                facilityId: detail.selectedOption.value ?? "",
              });
            }}
            selectedOption={
              facilitiesOptions?.find(
                (facilitiesOption) =>
                  facilitiesOption.value?.toString() ===
                  workflowTask?.facilityId?.toString(),
              ) ?? null
            }
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Start date</span>}>
          <DatePicker
            style={{ width: "100%" }}
            placeholder={"Start date"}
            onChange={(date, dateString) => {
              if (!date) {
                setWorkflowTask({ ...workflowTask, startDate: date });
              } else
                setWorkflowTask({
                  ...workflowTask,
                  startDate: date.format("YYYY-MM-DD") + "T00:00:00",
                });
            }}
            value={
              !!workflowTask?.startDate &&
              dayjs(workflowTask?.startDate).isValid()
                ? dayjs.utc(workflowTask?.startDate)
                : null
            }
            showNow={false}
            allowClear={true}
            format={"MM-DD-YYYY"}
          />
        </FormField>
      </div>
      <div style={{ minWidth: "200px" }}>
        <FormField label={<span>Due date</span>}>
          <DatePicker
            style={{ width: "100%" }}
            placeholder={"Due date"}
            onChange={(date, dateString) => {
              if (!date) {
                setWorkflowTask({ ...workflowTask, dueDate: date });
              } else
                setWorkflowTask({
                  ...workflowTask,
                  dueDate: date.format("YYYY-MM-DD") + "T00:00:00",
                });
            }}
            value={
              !!workflowTask?.dueDate && dayjs(workflowTask?.dueDate).isValid()
                ? dayjs.utc(workflowTask?.dueDate)
                : null
            }
            showNow={false}
            allowClear={true}
            format={"MM-DD-YYYY"}
          />
        </FormField>
      </div>
      <div
        style={{
          minWidth: "200px",
          alignContent: "center",
          justifyItems: "center",
        }}
      >
        <Button formAction="none" onClick={removeWorkFlowTask}>
          Remove
        </Button>
      </div>
    </Flex>
  );
}
