import { api } from "..";
import { LicenseType, LicenseTypeRequest } from "./types";

const LicenseTypeAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllLicenseType: build.query<LicenseType[], void>({
      query: () => "/licensetype/all",
      providesTags: ["LicenseType"],
    }),
    getLicenseType: build.query<LicenseType, { id: string }>({
      query: ({ id }) => `/licensetype/${id}`,
      providesTags: ["LicenseType"],
    }),
    addLicenseType: build.mutation<void, LicenseTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "licensetype",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["LicenseType"],
    }),
    updateLicenseType: build.mutation<
      void,
      { id: string } & Partial<LicenseTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `licensetype/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "LicenseType", id: arg.id },
        { type: "LicenseType" },
      ],
    }),
    deleteLicenseType: build.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `licensetype/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LicenseType"],
    }),
  }),
});

export const {
  useGetAllLicenseTypeQuery,
  useGetLicenseTypeQuery,
  useAddLicenseTypeMutation,
  useUpdateLicenseTypeMutation,
  useDeleteLicenseTypeMutation,
} = LicenseTypeAPI;
