import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Input,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useSelector } from "react-redux";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  useDeleteCredentialTemplateMutation,
  useGetAllCredentialTemplatesQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { CredentialTemplate } from "../../redux/api/credentialpackages/types";
import { useLocalStorage } from "../../common/localStorage";
import { TableEmptyState } from "../../common/common-components";
import { ApplicationState } from "../../redux/store";
import {
  CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import StyledRouterLink from "../../components/styled-route-link";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import { PATH_DASHBOARD } from "../../routes/paths";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";

export default function CredentialTemplates() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllCredentialTemplatesQuery();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deleteCredentialTemplate] = useDeleteCredentialTemplateMutation();
  const [selectedItem, setSelectedItem] = useState<CredentialTemplate>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CredentialTemplate-Table-Preferences",
    CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES,
  );
  const [name, setName] = useState("");
  const [filteredTemplate, setFilteredTemplate] =
    useState<CredentialTemplate[]>(data);
  const { items, collectionProps, paginationProps } = useCollection(
    filteredTemplate,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("credentialTemplates.table.header.label")}
            action={() => {
              navigate(`addnew`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  useEffect(() => {
    if (!data || !data?.length) return;
    let temp_filtered_template = [...data];
    if (name) {
      temp_filtered_template = temp_filtered_template.filter((template) =>
        template.name.toLowerCase().includes(name.toLowerCase()),
      );
      setFilteredTemplate(temp_filtered_template);
    } else {
      setFilteredTemplate(data);
    }
  }, [data, name, filteredTemplate]);

  useEffect(() => {
    const temp = data.find(
      (credentialTemplate) => "" + credentialTemplate.id === "" + id,
    );
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false),
  );

  const tableProps: TableProps<CredentialTemplate> = useMemo(() => {
    return {
      header: (
        <Header
          variant="h2"
          counter={`(${filteredTemplate.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission
                        ? `${t("credentialTemplates.table.header.actions.editTemplate")}`
                        : `${t("credentialTemplates.table.header.actions.viewTemplate")}`,
                    },
                    {
                      id: "delete_form",
                      text: `${t("credentialTemplates.table.header.actions.deleteTemplate")}`,
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "edit_form") {
                      if (!!selectedItem?.id)
                        navigate(
                          PATH_DASHBOARD.credential.templates.edit(
                            selectedItem?.id,
                          ),
                        );
                    }
                  }}
                >
                  {t(
                    "credentialTemplates.table.header.actions.templateActions",
                  )}
                </ButtonDropdown>
              </span>

              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate("addnew");
                  }}
                  disabled={!hasPermission}
                  variant="primary"
                >
                  {t(
                    "credentialTemplates.table.header.actions.addCredentialTemplate",
                  )}
                </Button>
              </span>
            </div>
          }
        >
          {t("credentialTemplates.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      filter: (
        <Input
          onChange={({ detail }) => setName(detail.value)}
          value={name}
          placeholder="Find template by name"
          type="search"
        />
      ),
      preferences: (
        <TablePreferences
          title="Template Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div> {t("credentialTemplates.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: (
            <div>{t("credentialTemplates.table.header.fields.name")}</div>
          ),
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={
                hasPermission
                  ? PATH_DASHBOARD.credential.templates.edit(item.id)
                  : `#`
              }
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "categories",
          header: (
            <div>
              {t("credentialTemplates.table.header.fields.categoriesCount")}
            </div>
          ),
          cell: (item) => <span>{item?.categories.length}</span>,
          sortingField: "categories.length",
          sortingComparator: (a, b) =>
            (a?.categories?.length ?? "")
              .toString()
              .localeCompare((b?.categories?.length ?? "").toString()),
        },
        {
          id: "licenseType",
          header: (
            <div>
              {t("credentialTemplates.table.header.fields.licenseType")}
            </div>
          ),
          cell: (item) => <span>{item?.licenseType?.name}</span>,
          sortingField: "licenseType.name",
          sortingComparator: (a, b) =>
            (a?.licenseType?.name ?? "").localeCompare(
              b?.licenseType?.name ?? "",
            ),
        },
      ],
    };
  }, [selectedItem, data, tablePreferences, items]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deleteCredentialTemplate({
                credentialTemplateId: selectedItem?.id,
              }),
              resourceName: "template",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("credentialTemplates.delete.header.label")}
        content={
          <>
            <Box>{t("credentialTemplates.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t("credentialTemplates.delete.header.content.description")}
      />
    </div>
  );
}
