import { api } from "..";
import { InvitationType, InviteCreateRequest } from "./types";

const InvitaionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllInvited: build.query<InvitationType[], void>({
      query: () => "invite/all",
      providesTags: ["ManageAdmin"],
    }),
    getInvited: build.query<InvitationType, { invitedId: string }>({
      query: ({ invitedId }) => `invite/${invitedId}`,
      providesTags: ["ManageAdmin"],
    }),
    createInvite: build.mutation<void, InviteCreateRequest>({
      query: ({ ...body }) => {
        return {
          url: "invite",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["ManageAdmin"],
    }),
    deleteInvite: build.mutation<
      void,
      {
        invitedId: string;
      }
    >({
      query: ({ invitedId }) => ({
        url: `invite/${invitedId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageAdmin"],
    }),
  }),
});
export const {
  useGetAllInvitedQuery,
  useGetInvitedQuery,
  useCreateInviteMutation,
  useDeleteInviteMutation,
} = InvitaionApi;
