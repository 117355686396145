import { Button, Header, SpaceBetween } from "@cloudscape-design/components";
import { connect } from "react-redux";
import { setModalName } from "../redux/UI/actions";
import "./index.css";
import ManageadminDetails from "./manageadmin-details/manageadmin-details";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_DASHBOARD } from "../routes/paths";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ManageAdminTable({ openModalName }: PropsFromDispatch) {
  const { manageadminId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false),
  );
  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"s"} direction="vertical">
        <Header
          actions={
            <Button
              disabled={!hasPermission}
              onClick={() => {
                navigate(PATH_DASHBOARD.manageadmin.add(`${manageadminId}`));
              }}
              variant="primary"
            >
              {t("members.header.actions.inviteMember")}
            </Button>
          }
        >
          {t("members.header.label")}
        </Header>
        <ManageadminDetails />
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageAdminTable);
