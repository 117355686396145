import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  CREDENTIALPACKAGE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALPACKAGE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import StyledRouterLink from "../components/styled-route-link";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import {
  useDeleteCredentialPackageMutation,
  useDownloadCredentialPackageMutation,
} from "../redux/api/credentialpackages/credentialpackages";
import { CredentialPackage } from "../redux/api/credentialpackages/types";
import ChangeTimeZone from "../components/Timezone";
import { PATH_DASHBOARD } from "../routes/paths";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import useCredentialPackagesFilter from "../hooks/useCredentialPackagesFilter";
import CredentialPackageFilters from "../components/filter/credentialpackage-filters";
import { openInNewTab } from "../helpers/utils";
import { HOST_API } from "../config";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

export default function CredentialPackages() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePackage] = useDeleteCredentialPackageMutation();
  const [downloadCredentialPackage] = useDownloadCredentialPackageMutation();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const [selectedItem, setSelectedItem] = useState<CredentialPackage>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CredentialPackage-Table-Preferences",
    CREDENTIALPACKAGE_TABLE_DEFAULT_PREFERENCES,
  );
  const {
    filteredCredentialPackages,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useCredentialPackagesFilter();
  const { items, collectionProps, paginationProps } = useCollection(
    filteredCredentialPackages,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("credentialPackages.table.header.label")}
            action={() => {
              navigate(`addnew`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  useEffect(() => {
    const temp = filteredCredentialPackages?.find(
      (packages) => "" + packages === "" + id,
    );
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false),
  );

  const tableProps: TableProps<CredentialPackage> = useMemo(() => {
    return {
      header: (
        <Header
          variant="h2"
          counter={`(${filteredCredentialPackages.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_package",
                      text: hasPermission
                        ? `${t("credentialPackages.table.header.actions.editPackage")}`
                        : `${t("credentialPackages.table.header.actions.viewPackage")}`,
                    },
                    {
                      id: "download_package",
                      text: `Download Package`,
                      disabled: !hasPermission,
                    },
                    ...(!!organization?.organizationServiceLimits?.eSignatures
                      ? [
                          {
                            id: "request_signature",
                            text: `Request Signature`,
                            disabled: !hasPermission,
                          },
                        ]
                      : []),
                    {
                      id: "delete_package",
                      text: `${t("credentialPackages.table.header.actions.deletePackage")}`,
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_package") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "download_package") {
                      if (!!selectedItem)
                        openInNewTab({
                          downloadUrl: `${HOST_API}/api/credentialing/packages/${selectedItem?.id}/documents/zip`,
                        });
                    } else if (id === "request_signature") {
                      if (!!selectedItem?.id)
                        navigate(
                          PATH_DASHBOARD.credential.packages.requestSignature(
                            selectedItem?.id,
                          ),
                        );
                    } else if (id === "edit_package") {
                      if (!!selectedItem?.id)
                        navigate(
                          PATH_DASHBOARD.credential.packages.edit(
                            selectedItem?.id,
                          ),
                        );
                    }
                  }}
                >
                  {t("credentialPackages.table.header.actions.packageActions")}
                </ButtonDropdown>
              </span>

              <span className="awsui-util-action-stripe-group">
                <Button
                  disabled={!hasPermission}
                  onClick={() => {
                    navigate("addnew");
                  }}
                  variant="primary"
                >
                  {t("credentialPackages.table.header.actions.startPackage")}
                </Button>
              </span>
            </div>
          }
        >
          {t("credentialPackages.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      filter: (
        <CredentialPackageFilters filter={filter} setFilter={setFilter} />
      ),
      preferences: (
        <TablePreferences
          title="Package Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={CREDENTIALPACKAGE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("credentialPackages.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>{t("credentialPackages.table.header.fields.name")}</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={
                hasPermission
                  ? PATH_DASHBOARD.credential.packages.edit(item.id)
                  : `#`
              }
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "packageType",
          header: (
            <div>{t("credentialPackages.table.header.fields.packageType")}</div>
          ),
          cell: (item) => <span>{item?.packageType?.name ?? "-"}</span>,
          sortingField: "packageType.name",
          sortingComparator: (a, b) =>
            (a?.packageType?.name ?? "").localeCompare(
              b?.packageType?.name ?? "",
            ),
        },
        {
          id: "status",
          header: (
            <div>{t("credentialPackages.table.header.fields.status")}</div>
          ),
          cell: (item) => <span>{item?.packageStatus?.name ?? "-"}</span>,
          sortingField: "packageStatus.name",
          sortingComparator: (a, b) =>
            (a?.packageStatus?.name ?? "").localeCompare(
              b?.packageStatus?.name ?? "",
            ),
        },
        {
          id: "provider",
          header: (
            <div>{t("credentialPackages.table.header.fields.provider")}</div>
          ),
          cell: (item) => (
            <span>
              {item?.provider?.firstName + " " + item?.provider?.lastName ??
                "-"}
            </span>
          ),
          sortingField: "provider.firstName",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.firstName ?? "",
            ),
        },
        {
          id: "credentialTemplate",
          header: (
            <div>
              {t("credentialPackages.table.header.fields.credentialTemplate")}
            </div>
          ),
          cell: (item) => <span>{item?.credentialTemplate?.name ?? "-"}</span>,
          sortingField: "credentialTemplate.name",
          sortingComparator: (a, b) =>
            (a?.credentialTemplate?.name ?? "").localeCompare(
              b?.credentialTemplate?.name ?? "",
            ),
        },
        {
          id: "submittedDate",
          header: (
            <div>
              {t("credentialPackages.table.header.fields.submittedDate")}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.submittedDate) ?? "-"}</span>
          ),
          sortingField: "submittedDate",
        },
        {
          id: "recredentialingDate",
          header: (
            <div>
              {t("credentialPackages.table.header.fields.recredentialingDate")}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.recredentialingDate) ?? "-"}</span>
          ),
        },
        {
          id: "followupDate",
          header: (
            <div>
              {t("credentialPackages.table.header.fields.followupDate")}
            </div>
          ),
          cell: (item) => <span>{item?.followupDate ?? "-"}</span>,
        },
      ],
    };
  }, [selectedItem, tablePreferences, items]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePackage({
                credentialPackageId: selectedItem?.id,
              }),
              resourceName: "package",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("credentialPackages.delete.header.label")}
        content={
          <>
            <Box>{t("credentialPackages.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t("credentialPackages.delete.header.content.description")}
      />
    </div>
  );
}
