import {
  AttributeEditor,
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Grid,
  Header,
  SpaceBetween,
  Input,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

import InnerAppLayout from "../../components/InnerAppLayout";
import RHFTextField from "../../components/RHF/RHFTextField";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { TASK_STATUS, TASK_TYPE } from "../../helpers/constants";
import FormSection from "../../components/FormSection";
import { WorkflowTemplateTasksRequest } from "../../redux/api/workflow/types";
import { useAddWorkflowTemplateMutation } from "../../redux/api/workflow/workflow";
import { useMemo, useState } from "react";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import Label from "../../components/Label";
import FloatingSelect from "../../components/FloatingSelect";

type FormInputType = {
  id: string;
  name: string;
  notes: string;
  tasks: {
    id: string;
    name: string;
    description: string;
    taskStatusId: string;
    taskTypeId: string;
  };
};

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddWorkflowTemplate({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();

  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const [tasks, setTasks] = useState<WorkflowTemplateTasksRequest[]>([]);

  const [addWorkflowTemplate] = useAddWorkflowTemplateMutation();

  const taskTypeOptions = useMemo(() => {
    return taskTypes.map((taskType) => ({
      label: taskType.name,
      value: "" + taskType.id,
    }));
  }, [taskTypes]);
  const taskStatusesOptions = useMemo(() => {
    return taskstatuses.map((status) => ({
      label: status.name,
      value: "" + status.id,
    }));
  }, [taskstatuses]);

  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: addWorkflowTemplate({
        name: data.name,
        notes: data.notes,
        tasks: tasks,
      }),
      resourceName: "workflow template",
      actionName: "add",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `Workflows Template`,
                href: `/workflows/template`,
              },
              {
                text: `Add`,
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <SpaceBetween size="xxl">
                <Container
                  header={
                    <div style={{ paddingBottom: "12px" }}>
                      <Header>Add Workflow Template</Header>
                    </div>
                  }
                >
                  <SpaceBetween size="s">
                    <Grid gridDefinition={[{ colspan: 8 }]}>
                      <FormSection columns={1}>
                        <RHFTextField
                          label={"Workflow Template Name"}
                          name="name"
                          stretch={true}
                          rules={{ required: "This field is required" }}
                        />
                      </FormSection>
                    </Grid>
                  </SpaceBetween>
                  {/*   <Divider />
                  <ExpandableSection
                    headerText="Notes"
                    defaultExpanded={!!notes}
                  >
                    <RHFTextArea label={"Notes"} name="notes" stretch={true} />
                  </ExpandableSection> */}
                </Container>
                <Container>
                  <AttributeEditor
                    onAddButtonClick={() =>
                      setTasks([
                        ...tasks,
                        {
                          id: "",
                          name: "",
                          description: "",
                          taskStatusId: "",
                          taskTypeId: "",
                        },
                      ])
                    }
                    onRemoveButtonClick={({ detail: { itemIndex } }) => {
                      const tmp = [...tasks];
                      tmp.splice(itemIndex, 1);
                      setTasks(tmp);
                    }}
                    items={tasks}
                    addButtonText="Add new item"
                    definition={[
                      {
                        label: <Label label={"Task Type"} required />,
                        control: (item, itemIndex) => (
                          <FloatingSelect
                            options={taskTypeOptions}
                            selectedOption={taskTypeOptions?.find((option) => {
                              return (
                                option?.value?.toString() ===
                                item?.taskTypeId?.toString()
                              );
                            })}
                            onSelectionChange={(changedOption) => {
                              const tmp = [...tasks];
                              tmp[itemIndex] = {
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                taskStatusId: item?.taskStatusId,
                                taskTypeId: changedOption?.value,
                              };
                              setTasks(tmp);
                            }}
                            enableFiltering
                            action={{
                              label: "Modify",
                              onClick: () => {
                                setModalName(TASK_TYPE);
                              },
                            }}
                          />
                        ),
                      },
                      {
                        label: <Label label={"Task Name"} />,
                        control: (item, itemIndex) => (
                          <Input
                            value={item.name}
                            onChange={(changeDetails) => {
                              const tmp = [...tasks];
                              tmp[itemIndex] = {
                                id: item.id,
                                name: changeDetails.detail.value,
                                description: item.description,
                                taskStatusId: item.taskStatusId,
                                taskTypeId: item.taskTypeId,
                              };
                              setTasks(tmp);
                            }}
                            placeholder="Enter Name"
                          />
                        ),
                      },
                      {
                        label: <Label label={"Task Status"} />,
                        control: (item, itemIndex) => (
                          <FloatingSelect
                            options={taskStatusesOptions}
                            selectedOption={taskStatusesOptions?.find(
                              (option) => {
                                return (
                                  option?.value?.toString() ===
                                  item?.taskStatusId?.toString()
                                );
                              },
                            )}
                            onSelectionChange={(changedOption) => {
                              const tmp = [...tasks];
                              tmp[itemIndex] = {
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                taskStatusId: changedOption?.value,
                                taskTypeId: item.taskTypeId,
                              };
                              setTasks(tmp);
                            }}
                            enableFiltering
                            action={{
                              label: "Modify",
                              onClick: () => {
                                setModalName(TASK_STATUS);
                              },
                            }}
                          />
                        ),
                      },
                      {
                        label: <Label label={"Task Notes"} />,
                        control: (item, itemIndex) => (
                          <Input
                            value={item.description}
                            onChange={(changeDetails) => {
                              const tmp = [...tasks];
                              tmp[itemIndex] = {
                                id: item.id,
                                name: item.name,
                                description: changeDetails.detail.value,
                                taskStatusId: item.taskStatusId,
                                taskTypeId: item.taskTypeId,
                              };
                              setTasks(tmp);
                            }}
                            placeholder="Enter description"
                          />
                        ),
                      },
                    ]}
                    empty="No items associated with the resource."
                  />
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddWorkflowTemplate);
