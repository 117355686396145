import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  Select,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import {
  useDeleteTaskMutation,
  useGetAllTaskStatusQuery,
  usePatchTaskMutation,
} from "../../redux/api/task";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../common/localStorage";
import {
  TASKS_CONTENT_DISPLAY_OPTIONS,
  TASKS_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { useMemo, useState } from "react";
import { Task } from "../../redux/api/task/types";
import ChangeTimeZone from "../../components/Timezone";
import LoadingScreen from "../../components/LoadingScreen";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useMyTaskFilter from "../../hooks/useMyTaskFilter";
import MyTaskFilters from "../../components/filter/mytask-filters";
import { findLast } from "lodash";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { captureException } from "@sentry/react";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { ADD_MY_TASK } from "../../helpers/constants";
import EditMyTaskModal from "./modals/editmytaskmodal";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}
function MyTasks({ setModalName }: PropsFromDispatch) {
  const [deleteTask] = useDeleteTaskMutation();
  const [patchTask] = usePatchTaskMutation();
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [isEditModalOpen, OpenEditModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Tasks-Table-Preferences",
    TASKS_TABLE_DEFAULT_PREFERENCES,
  );
  const { filteredTask, filter, isLoading, isFetching, setFilter, refetch } =
    useMyTaskFilter();
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();

  const taskstatusOptions: OptionDefinition[] = useMemo(() => {
    if (!taskstatuses) return [];
    return taskstatuses?.map((taskstatus) => ({
      label: taskstatus.name,
      value: "" + taskstatus.id,
    }));
  }, [taskstatuses]);
  const { items, collectionProps, paginationProps } = useCollection(
    filteredTask,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Task"}
            action={() => {
              setModalName(ADD_MY_TASK);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: {
        defaultState: {
          isDescending: true,
          sortingColumn: { sortingField: "createdDate" },
        },
      },
    },
  );

  const tableProps: TableProps<Task> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredTask.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedTask}
                  items={[
                    {
                      id: "edit_form",
                      text: "Edit",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedTask) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedTask?.id) OpenEditModal(true);
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    setModalName(ADD_MY_TASK);
                  }}
                  variant="primary"
                  data-test="add_task"
                >
                  Add Task
                </Button>
              </span>
            </div>
          }
        >
          Tasks
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      filter: <MyTaskFilters filter={filter} setFilter={setFilter} />,
      preferences: (
        <TablePreferences
          title="Task Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={TASKS_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedTask(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedTask(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedTask ? [selectedTask] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>Task ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "taskType",
          header: <div>Task Type</div>,
          cell: (item) => (
            <Link onFollow={() => OpenEditModal(true)}>
              {item.taskType?.name}
            </Link>
          ),
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{!!item?.name ? item?.name : "-"}</span>,
          sortingField: "name",
        },
        {
          id: "taskStatusId",
          header: <span>Task Status</span>,
          minWidth: 150,
          cell: (item) => <span>{item?.taskStatus?.name}</span>,
          editConfig: {
            editingCell: (item, { currentValue, setValue }) => {
              return (
                <Select
                  selectedOption={
                    findLast(
                      taskstatusOptions,
                      (option) =>
                        option?.value + "" ===
                        (currentValue ?? item?.taskStatus?.id + ""),
                    ) ?? null
                  }
                  expandToViewport
                  onChange={({ detail }) => {
                    if (!!detail?.selectedOption?.value)
                      setValue(detail.selectedOption.value);
                  }}
                  options={taskstatusOptions}
                />
              );
            },
          },
        },
        {
          id: "provider",
          header: <div>Provider</div>,
          cell: (item) => (
            <span>
              {!!item?.provider
                ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                : "-"}
            </span>
          ),
        },
        {
          id: "facility",
          header: <div>Facility</div>,
          cell: (item) => (
            <span>{!!item?.facility ? `${item?.facility?.name}` : "-"}</span>
          ),
        },
        {
          id: "workflow",
          header: <div>Workflow</div>,
          cell: (item) => (
            <span>
              {!!item?.workflow?.name ? (
                <Link
                  external
                  href={`/workflows/${item?.workflow?.id}?activeTabId=tasks`}
                >
                  {item?.workflow?.name}
                </Link>
              ) : (
                "-"
              )}
            </span>
          ),
        },
        {
          id: "startDate",
          header: <div>Start Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.startDate) ?? "-"}</span>,
          sortingField: "startDate",
        },
        {
          id: "completedDate",
          header: <div>Expiration Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.completedDate) ?? "-"}</span>
          ),
          sortingField: "completedDate",
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <span>{item?.description ?? "-"}</span>,
        },
        {
          id: "createdDate",
          header: <div>Created Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.createdDate) ?? "-"}</span>
          ),
        },
        {
          id: "dueDate",
          header: <div>Expiration Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.dueDate) ?? "-"}</span>,
          sortingField: "dueDate",
        },
        {
          id: "alertDays",
          header: <div>Alert Days</div>,
          cell: (item) => <span>{item?.alertDays ?? "-"}</span>,
          sortingField: "alertDays",
        },
      ],
      submitEdit: async (item, column, newValue) => {
        try {
          const columnId = column?.id;
          if (!columnId || !newValue) return;
          const temp = newValue as string;
          await notifyWrapper({
            promise: patchTask({
              id: item?.id,
              request: { [columnId]: temp },
            }),
            resourceName: "Workflow",
            actionName: "update",
          });
        } catch (error) {
          captureException(error);
        }
      },
    };
  }, [items, selectedTask]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedTask?.id)
            await notifyWrapper({
              promise: deleteTask({ id: selectedTask?.id }),
              resourceName: "Task",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header="Delete Task"
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedTask?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this task?"}
      />
      <EditMyTaskModal
        visible={isEditModalOpen}
        closeModal={() => OpenEditModal(false)}
        selectedTaskId={selectedTask?.id}
      />
    </div>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(MyTasks);
