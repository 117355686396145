import { api } from "..";
import {
  GetPrivilege,
  PrivilegeCreateRequest,
  PrivilegeType,
  PrivilegeTypeRequest,
  Privileges,
} from "./types";

const privilegeAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPrivileges: build.query<Privileges[], void>({
      query: () => `privilege/all`,
      providesTags: ["Privilege"],
    }),
    getPrivilege: build.query<Privileges, { privilegeId: string }>({
      query: ({ privilegeId }) => `privilege/${privilegeId}`,
      providesTags: (result, error, { privilegeId }) => [
        { type: "Privilege", id: privilegeId },
      ],
    }),
    getAllPrivilegeTypes: build.query<PrivilegeType[], void>({
      query: () => `privilege/type/all`,
      providesTags: ["Privilege"],
    }),

    getPrivilegeType: build.query<PrivilegeType, { privilegeTypeId: string }>({
      query: ({ privilegeTypeId }) => `privilege/type/${privilegeTypeId}`,
      providesTags: ["Privilege"],
    }),
    createPrivilege: build.mutation<Privileges, PrivilegeCreateRequest>({
      query: ({ ...body }) => {
        return {
          url: `privilege`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Privilege"],
    }),
    updatePrivilege: build.mutation<
      Privileges,
      Pick<Privileges, "id"> & Partial<PrivilegeCreateRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `privilege/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Privilege", id: arg.id },
        { type: "Privilege" },
      ],
    }),
    deletePrivilege: build.mutation<
      void,
      {
        privilegeId: string;
      }
    >({
      query: ({ privilegeId }) => ({
        url: `privilege/${privilegeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Privilege"],
    }),
    createPrivilegeType: build.mutation<void, PrivilegeTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "privilege/type",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Privilege"],
    }),
    saveAllPrivilegeTypes: build.mutation<void, PrivilegeType[]>({
      query: (privilegeTypes) => {
        return {
          url: "privilege/type/all",
          method: "POST",
          body: privilegeTypes,
        };
      },
      invalidatesTags: ["Privilege"],
    }),
    deletePrivilegeType: build.mutation<
      void,
      {
        privilegeTypeId: string;
      }
    >({
      query: ({ privilegeTypeId }) => ({
        url: `privilege/type/${privilegeTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Privilege"],
    }),
    updatePrivilegeType: build.mutation<
      void,
      Pick<PrivilegeType, "id"> & Partial<PrivilegeTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `privilege/type/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Privilege", id: arg.id },
        { type: "Privilege" },
      ],
    }),
    uploadPrivilegeAttachment: build.mutation<
      void,
      { privilegeId: string; file: File }
    >({
      query({ privilegeId, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (file) formData.append("file", file);

        return {
          url: `privilege/${privilegeId}/attachment`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Privilege" }],
    }),
  }),
});
export const {
  useSaveAllPrivilegeTypesMutation,
  useGetAllPrivilegesQuery,
  useGetPrivilegeQuery,
  useGetAllPrivilegeTypesQuery,
  useGetPrivilegeTypeQuery,
  useCreatePrivilegeMutation,
  useUpdatePrivilegeMutation,
  useDeletePrivilegeMutation,
  useCreatePrivilegeTypeMutation,
  useDeletePrivilegeTypeMutation,
  useUpdatePrivilegeTypeMutation,
  useUploadPrivilegeAttachmentMutation,
} = privilegeAPI;
