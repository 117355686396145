import { api } from "..";
import { Category, CategoryRequest } from "./types";

const CategoryAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCategory: build.query<Category[], void>({
      query: () => "/category/all",
      providesTags: ["Category"],
    }),
    getCategory: build.query<Category, { id: string }>({
      query: ({ id }) => `/category/${id}`,
      providesTags: ["Category"],
    }),
    addCategory: build.mutation<void, CategoryRequest>({
      query: ({ ...body }) => {
        return {
          url: "category",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Category"],
    }),
    updateCategory: build.mutation<
      void,
      { id: string } & Partial<CategoryRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `category/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: arg.id },
        { type: "Category" },
      ],
    }),
    deleteCategory: build.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useGetAllCategoryQuery,
  useGetCategoryQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = CategoryAPI;
