import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TableProps,
  Input,
} from "@cloudscape-design/components";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetAllProvidersQuery } from "../redux/api/provider/provider";
import { Provider } from "../redux/api/provider/types";
import { useTranslation } from "react-i18next";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { usePageIndex } from "../hooks/usePageIndex";

interface ProviderSafeNavigationProps {
  onChange?: (selectedProvider?: Provider) => void;
}
export default function ProviderSafeNavigation({
  onChange,
}: ProviderSafeNavigationProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [preferences, setPreferences] = React.useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { data = [], isLoading } = useGetAllProvidersQuery();
  const [filteredProviders, setFilteredProviders] = useState<Provider[]>(data);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredProviders,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "firstName",
            sortingComparator: (a, b) =>
              (a?.firstName ?? "").localeCompare(b?.firstName ?? ""),
          },
        },
      },
      selection: { keepSelection: true, trackBy: (item) => item.id },
      pagination: {
        pageSize: preferences?.pageSize,
        defaultPage: Number(searchParams.get("pageIndex")) ?? undefined,
      },
    },
  );

  usePageIndex(String(paginationProps.currentPageIndex));

  const [name, setName] = React.useState("");

  const selectedItem = useMemo(() => {
    const temp = data.find(
      (provider) => "" + provider.id === "" + searchParams.get("providerId"),
    );
    if (!!temp) return temp;
    else {
      const providerId = data.at(0)?.id;
      if (!!providerId)
        setSearchParams((searchParams) => {
          searchParams.set("providerId", String(providerId));
          return searchParams;
        });
      return undefined;
    }
  }, [data, searchParams]);

  useEffect(() => {
    onChange && onChange(selectedItem);
  }, [selectedItem, onChange]);

  useEffect(() => {
    let temp_filtered_providers = [...data];
    if (name) {
      temp_filtered_providers = temp_filtered_providers.filter((provider) =>
        [provider.firstName, provider.lastName]
          .join(" ")
          .toLowerCase()
          .includes(name.toLowerCase()),
      );
      setFilteredProviders(temp_filtered_providers);
    } else {
      setFilteredProviders(data);
    }
  }, [data, name]);

  const tableProps: TableProps<Provider> = React.useMemo(() => {
    return {
      header: (
        <Header variant="h2">
          {t("providerSafe.navigationTable.header.label")}
        </Header>
      ),
      filter: (
        <Input
          onChange={({ detail }) => setName(detail.value)}
          value={name}
          placeholder="Find by provider by name"
          type="search"
        />
      ),
      variant: "container",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: false,
      ...collectionProps,
      selectedItems: selectedItem ? [selectedItem] : [],
      wrapLines: true,
      stripedRows: true,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      onSelectionChange: (selectionChangeDetail) => {
        const temp_providerId =
          selectionChangeDetail.detail.selectedItems.at(0)?.id;
        if (!!temp_providerId) {
          searchParams.set("providerId", temp_providerId);
          setSearchParams(searchParams);
        }
      },
      onRowClick: (onRowClickDetail) => {
        const temp = onRowClickDetail.detail.item.id;
        if (!!temp) {
          searchParams.set("providerId", temp);
          setSearchParams(searchParams);
        }
      },
      empty: <p>{t("providerSafe.navigationTable.header.empty")}</p>,
      trackBy: (item) => item.id,
      loading: isLoading,
      items,
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>{t("providerSafe.navigationTable.header.fields.id")}</div>
          ),
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: (
            <div>{t("providerSafe.navigationTable.header.fields.name")}</div>
          ),
          cell: (item) => (
            <span>{item?.firstName + " " + item.lastName ?? "-"}</span>
          ),
          sortingField: "firstName",
          sortingComparator: (a, b) =>
            (a?.firstName ?? "").localeCompare(b?.firstName ?? ""),
        },
      ],
      columnDisplay: [{ id: "name", visible: true }],
    };
  }, [searchParams, selectedItem, items]);

  return <Table {...tableProps} />;
}
