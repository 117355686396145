import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];

export const PROVIDER_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Provider ID", alwaysVisible: false },
  { id: "fullname", label: "Profile", alwaysVisible: true },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "npiNumber", label: "NPI Number" },
  { id: "email", label: "Email" },
  { id: "facilityGroup", label: "Facility Group" },
  { id: "facility", label: "Facility" },
  { id: "licenseType", label: "License Type" },
  { id: "status", label: "Status" },
];

export const PROVIDER_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "fullname", visible: true },
      { id: "firstName", visible: true },
      { id: "lastName", visible: true },
      { id: "npiNumber", visible: true },
      { id: "email", visible: true },
      { id: "facilityGroup", visible: true },
      { id: "facility", visible: true },
      { id: "licenseType", visible: true },
      { id: "status", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const FACILITY_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Facility ID" },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "npiNumber", label: "NPI number" },
  { id: "facilityType", label: "Facility Type" },
  { id: "cliaId", label: "CLIA Id" },
  { id: "taxId", label: "Tax Id" },
  { id: "immunizationId", label: "Immunization Id" },
  { id: "taxonomyCode", label: "Taxonomy Code" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "mailingAddress", label: "Mailing Address" },
  { id: "addressLane1", label: "Address Line1" },
  { id: "addressLane2", label: "Address Line2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zipcode", label: "Zip Code" },
  { id: "country", label: "Country" },
];
export const FACILITY_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "npiNumber", visible: true },
      { id: "facilityType", visible: true },
      { id: "taxId", visible: true },
      { id: "cliaId", visible: true },
      { id: "immunizationId", visible: true },
      { id: "taxonomyCode", visible: true },
      { id: "phoneNumber", visible: true },
      { id: "mailingAddress", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zipcode", visible: true },
      { id: "country", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const LICENSE_TYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "License Type ID" },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "description", label: "Description" },
  { id: "code", label: "Code" },
];

export const LICENSE_TYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "code", visible: true },
      { id: "description", visible: false },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const CATEGORY_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Category ID" },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "description", label: "Description" },
  { id: "stateRequired", label: "State Required" },
  { id: "expirationRequired", label: "Expiration Required" },
];

export const CATEGORY_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "stateRequired", visible: true },
      { id: "expirationRequired", visible: true },
      { id: "description", visible: false },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const CONTRACTTYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ContractType ID", alwaysVisible: false },
  { id: "type", label: "Type", alwaysVisible: true },
  { id: "description", label: "Description" },
];
export const CONTRACTTYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "type", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const CONTRACT_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Contract ID", alwaysVisible: false },
  { id: "title", label: "Title", alwaysVisible: true },
  { id: "alertDays", label: "Alert Days", alwaysVisible: true },
  { id: "contractType", label: "Contract Type" },
  { id: "attachmentName", label: "Attachment Name" },
  { id: "attachmentSize", label: "Attachment Size" },
  { id: "attachmentContentType", label: "Attachment Content Type" },
  { id: "facilityName", label: "Facility Name" },
  { id: "facilityNPI", label: "Facility NPI" },
  { id: "facilityDescription", label: "Facility Description" },
  { id: "entityName", label: "Entity Name" },
  { id: "entityPhone", label: "Entity Phone" },
  { id: "entityEmail", label: "Entity Email" },
  { id: "executionDate", label: "Execution Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "lastRenewalDate", label: "Last Renewal Date" },
  { id: "description", label: "Description" },
  { id: "primaryContact", label: "Primary Contact" },
];
export const CONTRACT_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "title", visible: true },
      { id: "alertDays", visible: true },
      { id: "contractType", visible: true },
      { id: "attachmentName", visible: true },
      { id: "attachmentSize", visible: true },
      { id: "attachmentContentType", visible: true },
      { id: "facilityName", visible: true },
      { id: "facilityNPI", visible: true },
      { id: "facilityDescription", visible: true },
      { id: "entityName", visible: true },
      { id: "entityPhone", visible: true },
      { id: "entityEmail", visible: true },
      { id: "executionDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "lastRenewalDate", visible: true },
      { id: "description", visible: true },
      { id: "primaryContact", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const TASKS_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Task ID" },
  { id: "taskType", label: "Task Type", alwaysVisible: true },
  { id: "name", label: "Name" },
  { id: "admin", label: "Assigned To" },
  { id: "provider", label: "Provider" },
  { id: "facility", label: "Facility" },
  { id: "taskStatusId", label: "Task Status" },
  { id: "workflow", label: "Workflow" },
  { id: "startDate", label: "Start Date" },
  { id: "dueDate", label: "Due Date" },
  { id: "completedDate", label: "Completed Date" },
  { id: "description", label: "Description" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "alertDays", label: "Alert Days" },
];

export const TASKS_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "taskType", visible: true },
      { id: "name", visible: true },
      { id: "admin", visible: true },
      { id: "provider", visible: true },
      { id: "facility", visible: true },
      { id: "taskStatusId", visible: true },
      { id: "workflow", visible: true },
      { id: "startDate", visible: true },
      { id: "dueDate", visible: true },
      { id: "completedDate", visible: true },
      { id: "description", visible: true },
      { id: "expirationDate", visible: true },
      { id: "alertDays", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const WORKFLOWTEMPLATE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Workflow Template ID", alwaysVisible: false },
  { id: "name", label: "Name" },
];

export const WORKFLOWTEMPLATE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const ESIGNATURE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Signature Request ID", alwaysVisible: true },
  { id: "status", label: "Status" },
  { id: "provider", label: "Provider" },
  { id: "completed_date", label: "Completed Date" },
  { id: "readyform", label: "Ready Form" },
  { id: "attachment", label: "Attachment" },
  { id: "current_recipient", label: "Current Recipient" },
  { id: "primary_recipient", label: "Primary Recipient" },
  { id: "recipients", label: "Number of Recipients" },
  { id: "subject", label: "Subject" },
  { id: "created_date", label: "Created Date" },
  { id: "sender", label: "Sender" },
];

export const ESIGNATURE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "status", visible: true },
      { id: "provider", visible: true },
      { id: "completed_date", visible: true },
      { id: "readyform", visible: true },
      { id: "attachment", visible: true },
      { id: "current_recipient", visible: true },
      { id: "primary_recipient", visible: true },
      { id: "recipients", visible: true },
      { id: "subject", visible: true },
      { id: "created_date", visible: true },
      { id: "sender", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PRIVILEGETYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "PrivilegeType ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "description", label: "Description" },
];
export const PRIVILEGETYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PRIVILEGE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Privilege ID", alwaysVisible: false },
  { id: "privilegeType", label: "Privilege Type" },
  { id: "name", label: "Privilege Name", alwaysVisible: true },
  { id: "provider", label: "Provider" },
  { id: "providerAffiliation", label: "Provider Affiliation" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "followupDate", label: "Follow-up Date" },
];
export const PRIVILEGE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "privilegeType", visible: true },
      { id: "name", visible: true },
      { id: "provider", visible: true },
      { id: "providerAffiliation", visible: true },
      { id: "expirationDate", visible: true },
      { id: "followupDate", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const SIGNATUREREQUEST_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Request ID", alwaysVisible: false },
  { id: "signerEmail", label: "Recipient Email", alwaysVisible: true },
  { id: "status", label: "Status" },
  { id: "startDate", label: "Date Sent" },
  { id: "signedDate", label: "Date Signed" },
];
export const SIGNATUREREQUEST_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "signerEmail", visible: true },
      { id: "status", visible: true },
      { id: "startDate", visible: true },
      { id: "signedDate", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TEMPLATE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "digitalFormType", label: "Ready Form Type", alwaysVisible: true },
  { id: "template.name", label: "Attachment File Name" },
  { id: "datecreated", label: "Date Created" },
  { id: "description", label: "Notes" },
];
export const TEMPLATE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "digitalFormType", visible: true },
      { id: "template.name", visible: true },
      { id: "datecreated", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Member ID", alwaysVisible: false },
  { id: "firstName", label: "First Name", alwaysVisible: true },
  { id: "lastName", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "role", label: "Role" },
  { id: "lastlogin", label: "Last Login" },
];
export const MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "firstName", visible: true },
      { id: "lastName", visible: true },
      { id: "email", visible: true },
      { id: "role", visible: true },
      { id: "lastlogin", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Invitation ID", alwaysVisible: false },
  { id: "email", label: "Email", alwaysVisible: true },
  { id: "role", label: "Role" },
  { id: "status", label: "Status" },
  { id: "inviterName", label: "Inviter Name" },
  { id: "inviterEmail", label: "Inviter Email" },
];
export const MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "email", visible: true },
      { id: "role", visible: true },
      { id: "status", visible: true },
      { id: "inviterName", visible: true },
      { id: "inviterEmail", visible: false },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const MANAGEPROFILE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Profile ID", alwaysVisible: false },
  { id: "sectionName", label: "Section Name", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "facility", label: "Facility" },
  { id: "description", label: "description" },
];
export const MANAGEPROFILE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "sectionName", visible: true },
      { id: "type", visible: true },
      { id: "facility", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const GROUP_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Group ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "description", label: "Description" },
  { id: "dateCreated", label: "Date Created" },
];
export const GROUP_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "description", visible: true },
      { id: "dateCreated", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const AFFILIATION_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Affiliation ID", alwaysVisible: false },
  { id: "affiliationStatus", label: "Affiliation Status" },
  { id: "affiliationType", label: "Affiliation Type" },
  { id: "facility", label: "Facility" },
  { id: "provider", label: "Provider" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "alertDays", label: "Expiration Reminder" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "documentName", label: "Document Name" },
];
export const AFFILIATION_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "affiliationStatus", visible: true },
      { id: "affiliationType", visible: true },
      { id: "facility", visible: true },
      { id: "provider", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "alertDays", visible: true },
      { id: "name", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const DEA_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "DEA ID", alwaysVisible: false },
  { id: "deaNumber", label: "Dea Number", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "status", label: "Status" },
  { id: "state", label: "State" },
  { id: "documentName", label: "Document Name" },
];
export const DEA_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "deaNumber", visible: true },
      { id: "type", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "status", visible: true },
      { id: "state", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const EDUCATION_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Education ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "degree", label: "Degree" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
  { id: "residencyDirector", label: "Residency Director" },
  { id: "fellowshipDirector", label: "Fellowship Director" },
  { id: "documentName", label: "Document Name" },
];
export const EDUCATION_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "type", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "degree", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
      { id: "residencyDirector", visible: true },
      { id: "fellowshipDirector", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const LIABILITYINSURANCE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Liability Insurance ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "isCurrent", label: "isCurrent" },
  { id: "policyNumber", label: "Policy Number" },
  { id: "selfInsured", label: "Self Insured" },
  { id: "effectiveDate", label: "Effective Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "typeOfCoverage", label: "Type Of Coverage" },
  {
    id: "timeWithCurrentCarrierValue",
    label: "Time With Current Carrier Value",
  },
  { id: "timeWithCurrentCarrierUnit", label: "Time With Current Carrier Unit" },
  { id: "coveragePerOccurrence", label: "Coverage Per Occurrence" },
  { id: "aggregateCoverage", label: "Aggregate Coverage" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
  { id: "documentName", label: "Document Name" },
];
export const LIABILITYINSURANCE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "isCurrent", visible: true },
      { id: "policyNumber", visible: true },
      { id: "selfInsured", visible: true },
      { id: "effectiveDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "typeOfCoverage", visible: true },
      { id: "timeWithCurrentCarrierValue", visible: true },
      { id: "timeWithCurrentCarrierUnit", visible: true },
      { id: "coveragePerOccurrence", visible: true },
      { id: "aggregateCoverage", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const LICENSE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "License ID", alwaysVisible: false },
  { id: "licenseType", label: "License Type", alwaysVisible: true },
  { id: "licenseNumber", label: "License Number" },
  { id: "state", label: "State" },
  { id: "isCurrent", label: "is Current" },
  { id: "startDate", label: "Start Date" },
  { id: "notes", label: "Notes" },
  { id: "documentName", label: "Document Name" },
  { id: "expirationDate", label: "Expiration Date" },
];
export const LICENSE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "licenseType", visible: true },
      { id: "licenseNumber", visible: true },
      { id: "state", visible: true },
      { id: "isCurrent", visible: true },
      { id: "startDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "documentName", visible: true },
      { id: "notes", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PEERREFERENCE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Peer Reference ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "degree", label: "Degree" },
  { id: "specialty", label: "Specialty" },
  { id: "timeWithReferenceValue", label: "Time With Reference Value" },
  { id: "timeWithReferenceUnit", label: "Time With Reference Unit" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
];
export const PEERREFERENCE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "degree", visible: true },
      { id: "specialty", visible: true },
      { id: "timeWithReferenceValue", visible: true },
      { id: "timeWithReferenceUnit", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const SPECIALTYINFO_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Specialty ID", alwaysVisible: false },
  { id: "boardName", label: "Board Name", alwaysVisible: true },
  { id: "specialty", label: "Specialty" },
  { id: "psv", label: "PSV" },
  { id: "initialCertificationDate", label: "Initial Certification Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "reCertificationDate", label: "Recertification Date" },
  { id: "documentName", label: "Document Name" },
];
export const SPECIALTYINFO_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "boardName", visible: true },
      { id: "specialty", visible: true },
      { id: "psv", visible: true },
      { id: "initialCertificationDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "reCertificationDate", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const THIRDPARTYLOGIN_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Third Party Login ID", alwaysVisible: false },
  { id: "name", label: "Name" },
  { id: "accountNo", label: "Account No", alwaysVisible: true },
  { id: "websiteUrl", label: "Website Url" },
  { id: "username", label: "Username" },
  { id: "password", label: "Password" },
];
export const THIRDPARTYLOGIN_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "accountNo", visible: true },
      { id: "websiteUrl", visible: true },
      { id: "username", visible: true },
      { id: "password", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const WORKHISTORY_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Work History ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "positionHeld", label: "Position Held" },
  { id: "gapExplanation", label: "Gap Explanation" },
  { id: "referenceName", label: "Reference Name" },
  { id: "referenceEmail", label: "Reference Email" },
  { id: "referencePhone", label: "Reference Phone" },
  { id: "referernceFax", label: "Reference Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
  { id: "documentName", label: "Document Name" },
];
export const WORKHISTORY_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "type", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "positionHeld", visible: true },
      { id: "gapExplanation", visible: true },
      { id: "referenceName", visible: true },
      { id: "referencePhone", visible: true },
      { id: "referenceFax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PROVIDERSAFE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Provider Safe ID", alwaysVisible: false },
  { id: "firstName", label: "First Name", alwaysVisible: true },
  { id: "lastName", label: "Last Name" },
  { id: "facility", label: "Facility" },
  { id: "safeStatus", label: "Safe Status" },
];
export const PROVIDERSAFE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "firstName", visible: true },
      { id: "lastName", visible: true },
      { id: "facility", visible: true },
      { id: "safeStatus", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const ROLES_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Roles ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "description", label: "Description" },
];
export const ROLES_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const SAFECONTENT_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Document ID", alwaysVisible: false },
  { id: "documentName", label: "Document Name", alwaysVisible: true },
  { id: "categoryName", label: "Category Name" },
  { id: "fileName", label: "File Name" },
  { id: "expiration", label: "Expiration Date" },
  { id: "alertDays", label: "Expiration Reminder" },
  { id: "uploaded", label: "Uploaded Date" },
  { id: "notes", label: "Notes" },
];
export const SAFECONTENT_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "documentName", visible: true },
      { id: "categoryName", visible: true },
      { id: "fileName", visible: true },
      { id: "expiration", visible: true },
      { id: "alertDays", visible: true },
      { id: "uploaded", visible: true },
      { id: "notes", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "CredentialTemplate ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "categories", label: "Categories Count" },
  { id: "licenseType", label: "License Type" },
];
export const CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "categories", visible: true },
      { id: "licenseType", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const PAYER_CONTENT_DISPLAY_OPTIONS = [
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "payerId", label: "Payer ID" },
  { id: "website", label: "Website" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zipcode", label: "Zip Code" },
  { id: "contactName", label: "Contact Name" },
  { id: "contactEmail", label: "Contact Email" },
  { id: "contactPhone", label: "Contact Phone" },
  { id: "contactFax", label: "Contact Fax" },
];
export const PAYER_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "name", visible: true },
      { id: "payerId", visible: true },
      { id: "website", visible: true },
      { id: "addressLane1", visible: false },
      { id: "addressLane2", visible: false },
      { id: "city", visible: false },
      { id: "state", visible: true },
      { id: "zipcode", visible: false },
      { id: "contactName", visible: false },
      { id: "contactEmail", visible: false },
      { id: "contactPhone", visible: false },
      { id: "contactFax", visible: false },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PAYER_GROUP_CONTENT_DISPLAY_OPTIONS = [
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "groupCode", label: "Group Code" },
  { id: "description", label: "Notes" },
];
export const PAYER_GROUP_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "name", visible: true },
      { id: "groupCode", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const CREDENTIALPACKAGE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "CredentialPackage ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "packageType", label: "Package Type" },
  { id: "provider", label: "Provider" },
  { id: "status", label: "Status" },
  { id: "credentialTemplate", label: "Credential Template" },
  { id: "submittedDate", label: "Submitted Date" },
  { id: "recredentialingDate", label: "Re-credentialing Date" },
  { id: "followupDate", label: "Follow-up Date" },
];
export const PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "payer", visible: true },
      { id: "network", visible: true },
      { id: "provider", visible: true },
      { id: "payerProviderId", visible: true },
      { id: "facility", visible: true },
      { id: "status", visible: true },
      { id: "submittedDate", visible: true },
      { id: "approvedDate", visible: true },
      { id: "effectiveDate", visible: true },
      { id: "recredentialingDate", visible: true },
      { id: "followupDate", visible: true },
      { id: "description", visible: true },
      { id: "workflow", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const ENROLLEDPAYER_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Enrolled Payer ID" },
  { id: "payer", label: "Payer" },
  { id: "network", label: "Network" },
  { id: "provider", label: "Provider" },
  { id: "payerProviderId", label: "Provider ID" },
  { id: "facility", label: "Facility" },
  { id: "status", label: "Status" },
  { id: "submittedDate", label: "Submitted Date" },
  { id: "approvedDate", label: "Approved Date" },
  { id: "effectiveDate", label: "Effective Date" },
  { id: "recredentialingDate", label: "Re-Enrollment Date" },
  { id: "followupDate", label: "Follow Up Date" },
  { id: "description", label: "Notes" },
];
export const ENROLLEDPAYER_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "payer", visible: true },
      { id: "network", visible: true },
      { id: "provider", visible: true },
      { id: "payerProviderId", visible: true },
      { id: "facility", visible: true },
      { id: "status", visible: true },
      { id: "submittedDate", visible: true },
      { id: "approvedDate", visible: true },
      { id: "effectiveDate", visible: true },
      { id: "recredentialingDate", visible: true },
      { id: "followupDate", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "PayerEnrollment ID" },
  { id: "payer", label: "Payer" },
  { id: "network", label: "Network" },
  { id: "provider", label: "Provider" },
  { id: "payerProviderId", label: "Provider ID" },
  { id: "facility", label: "Facility" },
  { id: "status", label: "Status" },
  { id: "submittedDate", label: "Submitted Date" },
  { id: "approvedDate", label: "Approved Date" },
  { id: "effectiveDate", label: "Effective Date" },
  { id: "recredentialingDate", label: "Re-Enrollment Date" },
  { id: "followupDate", label: "Follow Up Date" },
  { id: "description", label: "Notes" },
  { id: "workflow", label: "Workflow" },
];
export const CREDENTIALPACKAGE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "packageType", visible: true },
      { id: "provider", visible: true },
      { id: "status", visible: true },
      { id: "credentialTemplate", visible: true },
      { id: "submittedDate", visible: true },
      { id: "recredentialingDate", visible: true },
      { id: "followupDate", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const PSV_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Psv ID", alwaysVisible: false },
  { id: "primarySource", label: "Source", alwaysVisible: true },
  { id: "sourceType", label: "Type" },
  { id: "status", label: "Statue" },
  { id: "createdDate", label: "Created Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "alertDays", label: "Alert Reminder" },
  { id: "attachmentName", label: "Attachment Name" },
];

export const PSV_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "primarySource", visible: true },
      { id: "sourceType", visible: true },
      { id: "status", visible: true },
      { id: "createdDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "alertDays", visible: true },
      { id: "attachmentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const WORKFLOW_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Workflow ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "workflowStatusId", label: "Workflow Status" },
  { id: "workflowTemplate", label: "Workflow Template" },
  { id: "admin", label: "Assigned To" },
  { id: "provider", label: "Provider" },
  { id: "startDate", label: "Start Date" },
  { id: "dueDate", label: "Due date" },
  { id: "completedDate", label: "Completed Date" },
  { id: "description", label: "Description" },
  { id: "createdDate", label: "Created Date" },
];
export const WORKFLOW_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "workflowStatusId", visible: true },
      { id: "workflowTemplate", visible: true },
      { id: "admin", visible: true },
      { id: "provider", visible: true },
      { id: "startDate", visible: true },
      { id: "dueDate", visible: true },
      { id: "completedDate", visible: true },
      { id: "description", visible: true },
      { id: "createdDate", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>,
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
