import { FormProvider, useForm } from "react-hook-form";
import { ProviderEducationRequestType } from "../../../../redux/api/provider/section/types";
import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderEducationMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import "./edit-education.css";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useTranslation } from "react-i18next";
import { Countries, USStatesList } from "../../../../helpers/constants";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";

export default function EditEducation() {
  const { providerId, educationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<ProviderEducationRequestType>();
  const { data: degrees = [] } = useGetAllDegreeQuery();
  const [type] = methods.watch(["type"]);
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });

  useEffect(() => {
    methods.clearErrors("residencyDirector");
    methods.clearErrors("fellowshipDirector");
  }, [type]);

  const [addProviderEducation] = useAddProviderEducationMutation();

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderEducationRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderEducation({
          providerId: providerId,
          ...data,
          ...(selectedDocument.value !== "-1" && {
            documentId: selectedDocument.value,
          }),
        }),
        resourceName: "education",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  useEffect(() => {
    if (provider && provider.providerEducations) {
      const education = provider.providerEducations.find(
        (education) => education?.id + "" === educationId + "",
      );

      if (education) {
        methods.setValue("id", education.id);
        methods.setValue("type", education.type);
        methods.setValue("name", education.name);
        methods.setValue("startDate", education.startDate);
        methods.setValue("endDate", education.endDate);
        methods.setValue("isCurrent", education.isCurrent);
        education.degree?.id &&
          methods.setValue("degreeId", education.degree?.id);
        methods.setValue("email", education.email);
        methods.setValue("phone", education.phone);
        methods.setValue("fax", education.fax);
        methods.setValue("addressLane1", education.addressLane1);
        methods.setValue("addressLane2", education.addressLane2);
        methods.setValue("city", education.city);
        methods.setValue("state", education.state);
        methods.setValue("zip", education.zip);
        methods.setValue("country", education.country);
        methods.setValue("residencyDirector", education.residencyDirector);
        methods.setValue("fellowshipDirector", education.fellowshipDirector);
        education?.document &&
          setSelectedDocument({
            label: education?.document?.name,
            value: education?.document?.id,
          });
      }
    }
  }, [provider, educationId]);

  return (
    <SpaceBetween size="s">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("providerProfiles.education.editEducation.header.breadcrumb.text")}`,
            href: `/manageprovider/${providerId}`,
          },
          {
            text: provider?.firstName + " " + provider?.lastName,
            href: `/manageprovider/${providerId}/profile?activeTabId=education`,
          },
          {
            text: `${t("providerProfiles.education.editEducation.header.breadcrumb.currentPage")}`,
            href: "#",
          },
        ]}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                formAction="none"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t(
                  "providerProfiles.education.editEducation.header.actions.cancel",
                )}
              </Button>
              <Button variant="primary">
                {t(
                  "providerProfiles.education.editEducation.header.actions.submit",
                )}
              </Button>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <SpaceBetween size="l">
              <Container
                header={
                  <SpaceBetween size="xs">
                    <Header variant="h2">
                      {t(
                        "providerProfiles.education.editEducation.header.label",
                      )}
                    </Header>
                    <Divider />
                  </SpaceBetween>
                }
              >
                <SpaceBetween size="l">
                  <FormSection columns={1} spacing="l">
                    <RHFSelect
                      name="type"
                      label={t(
                        "providerProfiles.education.editEducation.header.fields.type",
                      )}
                      stretch={false}
                      options={[
                        "Undergraduate",
                        "Graduate",
                        "Medical school",
                        "Internship",
                        "Residency",
                        "Fellowship",
                      ].map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.education.editEducation.header.fields.name.label",
                      )}
                      name="name"
                      description={t(
                        "providerProfiles.education.editEducation.header.fields.name.description",
                      )}
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="degreeId"
                      label={t(
                        "providerProfiles.education.editEducation.header.fields.degree",
                      )}
                      stretch={false}
                      options={degrees.map((degree) => ({
                        value: degree.id,
                        label: degree.name,
                      }))}
                      enableFiltering
                      rules={{ required: "This field is required" }}
                    />
                    <FormSection columns={3}>
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.startDate",
                        )}
                        name="startDate"
                        stretch={false}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.endDate",
                        )}
                        name="endDate"
                        stretch={false}
                      />
                    </FormSection>
                    {type === "Residency" && (
                      <RHFTextField
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.residencyDirector",
                        )}
                        name="residencyDirector"
                        /*   rules={
                          type === "Residency"
                            ? { required: "This field is required" }
                            : { required: false }
                        } */
                        stretch={false}
                      />
                    )}
                    {type === "Fellowship" && (
                      <RHFTextField
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.fellowshipDirector",
                        )}
                        name="fellowshipDirector"
                        /*   rules={
                          type === "Fellowship"
                            ? { required: "This field is required" }
                            : { required: false }
                        } */
                        stretch={false}
                      />
                    )}
                  </FormSection>
                  <FormSection
                    header={
                      <Box fontSize="heading-s" variant="h5">
                        {t(
                          "providerProfiles.education.editEducation.header.helperText",
                        )}
                      </Box>
                    }
                    headerDividerTop
                  >
                    <RHFTextField
                      label={t(
                        "providerProfiles.education.editEducation.header.fields.email",
                      )}
                      name="email"
                      stretch={false}
                      rules={{
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email",
                        },
                      }}
                    />
                    <FormSection columns={3}>
                      <RHFPhoneNumber
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.phone",
                        )}
                        name="phone"
                      />

                      <RHFTextField
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.fax",
                        )}
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch={false}
                      />
                    </FormSection>
                    <RHFTextField
                      label={t(
                        "providerProfiles.education.editEducation.header.fields.addressLane1",
                      )}
                      name="addressLane1"
                      stretch={false}
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.education.editEducation.header.fields.addressLane2",
                      )}
                      name="addressLane2"
                      stretch={false}
                    />
                    <FormSection columns={3}>
                      <RHFTextField
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.city",
                        )}
                        name="city"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.zipcode",
                        )}
                        name="zip"
                        stretch={false}
                      />
                    </FormSection>
                    <FormSection columns={3}>
                      <RHFSelect
                        name="state"
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.state",
                        )}
                        options={USStatesList.map((state) => ({
                          value: state.value,
                        }))}
                        stretch={false}
                      />
                      <RHFSelect
                        name="country"
                        label={t(
                          "providerProfiles.education.editEducation.header.fields.country",
                        )}
                        options={Countries.map((country) => ({
                          value: country.value,
                        }))}
                        stretch={false}
                      />
                    </FormSection>
                  </FormSection>
                  <DocumentSection
                    providerId={providerId}
                    selectedDocument={selectedDocument}
                    setSelectedDocument={setSelectedDocument}
                  />
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </FormProvider>
        </Form>
      </form>
    </SpaceBetween>
  );
}
