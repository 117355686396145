import {
  Box,
  Button,
  ExpandableSection,
  Form,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Divider } from "antd";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { setModalName } from "../../../redux/UI/actions";
import { TaskRequest } from "../../../redux/api/task/types";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
  useGetTaskQuery,
  useUpdateMyTaskMutation,
} from "../../../redux/api/task";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { useGetAllWorkflowQuery } from "../../../redux/api/workflow/workflow";
import { useGetAllProvidersQuery } from "../../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../../redux/api/facility/facility";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import "./mytaskmodal.css";
import { useGetOrganizationDetailsQuery } from "../../../redux/api/organization/organization";
import { alertReminderOptions } from "../../../helpers/constants";

type EditMyTaskModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
  selectedTaskId: string | undefined;
};

function EditMyTask({
  visible,
  closeModal,
  selectedTaskId,
}: EditMyTaskModalProps) {
  const methods = useForm<TaskRequest>();
  const { data: task, fulfilledTimeStamp } = useGetTaskQuery(
    !!selectedTaskId ? { id: selectedTaskId } : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [updateTask] = useUpdateMyTaskMutation();

  useEffect(() => {
    if (!!task) {
      methods.setValue("name", task.name ?? "");
      methods.setValue("description", task.description ?? "");
      methods.setValue("taskStatusId", task.taskStatus?.id ?? "");
      methods.setValue("taskTypeId", task.taskType?.id ?? "");
      methods.setValue("workflowId", task.workflow?.id ?? "");
      methods.setValue("providerId", task.provider?.id ?? "");
      methods.setValue("facilityId", task.facility?.id ?? "");
      methods.setValue("startDate", task.startDate ?? "");
      methods.setValue("dueDate", task.dueDate ?? "");
      methods.setValue("completedDate", task.completedDate ?? "");
      methods.setValue("alertDays", task.alertDays ?? "");
    }
  }, [fulfilledTimeStamp, methods, task]);

  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: workflows = [] } = useGetAllWorkflowQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const onSubmit = async (data: TaskRequest) => {
    if (!!selectedTaskId) {
      await notifyWrapper({
        promise: updateTask({ id: selectedTaskId, ...data }),
        resourceName: "task",
        actionName: "update",
      });
      closeModal();
    }
  };

  return (
    <Modal
      header={<Header>Edit My Task</Header>}
      visible={visible}
      size="large"
      onDismiss={closeModal}
    >
      <SpaceBetween size={"s"}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <SpaceBetween size="l">
                <FormSection columns={3}>
                  <RHFSelect
                    name="taskTypeId"
                    label="Task Type"
                    options={taskTypes.map((type) => ({
                      label: type.name,
                      value: "" + type.id,
                    }))}
                    rules={{ required: "This field is required" }}
                    stretch={false}
                  />
                  <RHFTextField
                    label={"Task Name"}
                    name="name"
                    stretch={false}
                  />
                  <RHFSelect
                    name="taskStatusId"
                    label="Task Status"
                    options={taskstatuses.map((status) => ({
                      label: status.name,
                      value: "" + status.id,
                    }))}
                    stretch={false}
                  />
                </FormSection>

                <FormSection columns={3}>
                  <RHFSelect
                    label={"Provider"}
                    name={"providerId"}
                    stretch={false}
                    filteringType="auto"
                    options={providers.map((provider) => ({
                      label: provider.firstName + " " + provider.lastName,
                      value: "" + provider.id,
                    }))}
                  />
                  <RHFSelect
                    label={"Facility"}
                    name={"facilityId"}
                    stretch={false}
                    filteringType="auto"
                    options={facilities.map((facility) => ({
                      label: facility.name,
                      value: "" + facility.id,
                    }))}
                  />
                  {!!organization?.organizationServiceLimits?.workflows && (
                    <RHFSelect
                      name="workflowId"
                      label="Associated Workflow"
                      options={workflows.map((workflow) => ({
                        label: workflow.name,
                        value: "" + workflow.id,
                      }))}
                      stretch={false}
                    />
                  )}
                </FormSection>

                <FormSection columns={2}>
                  <RHFDatePicker
                    label={"Start date"}
                    name={"startDate"}
                    defaultValue={new Date().toUTCString()}
                    stretch={false}
                  />
                  <RHFDatePicker
                    label={"Completed date"}
                    name={"completedDate"}
                    stretch={true}
                  />
                </FormSection>
                <FormSection columns={2}>
                  <RHFDatePicker label={"Expiration Date"} name={"dueDate"} />
                  <RHFSelect
                    label={"Alert Days"}
                    name={"alertDays"}
                    options={alertReminderOptions}
                  />
                </FormSection>
              </SpaceBetween>
              <Divider />
              <ExpandableSection headerText="Notes">
                <FormSection>
                  <RHFTextArea
                    label={"Notes"}
                    name="description"
                    stretch={true}
                  />
                </FormSection>
              </ExpandableSection>
            </Form>
          </form>
        </FormProvider>
      </SpaceBetween>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditMyTask);
