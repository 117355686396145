import { api } from "..";
import { Specialty, SpecialtyInfo } from "./types";

const specialtyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSpecialty: build.query<Specialty[], void>({
      query: () => "specialty/all",
      providesTags: ["Specialty"],
    }),
    saveAllSpecialty: build.mutation<void, SpecialtyInfo[]>({
      query: (types) => {
        return {
          url: `specialty`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Specialty"],
    }),
    deleteSpecialty: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `specialty/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Specialty"],
    }),
  }),
});

export const {
  useGetAllSpecialtyQuery,
  useSaveAllSpecialtyMutation,
  useDeleteSpecialtyMutation,
} = specialtyApi;
