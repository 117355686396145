import { Container } from "@cloudscape-design/components";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { disabledElements } from "../../helpers/constants";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/react";

interface PDFTronViewerProps {
  attachmentFile?: File;
  attachmentKey?: string;
  replaceFile?: (file: File) => void;
}

export default function PDFTronViewer({
  attachmentFile,
  attachmentKey,
  replaceFile,
}: PDFTronViewerProps) {
  const viewer = useRef<HTMLDivElement>(null);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [instance, setInstance] = useState<WebViewerInstance | undefined>(
    undefined,
  );

  useEffect(() => {
    if (viewer.current != null)
      WebViewer(
        {
          path: "/webviewer",
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
        },
        viewer.current,
      )
        .then((instance) => {
          instance.UI.disableElements(disabledElements);
          instance.UI.disableElements(["toolsHeader"]);

          instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
          const replaceButtonStyle = {
            backgroundColor: "#0972d3",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
            border: "none",
            fontSize: "16px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          };

          const handleReplace = () => {
            hiddenFileInput && hiddenFileInput.current?.click();
          };

          const replaceBttn = () => {
            const menuBtn = React.createElement(
              "button",
              {
                style: replaceButtonStyle,
                onClick: handleReplace,
              },
              "Replace",
            );
            return menuBtn;
          };

          const buttonStyle = {
            backgroundColor: "#0972d3",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
            border: "none",
            fontSize: "16px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: "120px",
          };

          const iconStyle = {
            fill: "#fff",
            marginRight: "5px",
          };

          const downloadBtn = () => {
            const menuBtn = React.createElement(
              "button",
              {
                style: buttonStyle,
                onClick: () => {
                  instance.UI.downloadPdf();
                },
              },
              React.createElement(
                "svg",
                {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "22",
                  height: "16",
                  viewBox: "0 0 512 512",
                  style: iconStyle,
                },
                React.createElement("path", {
                  d: "M272 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V329.4L139.3 228.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l128 128c6.2 6.2 16.4 6.2 22.6 0l128-128c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L272 329.4V16zM140.1 320H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384c0-35.3-28.7-64-64-64H371.9l-32 32H448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32H172.1l-32-32zM432 416a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z",
                }),
              ),
              "Download",
            );

            return menuBtn;
          };
          setInstance(instance);
          instance.UI.setHeaderItems((header) => {
            if (!!replaceFile)
              header.push({
                type: "customElement",
                render: replaceBttn,
              });
            header.push({
              type: "divider",
            });
            header.push({
              type: "customElement",
              render: downloadBtn,
            });
          });
        })
        .catch((error) => {
          captureException(error);
          console.log(error);
        });
  });

  useEffect(() => {
    if (instance && !!attachmentKey) {
      instance.UI.loadDocument(encodeURI(getFileUrl(attachmentKey)), {
        withCredentials: true,
      });
    }
  }, [instance]);

  useEffect(() => {
    if (instance && attachmentFile) {
      instance.UI.loadDocument(attachmentFile, {
        filename: attachmentFile.name,
      });
    }
  }, [instance, attachmentFile?.size]);

  return (
    <Container>
      <input
        ref={hiddenFileInput}
        type="file"
        onChange={(fileUpload) => {
          const uploadFile = fileUpload.currentTarget.files?.item(0);
          if (!!uploadFile && !!replaceFile) replaceFile(uploadFile);
        }}
        style={{ display: "none" }}
      />
      <div
        ref={viewer}
        key={attachmentKey}
        style={{
          height: `calc(100vh - 180px)`,
        }}
      />
    </Container>
  );
}
