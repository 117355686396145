import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/usePayerFilter";
import { USStatesList } from "../../helpers/constants";

interface PayerFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function PayerFilters({
  filter,
  setFilter,
  countText,
}: PayerFiltersProps) {
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Payer Name",
    value: "payerName",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (!!filter.stateFilterOptions && filter.stateFilterOptions.length > 0)
      temp = [
        ...temp,
        {
          label: `State = ${join(
            filter.stateFilterOptions.map(
              (stateFilterOptions) => stateFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterPayerName: "",
              filterPayerID: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Payer Name", value: "payerName" },
            { label: "Payer ID", value: "payerId" },
            { label: "State", value: "state" },
          ]}
        />
        <div>
          {selectedProperty.value === "payerName" && (
            <TextFilter
              filteringPlaceholder="Find Payer Name"
              filteringText={filter.filterPayerName}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterPayerName: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "payerId" && (
            <TextFilter
              filteringPlaceholder="Find Payer ID"
              filteringText={filter.filterPayerID}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterPayerID: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "state" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter.stateFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  stateFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={USStatesList.map((state) => ({
                label: state.value,
                value: state.value + "",
              }))}
              placeholder="Choose State"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("State")) {
            setFilter({
              ...filter,
              stateFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
