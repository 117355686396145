import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Divider } from "@mui/material";
import {
  Box,
  Button,
  FormField,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";

import { concat } from "lodash";

import { useTranslation } from "react-i18next";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import FormSection from "../../components/FormSection";
import { PATH_DASHBOARD } from "../../routes/paths";

type selectedWorkFlowsPorps = {
  selectedWorkflow: OptionDefinition;
  setselectedWorkflow: React.Dispatch<React.SetStateAction<{}>>;
};

const WorkflowSection = ({
  workflowId,
  selectedWorkflow,
  setselectedWorkflow,
}: {
  workflowId?: string;
} & selectedWorkFlowsPorps) => {
  const {
    data: workflowData = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllWorkflowQuery();
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <FormSection
        columns={2}
        header={
          <Box fontSize="heading-s" variant="h5">
            {t("payerenrollments.addPayerEnrollment.header.fields.workflow")}
          </Box>
        }
      >
        <FormField stretch={true}>
          <Select
            selectedOption={selectedWorkflow}
            onChange={({ detail }) =>
              setselectedWorkflow(detail.selectedOption)
            }
            filteringType="auto"
            options={concat(
              [{ label: "None - no Workflow docs selected ", value: "-1" }],
              workflowData.map((workFlowItem) => {
                return {
                  label: workFlowItem?.name,
                  value: "" + workFlowItem?.id,
                };
              }),
            )}
          />
        </FormField>
        <FormField stretch={false}>
          <SpaceBetween size="m" direction="horizontal">
            {isFetching || isLoading ? (
              <Spinner />
            ) : (
              <Button
                iconName="refresh"
                variant="icon"
                formAction="none"
                onClick={() => {
                  refetch();
                }}
              />
            )}
            <Button
              href={PATH_DASHBOARD?.manage?.workflows?.add}
              iconAlign="right"
              iconName="external"
              target="_blank"
              formAction="none"
            >
              {t("workflow.table.header.actions.createdNewWorkflow")}
            </Button>
          </SpaceBetween>
        </FormField>
      </FormSection>
    </>
  );
};
export default WorkflowSection;
