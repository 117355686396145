import { BreadcrumbGroup, SpaceBetween } from "@cloudscape-design/components";
import { useGetAllExpiredDocumentsQuery } from "../redux/api/reporting/reporting";
import ChangeTimeZone from "../components/Timezone";
import { useTranslation } from "react-i18next";

import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./ReportingToolBar";

export default function ExpiredDocuments() {
  const { data = [], isLoading } = useGetAllExpiredDocumentsQuery();
  const { t } = useTranslation();
  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("reports.expiredDocuments.breadcrumb.text")}`,
            href: "/reports",
          },
          {
            text: `${t("reports.expiredDocuments.breadcrumb.currentPage")}`,
            href: "#",
          },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          columns={[
            {
              field: "DocumentName",
              headerName: "Document_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.document?.name ?? "-",
            },
            {
              field: "expirationDate",
              headerName: "Expiration_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.document?.expirationDate) ?? "-",
            },
            {
              field: "createdDate",
              headerName: "Created_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.document?.createdDate) ?? "-",
            },
            {
              field: "category",
              headerName: "Category",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.document?.category?.name ?? "-",
            },
            {
              field: "lastName",
              headerName: "Provider_LastName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.lastName ?? "-",
            },
            {
              field: "firstname",
              headerName: "Provider_FirstName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.firstName ?? "-",
            },
            {
              field: "npi",
              headerName: "NPI_Number",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.npiNumber ?? "-",
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.email ?? "-",
            },
            {
              field: "licenseType",
              headerName: "License_Type",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.licenseType?.name ?? "-",
            },
            {
              field: "identificationNumber",
              headerName: "IdentificationNumber",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.identificationNumber ?? "-",
            },
            {
              field: "facility",
              headerName: "Facility_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.facility?.name ?? "-",
            },
          ]}
          rows={data}
          getRowId={(row) => row?.provider?.id + "_" + row?.document?.id}
          loading={isLoading}
          slots={{ toolbar: ToolBar }}
          disableRowSelectionOnClick={true}
          disableColumnMenu
          density="compact"
        />
      </div>
    </SpaceBetween>
  );
}

const ToolBar = () => <CustomToolbar fileName="expired_documents_report" />;
