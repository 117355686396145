import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllSignatureRequestsQuery } from "../redux/api/signaturerequest/signaturerequest";
import { eSignatureResponse } from "../redux/api/signaturerequest/types";

export type FilterType = {
  providerFilterOptions: OptionDefinition[];
  digitalformFilterOptions: OptionDefinition[];
  filterID: string;
  filterSubjectName: string;
  statusFilterOptions: OptionDefinition[];
};

export default function useESignatureFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllSignatureRequestsQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterSubjectName: "",
    filterID: "",
    statusFilterOptions: [],
    providerFilterOptions: [],
    digitalformFilterOptions: [],
  });

  const [filteredSignature, setFilteredSignature] =
    useState<eSignatureResponse[]>(data);

  useEffect(() => {
    setFilteredSignature(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_signature = [...data];
    if (!!filter.filterID) {
      temp_filtered_signature = temp_filtered_signature.filter(
        (signature) => signature.id + "" === filter.filterID + "",
      );
    }

    if (filter.filterSubjectName) {
      temp_filtered_signature = temp_filtered_signature.filter((signature) =>
        signature.subject
          .toLowerCase()
          .includes(filter.filterSubjectName.toLowerCase()),
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (!!selectedStatus && selectedStatus.length > 0) {
      temp_filtered_signature = temp_filtered_signature.filter(
        (signature) => selectedStatus.indexOf(signature.status + "") >= 0,
      );
    }

    const selectedProviderIdList: string[] =
      filter.providerFilterOptions
        ?.map((option) => option.value + "")
        ?.filter((value) => !!value) ?? [];

    if (!!selectedProviderIdList && selectedProviderIdList?.length > 0) {
      const selectedProviderIdsSet = new Set(selectedProviderIdList);
      // Filter signatures in a single pass
      temp_filtered_signature = temp_filtered_signature.filter((signature) =>
        signature.recipients?.some((recipient) =>
          selectedProviderIdsSet.has(recipient?.provider?.id + ""),
        ),
      );
    }

    const selectedReadyformIDList: string[] =
      filter.digitalformFilterOptions
        ?.map((option) => option.value + "")
        ?.filter((value) => !!value) ?? [];

    if (!!selectedReadyformIDList && selectedReadyformIDList?.length > 0) {
      const selectedReadyformIDSet = new Set(selectedReadyformIDList);
      temp_filtered_signature = temp_filtered_signature.filter((signature) =>
        selectedReadyformIDSet.has(signature?.digitalForm?.id + ""),
      );
    }

    setFilteredSignature(temp_filtered_signature);
  }, [filter]);

  return {
    filteredSignature,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
