import {
  Table,
  TableProps,
  Box,
  Header,
  Button,
  ButtonDropdown,
  Pagination,
  Input,
} from "@cloudscape-design/components";
import { LicenseType } from "../redux/api/licensetype/types";
import { useState, useMemo, useEffect } from "react";
import StyledRouterLink from "../components/styled-route-link";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useNavigate } from "react-router";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import {
  LICENSE_TYPE_CONTENT_DISPLAY_OPTIONS,
  LICENSE_TYPE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../components/LoadingScreen";
import {
  useDeleteLicenseTypeMutation,
  useGetAllLicenseTypeQuery,
} from "../redux/api/licensetype/licensetype";
import { PATH_DASHBOARD } from "../routes/paths";

export default function ManageLicenseType() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllLicenseTypeQuery();
  const [deleteLicenseType] = useDeleteLicenseTypeMutation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [name, setName] = useState("");
  const [filteredLicenseType, setFilteredLicenseType] =
    useState<LicenseType[]>(data);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Licensetype-Table-Preferences",
    LICENSE_TYPE_TABLE_DEFAULT_PREFERENCES,
  );
  useEffect(() => {
    let temp_filtered_licensetype = [...data];
    if (name) {
      temp_filtered_licensetype = temp_filtered_licensetype.filter((type) =>
        type.name.toLowerCase().includes(name.toLowerCase()),
      );
      setFilteredLicenseType(temp_filtered_licensetype);
    } else {
      setFilteredLicenseType(data);
    }
  }, [data, name, filteredLicenseType]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredLicenseType,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"License Type"}
            action={() => {
              navigate(`add`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedLicenseType, setSelectedLicenseType] = useState<LicenseType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  const tableProps: TableProps<LicenseType> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredLicenseType.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedLicenseType}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission ? "Edit" : "View",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedLicenseType) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedLicenseType?.id)
                        navigate(
                          PATH_DASHBOARD.manage.licensetype.edit(
                            selectedLicenseType?.id,
                          ),
                        );
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  disabled={!hasPermission}
                  onClick={() => {
                    navigate(`add`);
                  }}
                  variant="primary"
                  data-test="add_licensetype"
                >
                  Add License Type
                </Button>
              </span>
            </div>
          }
        >
          License Types
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="License Type Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={LICENSE_TYPE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      filter: (
        <Input
          onChange={({ detail }) => setName(detail.value)}
          value={name}
          placeholder="Find by license type by name"
          type="search"
        />
      ),
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedLicenseType(
            selectionChangeDetail.detail.selectedItems.at(0),
          );
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedLicenseType(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedLicenseType ? [selectedLicenseType] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>License Type ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `${item.id}` : `#`}
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <span>{item?.description}</span>,
        },
        {
          id: "code",
          header: <div>Code</div>,
          cell: (item) => <span>{item?.code}</span>,
        },
      ],
    };
  }, [tablePreferences, items, selectedLicenseType]);

  return (
    <div style={{ paddingTop: "24px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedLicenseType?.id)
            await notifyWrapper({
              promise: deleteLicenseType({ id: selectedLicenseType?.id }),
              resourceName: "license Type",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header="Delete License Type"
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedLicenseType?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this license type?"}
      />
    </div>
  );
}
