import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetDigitalFormQuery,
  useUpdateDigitalFormAnnotationMutation,
} from "../../redux/api/digitalform/digitalforms";
import { useNavigate, useParams } from "react-router-dom";
import TemplateDesigner from "../template-designer";
import LoadingScreen from "../../components/LoadingScreen";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

export default function DigitalformTemplateDesigner() {
  const { id } = useParams();

  const { data: digitalform } = useGetDigitalFormQuery(id ?? skipToken);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const [updateDigitalFormAnnotation] =
    useUpdateDigitalFormAnnotationMutation();

  if (!digitalform) return <LoadingScreen isOpen={true} />;
  else
    return (
      <TemplateDesigner
        breadCrumbItems={[
          { text: "Digitalforms", href: "/digitalforms" },
          { text: digitalform.name, href: `/digitalforms/${digitalform.id}` },
          { text: "Template editor", href: "#" },
        ]}
        saveXMLString={async (xfdfString) => {
          navigate(-1);
          await notifyWrapper({
            promise: updateDigitalFormAnnotation({
              id: digitalform.id,
              xfdfString,
            }),
            resourceName: "digitalform template",
            actionName: "update",
          });
        }}
        fileKey={digitalform?.annotatedTemplate?.key}
      />
    );
}
