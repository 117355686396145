import {
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
} from "@cloudscape-design/components";
import { useGetExclusionsQuery } from "../../../../redux/api/provider/provider";
import { useTranslation } from "react-i18next";

interface ExclusionProps {
  npi_number: string;
}
export const NoExlusions = () => {
  const { t } = useTranslation();
  return (
    <Container
      header={
        <Header
          actions={
            <Button>
              {t("providerProfiles.overview.exclusions.header.actions.scan")}
            </Button>
          }
        >
          {t("providerProfiles.overview.exclusions.header.label")}
        </Header>
      }
    >
      <ColumnLayout columns={2} variant="text-grid">
        <i>{t("providerProfiles.overview.exclusions.header.empty")}</i>
      </ColumnLayout>
    </Container>
  );
};

function Exclusions({ npi_number }: ExclusionProps) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetExclusionsQuery({ npi_number: npi_number });

  if (data && data?.length > 0)
    return (
      <Container
        key={npi_number}
        header={t("providerProfiles.overview.exclusions.header.label")}
      >
        <ColumnLayout columns={2} variant="text-grid">
          {data.map((exclusion) => (
            <div className="awsui-child-vertical">
              <div className="awsui-key-label">
                {exclusion.name}
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href={exclusion.link}
                />
              </div>
              <div>
                {t(
                  "providerProfiles.overview.exclusions.header.fields.exclusionDate",
                )}
                : {exclusion.exclusionDate}
              </div>
            </div>
          ))}
        </ColumnLayout>
      </Container>
    );
  else return <NoExlusions key={npi_number} />;
}

export default Exclusions;
