import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import {
  useGetPayerGroupQuery,
  useUpdatePayerGroupMutation,
} from "../../redux/api/payergroup/payergroup";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import AssoicatedPayersTable from "./associated-payers-table";
import { Payers } from "../../redux/api/payers/types";

type FormInputProps = {
  name: string;
  description: string;
  groupCode: string;
};
export default function EditPayerGroup() {
  const methods = useForm<FormInputProps>();
  const [associatedPayers, setAssociatedPayers] = useState<Payers[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: payergroup,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPayerGroupQuery(
    !!id
      ? {
          id: id,
        }
      : skipToken,
  );
  const [updatePayerGroup] = useUpdatePayerGroupMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const onSubmit = async (data: FormInputProps) => {
    if (!!id) {
      await notifyWrapper({
        promise: updatePayerGroup({
          id: id,
          name: data?.name,
          description: data?.description,
          groupCode: data?.groupCode,
        }),
        resourceName: "payergroup",
        actionName: "update",
      });
    }
    navigate(-1);
  };
  useEffect(() => {
    if (payergroup) {
      methods.setValue("name", payergroup?.name);
      methods.setValue("description", payergroup?.description);
      methods.setValue("groupCode", payergroup?.groupCode);
      if (!!payergroup?.payers) setAssociatedPayers(payergroup?.payers);
    }
  }, [fulfilledTimeStamp, methods, payergroup]);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("payergroup.editPayergroup.header.breadcrumb.text")}`,
                href: `/payergroup`,
              },
              {
                text: `${t("payergroup.editPayergroup.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <SpaceBetween size="m">
            <Container
              header={
                <Header>{t("payergroup.editPayergroup.header.label")}</Header>
              }
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate("/payergroup");
                          }}
                          formAction="none"
                        >
                          {t("payergroup.editPayergroup.header.actions.cancel")}
                        </Button>
                        <Button
                          formAction="submit"
                          variant="primary"
                          disabled={!hasPermission}
                        >
                          {t("payergroup.editPayergroup.header.actions.submit")}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={2}>
                      <RHFTextField
                        label="Network name"
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />

                      <RHFTextField
                        label="Network code"
                        name="groupCode"
                        stretch={false}
                      />
                      <ExpandableSection headerText="Notes">
                        <FormSection>
                          <RHFTextArea
                            label={"Notes"}
                            name="description"
                            stretch={true}
                          />
                        </FormSection>
                      </ExpandableSection>
                    </FormSection>
                  </Form>
                </form>
              </FormProvider>
            </Container>
            <AssoicatedPayersTable
              associatedPayers={associatedPayers}
              setAssociatedPayers={setAssociatedPayers}
            />
          </SpaceBetween>
        }
      />
    </>
  );
}
