import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetAllRoleQuery } from "../../redux/api/role/role";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../../hooks/useIsLoading";
import { useNavigate, useParams } from "react-router-dom";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useEffect, useState } from "react";
import { Facility } from "../../redux/api/facility/types";

import { PATH_DASHBOARD } from "../../routes/paths";
import AssoicatedFacilitiesTable from "../invitation/associated-facilities-table";
import FormSection from "../../components/FormSection";
import {
  useGetMemberQuery,
  useUpdateMemberRoleMutation,
} from "../../redux/api/manageadmin/manageadmin";
import { ApplicationState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";

type FormInputType = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};
export default function EditMember() {
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();

  const [updateMemberRole, { isLoading: isCreating }] =
    useUpdateMemberRoleMutation();

  const { data: organization, isLoading: isOrgLoading } =
    useGetOrganizationDetailsQuery();

  const { data: roles, isLoading: isRoleLoading } = useGetAllRoleQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const [associatedFacilities, setAssociatedFacilities] = useState<Facility[]>(
    [],
  );
  const { manageadminId } = useParams();

  const { data: member } = useGetMemberQuery(
    !!manageadminId
      ? {
          activeId: manageadminId,
        }
      : skipToken,
  );

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false),
  );

  useIsLoading(isCreating || isRoleLoading || isOrgLoading);

  useEffect(() => {
    if (!!member) {
      methods.setValue("firstName", member.firstName);
      methods.setValue("lastName", member?.lastName);
      methods.setValue("email", member?.email);
      methods.setValue("role", member?.role.id);
      setAssociatedFacilities(member?.facilities);
    }
  }, [member]);

  useIsLoading(isCreating || isRoleLoading);

  const onSubmit = async (data: FormInputType) => {
    if (data.role && manageadminId != null) {
      await notifyWrapper({
        promise: updateMemberRole({
          id: "" + manageadminId,
          role_ids: data.role,
        }),
        resourceName: "member",
        actionName: "update",
      });
    }
    // setModalName("");
  };

  return (
    <SpaceBetween size="s">
      <BreadcrumbGroup
        items={[
          { text: "Members", href: PATH_DASHBOARD.manageadmin.list },
          { text: "Edit Member", href: "#" },
        ]}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween size="s" direction="horizontal">
                    <Button
                      formAction="none"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t("members.header.EditScreen.header.actions.cancel")}
                    </Button>
                    <Button
                      disabled={!hasPermission}
                      formAction="submit"
                      variant="primary"
                    >
                      {t(
                        "members.header.active.editMember.header.actions.save",
                      )}
                    </Button>
                  </SpaceBetween>
                }
                description={t("members.header.EditScreen.header.description")}
              >
                {t("members.header.EditScreen.header.label")}
              </Header>
            }
          >
            <SpaceBetween direction="vertical" size="s">
              <FormSection columns={2}>
                <RHFTextField
                  label={t(
                    "members.header.active.editMember.header.fields.firstName",
                  )}
                  name="firstName"
                  stretch
                  readOnly
                  rules={{ required: "This field is required" }}
                />
                <RHFTextField
                  label={t(
                    "members.header.active.editMember.header.fields.lastName",
                  )}
                  name="lastName"
                  stretch
                  readOnly
                  rules={{ required: "This field is required" }}
                />
              </FormSection>
              <RHFTextField
                label={t(
                  "members.header.active.editMember.header.fields.email",
                )}
                name="email"
                stretch
                readOnly
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email",
                  },
                }}
              />
              <RHFSelect
                label={t("members.header.active.editMember.header.fields.role")}
                name={"role"}
                disabled={member?.role.default || !hasPermission}
                options={roles?.map((role) => {
                  return {
                    label: role.name,
                    value: role.id,
                    disabled: role.default,
                  };
                })}
              />
            </SpaceBetween>
          </Container>
        </form>
      </FormProvider>
      {!!organization?.settings?.enableFacilityBasedAccess && (
        <AssoicatedFacilitiesTable
          associatedFacilities={associatedFacilities}
          setAssociatedFacilities={setAssociatedFacilities}
        />
      )}
    </SpaceBetween>
  );
}
