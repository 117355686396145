import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetRoleQuery,
  useUpdateRoleMutation,
} from "../../redux/api/role/role";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { Grid } from "@mui/material";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";

type FormInputType = {
  name: string;
  npiNumber: string;
  description: string;
};

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditRole({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();
  const { id: roleId } = useParams();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [updateRole] = useUpdateRoleMutation();

  const { data: role } = useGetRoleQuery({
    id: roleId ?? "",
  });

  useEffect(() => {
    if (role) {
      methods.setValue("name", role?.name ?? "");
      methods.setValue("description", role?.description ?? "");
    }
  }, [role]);

  const onSubmit = async (data: FormInputType) => {
    if (roleId)
      await notifyWrapper({
        promise: updateRole({
          id: roleId,
          name: data.name,
          description: data.description,
        }),
        resourceName: "role",
        actionName: "update",
      });
    setModalName("");
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={
        <Header variant="h2">
          {t("rolesandpermissions.roles.editRole.header.label")}
        </Header>
      }
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <RHFTextField
              label={t("rolesandpermissions.roles.editRole.header.fields.name")}
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea
              label={t(
                "rolesandpermissions.roles.editRole.header.fields.description",
              )}
              name={"description"}
            />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  {t(
                    "rolesandpermissions.roles.editRole.header.actions.cancel",
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">
                  {t("rolesandpermissions.roles.editRole.header.actions.save")}
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditRole);
