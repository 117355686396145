import { FormProvider, useForm } from "react-hook-form";
import { ProviderThirdPartyLoginType } from "../../../../redux/api/provider/section/types";
import {
  Alert,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import FormSection from "../../../../components/FormSection";
import { Divider } from "@mui/material";
import {
  useAddProviderThirdPartyLoginMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import { Switch } from "antd";

export default function EditThirdPartyLogin() {
  const { t } = useTranslation();
  const methods = useForm<ProviderThirdPartyLoginType>();
  const { providerId, thirdpartyloginId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderThirdPartyLoginType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderThirdPartyLogin({
          providerId: providerId,
          ...data,
        }),
        resourceName: "Third Party Login",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderThirdPartyLogin] = useAddProviderThirdPartyLoginMutation();

  useEffect(() => {
    if (provider && provider.providerThirdPartyLogins) {
      const thirdPartyLogin = provider.providerThirdPartyLogins.find(
        (thirdPartyLogin) =>
          thirdPartyLogin?.id + "" === thirdpartyloginId + "",
      );

      if (thirdPartyLogin) {
        methods.setValue("id", thirdPartyLogin.id);
        methods.setValue("name", thirdPartyLogin.name);
        methods.setValue("accountNo", thirdPartyLogin.accountNo);
        methods.setValue("username", thirdPartyLogin.username);
        methods.setValue("password", thirdPartyLogin.password);
        methods.setValue("websiteUrl", thirdPartyLogin.websiteUrl);
      }
    }
  }, [fulfilledTimeStamp, provider, thirdpartyloginId]);

  const [showSSN, setShowSSN] = React.useState(false);
  const [alertShow, setAlertShow] = React.useState(false);

  const hasPermissionForView = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("ssn.view") ?? false),
  );

  const hasPermissionForEdit = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("ssn.edit") ?? false),
  );
  const showSecrets = () => {
    if (hasPermissionForView || hasPermissionForEdit) {
      setShowSSN(!showSSN);
    } else {
      setAlertShow(!alertShow);
    }
  };

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=thirdpartylogin`,
            },
            {
              text: `${t("providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    <RHFTextField
                      label={t(
                        "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.fields.name",
                      )}
                      name="name"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.fields.accountNo",
                      )}
                      name="accountNo"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.fields.websiteUrl",
                      )}
                      name="websiteUrl"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.fields.username",
                      )}
                      name="username"
                      stretch
                    />
                    <SpaceBetween size="xs">
                      <RHFTextField
                        type={showSSN ? "text" : "password"}
                        readOnly={
                          showSSN && hasPermissionForEdit ? false : true
                        }
                        label={t(
                          "providerProfiles.thirdPartyLogin.editThirdPartyLogin.header.fields.password",
                        )}
                        name="password"
                        stretch
                      />
                      {alertShow ? (
                        <Alert
                          dismissible
                          onDismiss={showSecrets}
                          type="warning"
                        >
                          You Don't Have A Permission
                        </Alert>
                      ) : (
                        <SpaceBetween size="xs" direction="horizontal">
                          {" "}
                          <Switch
                            value={showSSN}
                            size="small"
                            onClick={showSecrets}
                          />{" "}
                          Show Password
                        </SpaceBetween>
                      )}
                    </SpaceBetween>
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
