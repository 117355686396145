import {
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { FilterType } from "../../hooks/usePayerGroupFilter";
import { useState } from "react";

interface PayerGroupFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function PayerGroupFilters({
  filter,
  setFilter,
  countText,
}: PayerGroupFiltersProps) {
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Network Name",
    value: "payerGroupName",
  });

  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterPayerGroupName: "",
              filterPayerGroupID: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Network Name", value: "payerGroupName" },
            { label: "Network Code", value: "payerGroupId" },
          ]}
        />
        <div>
          {selectedProperty.value === "payerGroupName" && (
            <TextFilter
              filteringPlaceholder="Find Network Name"
              filteringText={filter.filterPayerGroupName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterPayerGroupName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "payerGroupId" && (
            <TextFilter
              filteringPlaceholder="Find Network Code"
              filteringText={filter.filterPayerGroupID}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterPayerGroupID: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}
        </div>
      </Grid>
    </SpaceBetween>
  );
}
