import { FormProvider, useForm } from "react-hook-form";
import {
  useAddLicenseTypeMutation,
  useGetLicenseTypeQuery,
  useUpdateLicenseTypeMutation,
} from "../redux/api/licensetype/licensetype";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../hooks/useIsLoading";
import { useNavigate } from "react-router";
import InnerAppLayout from "../components/InnerAppLayout";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../components/FormSection";
import RHFTextField from "../components/RHF/RHFTextField";
import { LicenseTypeRequest } from "../redux/api/licensetype/types";
import { PATH_DASHBOARD } from "../routes/paths";
import RHFTextArea from "../components/RHF/RHFTextArea";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";

export default function EditLicenseType() {
  const { id: licensetypeId } = useParams();

  const { data: licensetype, fulfilledTimeStamp } = useGetLicenseTypeQuery(
    !!licensetypeId
      ? {
          id: licensetypeId,
        }
      : skipToken,
  );

  const methods = useForm<LicenseTypeRequest>();
  const [updateLicenseType, { isLoading: isCreating }] =
    useUpdateLicenseTypeMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  useIsLoading(isCreating);
  useEffect(() => {
    if (!!licensetype) {
      methods.setValue("name", licensetype.name ?? "");
      methods.setValue("code", licensetype.code ?? "");
      methods.setValue("description", licensetype.description ?? "");
    }
  }, [fulfilledTimeStamp, methods, licensetype]);

  const onSubmit = async (data: LicenseTypeRequest) => {
    if (!licensetypeId) return;
    await notifyWrapper({
      promise: updateLicenseType({
        id: licensetypeId,
        name: data?.name,
        description: data?.description,
        code: data?.code,
      }),
      resourceName: "license type",
      actionName: "update",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: "License Types",
              href: PATH_DASHBOARD.manage.licensetype.list,
            },
            {
              text: "Add",
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Add License Type</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(PATH_DASHBOARD.manage.licensetype.list);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <RHFTextField
                    label={"Name"}
                    name="name"
                    stretch={false}
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField label={"Code"} name="code" stretch={false} />
                  <RHFTextArea
                    label={"Description"}
                    name="description"
                    stretch={false}
                  />
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
