import { BreadcrumbGroup, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";

import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./ReportingToolBar";
import { useGetAllProvidersReportQuery } from "../redux/api/reporting/reporting";

export default function ProviderRoasterReport() {
  const { data = [], isLoading } = useGetAllProvidersReportQuery();
  const { t } = useTranslation();
  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("reports.expiredDocuments.breadcrumb.text")}`,
            href: "/reports",
          },
          {
            text: `Provider report`,
            href: "#",
          },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          columns={[
            {
              field: "lastName",
              headerName: "Provider_LastName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.lastName ?? "-",
            },
            {
              field: "firstname",
              headerName: "Provider_FirstName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.firstName ?? "-",
            },
            {
              field: "npi",
              headerName: "NPI_Number",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.npiNumber ?? "-",
            },
            {
              field: "email",
              headerName: "Provider_Email",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.email ?? "-",
            },
            {
              field: "licenseType",
              headerName: "Provider_License_Type",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.licenseType?.name ?? "-",
            },
            {
              field: "title",
              headerName: "Provider_Title",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.title?.name ?? "-",
            },
            {
              field: "facility",
              headerName: "Provider_Facility_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.facility?.name ?? "-",
            },
            {
              field: "identificationNumber",
              headerName: "IdentificationNumber",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.identificationNumber ?? "-",
            },
            {
              field: "isActive",
              headerName: "Provider_Status",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                !!params.row?.isActive ? "Active" : "Inactive",
            },
          ]}
          rows={data}
          getRowId={(row) => row?.id}
          loading={isLoading}
          slots={{ toolbar: ToolBar }}
          disableRowSelectionOnClick={true}
          disableColumnMenu
          density="compact"
        />
      </div>
    </SpaceBetween>
  );
}

const ToolBar = () => <CustomToolbar fileName="providers_report" />;
