import React from "react";
import { Spin } from "antd";
import "./spin.css";
import { TopNavigation } from "@cloudscape-design/components";
const SigningLoader: React.FC = () => (
  <div>
    <TopNavigation
      identity={{
        href: "#",
        title: "Ready Doc",
        logo: {
          //pointing to logo image in public folder
          src: "/RD_Horizozntal_Color_Logo.svg",
          alt: "Readydoc logo",
        },
      }}
    />
    <Spin
      fullscreen
      tip={<span style={{ fontSize: "14px" }}>Loading</span>}
      indicator={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 720 720"
          className="spinner"
          style={{ fontSize: 96 }}
        >
          <g transform="scale(7.999999999999999) translate(10, 10)">
            <g
              id="SvgjsG1032"
              transform="matrix(0.9057736872854413,0,0,0.9057736872854413,-10.288683500458957,-10.814032169979466)"
              fill="#2b6be6"
            >
              <g xmlns="http://www.w3.org/2000/svg">
                <polygon points="78.268,32.181 57.521,32.181 47.148,50.146 57.521,68.112 78.268,68.112 88.641,50.146  "></polygon>
                <polygon points="21.732,52.129 11.359,70.095 21.732,88.061 42.478,88.061 52.851,70.095 42.478,52.129  "></polygon>
                <polygon points="42.478,47.871 52.851,29.905 42.478,11.939 21.732,11.939 11.359,29.905 21.732,47.871  "></polygon>
              </g>
            </g>
          </g>
        </svg>
      }
    />
  </div>
);

export default SigningLoader;
