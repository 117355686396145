import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGetAllProvidersQuery } from "../redux/api/provider/provider";
import { useEffect, useState } from "react";
import { Provider } from "../redux/api/provider/types";

export type FilterType = {
  filterProviderName: string;
  filterEmail: string;
  facilityFilterOptions: OptionDefinition[];
  licenseTypeFilterOptions: OptionDefinition[];
  statusFilterOptions: OptionDefinition[];
  facilityGroupFilterOptions: OptionDefinition[];
};

export default function useFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProvidersQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterProviderName: "",
    filterEmail: "",
    facilityFilterOptions: [],
    licenseTypeFilterOptions: [],
    statusFilterOptions: [],
    facilityGroupFilterOptions: [],
  });

  const [filteredProviders, setFilteredProviders] = useState<Provider[]>([]);

  useEffect(() => {
    setFilter({
      ...filter,
      statusFilterOptions: [{ label: "Active", value: "true" }],
    });

    setFilteredProviders(data?.filter((provider) => !!provider?.isActive));
  }, [data]);

  useEffect(() => {
    let temp_filtered_providers = [...data];
    if (filter.filterProviderName) {
      temp_filtered_providers = temp_filtered_providers.filter((provider) =>
        [provider.firstName, provider.lastName]
          .join(" ")
          .toLowerCase()
          .includes(filter.filterProviderName.toLowerCase()),
      );
    }
    if (filter.filterEmail) {
      temp_filtered_providers = temp_filtered_providers.filter((provider) =>
        provider.email
          ?.toLowerCase()
          .includes(filter.filterEmail.toLowerCase()),
      );
    }

    const selectedFaciltiesId = filter.facilityFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesId && selectedFaciltiesId.length > 0) {
      temp_filtered_providers = temp_filtered_providers.filter((provider) =>
        selectedFaciltiesId?.includes(provider.facility?.id + ""),
      );
    }

    const selectedFaciltyGroupId = filter.facilityGroupFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltyGroupId && selectedFaciltyGroupId.length > 0) {
      temp_filtered_providers = temp_filtered_providers.filter((provider) =>
        selectedFaciltyGroupId?.includes(provider.facilityGroup?.id + ""),
      );
    }

    const selectedLicenseTypeId = filter.licenseTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedLicenseTypeId && selectedLicenseTypeId.length > 0) {
      temp_filtered_providers = temp_filtered_providers.filter(
        (provider) =>
          selectedLicenseTypeId.indexOf(provider.licenseType?.id + "") >= 0,
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_providers = temp_filtered_providers.filter(
        (provider) =>
          selectedStatus.indexOf(provider?.isActive.toString() + "") >= 0,
      );
    }
    setFilteredProviders(temp_filtered_providers);
  }, [filter]);

  return {
    filteredProviders,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
