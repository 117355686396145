import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDeletePayerEnrollmentMutation } from "../../../../redux/api/payerenrollment/payerenrollment";
import { PayerEnrollment } from "../../../../redux/api/payerenrollment/types";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import {
  ENROLLEDPAYER_CONTENT_DISPLAY_OPTIONS,
  ENROLLEDPAYER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import ChangeTimeZone from "../../../../components/Timezone";
import LoadingScreen from "../../../../components/LoadingScreen";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import KeyValue from "../../../../components/KeyValue";
import ProviderEnrollentFilters from "../../../../components/filter/providerenrollment-filters";
import useProviderEnrollmentFilter from "../../../../hooks/useProviderEnrollmentFilter";

export default function EnrolledPayers() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<PayerEnrollment>();
  const { t } = useTranslation();
  const [deletePayerEnrollment] = useDeletePayerEnrollmentMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-EnrolledPayer-Table-Preferences",
    ENROLLEDPAYER_TABLE_DEFAULT_PREFERENCES,
  );
  const {
    filteredPayerEnrollments,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useProviderEnrollmentFilter();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false),
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerEnrollments,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t(
              "providerProfiles.enrolledPayers.table.header.label",
            )}
            action={() => {
              navigate(`addenrolledpayers`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  const tableProps: TableProps<PayerEnrollment> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "payer",
          header: (
            <div>
              {t("providerProfiles.enrolledPayers.table.header.fields.payer")}
            </div>
          ),
          cell: (item) => <span>{item?.payer?.name}</span>,
          sortingField: "payer.name",
          sortingComparator: (a, b) =>
            (a?.payer?.name ?? "").localeCompare(b?.payer?.name ?? ""),
        },
        {
          id: "network",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.payergroup",
              )}
            </div>
          ),
          cell: (item) => <span>{item?.payerGroup?.name}</span>,
          sortingField: "payerGroup.name",
          sortingComparator: (a, b) =>
            (a?.payerGroup?.name ?? "").localeCompare(
              b?.payerGroup?.name ?? "",
            ),
        },
        {
          id: "status",
          header: (
            <div>
              {t("providerProfiles.enrolledPayers.table.header.fields.status")}
            </div>
          ),
          cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
          sortingField: "status.name",
          sortingComparator: (a, b) =>
            (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
        },
        {
          id: "provider",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.provider",
              )}
            </div>
          ),
          cell: (item) => (
            <span>
              {item?.provider?.firstName + " " + item?.provider?.lastName ??
                "-"}
            </span>
          ),
          sortingField: "provider.firstName",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.firstName ?? "",
            ),
        },
        {
          id: "payerProviderId",
          header: <div>Provider ID</div>,
          cell: (item) => (
            <span>{item?.payerProviderId ? item?.payerProviderId : "-"}</span>
          ),
          sortingField: "payerProviderId",
          sortingComparator: (a, b) =>
            (a?.payerProviderId ?? "").localeCompare(b?.payerProviderId ?? ""),
        },
        {
          id: "facility",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.facility",
              )}
            </div>
          ),
          cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
          sortingField: "facility.name",
          sortingComparator: (a, b) =>
            (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
        },
        {
          id: "submittedDate",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.submittedDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.submittedDate) ?? "-"}</span>
          ),
        },
        {
          id: "approvedDate",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.approvedDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.approvedDate) ?? "-"}</span>
          ),
        },
        {
          id: "effectiveDate",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.effectiveDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.effectiveDate) ?? "-"}</span>
          ),
        },
        {
          id: "recredentialingDate",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.recredentialingDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.recredentialingDate) ?? "-"}</span>
          ),
        },
        {
          id: "followupDate",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.followupDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}</span>
          ),
        },
        {
          id: "description",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.table.header.fields.description",
              )}
            </div>
          ),
          cell: (item) => <span>{item?.description ?? "-"}</span>,
        },
      ],
      items,
      filter: (
        <ProviderEnrollentFilters filter={filter} setFilter={setFilter} />
      ),
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Enrolled Payer Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={ENROLLEDPAYER_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loading: isLoading || isFetching,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      header: (
        <Header
          counter={`(${filteredPayerEnrollments?.length})`}
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  {
                    text: hasPermission
                      ? `${t("providerProfiles.enrolledPayers.table.header.actions.enrolledPayerEdit")}`
                      : `${t("providerProfiles.enrolledPayers.table.header.actions.enrolledPayerView")}`,
                    id: "edit",
                  },
                  {
                    text: `${t("providerProfiles.enrolledPayers.table.header.actions.enrolledPayerDelete")}`,
                    id: "delete",
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editenrolledpayers/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id) OpenDeleteModal(true);
                  }
                }}
              >
                {t(
                  "providerProfiles.enrolledPayers.table.header.actions.enrolledPayerActions",
                )}
              </ButtonDropdown>
              <Button
                disabled={!hasPermission}
                variant="primary"
                onClick={() => {
                  navigate(`addenrolledpayers`);
                }}
              >
                {t(
                  "providerProfiles.enrolledPayers.table.header.actions.addEnrolledPayer",
                )}
              </Button>
            </SpaceBetween>
          }
        >
          {t("providerProfiles.enrolledPayers.table.header.label")}
        </Header>
      ),
    };
  }, [selectedItem, tablePreferences, items, filteredPayerEnrollments]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePayerEnrollment({
                payerEnrollmentId: selectedItem?.id,
              }),
              resourceName: "Enrolled Payer",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerProfiles.enrolledPayers.delete.header.label")}
        content={
          <SpaceBetween size="s" direction="vertical">
            <KeyValue
              label={t(
                "providerProfiles.enrolledPayers.delete.header.content.label",
              )}
              value={selectedItem?.payer.name}
            />
            <KeyValue
              label={t(
                "providerProfiles.enrolledPayers.delete.header.content.providerLabel",
              )}
              value={
                selectedItem?.provider.firstName +
                " " +
                selectedItem?.provider.lastName
              }
            />
          </SpaceBetween>
        }
        description={t(
          "providerProfiles.enrolledPayers.delete.header.content.description",
        )}
      />
    </div>
  );
}
