import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import {
  PAYER_GROUP_CONTENT_DISPLAY_OPTIONS,
  PAYER_GROUP_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import { PayerGroup } from "../redux/api/payergroup/types";
import usePayerGroupFilter from "../hooks/usePayerGroupFilter";
import PayerGroupFilters from "../components/filter/payergroup-filter";
import StyledRouterLink from "../components/styled-route-link";
import { useDeletePayerGroupMutation } from "../redux/api/payergroup/payergroup";

export default function PayerGroupTable() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePayer] = useDeletePayerGroupMutation();
  const [selectedItem, setSelectedItem] = useState<PayerGroup>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Payer-Group-Table-Preferences",
    PAYER_GROUP_TABLE_DEFAULT_PREFERENCES,
  );
  const {
    filteredPayerGroup,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = usePayerGroupFilter();
  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerGroup,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("payergroup.table.header.label")}
            action={() => {
              navigate(`addnew`);
            }}
          />
        ),
      },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  useEffect(() => {
    const temp = filteredPayerGroup.find(
      (payergroup) => "" + payergroup.id === "" + id,
    );
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false),
  );

  const tableProps: TableProps<PayerGroup> = {
    header: (
      <Header
        variant="h2"
        counter={`(${filteredPayerGroup.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
            </span>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectedItem}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission
                      ? `${t("payergroup.table.header.actions.payerGroupEdit")}`
                      : `${t("payergroup.table.header.actions.payerGroupView")}`,
                  },
                  {
                    id: "delete_form",
                    text: `${t("payergroup.table.header.actions.payerGroupDelete")}`,
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") {
                    if (selectedItem) OpenDeleteModal(true);
                  } else if (id === "edit_form") {
                    navigate(`/payergroup/${selectedItem?.id}`);
                  }
                }}
              >
                {t("payergroup.table.header.actions.payerGroupActions")}
              </ButtonDropdown>
            </span>

            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  navigate("addnew");
                }}
                variant="primary"
              >
                {t("payergroup.table.header.actions.addPayerGroup")}
              </Button>
            </span>
          </div>
        }
      >
        Networks
      </Header>
    ),
    items: items,
    filter: <PayerGroupFilters filter={filter} setFilter={setFilter} />,
    preferences: (
      <TablePreferences
        title="Network Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={PAYER_GROUP_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    pagination: <Pagination {...paginationProps} />,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    loadingText: "Loading...",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    selectedItems: selectedItem ? [selectedItem] : [],
    onSelectionChange: ({ detail }) => {
      if (detail.selectedItems && detail.selectedItems.at(0))
        setSelectedItem(detail.selectedItems.at(0));
    },
    onRowClick: ({ detail }) => {
      if (detail.item) setSelectedItem(detail.item);
    },
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>{t("payergroup.table.header.fields.id")}</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: <div>Network name</div>,
        cell: (item) => (
          <StyledRouterLink
            className={hasPermission ? "edit_link" : "normal"}
            to={hasPermission ? `/payergroup/${item.id}` : `#`}
            label={item?.name}
          />
        ),
        sortingField: "name",
      },
      {
        id: "description",
        header: <span>Notes</span>,
        cell: (item) => <div>{item?.description ?? "-"}</div>,
      },
      {
        id: "groupCode",
        header: "Network id",
        cell: (item) => <Link href="#">{item.groupCode || "-"}</Link>,
        sortingField: "groupCode",
        isRowHeader: true,
      },
    ],
  };
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePayer({
                id: selectedItem?.id,
              }),
              resourceName: "Network",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("payergroup.delete.header.label")}
        content={
          <>
            <Box>{t("payergroup.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t("payergroup.delete.header.content.description")}
      />
    </div>
  );
}
