import {
  Multiselect,
  SpaceBetween,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { FilterType } from "../../hooks/useAffiliationFilter";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGetAllAffiliationTypeQuery } from "../../redux/api/affiliationType/affiliation";
import {
  useGetAllAffiliationStatusQuery,
  useGetAllProvidersQuery,
} from "../../redux/api/provider/provider";
import { join } from "lodash";

interface ProviderFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
}
export default function AffiliationFilters({
  filter,
  setFilter,
}: ProviderFiltersProps) {
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: affiliationType = [] } = useGetAllAffiliationTypeQuery();
  const { data: affiliationStatus = [] } = useGetAllAffiliationStatusQuery();

  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Provider",
    value: "provider",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  // side effect, to display the token groups
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.providerFilterOptions &&
      filter.providerFilterOptions.length > 0
    )
      temp = [
        {
          label: `Providers = ${join(
            filter.providerFilterOptions.map(
              (providerFilterOption) => providerFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];
    if (
      !!filter.facilityFilterOptions &&
      filter.facilityFilterOptions.length > 0
    )
      temp = [
        {
          label: `Facilities = ${join(
            filter.facilityFilterOptions.map(
              (facilityFilterOption) => facilityFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];
    if (
      !!filter.affiliationTypeFilterOptions &&
      filter.affiliationTypeFilterOptions.length > 0
    )
      temp = [
        {
          label: `Affiliation Types = ${join(
            filter.affiliationTypeFilterOptions.map(
              (affiliationTypeFilterOption) =>
                affiliationTypeFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];

    if (
      !!filter.affiliationStatusFilterOptions &&
      filter.affiliationStatusFilterOptions.length > 0
    )
      temp = [
        {
          label: `Affiliation Status = ${join(
            filter.affiliationStatusFilterOptions.map(
              (affiliationStatusFilterOption) =>
                affiliationStatusFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];
    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Provider", value: "provider" },
            { label: "Facility", value: "facility" },
            { label: "Affiliation Type", value: "affiliation_type" },
            { label: "Affiliation Status", value: "affiliation_status" },
          ]}
        />
        <div>
          {selectedProperty.value === "provider" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.providerFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  providerFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={providers?.map((provider) => ({
                label: `${provider.firstName} ${provider.lastName}`,
                value: provider.id + "",
              }))}
              placeholder="Choose Providers"
            />
          )}
          {selectedProperty.value === "facility" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.facilityFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={facilities?.map((facility) => ({
                label: facility.name,
                value: facility.id + "",
              }))}
              placeholder="Choose Facilites"
            />
          )}
          {selectedProperty.value === "affiliation_type" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.affiliationTypeFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  affiliationTypeFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={affiliationType?.map((type) => ({
                label: type.name,
                value: type.id + "",
              }))}
              placeholder="Choose Affiliation Types"
              filteringType="auto"
            />
          )}
          {selectedProperty.value === "affiliation_status" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.affiliationStatusFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  affiliationStatusFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={affiliationStatus?.map((status) => ({
                label: status.name,
                value: status.id,
              }))}
              placeholder="Choose Affiliation Status"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Providers")) {
            setFilter({
              ...filter,
              providerFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Facilities")
          ) {
            setFilter({
              ...filter,
              facilityFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Affiliation Types")
          ) {
            setFilter({
              ...filter,
              affiliationTypeFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Affiliation Status")
          ) {
            setFilter({
              ...filter,
              affiliationStatusFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
