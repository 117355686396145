import Grid from "@cloudscape-design/components/grid";
import { serviceOverview } from "./wdigets/service-overview";
import { BaseStaticWidget } from "./wdigets/basic-static-widget";
import { usageByFacility } from "./wdigets/usage-by-facility";
import { eSignatureStatus } from "./wdigets/eSignatures-status";
import { documentsSnapShot } from "./wdigets/document-snapshot";
import { workflowStatus } from "./wdigets/workflow-tasks-widget/workflows-status-widget";
import { taskStatus } from "./wdigets/workflow-tasks-widget/tasks-status-widget";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

export default function Dashboard() {
  const { data: organization } = useGetOrganizationDetailsQuery();

  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 8, m: 8, default: 12 } },
        { colspan: { l: 4, m: 4, default: 12 } },
        { colspan: { l: 4, m: 4, default: 12 } },
        { colspan: { l: 4, m: 4, default: 12 } },
        { colspan: { l: 4, m: 4, default: 12 } },
      ]}
    >
      {[
        serviceOverview,
        usageByFacility,
        ...(!!organization?.organizationServiceLimits?.eSignatures
          ? [eSignatureStatus]
          : []),
        documentsSnapShot,
        ...(!!organization?.organizationServiceLimits?.tasks
          ? [taskStatus]
          : []),
        ...(!!organization?.organizationServiceLimits?.workflows
          ? [workflowStatus]
          : []),
      ].map((widget, index) => (
        <BaseStaticWidget key={index} config={widget} />
      ))}
    </Grid>
  );
}
