import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useEffect } from "react";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { CredentialPackageRequest } from "../../../redux/api/credentialpackages/types";
import {
  PACKAGE_STATUS,
  PACKAGE_TYPE,
  alertReminderOptions,
} from "../../../helpers/constants";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../../components/FormSection";
import RHFFloatingSelect from "../../../components/RHF/RHFFloatingSelect";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../../../redux/api/provider/provider";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditPacakgeModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<CredentialPackageRequest>();
  const { t } = useTranslation();
  const { id: credentialPackageId } = useParams();
  const {
    data: credentialPackage,
    fulfilledTimeStamp,
    isLoading,
  } = useGetCredentialPackageQuery(credentialPackageId ?? skipToken);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );

  const [updateCredentialPackage] = useUpdateCredentialPackageMutation();

  const { data: packageType = [] } = useGetAllPackageTypesQuery();
  const { data: template = [] } = useGetAllCredentialTemplatesQuery();
  const { data: packageStatus = [] } = useGetAllPackageStatusQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const onSubmit = async (data: CredentialPackageRequest) => {
    if (!!credentialPackageId) {
      await notifyWrapper({
        promise: updateCredentialPackage({
          id: credentialPackageId,
          ...data,
        }),
        resourceName: "Credential Package",
        actionName: "Update",
      });
      setModalName("");
    }
  };

  useEffect(() => {
    if (credentialPackage) {
      methods.setValue("name", credentialPackage?.name);
      methods.setValue("description", credentialPackage?.description);
      !!credentialPackage?.packageType?.id &&
        methods.setValue("packageTypeId", credentialPackage?.packageType?.id);
      !!credentialPackage?.packageStatus?.id &&
        methods.setValue(
          "packageStatusId",
          credentialPackage?.packageStatus?.id,
        );
      methods.setValue("providerId", credentialPackage?.provider?.id);
      methods.setValue("templateId", credentialPackage?.credentialTemplate?.id);
      methods.setValue("submittedDate", credentialPackage?.submittedDate);
      methods.setValue(
        "recredentialingDate",
        credentialPackage?.recredentialingDate,
      );
      methods.setValue("followupDate", credentialPackage?.followupDate);
    }
  }, [fulfilledTimeStamp, methods, credentialPackage]);

  return (
    <Modal
      header={
        <Header>{t("credentialPackages.editPackage.header.label")}</Header>
      }
      visible={true}
      size="medium"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <SpaceBetween size={"s"}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SpaceBetween size={"s"}>
              <FormSection columns={2}>
                <RHFFloatingSelect
                  name="packageTypeId"
                  label={t(
                    "credentialPackages.editPackage.header.fields.packageType.label",
                  )}
                  options={packageType.map((type) => ({
                    label: type.name,
                    value: "" + type.id,
                  }))}
                  rules={{ required: "This field is required" }}
                  stretch={false}
                  action={{
                    label: `${t("credentialPackages.editPackage.header.fields.packageType.actions.modify")}`,
                    onClick: () => {
                      setModalName(PACKAGE_TYPE);
                    },
                  }}
                />
                <RHFTextField
                  label={t("credentialPackages.editPackage.header.fields.name")}
                  name="name"
                  stretch={false}
                  rules={{ required: "This field is required" }}
                />
                <RHFSelect
                  disabled
                  name="templateId"
                  label={t(
                    "credentialPackages.editPackage.header.fields.credentialTemplate",
                  )}
                  options={template.map((temp) => ({
                    label: temp.name,
                    value: "" + temp.id,
                  }))}
                  stretch={false}
                  rules={{ required: "This field is required" }}
                />
                <RHFSelect
                  disabled
                  label={t(
                    "credentialPackages.editPackage.header.fields.provider",
                  )}
                  name={"providerId"}
                  stretch={false}
                  options={provider.map((temp) => ({
                    label: temp.firstName,
                    value: "" + temp.id,
                    tags: temp?.providerLicenses?.map(
                      (type) => type.licenseType,
                    ),
                  }))}
                  rules={{ required: "This field is required" }}
                />
                <RHFFloatingSelect
                  name="packageStatusId"
                  label={t(
                    "credentialPackages.editPackage.header.fields.packageStatus.label",
                  )}
                  options={packageStatus.map((status) => ({
                    label: status.name,
                    value: "" + status.id,
                  }))}
                  rules={{ required: "This field is required" }}
                  stretch={false}
                  action={{
                    label: `${t("credentialPackages.editPackage.header.fields.packageStatus.actions.modify")}`,
                    onClick: () => {
                      setModalName(PACKAGE_STATUS);
                    },
                  }}
                />
                <RHFDatePicker
                  label={t(
                    "credentialPackages.editPackage.header.fields.submittedDate",
                  )}
                  name={"submittedDate"}
                  stretch={false}
                />
                <RHFDatePicker
                  label={t(
                    "credentialPackages.editPackage.header.fields.recredentialingDate",
                  )}
                  name={"recredentialingDate"}
                  stretch={false}
                />

                <RHFSelect
                  label={t(
                    "credentialPackages.editPackage.header.fields.followupDate",
                  )}
                  name={"followupDate"}
                  stretch={false}
                  options={alertReminderOptions}
                />
              </FormSection>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    formAction="none"
                    onClick={() => {
                      setModalName("");
                    }}
                  >
                    {t("credentialPackages.editPackage.header.actions.cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button formAction="submit" variant="primary">
                    {t("credentialPackages.editPackage.header.actions.save")}
                  </Button>
                </Grid>
              </Grid>
            </SpaceBetween>
          </form>
        </FormProvider>
      </SpaceBetween>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditPacakgeModal);
