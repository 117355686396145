import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Container,
  Header,
  Pagination,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import LoadingScreen from "../../../components/LoadingScreen";
import Comment from "../../../redux/api/providerComments/types";
import {
  Avatar,
  Card,
  Button as AntButton,
  Divider,
  Flex,
  ConfigProvider,
  Space,
  Row,
  Col,
  Typography,
} from "antd";
import { capitalize, upperCase } from "lodash";
import * as awsui from "@cloudscape-design/design-tokens";
import { useGetProfileDetailsQuery } from "../../../redux/api/profile/profile";
const { Text } = Typography;
interface CommentsProps {
  commentsList: Comment[];
  postComment: (message: string) => void;
  editComment: (commentId: string, message: string) => void;
  deleteComment: (commentId: string) => void;
}

export default function CommentActivies({
  commentsList,
  postComment,
  editComment,
  deleteComment,
}: CommentsProps) {
  const [message, setMessage] = useState<string>("");
  const [commentIdToEdit, setCommentIdToEdit] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [preferences, setPreferences] = React.useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { data: user } = useGetProfileDetailsQuery();

  const { paginationProps, items } = useCollection(commentsList ?? [], {
    pagination: {
      pageSize: preferences?.pageSize,
      defaultPage: 1,
    },
    sorting: {
      defaultState: {
        isDescending: true,
        sortingColumn: {
          sortingField: "createdDate",
          sortingComparator: (a, b) =>
            (a?.createdDate ?? "").localeCompare(b?.createdDate ?? ""),
        },
      },
    },
  });

  const saveComments = async () => {
    setLoading(true);
    if (!!commentIdToEdit) {
      await editComment(commentIdToEdit, message);
    } else {
      await postComment(message);
    }

    setMessage("");
    setEnableButtons(false);
    setLoading(false);
    setCommentIdToEdit(undefined);
  };

  const handleEditClick = (item: Comment) => {
    setMessage(item?.message);
    setCommentIdToEdit(item?.id);
    setEnableButtons(true);
  };

  const handleDeleteClick = async (commentId: string) => {
    setLoading(true);
    if (!!commentId) {
      await deleteComment(commentId);
    }

    setLoading(false);
  };

  return (
    <div style={{ height: `calc(100vh - 210px)`, overflowY: "auto" }}>
      <LoadingScreen isOpen={loading} />
      <Container
        header={
          <Header
            actions={
              <Flex align="center">
                <Pagination {...paginationProps} />
                <CollectionPreferences
                  onConfirm={({ detail }) => {
                    if (!!detail) {
                      setPreferences(detail);
                    }
                  }}
                  preferences={preferences}
                  pageSizePreference={{
                    options: [
                      { value: 10, label: "10 items" },
                      { value: 30, label: "30 items" },
                      { value: 50, label: "50 items" },
                    ],
                  }}
                />
              </Flex>
            }
          >
            Comments
          </Header>
        }
      >
        <SpaceBetween size="m" direction="vertical">
          <Row gutter={8} align="middle">
            <Col flex="none">
              <Avatar>
                {upperCase(user?.firstName?.at(0) + "" + user?.lastName?.at(0))}
              </Avatar>
            </Col>
            <Col flex="auto">
              <Textarea
                value={message}
                onChange={(changeDetails) => {
                  setMessage(changeDetails.detail.value);
                }}
                onFocus={() => {
                  setEnableButtons(true);
                }}
                placeholder="Add a comment..."
              />
            </Col>
          </Row>
          {!!enableButtons && (
            <Flex align="center" gap={"small"} justify="flex-end">
              <Button
                data-testid
                formAction="none"
                variant="primary"
                onClick={saveComments}
              >
                Save
              </Button>
              <Button
                data-testid="cancel"
                formAction="none"
                onClick={() => {
                  setEnableButtons(false);
                  setMessage("");
                }}
              >
                Cancel
              </Button>
            </Flex>
          )}

          <Divider
            dashed
            style={{
              borderColor: awsui.colorBorderDividerDefault,
              borderWidth: "1px 0 0",
              margin: "0 0",
            }}
          />
          <SpaceBetween size="m" direction="vertical">
            {!!items ? (
              items.map((comment) => {
                return (
                  <Card
                    key={comment.id}
                    size="small"
                    title={
                      <div style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                        <Space align="center">
                          <Avatar>
                            {upperCase(
                              comment?.user?.firstName?.at(0) +
                                "" +
                                comment?.user?.lastName?.at(0),
                            )}
                          </Avatar>
                          <Box>
                            <Text>
                              {capitalize(comment?.user?.firstName) +
                                " " +
                                capitalize(comment?.user?.lastName)}
                            </Text>
                          </Box>
                          <Box>
                            {!!comment?.createdDate ? (
                              <Text>
                                {new Date(
                                  comment?.createdDate,
                                ).toLocaleString()}
                              </Text>
                            ) : (
                              "-"
                            )}
                          </Box>
                        </Space>
                        {!!comment?.updatedDate && (
                          <Box>
                            <Text type="secondary">
                              Edited on:{" "}
                              {new Date(comment?.updatedDate).toLocaleString()}
                            </Text>
                          </Box>
                        )}
                      </div>
                    }
                  >
                    <Row>
                      <Col span={comment.message?.length < 36 ? 18 : 24}>
                        {comment.message}
                      </Col>
                      <Col flex="auto">
                        <Box float="right">
                          <ConfigProvider
                            theme={{
                              components: {
                                Button: {
                                  paddingInlineSM: 0,
                                },
                              },
                            }}
                          >
                            <Flex gap="small" wrap="wrap">
                              <AntButton
                                data-testid="delete"
                                formAction="none"
                                size="small"
                                type="link"
                                onClick={() => {
                                  if (!!comment?.id)
                                    handleDeleteClick(comment?.id);
                                }}
                              >
                                Delete
                              </AntButton>
                              <AntButton
                                data-testid="edit"
                                formAction="none"
                                size="small"
                                type="link"
                                onClick={() => {
                                  handleEditClick(comment);
                                }}
                              >
                                Edit
                              </AntButton>
                            </Flex>
                          </ConfigProvider>
                        </Box>
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <div>No Commnets Founds</div>
            )}
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </div>
  );
}
