import React from "react";
import FormSection from "../../components/FormSection";
import Divider from "@mui/material/Divider";
import {
  Button,
  Grid,
  Input,
  SpaceBetween,
  TokenGroup,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFCheckBox from "../../components/RHF/RHFCheckBox";

interface DropDownConfigStateProps {
  options: {
    label: string;
  }[];
  setOptions: (
    options: {
      label: string;
    }[],
  ) => void;
}
export function DropDownConfig({
  options = [],
  setOptions,
}: DropDownConfigStateProps) {
  const [value, setValue] = React.useState<string>();

  return (
    <SpaceBetween size="s">
      <RHFTextField label="Name" name="name" readOnly />
      <RHFTextField label="Description" name="description" />
      <RHFSelect
        label="Default value"
        name="defaultValue"
        options={Array.from(options).map((item) => {
          return { label: item.label, value: item.label };
        })}
      />

      <SpaceBetween size={"s"}>
        <Divider></Divider>
        <b>Options</b>
      </SpaceBetween>
      <form
        id="optionsForm"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
          <Input
            value={value ?? ""}
            placeholder="add option"
            onChange={(changeDetails) => {
              setValue(changeDetails.detail.value);
            }}
          />
          <Button
            formAction="submit"
            form="optionsForm"
            onClick={(changeDetail) => {
              changeDetail.preventDefault();
              if (value) {
                let duplicate = !!options.find(
                  (option) =>
                    option.label.toLowerCase() === value?.toLowerCase(),
                );
                if (!duplicate) setOptions([...options, { label: value }]);
                setValue("");
              }
            }}
          >
            Add
          </Button>
        </Grid>
      </form>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          setOptions([
            ...options.slice(0, itemIndex),
            ...options.slice(itemIndex + 1),
          ]);
        }}
        items={options}
      />

      <FormSection columns={2} header={"Validations"}>
        <RHFCheckBox label="Is Required" name="required" />
        <RHFCheckBox label="Is Sensitive info" name="sensitive" />
      </FormSection>
    </SpaceBetween>
  );
}
