import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../components/LoadingScreen";
import FacilityFilters from "../../components/filter/facility-filters";
import { Facility } from "../../redux/api/facility/types";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFacilityFilter from "../../hooks/useFacilityFilter";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useUpdateAdminFacilityMutation } from "../../redux/api/manageadmin/manageadmin";

export default function AssociatedFacilitiesModals({
  isAssociatedFacilitiesModalOpen,
  setAssociatedFacilitiesModalOpen,
  associatedFacilities,
  setAssociatedFacilities,
}: {
  isAssociatedFacilitiesModalOpen: boolean;
  setAssociatedFacilitiesModalOpen: Dispatch<SetStateAction<boolean>>;
  associatedFacilities: Facility[];
  setAssociatedFacilities: Dispatch<SetStateAction<Facility[]>>;
}) {
  const { manageadminId } = useParams();
  const [updateAdminFacility] = useUpdateAdminFacilityMutation();
  const { t } = useTranslation();
  const { filteredFacilities, filter, isLoading, isFetching, setFilter } =
    useFacilityFilter();

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <FacilityFilters filter={filter} setFilter={setFilter} />,
      variant: "borderless",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacilities(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacilities;
        if (
          !detail.item ||
          !!associatedFacilities
            ?.map((associatedFacility) => associatedFacility?.id + "")
            ?.includes(detail.item.id + "")
        )
          return;
        if (temp?.includes(detail.item))
          setSelectedFacilities(temp.filter((item) => item !== detail.item));
        else setSelectedFacilities([detail.item, ...temp]);
      },
      selectedItems: selectedFacilities,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "npiNumber", visible: true },
        { id: "facilityType", visible: true },
      ],
      isItemDisabled: (item) => {
        return associatedFacilities
          ?.map((associatedFacility) => associatedFacility?.id + "")
          ?.includes(item?.id + "");
      },
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("facility.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>{t("facility.table.header.fields.name")}</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "npiNumber",
          header: <div>{t("facility.table.header.fields.npiNumber")}</div>,
          cell: (item) => <span>{item.npiNumber}</span>,
        },
        {
          id: "facilityType",
          header: <div>{t("facility.table.header.fields.facilityType")}</div>,
          cell: (item) => <span>{item?.facilityType?.name}</span>,
        },
      ],
    };
  }, [items, selectedFacilities]);
  return (
    <Modal
      visible={isAssociatedFacilitiesModalOpen}
      size="max"
      header={
        <Header counter={`(${filteredFacilities.length})`}>Facilities</Header>
      }
      onDismiss={() => {
        setAssociatedFacilitiesModalOpen(false);
      }}
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={() => {
                setSelectedFacilities([]);
                setAssociatedFacilitiesModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                try {
                  if (!!selectedFacilities) {
                    const faciltiesToUpdate =
                      selectedFacilities?.map(
                        (selectedFacility) => selectedFacility?.id + "",
                      ) || [];

                    const manageadminIdNum = parseInt(manageadminId || "", 10);

                    if (isNaN(manageadminIdNum)) {
                      throw new Error("Invalid manageadminId");
                    }

                    await updateAdminFacility({
                      manageadminId: manageadminIdNum,
                      faciltiesToupdate: faciltiesToUpdate,
                    });
                    setAssociatedFacilities([
                      ...associatedFacilities,
                      ...selectedFacilities,
                    ]);
                    setSelectedFacilities([]);
                    setAssociatedFacilitiesModalOpen(false);
                  }
                } catch (error) {
                  console.error("Error updating admin facility:", error);
                  // Handle error (e.g., show a message to the user)
                }
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div>
        <LoadingScreen isOpen={isLoading} />
        <Table {...tableProps} />
      </div>
    </Modal>
  );
}
