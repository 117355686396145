import {
  Box,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Card } from "antd";
import { useGetAllDocumentVersionsQuery } from "../../../redux/api/document/document";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import "./document-activities.css";
import ChangeTimeZone from "../../../components/Timezone";
import useIsLoading from "../../../hooks/useIsLoading";

export default function DocumentVersions() {
  const { providerId, documentId } = useParams();
  const navigate = useNavigate();
  const { data: documentVersions = [], isLoading } =
    useGetAllDocumentVersionsQuery(
      !!documentId && !!providerId ? { providerId, documentId } : skipToken,
    );

  useIsLoading(isLoading);
  return (
    <Container header={<Header>Version History</Header>}>
      <SpaceBetween size="m">
        {(!documentVersions || documentVersions?.length === 0) &&
          "No Versions Founds"}

        {!!documentVersions &&
          !!documentVersions?.length &&
          documentVersions?.map((documentVersion) => {
            return (
              <Card
                size="small"
                onClick={() => {
                  navigate(
                    `/safe/${providerId}/documents/${documentId}/version/${documentVersion.id}`,
                  );
                }}
                title={
                  <Box variant="p">
                    Added by{" "}
                    <b>
                      {documentVersion?.user?.firstName}{" "}
                      {documentVersion?.user?.lastName}
                    </b>{" "}
                    on{" "}
                    <b>
                      {!!documentVersion?.createdDate
                        ? new Date(
                            documentVersion?.createdDate,
                          ).toLocaleString()
                        : "-"}
                    </b>
                  </Box>
                }
                hoverable
              >
                <Box variant="p">
                  Name: <i>{documentVersion?.name ?? "-"} </i>
                </Box>

                <Box variant="p">
                  File: <i>{documentVersion?.attachment?.name ?? "-"}</i>
                </Box>

                <Box variant="p">
                  Category: <i>{documentVersion?.category?.name ?? "-"}</i>
                </Box>

                <Box variant="p">
                  Expiration Date:{" "}
                  <i>
                    {ChangeTimeZone(documentVersion?.expirationDate) ?? "-"}
                  </i>
                </Box>

                <Box variant="p">
                  Expiration Alert Reminder:{" "}
                  <i>
                    {documentVersion?.alertDays !== 0
                      ? `${documentVersion?.alertDays} days`
                      : "None"}
                  </i>
                </Box>
              </Card>
            );
          })}
      </SpaceBetween>
    </Container>
  );
}
