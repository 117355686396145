import {
  ExpandableSection,
  Header,
  Modal,
  SpaceBetween,
  Button,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../../../redux/api/provider/provider";
import {
  useAddMyTaskMutation,
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../../redux/api/task";
import { TaskRequest } from "../../../redux/api/task/types";
import { useGetAllWorkflowQuery } from "../../../redux/api/workflow/workflow";
import { useGetFacilitiesQuery } from "../../../redux/api/facility/facility";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import { Divider } from "antd";
import { Grid } from "@mui/material";
import "./mytaskmodal.css";
import { useGetOrganizationDetailsQuery } from "../../../redux/api/organization/organization";
import { alertReminderOptions } from "../../../helpers/constants";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddMyTaskModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<TaskRequest>();
  const [addMyTask] = useAddMyTaskMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: workflows = [] } = useGetAllWorkflowQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const onSubmit = async (data: TaskRequest) => {
    await notifyWrapper({
      promise: addMyTask(data),
      resourceName: "Task",
      actionName: "Add",
    });
    setModalName("");
  };

  return (
    <Modal
      header={<Header>Add My Task</Header>}
      visible={true}
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <SpaceBetween size={"s"}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SpaceBetween size="l">
              <FormSection columns={3}>
                <RHFSelect
                  name="taskTypeId"
                  label="Task Type"
                  options={taskTypes.map((type) => ({
                    label: type.name,
                    value: "" + type.id,
                  }))}
                  rules={{ required: "This field is required" }}
                  stretch={false}
                />
                <RHFTextField label={"Task Name"} name="name" stretch={false} />
                <RHFSelect
                  name="taskStatusId"
                  label="Task Status"
                  options={taskstatuses.map((status) => ({
                    label: status.name,
                    value: "" + status.id,
                  }))}
                  stretch={false}
                />
              </FormSection>

              <FormSection columns={3}>
                <RHFSelect
                  label={"Provider"}
                  name={"providerId"}
                  stretch={false}
                  filteringType="auto"
                  options={providers.map((provider) => ({
                    label: provider.firstName + " " + provider.lastName,
                    value: "" + provider.id,
                  }))}
                />
                <RHFSelect
                  label={"Facility"}
                  name={"facilityId"}
                  stretch={false}
                  filteringType="auto"
                  options={facilities.map((facility) => ({
                    label: facility.name,
                    value: "" + facility.id,
                  }))}
                />
                {!!organization?.organizationServiceLimits?.workflows && (
                  <RHFSelect
                    name="workflowId"
                    label="Associated Workflow"
                    options={workflows.map((workflow) => ({
                      label: workflow.name,
                      value: "" + workflow.id,
                    }))}
                    stretch={false}
                  />
                )}
              </FormSection>

              <FormSection columns={2}>
                <RHFDatePicker
                  label={"Start date"}
                  name={"startDate"}
                  defaultValue={new Date().toUTCString()}
                  stretch={true}
                />
                <RHFDatePicker
                  label={"Completed date"}
                  name={"completedDate"}
                  stretch={true}
                />
              </FormSection>
              <FormSection columns={2}>
                <RHFDatePicker label={"Expiration Date"} name={"dueDate"} />
                <RHFSelect
                  label={"Alert Days"}
                  name={"alertDays"}
                  options={alertReminderOptions}
                />
              </FormSection>
            </SpaceBetween>
            <Divider />
            <ExpandableSection headerText="Notes">
              <FormSection>
                <RHFTextArea
                  label={"Notes"}
                  name="description"
                  stretch={true}
                />
              </FormSection>
            </ExpandableSection>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button formAction="submit" variant="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SpaceBetween>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddMyTaskModal);
