import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import FormSection from "../../../../components/FormSection";
import KeyValue from "../../../../components/KeyValue";
import { useGetProviderQuery } from "../../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import ChangeTimeZone from "../../../../components/Timezone";
import { AsYouType } from "libphonenumber-js";

export default function ProfessionalInfo() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { t } = useTranslation();
  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  return (
    <Container
      header={
        <SpaceBetween size="xxs">
          <Header
            actions={
              <Button
                onClick={() => {
                  navigate(`editprofessionalinfo`);
                }}
              >
                {t(
                  "providerProfiles.professionalInfo.content.header.actions.edit",
                )}
              </Button>
            }
          >
            {t("providerProfiles.professionalInfo.content.header.label")}
          </Header>
          <Divider></Divider>
        </SpaceBetween>
      }
    >
      <div style={{ paddingTop: "12px" }}>
        <SpaceBetween size="m">
          <FormSection columns={4}>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.caqh",
              )}
              value={provider?.professionalInfo?.caqh ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.primarySpecialty",
              )}
              value={provider?.professionalInfo?.primarySpecialty?.name ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.title",
              )}
              value={provider?.professionalInfo?.title ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.workPhone",
              )}
              value={
                new AsYouType("US").input(
                  provider?.professionalInfo?.workPhone ?? "",
                ) ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.identificationNumber",
              )}
              value={provider?.professionalInfo?.identificationNumber ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.workPhoneAlt",
              )}
              value={
                new AsYouType("US").input(
                  provider?.professionalInfo?.workPhoneAlt ?? "",
                ) ?? "-"
              }
            ></KeyValue>
          </FormSection>
          <Divider></Divider>
          <FormSection
            columns={4}
            header={
              <Header variant="h3">
                {t(
                  "providerProfiles.professionalInfo.content.header.practiceInfo",
                )}
              </Header>
            }
          >
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.practiceName",
              )}
              value={provider?.professionalInfo?.practiceInfo?.name ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.yearsOfPractice",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.yearsOfPractice ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.startDate",
              )}
              value={
                ChangeTimeZone(
                  provider?.professionalInfo?.practiceInfo?.startDate,
                ) ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.endDate",
              )}
              value={
                ChangeTimeZone(
                  provider?.professionalInfo?.practiceInfo?.endDate,
                ) ?? "-"
              }
            ></KeyValue>

            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.addressLane1",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.addressLane1 ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.addressLane2",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.addressLane2 ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.city",
              )}
              value={provider?.professionalInfo?.practiceInfo?.city ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.state",
              )}
              value={provider?.professionalInfo?.practiceInfo?.state ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.zipcode",
              )}
              value={provider?.professionalInfo?.practiceInfo?.zipcode ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.country",
              )}
              value={provider?.professionalInfo?.practiceInfo?.country ?? "-"}
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.practiceContactName",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.contactName ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.practiceContactTitle",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.contactTitle ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.practiceContactPhone",
              )}
              value={
                new AsYouType("US").input(
                  provider?.professionalInfo?.practiceInfo?.contactPhone ?? "",
                ) ?? "-"
              }
            ></KeyValue>

            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.practiceContactEmail",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.contactEmail ?? "-"
              }
            ></KeyValue>
            <KeyValue
              label={t(
                "providerProfiles.professionalInfo.content.header.fields.practiceContactFax",
              )}
              value={
                provider?.professionalInfo?.practiceInfo?.contactFax ?? "-"
              }
            ></KeyValue>
          </FormSection>
        </SpaceBetween>
      </div>
    </Container>
  );
}
