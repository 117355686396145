import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useCredentialPackagesFilter";
import {
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";

interface CredentialPackageFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function CredentialPackageFilters({
  filter,
  setFilter,
  countText,
}: CredentialPackageFiltersProps) {
  const { data: packageType = [] } = useGetAllPackageTypesQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { data: status = [] } = useGetAllPackageStatusQuery();
  const { data: credentialTemplate = [] } = useGetAllCredentialTemplatesQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Package Name",
    value: "packageName",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.packageTypeFilterOptions &&
      filter.packageTypeFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Package Type = ${join(
            filter.packageTypeFilterOptions.map(
              (packageTypeFilterOptions) => packageTypeFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (
      !!filter.providerFilterOptions &&
      filter.providerFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Provider = ${join(
            filter.providerFilterOptions.map(
              (providerFilterOptions) => providerFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (!!filter.statusFilterOptions && filter.statusFilterOptions.length > 0)
      temp = [
        ...temp,
        {
          label: `Status = ${join(
            filter.statusFilterOptions.map(
              (statusFilterOption) => statusFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (
      !!filter.credentialTemplateFilterOptions &&
      filter.credentialTemplateFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Credential Template = ${join(
            filter.credentialTemplateFilterOptions.map(
              (credentialTemplateFilterOptions) =>
                credentialTemplateFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterPackageName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Package Name", value: "packageName" },
            { label: "Package Type", value: "packageType" },
            { label: "Provider", value: "provider" },
            { label: "Status", value: "status" },
            { label: "Credential Template", value: "credentialTemplate" },
          ]}
        />
        <div>
          {selectedProperty.value === "packageName" && (
            <TextFilter
              filteringPlaceholder="Find Package Name"
              filteringText={filter.filterPackageName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterPackageName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "packageType" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.packageTypeFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  packageTypeFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={packageType.map((type) => ({
                label: type.name,
                value: type.id + "",
              }))}
              placeholder="Choose Package Type"
            />
          )}
          {selectedProperty.value === "provider" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.providerFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  providerFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={provider.map((provider) => ({
                label: provider?.firstName + " " + provider?.lastName,
                value: provider?.id + "",
              }))}
              placeholder="Choose Provider"
            />
          )}
          {selectedProperty.value === "status" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.statusFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  statusFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={status.map((status) => ({
                label: status.name,
                value: status.id,
              }))}
              placeholder="Choose Status"
            />
          )}
          {selectedProperty.value === "credentialTemplate" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.credentialTemplateFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  credentialTemplateFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={credentialTemplate.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
              placeholder="Choose Template"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Package Type")
          ) {
            setFilter({
              ...filter,
              packageTypeFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Provider")
          ) {
            setFilter({
              ...filter,
              providerFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Status")
          ) {
            setFilter({
              ...filter,
              statusFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Credential Template")
          ) {
            setFilter({
              ...filter,
              credentialTemplateFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
