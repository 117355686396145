import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllDigitalFormsQuery } from "../redux/api/digitalform/digitalforms";
import { DigitalForm } from "../redux/api/digitalform/types";

export type FilterType = {
  filterReadyFormName: string;
  readyFormTypeFilterOptions: OptionDefinition[];
};

export default function useReadyFormFilter() {
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useGetAllDigitalFormsQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterReadyFormName: "",
    readyFormTypeFilterOptions: [],
  });

  const [filteredReadyForm, setFilteredReadyForm] =
    useState<DigitalForm[]>(data);

  useEffect(() => {
    setFilteredReadyForm(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_readyform = [...data];
    if (filter.filterReadyFormName) {
      temp_filtered_readyform = temp_filtered_readyform.filter((readyform) =>
        readyform?.name
          ?.toLowerCase()
          .includes(filter?.filterReadyFormName?.toLowerCase()),
      );
    }

    const selectedReadyFormTypeId = filter.readyFormTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedReadyFormTypeId && selectedReadyFormTypeId.length > 0) {
      temp_filtered_readyform = temp_filtered_readyform.filter(
        (readyform) =>
          selectedReadyFormTypeId.indexOf(
            readyform?.digitalFormType?.id + "",
          ) >= 0,
      );
    }

    setFilteredReadyForm(temp_filtered_readyform);
  }, [filter]);

  return {
    filteredReadyForm,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
