import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../redux/UI/actions";
import { ContractType } from "../../redux/api/contracts/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllContractTypesQuery,
  useSaveAllContractTypesMutation,
} from "../../redux/api/contracts/contract";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function ContractTypeModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllContractTypesQuery();
  const { t } = useTranslation();
  const [contractType, setContractType] = useState<ContractType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllContractTypes] = useSaveAllContractTypesMutation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contract.edit") ?? false),
  );

  const handleSubmit = async () => {
    setModalName("");
    const tmp = [...contractType].filter((item) => !!item.type);
    await notifyWrapper({
      promise: saveAllContractTypes(tmp),
      resourceName: "contract type",
      actionName: "save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setContractType(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>{t("contracts.contractType.header.label")}</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t("contracts.contractType.header.actions.cancel")}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
              disabled={!hasPermission}
            >
              {t("contracts.contractType.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>

          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setContractType([
                      ...contractType,
                      { id: "", type: "", description: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...contractType];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      type: item.type,
                      description: item.description,
                      deleted: true,
                    };
                    setContractType(tmp);
                  }}
                  items={contractType.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "contracts.contractType.header.actions.addContractType",
                  )}
                  definition={[
                    {
                      label: `${t("contracts.contractType.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.type}
                          onChange={(changeDetails) => {
                            const tmp = [...contractType];
                            tmp[itemIndex] = {
                              id: item.id,
                              type: changeDetails.detail.value,
                              description: item.description,
                              deleted: item.deleted,
                            };
                            setContractType(tmp);
                          }}
                          placeholder={t(
                            "contracts.contractType.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ContractTypeModal);
