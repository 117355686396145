import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { Document } from "../../../redux/api/document/types";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../../components/RHF/RHFTextField";
import Grid from "@mui/material/Grid";

export default function ShareDocumentModal({
  selectedDocument,
  shareDocument,
  closeModal,
  visible,
}: {
  selectedDocument: Document | undefined;
  shareDocument: (email: string) => void;
  closeModal: VoidFunction;
  visible: boolean;
}) {
  const methods = useForm<{ email: string }>({ defaultValues: { email: "" } });
  const onSubmit = (data: { email: string }) => {
    methods.resetField("email");
    shareDocument(data.email);
  };
  React.useEffect(() => {
    methods.resetField("email");
  }, []);

  return (
    <Modal
      onDismiss={() => {
        methods.resetField("email");
        closeModal();
      }}
      visible={visible}
      header={`Share "${selectedDocument?.name}"`}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween size="l">
            <RHFTextField
              label={"Email address"}
              name="email"
              placeholder={"Enter email address"}
              rules={{
                required: "This field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email",
                },
              }}
            />
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    methods.resetField("email");
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Share</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
