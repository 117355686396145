import {
  BreadcrumbGroup,
  Button,
  Container,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useMemo, useState, useTransition } from "react";
import InnerAppLayout from "../../components/InnerAppLayout";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { FormProvider, useForm } from "react-hook-form";
import useIsLoading from "../../hooks/useIsLoading";
import {
  useGetDocumentQuery,
  useSavePsvMutation,
} from "../../redux/api/document/document";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import { alertReminderOptions } from "../../helpers/constants";
import Grid from "@mui/material/Grid";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";

type FormInputType = {
  sourceType: string;
  primarySource: string;
  status: string;
  alertDays: string;
  expiration: string;
  file: File;
};

export default function AddPSV() {
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState<File>();
  const methods = useForm<FormInputType>();
  const [addPsv, { isLoading: isCreating }] = useSavePsvMutation();
  const { providerId, documentId } = useParams();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const { data: document } = useGetDocumentQuery(
    documentId && providerId ? { documentId, providerId } : skipToken,
  );

  useIsLoading(isCreating);

  const onSubmit = async (data: FormInputType) => {
    if (documentId) {
      await notifyWrapper({
        promise: addPsv({
          documentId,
          sourceType: data?.sourceType,
          primarySource: data?.primarySource,
          status: data?.status,
          alertDays: data?.alertDays,
          expiration: data?.expiration,
          file: uploadFile,
        }),
        resourceName: "psv",
        actionName: "add",
      });
    }
  };
  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerSafe.content.details.psv.addPsv.header.breadcrumb.text")}`,
              href: "/manageprovider",
            },
            { text: displayName, href: `/safe/${providerId}` },
            {
              text: document?.name ?? "",
              href: `#`,
            },
            {
              text: `${t("providerSafe.content.details.psv.addPsv.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      rightPanel={
        <AttachmentViewer
          attachment={undefined}
          hideHeader
          maxHeight
          file={uploadFile}
          onFileChange={(uploadFile?: File) => {
            setUploadFile(uploadFile);
          }}
        />
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container>
              <SpaceBetween direction="vertical" size="s">
                <FormSection columns={2}>
                  <RHFTextField
                    label={t(
                      "providerSafe.content.details.psv.addPsv.header.fields.sourceType",
                    )}
                    name="sourceType"
                    stretch
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField
                    label={t(
                      "providerSafe.content.details.psv.addPsv.header.fields.primarySource",
                    )}
                    name="primarySource"
                    stretch
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField
                    label={t(
                      "providerSafe.content.details.psv.addPsv.header.fields.status",
                    )}
                    name="status"
                    stretch
                  />
                  <RHFDatePicker
                    label={t(
                      "providerSafe.content.details.psv.addPsv.header.fields.expirationDate",
                    )}
                    name={"expiration"}
                  />
                  <RHFSelect
                    label={t(
                      "providerSafe.content.details.psv.addPsv.header.fields.alertDays",
                    )}
                    name={"alertDays"}
                    options={alertReminderOptions}
                  />
                </FormSection>

                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      formAction="none"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t(
                        "providerSafe.content.details.psv.addPsv.header.actions.cancel",
                      )}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button formAction="submit" variant="primary">
                      {t(
                        "providerSafe.content.details.psv.addPsv.header.actions.add",
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </SpaceBetween>
            </Container>
          </form>
        </FormProvider>
      }
    />
  );
}
