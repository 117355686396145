import { api } from "..";
import { Category } from "../category/types";
import { DigitalForm } from "../digitalform/types";
import { AllDocumentResponse } from "../document/types";
import {
  CredentialPackage,
  CredentialPackageRequest,
  CredentialPackageStatus,
  CredentialPackageType,
  CredentialPackageWithAttachment,
  CredentialTemplate,
  CredentialTemplateRequest,
} from "./types";
const CredentialPackagesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCredentialTemplates: build.query<CredentialTemplate[], void>({
      query: () => `credentialing/templates/all`,
      providesTags: ["Credentialing"],
    }),
    getCredentialTemplate: build.query<
      CredentialTemplate,
      { credentialTemplateId: string }
    >({
      query: ({ credentialTemplateId }) =>
        `credentialing/templates/${credentialTemplateId}`,
      providesTags: (result, error, { credentialTemplateId }) => [
        { type: "Credentialing", id: credentialTemplateId },
      ],
    }),
    createCredentialTemplate: build.mutation<
      CredentialTemplate,
      CredentialTemplateRequest
    >({
      query: ({ ...body }) => {
        return {
          url: `credentialing/templates`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Credentialing"],
    }),
    updateCredentialTemplate: build.mutation<
      CredentialTemplate,
      Pick<CredentialTemplate, "id"> & Partial<CredentialTemplateRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `credentialing/templates/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.id },
        { type: "Credentialing" },
      ],
    }),
    getAllCredentialTemplateCategories: build.query<
      Category[],
      { credentialTemplateId: string }
    >({
      query: ({ credentialTemplateId }) =>
        `credentialing/templates/${credentialTemplateId}/categories`,
      providesTags: (result, error, { credentialTemplateId }) => [
        { type: "Credentialing", id: credentialTemplateId },
      ],
    }),
    updateCredentialTemplateCategories: build.mutation<
      CredentialTemplate,
      { id: string; categories: string[] }
    >({
      query: ({ id, categories }) => {
        return {
          url: `credentialing/templates/${id}/categories`,
          method: "POST",
          body: categories,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.id },
        { type: "Credentialing" },
      ],
    }),
    deleteCredentialTemplateCategories: build.mutation<
      CredentialTemplate,
      { credentialTemplateId: string; categories: string[] }
    >({
      query: ({ credentialTemplateId, categories }) => {
        return {
          url: `credentialing/templates/${credentialTemplateId}/categories`,
          method: "DELETE",
          body: categories,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.credentialTemplateId },
        { type: "Credentialing" },
      ],
    }),
    deleteCredentialTemplate: build.mutation<
      void,
      {
        credentialTemplateId: string;
      }
    >({
      query: ({ credentialTemplateId }) => ({
        url: `credentialing/templates/${credentialTemplateId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Credentialing"],
    }),

    uploadCredentialTemplateAttachment: build.mutation<
      void,
      { credentialTemplateId: string; file: File }
    >({
      query({ credentialTemplateId, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (file) formData.append("file", file);

        return {
          url: `credentialing/templates/${credentialTemplateId}/attachment`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Credentialing" }],
    }),
    getAllPackageTypes: build.query<CredentialPackageType[], void>({
      query: () => `credentialing/packages/type/all`,
      providesTags: ["Credentialing"],
    }),

    getPackageType: build.query<
      CredentialPackageType,
      { packageTypeId: string }
    >({
      query: ({ packageTypeId }) =>
        `credentialing/packages/type/${packageTypeId}`,
      providesTags: ["Credentialing"],
    }),
    saveAllPackageTypes: build.mutation<void, CredentialPackageType[]>({
      query: (packageTypes) => {
        return {
          url: "credentialing/packages/type/all",
          method: "POST",
          body: packageTypes,
        };
      },
      invalidatesTags: ["Credentialing"],
    }),
    getAllCredentialPackages: build.query<CredentialPackage[], void>({
      query: () => `credentialing/packages/all`,
      providesTags: ["Credentialing"],
    }),
    getCredentialPackage: build.query<CredentialPackage, string>({
      query: (id) => `credentialing/packages/${id}`,
      providesTags: (result, error, id) => [{ type: "Credentialing", id: id }],
    }),
    downloadCredentialPackage: build.mutation<
      void,
      {
        credentialPackageId: string;
      }
    >({
      query: ({ credentialPackageId }) => {
        return {
          url: `credentialing/packages/${credentialPackageId}/documents/zip`,
          method: "GET",
        };
      },
    }),
    getCredentialPackageCombinedPdf: build.query<
      CredentialPackageWithAttachment,
      string
    >({
      query: (id) => `credentialing/packages/${id}/pdf`,
      providesTags: (result, error, id) => [{ type: "Credentialing", id: id }],
    }),
    createCredentialPackage: build.mutation<
      CredentialPackage,
      CredentialPackageRequest
    >({
      query: ({ ...body }) => {
        return {
          url: `credentialing/packages`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Credentialing"],
    }),
    updateCredentialPackage: build.mutation<
      CredentialPackage,
      Pick<CredentialPackage, "id"> & Partial<CredentialPackageRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `credentialing/packages/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.id },
        { type: "Credentialing" },
      ],
    }),
    deleteCredentialPackage: build.mutation<
      void,
      {
        credentialPackageId: string;
      }
    >({
      query: ({ credentialPackageId }) => ({
        url: `credentialing/packages/${credentialPackageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Credentialing"],
    }),

    getAllPackageStatus: build.query<CredentialPackageStatus[], void>({
      query: () => `credentialing/packages/status/all`,
      providesTags: ["Credentialing"],
    }),
    saveAllStatusTypes: build.mutation<void, CredentialPackageStatus[]>({
      query: (statusTypes) => {
        return {
          url: "credentialing/packages/status/all",
          method: "POST",
          body: statusTypes,
        };
      },
      invalidatesTags: ["Credentialing"],
    }),
    getAllCredentialPackageDocuments: build.query<
      AllDocumentResponse[],
      { credentialPackageId: string }
    >({
      query: ({ credentialPackageId }) =>
        `credentialing/packages/${credentialPackageId}/documents`,
      providesTags: (result, error, { credentialPackageId }) => [
        { type: "Credentialing", id: credentialPackageId },
      ],
    }),
    updateCredentialPackageDocuments: build.mutation<
      CredentialPackage,
      { id: string; documents: string[] }
    >({
      query: ({ id, documents }) => {
        return {
          url: `credentialing/packages/${id}/documents`,
          method: "POST",
          body: documents,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.id },
        { type: "Credentialing" },
      ],
    }),
    deleteCredentialPackageDocuments: build.mutation<
      CredentialPackage,
      { credentialPackageId: string; documents: string[] }
    >({
      query: ({ credentialPackageId, documents }) => {
        return {
          url: `credentialing/packages/${credentialPackageId}/documents`,
          method: "DELETE",
          body: documents,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.credentialPackageId },
        { type: "Credentialing" },
      ],
    }),
    getAllCredentialTemplateForms: build.query<
      DigitalForm[],
      { credentialTemplateId: string }
    >({
      query: ({ credentialTemplateId }) =>
        `credentialing/templates/${credentialTemplateId}/forms`,
      providesTags: (result, error, { credentialTemplateId }) => [
        { type: "Credentialing", id: credentialTemplateId },
      ],
    }),
    updateCredentialTemplateForms: build.mutation<
      CredentialTemplate,
      { id: string; forms: string[] }
    >({
      query: ({ id, forms }) => {
        return {
          url: `credentialing/templates/${id}/forms`,
          method: "POST",
          body: forms,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.id },
        { type: "Credentialing" },
      ],
    }),
    deleteCredentialTemplateForms: build.mutation<
      CredentialTemplate,
      { credentialTemplateId: string; forms: string[] }
    >({
      query: ({ credentialTemplateId, forms }) => {
        return {
          url: `credentialing/templates/${credentialTemplateId}/forms`,
          method: "DELETE",
          body: forms,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.credentialTemplateId },
        { type: "Credentialing" },
      ],
    }),
    getAllCredentialPackageForms: build.query<
      DigitalForm[],
      { credentialPackageId: string }
    >({
      query: ({ credentialPackageId }) =>
        `credentialing/packages/${credentialPackageId}/forms`,
      providesTags: (result, error, { credentialPackageId }) => [
        { type: "Credentialing", id: credentialPackageId },
      ],
    }),
    updateCredentialPackageForms: build.mutation<
      CredentialPackage,
      { id: string; forms: string[] }
    >({
      query: ({ id, forms }) => {
        return {
          url: `credentialing/packages/${id}/forms`,
          method: "POST",
          body: forms,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.id },
        { type: "Credentialing" },
      ],
    }),
    deleteCredentialPackageForms: build.mutation<
      CredentialPackage,
      { credentialPackageId: string; forms: string[] }
    >({
      query: ({ credentialPackageId, forms }) => {
        return {
          url: `credentialing/packages/${credentialPackageId}/forms`,
          method: "DELETE",
          body: forms,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Credentialing", id: arg.credentialPackageId },
        { type: "Credentialing" },
      ],
    }),
  }),
});
export const {
  useGetAllCredentialTemplateCategoriesQuery,
  useDeleteCredentialTemplateCategoriesMutation,
  useUpdateCredentialTemplateCategoriesMutation,
  useGetAllCredentialTemplatesQuery,
  useDeleteCredentialTemplateMutation,
  useGetCredentialTemplateQuery,
  useCreateCredentialTemplateMutation,
  useUpdateCredentialTemplateMutation,
  useUploadCredentialTemplateAttachmentMutation,
  useGetAllPackageTypesQuery,
  useGetPackageTypeQuery,
  useSaveAllPackageTypesMutation,
  useCreateCredentialPackageMutation,
  useDeleteCredentialPackageMutation,
  useGetAllCredentialPackagesQuery,
  useGetCredentialPackageCombinedPdfQuery,
  useGetCredentialPackageQuery,
  useDownloadCredentialPackageMutation,
  useUpdateCredentialPackageMutation,
  useGetAllPackageStatusQuery,
  useSaveAllStatusTypesMutation,
  useGetAllCredentialPackageDocumentsQuery,
  useUpdateCredentialPackageDocumentsMutation,
  useDeleteCredentialPackageDocumentsMutation,
  useGetAllCredentialTemplateFormsQuery,
  useDeleteCredentialTemplateFormsMutation,
  useUpdateCredentialTemplateFormsMutation,
  useGetAllCredentialPackageFormsQuery,
  useUpdateCredentialPackageFormsMutation,
  useDeleteCredentialPackageFormsMutation,
} = CredentialPackagesAPI;
