import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function usePageIndex(
  currentPageIndex: string,
  options: { queryParamName: string } = { queryParamName: "pageIndex" },
) {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (
      !!searchParams.has(options.queryParamName) &&
      searchParams.get(options.queryParamName) === currentPageIndex
    )
      return;
    setSearchParams((searchParams) => {
      searchParams.set(options.queryParamName, currentPageIndex);
      return searchParams;
    });
  }, [currentPageIndex, setSearchParams, searchParams, options.queryParamName]);
}
