import {
  ButtonDropdown,
  FormField,
  BreadcrumbGroup,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useGetAllExpiringDocumentsQuery } from "../redux/api/reporting/reporting";
import ChangeTimeZone from "../components/Timezone";
import { useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./ReportingToolBar";
import { useTranslation } from "react-i18next";

export default function ExpiringDocuments() {
  const { t } = useTranslation();
  const [days, setDays] = useState<string>("180");
  const { data = [], isLoading } = useGetAllExpiringDocumentsQuery({
    days: days ?? "180",
  });
  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("reports.expiringDocuments.breadcrumb.text")}`,
            href: "/reports",
          },
          {
            text: `${t("reports.expiringDocuments.breadcrumb.currentPage")}`,
            href: "#",
          },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <FormField
        label={t("reports.expiringDocuments.table.header.filter.label")}
        description={t(
          "reports.expiringDocuments.table.header.filter.description",
        )}
      >
        <ButtonDropdown
          onItemClick={({ detail }) => {
            setDays(detail.id);
          }}
          items={[
            { text: "Last 180 days", id: "180", disabled: false },
            { text: "Last 120 days", id: "120" },
            { text: "Last 90 days", id: "90" },
            {
              text: "Last 30 days",
              id: "30",
            },
          ]}
        >
          {`Last ${days} days`}
        </ButtonDropdown>
      </FormField>
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid
          columns={[
            {
              field: "DocumentName",
              headerName: "Document_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.document?.name ?? "-",
            },
            {
              field: "expirationDate",
              headerName: "Expiration_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.document?.expirationDate) ?? "-",
            },
            {
              field: "createdDate",
              headerName: "Created_Date",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                ChangeTimeZone(params.row?.document?.createdDate) ?? "-",
            },
            {
              field: "category",
              headerName: "Category",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.document?.category?.name ?? "-",
            },
            {
              field: "lastName",
              headerName: "Provider_LastName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.lastName ?? "-",
            },
            {
              field: "firstname",
              headerName: "Provider_FirstName",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.firstName ?? "-",
            },
            {
              field: "npi",
              headerName: "NPI_Number",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.npiNumber ?? "-",
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) => params.row?.provider?.email ?? "-",
            },
            {
              field: "licenseType",
              headerName: "License_Type",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.licenseType?.name ?? "-",
            },
            {
              field: "identificationNumber",
              headerName: "IdentificationNumber",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.identificationNumber ?? "-",
            },
            {
              field: "facility",
              headerName: "Facility_Name",
              flex: 1,
              minWidth: 200,
              valueGetter: (params) =>
                params.row?.provider?.facility?.name ?? "-",
            },
          ]}
          rows={data}
          getRowId={(row) => row?.provider?.id + "_" + row?.document?.id}
          loading={isLoading}
          slots={{ toolbar: ToolBar }}
          disableRowSelectionOnClick={true}
          disableColumnMenu
          density="compact"
        />
      </div>
    </SpaceBetween>
  );
}

const ToolBar = () => <CustomToolbar fileName="expiring_documents_report" />;
