import { Box } from "@cloudscape-design/components";

type LabelProp = {
  label: String;
  required?: boolean;
};

export default function Label({ label, required = false }: LabelProp) {
  if (!!required)
    return (
      <span>
        {label} {<span style={{ color: "#fc0a37" }}>*</span>}
      </span>
    );
  else
    return (
      <div>
        <Box variant="awsui-key-label">{label}</Box>
      </div>
    );
}
