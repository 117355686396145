import {
  ColumnLayout,
  Container,
  Header,
  Grid,
  SpaceBetween,
  Button,
  Input,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../components/RHF/RHFTextField";
import useIsLoading from "../hooks/useIsLoading";
import {
  useGetProfileDetailsQuery,
  useUpdateAlertPreferncesMutation,
  useUpdateProfileMutation,
} from "../redux/api/profile/profile";
import KeyValue from "../components/KeyValue";
import FormSection from "../components/FormSection";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { t } from "i18next";
import {
  Card,
  Button as AntButton,
  Space,
  Radio,
  RadioChangeEvent,
  Typography,
  Col,
  Row,
} from "antd";
import { AlterPreferenceType } from "../redux/api/profile/types";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

const { Text } = Typography;

type IFormInput = {
  firstName: string;
  lastName: string;
  preferredName: string;
};

function Profile() {
  const {
    data: user,
    fulfilledTimeStamp,
    isLoading,
  } = useGetProfileDetailsQuery();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const methods = useForm<IFormInput>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: IFormInput) => {
    if (user?.id)
      await notifyWrapper({
        promise: updateProfile({
          id: user?.id,
          firstName: data.firstName,
          lastName: data.lastName,
          preferredName: data.preferredName,
        }),
        resourceName: "profile",
        actionName: "upload",
      });
  };

  const [alertPreference, setAlertPreference] = useState<AlterPreferenceType>();

  const [updateAlertPrefernces] = useUpdateAlertPreferncesMutation();

  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();

  useIsLoading(isLoading || isUpdating);

  useEffect(() => {
    if (user) {
      methods.setValue("firstName", user.firstName);
      methods.setValue("lastName", user.lastName);
      methods.setValue("preferredName", user.preferredName);
      setAlertPreference({
        enableDocumentAlerts: user.enableDocumentAlerts,
        enablePrivilegeAlerts: user.enablePrivilegeAlerts,
        enablePackageAlerts: user.enablePackageAlerts,
        enableEnrollmentAlerts: user.enableEnrollmentAlerts,
        enableContractAlerts: user.enableContractAlerts,
        enableAffiliationAlerts: user.enableAffiliationAlerts,
        enableSignatureAlerts: user.enableSignatureAlerts,
      });
    }
  }, [fulfilledTimeStamp]);

  return (
    <div>
      <SpaceBetween size="s">
        <Header>{t("profile.header.label")}</Header>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SpaceBetween direction="vertical" size="xl">
              <div>
                <Container
                  footer={
                    <div style={{ float: "right", padding: "0px 12px 0 0" }}>
                      <Button variant="primary">
                        {t("profile.header.content.header.actions.save")}
                      </Button>
                    </div>
                  }
                >
                  <ColumnLayout columns={2}>
                    <RHFTextField
                      label={t(
                        "profile.header.content.header.fields.firstName",
                      )}
                      name="firstName"
                      stretch
                      rules={{ required: "This field is required" }}
                    />

                    <RHFTextField
                      label={t("profile.header.content.header.fields.lastName")}
                      name="lastName"
                      stretch
                      rules={{ required: "This field is required" }}
                    />

                    <RHFTextField
                      label={t(
                        "profile.header.content.header.fields.preferredName",
                      )}
                      name="preferredName"
                      stretch
                      rules={{ required: "This field is required" }}
                    />
                  </ColumnLayout>
                </Container>
              </div>
              <Container>
                <FormSection columns={2}>
                  <KeyValue
                    label={t("profile.header.content.header.fields.email")}
                    value={user === null ? "" : user?.email}
                  />
                  <KeyValue
                    label={t(
                      "profile.header.content.header.fields.organization",
                    )}
                    value={user?.org}
                  />
                  <KeyValue
                    label={t("profile.header.content.header.fields.role")}
                    value={user?.role}
                  />
                  <KeyValue
                    label={t("profile.header.content.header.fields.lastLogin")}
                    value={
                      user?.lastLogin
                        ? new Date(user?.lastLogin)?.toLocaleString()
                        : "-"
                    }
                  />
                </FormSection>
              </Container>
              <Card
                title="Expiration Alert Preferences"
                extra={
                  <AntButton
                    type="primary"
                    onClick={() => {
                      if (!!alertPreference)
                        updateAlertPrefernces(alertPreference);
                    }}
                  >
                    Save
                  </AntButton>
                }
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Row>
                    <Col span={6}>
                      <Text strong style={{ margin: 0 }}>
                        Document Expiration Alerts
                      </Text>
                    </Col>
                    <Col span={4}>
                      <Radio.Group
                        value={alertPreference?.enableDocumentAlerts}
                        onChange={(e: RadioChangeEvent) => {
                          setAlertPreference({
                            ...alertPreference,
                            enableDocumentAlerts: e.target.value,
                          });
                        }}
                      >
                        <Radio value={true}>Enable</Radio>
                        <Radio value={false}>Disable</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  {!!organization?.organizationServiceLimits?.privileges && (
                    <Row>
                      <Col span={6}>
                        <Text strong style={{ margin: 0 }}>
                          Privilege Expiration Alerts
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Radio.Group
                          value={alertPreference?.enablePrivilegeAlerts}
                          onChange={(e: RadioChangeEvent) => {
                            setAlertPreference({
                              ...alertPreference,
                              enablePrivilegeAlerts: e.target.value,
                            });
                          }}
                        >
                          <Radio value={true}>Enable</Radio>
                          <Radio value={false}>Disable</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  )}
                  {!!organization?.organizationServiceLimits
                    ?.payerEnrollments && (
                    <Row>
                      <Col span={6}>
                        <Text strong style={{ margin: 0 }}>
                          Payer Enrollment Expiration Alerts
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Radio.Group
                          value={alertPreference?.enableEnrollmentAlerts}
                          onChange={(e: RadioChangeEvent) => {
                            setAlertPreference({
                              ...alertPreference,
                              enableEnrollmentAlerts: e.target.value,
                            });
                          }}
                        >
                          <Radio value={true}>Enable</Radio>
                          <Radio value={false}>Disable</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  )}
                  {!!organization?.organizationServiceLimits?.eSignatures && (
                    <Row>
                      <Col span={6}>
                        <Text strong style={{ margin: 0 }}>
                          e-Signature Expiration Alerts
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Radio.Group
                          value={alertPreference?.enableSignatureAlerts}
                          onChange={(e: RadioChangeEvent) => {
                            setAlertPreference({
                              ...alertPreference,
                              enableSignatureAlerts: e.target.value,
                            });
                          }}
                        >
                          <Radio value={true}>Enable</Radio>
                          <Radio value={false}>Disable</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  )}
                  {!!organization?.organizationServiceLimits?.contracts && (
                    <Row>
                      <Col span={6}>
                        <Text strong style={{ margin: 0 }}>
                          Contract Expiration Alerts
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Radio.Group
                          value={alertPreference?.enableContractAlerts}
                          onChange={(e: RadioChangeEvent) => {
                            setAlertPreference({
                              ...alertPreference,
                              enableContractAlerts: e.target.value,
                            });
                          }}
                        >
                          <Radio value={true}>Enable</Radio>
                          <Radio value={false}>Disable</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  )}
                </Space>
              </Card>
            </SpaceBetween>
          </form>
        </FormProvider>
      </SpaceBetween>
    </div>
  );
}

export default Profile;
