import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetAllLicenseTypesQuery } from "../../redux/api/provider/provider";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useCreateCredentialTemplateMutation } from "../../redux/api/credentialpackages/credentialpackages";
import { CredentialTemplateRequest } from "../../redux/api/credentialpackages/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useTranslation } from "react-i18next";

export default function AddCredentailTemplates() {
  const methods = useForm<CredentialTemplateRequest>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [addCredentialTemplate] = useCreateCredentialTemplateMutation();
  const onSubmit = async (data: CredentialTemplateRequest) => {
    await notifyWrapper({
      promise: addCredentialTemplate(data),
      resourceName: "Credential template",
      actionName: "create",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("credentialTemplates.addCredentialTemplate.header.breadcrumb.text")}`,
                href: `/credentialtemplates`,
              },
              {
                text: `${t("credentialTemplates.addCredentialTemplate.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      {t(
                        "credentialTemplates.addCredentialTemplate.header.actions.cancel",
                      )}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t(
                        "credentialTemplates.addCredentialTemplate.header.actions.submit",
                      )}
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <SpaceBetween direction="vertical" size="xl">
                <Container
                  header={
                    <Header>
                      {" "}
                      {t(
                        "credentialTemplates.addCredentialTemplate.header.label",
                      )}
                    </Header>
                  }
                >
                  <SpaceBetween direction="vertical" size="m">
                    <RHFTextField
                      label={t(
                        "credentialTemplates.addCredentialTemplate.header.fields.name.label",
                      )}
                      name="name"
                      description={t(
                        "credentialTemplates.addCredentialTemplate.header.fields.name.description",
                      )}
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <FormSection>
                      <RHFSelect
                        name="licenseTypeId"
                        label={t(
                          "credentialTemplates.addCredentialTemplate.header.fields.licenseType.label",
                        )}
                        description={t(
                          "credentialTemplates.addCredentialTemplate.header.fields.licenseType.description",
                        )}
                        options={licenses.map((license) => {
                          return { label: license.name, value: license.id };
                        })}
                        stretch={false}
                      />
                    </FormSection>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
