import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllTaskStatusQuery,
  useSaveAllTaskStatusMutation,
} from "../../../redux/api/task";
import { TaskStatus } from "../../../redux/api/task/types";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function TaskStatusModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllTaskStatusQuery();
  const [taskStatus, setTaskStatus] = useState<TaskStatus[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllTaskStatus] = useSaveAllTaskStatusMutation();

  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...taskStatus].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllTaskStatus(tmp),
      resourceName: "Task Status",
      actionName: "save",
    });
  };

  useEffect(() => {
    const tmpItems = !!data ? [...data] : [];
    setTaskStatus(tmpItems);
  }, [fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>Task Status</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              Cancel
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setTaskStatus([
                      ...taskStatus,
                      { id: "", name: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...taskStatus];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      deleted: true,
                    };
                    setTaskStatus(tmp);
                  }}
                  items={taskStatus.filter((item) => item.deleted !== true)}
                  addButtonText={"Add"}
                  definition={[
                    {
                      label: "Name",
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...taskStatus];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              deleted: item.deleted,
                            };
                            setTaskStatus(tmp);
                          }}
                          placeholder={"Enter task status name"}
                        />
                      ),
                    },

                    /*   {
                                                label: "Description",
                                                control: (item, itemIndex: number) => (
                                                  <Input
                                                    value={item.description}
                                                    onChange={(changeDetails) => {
                                                      const tmp = [...privilegeType];
                                                      tmp[itemIndex] = {
                                                        id: item.id,
                                                        name: item.name,
                                                        description: changeDetails.detail.value,
                                                      };
                                                      setPrivilegeType(tmp);
                                                    }}
                                                    placeholder="Enter Description"
                                                  />
                                                ),
                                              }, */
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(TaskStatusModal);
