import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllFacilityGroupQuery } from "../redux/api/facilitygroup/facilityGroup";
import { FacilityGroup } from "../redux/api/facilitygroup/types";
export type FilterType = {
  filterName: string;
  facilityFilterOptions: OptionDefinition[];
};
export default function useFacilityGroupFilter(items?: FacilityGroup[]) {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllFacilityGroupQuery();
  const [filter, setFilter] = useState<FilterType>({
    filterName: "",
    facilityFilterOptions: [],
  });
  const [filteredFacilityGroup, setFilteredFacilityGroup] = useState<
    FacilityGroup[]
  >(items ?? data);
  useEffect(() => {
    setFilteredFacilityGroup(items ?? data);
  }, [data, items]);
  useEffect(() => {
    let temp_filtered_facilities = !!items ? [...items] : [...data];
    if (filter.filterName) {
      temp_filtered_facilities = temp_filtered_facilities.filter((facility) =>
        facility.name.toLowerCase().includes(filter.filterName.toLowerCase()),
      );
    }
    const selectedFaciltiesTypeId = filter.facilityFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedFaciltiesTypeId && selectedFaciltiesTypeId.length > 0) {
      temp_filtered_facilities = temp_filtered_facilities.filter(
        (facility) => selectedFaciltiesTypeId.indexOf(facility?.id + "") >= 0,
      );
    }
    setFilteredFacilityGroup(temp_filtered_facilities);
  }, [filter]);
  return {
    filteredFacilityGroup,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
