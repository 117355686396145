import {
  Table,
  TableProps,
  Box,
  Header,
  Button,
  ButtonDropdown,
  Pagination,
  Link,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import StyledRouterLink from "../../components/styled-route-link";

import { useNavigate } from "react-router";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

import { TableEmptyState } from "../../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../../components/LoadingScreen";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useDeleteTaskMutation } from "../../redux/api/task";
import { Task } from "../../redux/api/task/types";
import ChangeTimeZone from "../../components/Timezone";
import {
  TASKS_CONTENT_DISPLAY_OPTIONS,
  TASKS_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useLocalStorage } from "../../common/localStorage";
import TaskFilters from "../../components/filter/task-filters";
import useTaskFilter from "../../hooks/useTaskFilter";

export default function Tasks() {
  const [deleteTask] = useDeleteTaskMutation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Tasks-Table-Preferences",
    TASKS_TABLE_DEFAULT_PREFERENCES,
  );
  const { filteredTask, filter, isLoading, isFetching, setFilter, refetch } =
    useTaskFilter();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredTask,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Task"}
            action={() => {
              navigate(`add`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  const tableProps: TableProps<Task> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredTask.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedTask}
                  items={[
                    {
                      id: "edit_form",
                      text: "Edit",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedTask) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedTask?.id)
                        navigate(PATH_DASHBOARD.tasks.edit(selectedTask?.id));
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate(`add`);
                  }}
                  variant="primary"
                  data-test="add_task"
                >
                  Add Task
                </Button>
              </span>
            </div>
          }
        >
          Tasks
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      filter: <TaskFilters filter={filter} setFilter={setFilter} />,
      preferences: (
        <TablePreferences
          title="Task Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={TASKS_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedTask(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedTask(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedTask ? [selectedTask] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>Task ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "taskType",
          header: <div>Task Type</div>,
          cell: (item) => (
            <StyledRouterLink
              className={"edit_link"}
              to={`${item.id}`}
              label={item?.taskType?.name}
            />
          ),
          sortingField: "taskType.name",
          sortingComparator: (a, b) =>
            (a?.taskType?.name ?? "").localeCompare(b?.taskType?.name ?? ""),
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{!!item?.name ? item?.name : "-"}</span>,
          sortingField: "name",
          sortingComparator: (a, b) =>
            (a?.name ?? "").localeCompare(b?.name ?? ""),
        },
        {
          id: "admin",
          header: <div>Assigned To</div>,
          cell: (item) => (
            <span>
              {!!item?.admin
                ? `${item?.admin?.firstName} ${item?.admin?.lastName}`
                : "-"}
            </span>
          ),
          sortingField: "admin",
          sortingComparator: (a, b) =>
            (a?.admin?.firstName ?? "").localeCompare(b?.admin?.lastName ?? ""),
        },
        {
          id: "provider",
          header: <div>Provider</div>,
          cell: (item) => (
            <span>
              {!!item?.provider
                ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                : "-"}
            </span>
          ),
          sortingField: "provider",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.lastName ?? "",
            ),
        },
        {
          id: "facility",
          header: <div>Facility</div>,
          cell: (item) => (
            <span>{!!item?.facility ? `${item?.facility?.name}` : "-"}</span>
          ),
          sortingField: "facility.name",
          sortingComparator: (a, b) =>
            (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
        },
        {
          id: "taskStatusId",
          header: <div>Task Status</div>,
          cell: (item) => <span>{item?.taskStatus?.name}</span>,
          sortingField: "taskStatus.name",
          sortingComparator: (a, b) =>
            (a?.taskStatus?.name ?? "").localeCompare(
              b?.taskStatus?.name ?? "",
            ),
        },
        {
          id: "workflow",
          header: <div>Workflow</div>,
          cell: (item) => (
            <span>
              {!!item?.workflow?.name ? (
                <Link
                  external
                  href={`/workflows/${item?.workflow?.id}?activeTabId=tasks`}
                >
                  {item?.workflow?.name}
                </Link>
              ) : (
                "-"
              )}
            </span>
          ),
          sortingField: "workflow.name",
          sortingComparator: (a, b) =>
            (a?.workflow?.name ?? "").localeCompare(b?.workflow?.name ?? ""),
        },
        {
          id: "startDate",
          header: <div>Start Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.startDate) ?? "-"}</span>,
          sortingField: "startDate",
        },
        {
          id: "completedDate",
          header: <div>Expiration Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.completedDate) ?? "-"}</span>
          ),
          sortingField: "completedDate",
        },
        {
          id: "dueDate",
          header: <div>Expiration Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.dueDate) ?? "-"}</span>,
          sortingField: "dueDate",
        },
        {
          id: "alertDays",
          header: <div>Alert Days</div>,
          cell: (item) => <span>{item?.alertDays ?? "-"}</span>,
          sortingField: "alertDays",
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <span>{item?.description ?? "-"}</span>,
          sortingField: "description",
          sortingComparator: (a, b) =>
            (a?.description ?? "").localeCompare(b?.description ?? ""),
        },
        {
          id: "createdDate",
          header: <div>Created Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.createdDate)}</span>,
          sortingField: "createdDate",
        },
      ],
    };
  }, [items, selectedTask, tablePreferences]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedTask?.id)
            await notifyWrapper({
              promise: deleteTask({ id: selectedTask?.id }),
              resourceName: "Task",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header="Delete Task"
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedTask?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this task?"}
      />
    </div>
  );
}
