import { FormProvider, useForm } from "react-hook-form";
import { ProfessionalInfoRequestType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../../../../components/FormSection";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddProviderProfessionalinfoMutation,
  useGetAllLicenseTypesQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useEffect } from "react";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import { Countries, USStatesList } from "../../../../helpers/constants";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../../../redux/UI/actions";
import { SPECIALTY } from "../../../../helpers/constants";
import { connect } from "react-redux";
import { useGetAllSpecialtyQuery } from "../../../../redux/api/specialty/specialty";

type FormInputType = ProfessionalInfoRequestType;

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditProfessionalInfo({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addProviderProfessionalinfo] =
    useAddProviderProfessionalinfoMutation();

  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const { data: specialities = [] } = useGetAllSpecialtyQuery();

  const { providerId } = useParams();

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const onSubmit = async (data: FormInputType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderProfessionalinfo({
          providerId: providerId,
          ...data,
        }),
        resourceName: "professional info",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!!provider) {
      const providerProfessionalInfo = provider.professionalInfo;

      if (providerProfessionalInfo) {
        methods.setValue("id", providerProfessionalInfo.id);
        methods.setValue("caqh", providerProfessionalInfo.caqh);
        methods.setValue(
          "licenseTypeId",
          providerProfessionalInfo.licenseType?.id,
        );
        methods.setValue(
          "primarySpecialtyId",
          providerProfessionalInfo.primarySpecialty?.id,
        );
        methods.setValue("title", providerProfessionalInfo.title);
        methods.setValue("workPhone", providerProfessionalInfo?.workPhone);
        methods.setValue(
          "workPhoneAlt",
          providerProfessionalInfo?.workPhoneAlt,
        );
        methods.setValue(
          "identificationNumber",
          providerProfessionalInfo?.identificationNumber,
        );
        methods.setValue(
          "practiceInfo.endDate",
          providerProfessionalInfo?.practiceInfo?.endDate,
        );
        methods.setValue(
          "practiceInfo.name",
          providerProfessionalInfo?.practiceInfo?.name,
        );
        methods.setValue(
          "practiceInfo.yearsOfPractice",
          providerProfessionalInfo?.practiceInfo?.yearsOfPractice,
        );
        methods.setValue(
          "practiceInfo.startDate",
          providerProfessionalInfo?.practiceInfo?.startDate,
        );

        methods.setValue(
          "practiceInfo.isCurrent",
          providerProfessionalInfo?.practiceInfo?.isCurrent,
        );
        methods.setValue(
          "practiceInfo.addressLane1",
          providerProfessionalInfo?.practiceInfo?.addressLane1,
        );
        methods.setValue(
          "practiceInfo.addressLane2",
          providerProfessionalInfo?.practiceInfo?.addressLane2,
        );
        methods.setValue(
          "practiceInfo.city",
          providerProfessionalInfo?.practiceInfo?.city,
        );
        methods.setValue(
          "practiceInfo.state",
          providerProfessionalInfo?.practiceInfo?.state,
        );
        methods.setValue(
          "practiceInfo.zipcode",
          providerProfessionalInfo?.practiceInfo?.zipcode,
        );
        methods.setValue(
          "practiceInfo.country",
          providerProfessionalInfo?.practiceInfo?.country,
        );
        methods.setValue(
          "practiceInfo.contactName",
          providerProfessionalInfo?.practiceInfo?.contactName,
        );
        methods.setValue(
          "practiceInfo.contactTitle",
          providerProfessionalInfo?.practiceInfo?.contactTitle,
        );
        methods.setValue(
          "practiceInfo.contactPhone",
          providerProfessionalInfo?.practiceInfo?.contactPhone,
        );
        methods.setValue(
          "practiceInfo.contactEmail",
          providerProfessionalInfo?.practiceInfo?.contactEmail,
        );
        methods.setValue(
          "practiceInfo.contactFax",
          providerProfessionalInfo?.practiceInfo?.contactFax,
        );
      }
    }
  }, [fulfilledTimeStamp, provider]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.professionalInfo.editProfessionalInfo.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=professional_info`,
            },
            {
              text: `${t("providerProfiles.professionalInfo.editProfessionalInfo.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.professionalInfo.editProfessionalInfo.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="m">
                    <FormSection columns={4}>
                      <RHFTextField
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.caqh",
                        )}
                        name="caqh"
                        stretch
                      />
                      {/* <RHFSelect
                        name="licenseTypeId"
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.licenseType",
                        )}
                        options={licenses.map((license) => ({
                          value: license.id,
                          label: license.name,
                        }))}
                      /> */}
                      {/* <RHFSelect
                        name="primarySpecialtyId"
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.primarySpecialty",
                        )}
                        filteringType="auto"
                        options={specialities.map((speciality) => ({
                          value: speciality.id + "",
                          label: speciality.name,
                        }))}
                      /> */}
                      <RHFFloatingSelect
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.primarySpecialty",
                        )}
                        name="primarySpecialtyId"
                        options={specialities.map((speciality) => ({
                          value: speciality?.id ?? "",
                          label: speciality.name,
                        }))}
                        stretch={false}
                        action={{
                          label: `${t("providerProfiles.affiliations.editAffiliation.header.fields.affiliationStatus.actions.modify")}`,
                          onClick: () => {
                            setModalName(SPECIALTY);
                          },
                        }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.title",
                        )}
                        name="title"
                        stretch
                      />
                      <RHFTextField
                        label={"Identification Number"}
                        name="identificationNumber"
                        stretch
                      />
                      <RHFPhoneNumber
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.workPhone",
                        )}
                        name="workPhone"
                      />
                      <RHFPhoneNumber
                        label={t(
                          "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.workPhoneAlt",
                        )}
                        name="workPhoneAlt"
                      />
                    </FormSection>

                    <ExpandableSection
                      defaultExpanded
                      headerText={t(
                        "providerProfiles.professionalInfo.editProfessionalInfo.header.practiceInfo",
                      )}
                    >
                      <div style={{ paddingTop: "12px" }}>
                        <FormSection columns={4}>
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.practiceName",
                            )}
                            name="practiceInfo.name"
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.yearsOfPractice",
                            )}
                            name="practiceInfo.yearsOfPractice"
                            rules={{
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a number",
                              },
                            }}
                            stretch
                          />
                          <RHFDatePicker
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.startDate",
                            )}
                            name="practiceInfo.startDate"
                            stretch
                          />
                          <RHFDatePicker
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.endDate",
                            )}
                            name="practiceInfo.endDate"
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.addressLane1",
                            )}
                            name="practiceInfo.addressLane1"
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.addressLane2",
                            )}
                            name="practiceInfo.addressLane2"
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.city",
                            )}
                            name="practiceInfo.city"
                            stretch
                          />
                          <RHFSelect
                            name="practiceInfo.state"
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.state",
                            )}
                            options={USStatesList.map((state) => ({
                              value: state.value,
                            }))}
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.zipcode",
                            )}
                            name="practiceInfo.zipcode"
                            stretch
                          />
                          <RHFSelect
                            name="practiceInfo.country"
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.country",
                            )}
                            options={Countries.map((country) => ({
                              value: country.value,
                            }))}
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.practiceContactName",
                            )}
                            name="practiceInfo.contactName"
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.practiceContactTitle",
                            )}
                            name="practiceInfo.contactTitle"
                            stretch
                          />
                          <RHFPhoneNumber
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.practiceContactPhone",
                            )}
                            name="practiceInfo.contactPhone"
                          />

                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.practiceContactEmail",
                            )}
                            name="practiceInfo.contactEmail"
                            rules={{
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email",
                              },
                            }}
                            stretch
                          />
                          <RHFTextField
                            label={t(
                              "providerProfiles.professionalInfo.editProfessionalInfo.header.fields.practiceContactFax",
                            )}
                            name="practiceInfo.contactFax"
                            rules={{
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a number",
                              },
                            }}
                            stretch
                          />
                        </FormSection>
                      </div>
                    </ExpandableSection>
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditProfessionalInfo);
