import Button from "@cloudscape-design/components/button";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Header from "@cloudscape-design/components/header";
import { connect } from "react-redux";
import { useState } from "react";
import { setModalName } from "../../redux/UI/actions";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import {
  useDeleteDigitalFormMutation,
  useGetDigitalFormQuery,
} from "../../redux/api/digitalform/digitalforms";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import { openInNewTab } from "../../helpers/utils";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { Box } from "@cloudscape-design/components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DigitalForm } from "../../redux/api/digitalform/types";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "../../config";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

interface DigitalFormTableHeaderProps {
  counter?: string | undefined;
  selectDigitalform: DigitalForm | undefined;
  refetch: () => void;
}

function DigitalFormTableHeader({
  openModalName,
  refetch,
  counter,
  selectDigitalform,
}: PropsFromDispatch & DigitalFormTableHeaderProps) {
  const { t } = useTranslation();
  const { data: digitalform } = useGetDigitalFormQuery(
    selectDigitalform?.id ? selectDigitalform?.id : skipToken,
  );
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteDigitalForm] = useDeleteDigitalFormMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("readyforms.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("readyforms.delete") ?? false),
  );
  return (
    <div>
      <Header
        variant="h2"
        counter={`(${counter})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
            </span>

            <span className="awsui-util-action-stripe-group">
              {!!digitalform && hasPermission && !!digitalform?.template ? (
                <Button
                  disabled={!selectDigitalform}
                  onClick={() => {
                    navigate(
                      PATH_DASHBOARD.digitalforms.templates.designer(
                        digitalform.id,
                      ),
                    );
                  }}
                >
                  {t("readyForms.table.header.actions.templateEditor")}
                </Button>
              ) : (
                <Button disabled={true}>
                  {t("readyForms.table.header.actions.templateEditor")}
                </Button>
              )}
            </span>
            <span className="awsui-util-action-stripe-group">
              {!!digitalform && hasPermission && !!digitalform?.template ? (
                <Button
                  disabled={!selectDigitalform}
                  formAction="none"
                  onClick={() => {
                    if (!!selectDigitalform) {
                      navigate(
                        PATH_DASHBOARD.digitalforms.signaturerequest(
                          selectDigitalform.id,
                        ),
                      );
                    }
                  }}
                >
                  {t("readyForms.table.header.actions.requestSignature")}
                </Button>
              ) : (
                <Button disabled={true}>
                  {t("readyForms.table.header.actions.requestSignature")}
                </Button>
              )}
            </span>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectDigitalform}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission
                      ? `${t("readyForms.table.header.actions.readyFormEdit")}`
                      : `${t("readyForms.table.header.actions.readyFormView")}`,
                  },
                  {
                    id: "delete_form",
                    text: `${t("readyForms.table.header.actions.readyFormDelete")}`,
                    disabled: !hasDeletePermission,
                  },
                  {
                    id: "download_form",
                    text: `${t("readyForms.table.header.actions.readyFormDownload")}`,
                    disabled: !hasPermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") OpenDeleteModal(true);
                  else if (id === "edit_form") {
                    if (!!selectDigitalform) {
                      navigate(`/digitalforms/${selectDigitalform.id}`);
                    }
                  } else if (id === "download_form") {
                    const attachmentKey = digitalform?.template.key;
                    !!attachmentKey &&
                      openInNewTab({ downloadUrl: getFileUrl(attachmentKey) });
                  }
                }}
              >
                {t("readyForms.table.header.actions.readyFormActions")}
              </ButtonDropdown>
            </span>
            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  navigate(`addNew`);
                }}
                variant="primary"
              >
                {t("readyForms.table.header.actions.createReadyForm")}
              </Button>
            </span>
          </div>
        }
      >
        {t("readyForms.table.header.label")}
      </Header>
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (digitalform)
            await notifyWrapper({
              promise: deleteDigitalForm({ id: digitalform.id }),
              resourceName: "Ready Form Template",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("readyForms.delete.header.label")}
        content={
          <>
            <Box>{t("readyForms.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{digitalform?.name}</Box>
          </>
        }
        description={t("readyForms.delete.header.content.description")}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DigitalFormTableHeader);
