import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setModalName } from "../../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { SpecialtyInfo } from "../../../../redux/api/specialty/types";
import {
  useGetAllSpecialtyQuery,
  useSaveAllSpecialtyMutation,
} from "../../../../redux/api/specialty/specialty";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function ProviderSpecialtyModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllSpecialtyQuery();
  const { t } = useTranslation();
  const [specialtyInfo, setSpecialtyInfo] = useState<SpecialtyInfo[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllSpecialty] = useSaveAllSpecialtyMutation();
  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...specialtyInfo].filter((item) => !!item.name);

    await notifyWrapper({
      promise: saveAllSpecialty(tmp),
      resourceName: "Specialty",
      actionName: "Save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setSpecialtyInfo(tmpItems);
  }, [data, fulfilledTimeStamp]);
  return (
    <Modal
      header={
        <Header>
          {t("providerProfiles.affiliations.specialty.header.label")}
        </Header>
      }
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t(
                "providerProfiles.affiliations.specialty.header.actions.cancel",
              )}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              {t("providerProfiles.affiliations.specialty.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setSpecialtyInfo([
                      ...specialtyInfo,
                      {
                        name: "",
                        description: "",
                      },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...specialtyInfo];
                    const item = { ...tmp[itemIndex] };
                    if (!!item.name) {
                      tmp[itemIndex] = {
                        id: item.id,
                        name: item.name,
                        description: item.description,
                        deleted: true,
                      };
                      setSpecialtyInfo(tmp);
                    }
                  }}
                  items={specialtyInfo.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "providerProfiles.affiliations.specialty.header.actions.addSpecialty",
                  )}
                  definition={[
                    {
                      label: `${t("providerProfiles.affiliations.specialty.header.fields.name.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...specialtyInfo];
                            tmp[itemIndex] = {
                              name: changeDetails.detail.value,
                              description: item.description,
                            };
                            setSpecialtyInfo(tmp);
                          }}
                          placeholder={t(
                            "providerProfiles.affiliations.specialty.header.fields.name.placeHolder",
                          )}
                        />
                      ),
                    },
                    {
                      label: `${t("providerProfiles.affiliations.specialty.header.fields.description.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.description}
                          onChange={(changeDetails) => {
                            const tmp = [...specialtyInfo];
                            tmp[itemIndex] = {
                              name: item.name,
                              description: changeDetails.detail.value,
                            };
                            setSpecialtyInfo(tmp);
                          }}
                          placeholder={t(
                            "providerProfiles.affiliations.specialty.header.fields.description.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ProviderSpecialtyModal);
