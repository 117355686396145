import { Alert, Button, Link } from "@cloudscape-design/components";
import { useEffect, useRef, useState } from "react";
import prettyBytes from "pretty-bytes";
import { Accept, useDropzone } from "react-dropzone";

import "./UploadNewFile.css";
import { useTranslation } from "react-i18next";
type UploadNewFileProps = {
  header?: string;
  file?: File;
  accept?: Accept;
  maxHeight?: boolean;
  onFileChange: (file?: File) => void;
};

function UploadNewFile({
  header,
  file,
  accept,
  maxHeight = false,
  onFileChange,
}: UploadNewFileProps) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: accept,
  });
  const { t } = useTranslation();
  useEffect(() => {
    onFileChange(acceptedFiles?.at(0));
  }, [acceptedFiles]);

  if (!!file) {
    return (
      <Alert
        dismissible
        onDismiss={() => {
          onFileChange(undefined);
        }}
        header={
          <div>
            {t("uploadNewFile.header.alert.fileName")}: {file?.name}
          </div>
        }
      >
        <div>
          {t("uploadNewFile.header.alert.fileSize")}: {prettyBytes(file?.size)}
        </div>
        <div>
          {t("uploadNewFile.header.alert.fileType")}: {file?.type}
        </div>
      </Alert>
    );
  } else
    return (
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>{t("uploadNewFile.header.label")}</p>
      </div>
    );
}
export default UploadNewFile;
