// set host api based on environment, when REACT_APP_ENV = dev, set HOST_API to "https://api.dev.intivahealth.com" when REACT_APP_ENV = local set locahost else set windwos.localhost
export const HOST_API: string = (() => {
  if (process.env.REACT_APP_ENV === "local") return "http://localhost:8080";
  else if (process.env.REACT_APP_ENV === "dev")
    return "https://dev.readydoc.app";
  else return `https://${window.location.hostname}`;
})();

export const Signing_HOST_API = `${HOST_API}/api/signing`;

export const getSigningFileUrl = (fileKey: string) => {
  return `${HOST_API}/file/token/${fileKey}`;
};

export const getFileUrl = (fileKey: string) => {
  return process.env.REACT_APP_ENV === "local"
    ? `https://dev.readydoc.app/file/${fileKey}`
    : `${HOST_API}/file/${fileKey}`;
};
