import {
  Button,
  Header,
  Link,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../routes/paths";

export default function ReportsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Table
      columnDefinitions={[
        {
          id: "id",
          header: <div>{t("reports.table.header.fields.id")}</div>,
          cell: (item) => item.id,
        },
        {
          id: "name",
          header: <div>{t("reports.table.header.fields.reportName")}</div>,
          cell: (item) => (
            <SpaceBetween size={"xxs"}>
              <Link href={`${item.href}`}>
                <span style={{ fontSize: 20 }}>{item.name}</span>
              </Link>
              <span>{item.description}</span>
            </SpaceBetween>
          ),
        },
      ]}
      items={[
        {
          name: "Providers Report",
          description: "A detailed report of all providers.",
          href: PATH_DASHBOARD.reports.providerroatser.list,
          id: "5",
        },
        {
          name: "Payer Enrollments Report",
          description: "A detailed report of all enrollments.",
          href: PATH_DASHBOARD.reports.payerenrollment.list,
          id: "4",
        },
        {
          name: "Provider Affiliations Report",
          description: "A detailed report of all Provider Affiliations.",
          href: PATH_DASHBOARD.reports.provideraffiliation.list,
          id: "6",
        },
        {
          name: "All Documents Report",
          description: "A detailed report of all documents.",
          href: PATH_DASHBOARD.reports.alldocuments.list,
          id: "3",
        },
        {
          name: "Expiring Documents Report",
          description:
            "A detailed report on documents approaching their expiration date.",
          href: PATH_DASHBOARD.reports.expiringdocuments.list,
          id: "2",
        },
        {
          name: "Expired Documents Report",
          description: "Comprehensive list of all expired documents",
          href: PATH_DASHBOARD.reports.expireddocuments.list,
          id: "1",
        },
      ]}
      visibleColumns={["name", "actions"]}
      loadingText="Loading resources"
      sortingDisabled
      header={<Header> Reports </Header>}
    />
  );
}
