import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../components/LoadingScreen";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { PayerGroup } from "../../redux/api/payergroup/types";
import { useUpdateAssociatedNetworkMutation } from "../../redux/api/payers/payers";
import usePayerGroupFilter from "../../hooks/usePayerGroupFilter";
import PayerGroupFilters from "../../components/filter/payergroup-filter";

export default function AssociatedNetworksModals({
  isAssociatedNetworksModalOpen,
  setAssociatedNetworksModalOpen,
  associatedNetworks,
  setAssociatedNetworks,
}: {
  isAssociatedNetworksModalOpen: boolean;
  setAssociatedNetworksModalOpen: Dispatch<SetStateAction<boolean>>;
  associatedNetworks: PayerGroup[];
  setAssociatedNetworks: Dispatch<SetStateAction<PayerGroup[]>>;
}) {
  const { id: payerId } = useParams();
  const [updateAssociatedNetwork] = useUpdateAssociatedNetworkMutation();
  const { t } = useTranslation();

  const { filteredPayerGroup, filter, isLoading, isFetching, setFilter } =
    usePayerGroupFilter();

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerGroup,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedPayerGroups, setSelectedPayerGroups] = useState<PayerGroup[]>(
    [],
  );

  const tableProps: TableProps<PayerGroup> = useMemo(() => {
    return {
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <PayerGroupFilters filter={filter} setFilter={setFilter} />,
      variant: "borderless",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedPayerGroups(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedPayerGroups;
        if (
          !detail.item ||
          !!associatedNetworks
            ?.map((associatedNetwork) => associatedNetwork?.id + "")
            ?.includes(detail.item.id + "")
        )
          return;
        if (temp?.includes(detail.item))
          setSelectedPayerGroups(temp.filter((item) => item !== detail.item));
        else setSelectedPayerGroups([detail.item, ...temp]);
      },
      selectedItems: selectedPayerGroups,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "groupCode", visible: true },
        { id: "description", visible: true },
      ],
      isItemDisabled: (item) => {
        return associatedNetworks
          ?.map((associatedNetwork) => associatedNetwork?.id + "")
          ?.includes(item?.id + "");
      },
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("payergroup.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>Network name</div>,
          cell: (item) => (
            <Link external href={`/payergroup/${item.id}`} variant="primary">
              {item?.name ?? "-"}
            </Link>
          ),
          sortingField: "name",
        },
        {
          id: "groupCode",
          header: "Network id",
          cell: (item) => <Link href="#">{item.groupCode || "-"}</Link>,
          sortingField: "groupCode",
          isRowHeader: true,
        },
        {
          id: "description",
          header: <span>Notes</span>,
          cell: (item) => <div>{item?.description ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedPayerGroups]);

  return (
    <Modal
      visible={isAssociatedNetworksModalOpen}
      size="max"
      header={
        <Header counter={`(${filteredPayerGroup.length})`}>Networks</Header>
      }
      onDismiss={() => {
        setAssociatedNetworksModalOpen(false);
      }}
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={() => {
                setAssociatedNetworks([]);
                setAssociatedNetworksModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                try {
                  if (!!selectedPayerGroups && !!payerId) {
                    const networksToUpdate =
                      selectedPayerGroups?.map(
                        (selectedPayerGroup) => selectedPayerGroup?.id + "",
                      ) || [];

                    await updateAssociatedNetwork({
                      payerId: payerId,
                      networks: networksToUpdate,
                    });

                    setAssociatedNetworks([
                      ...associatedNetworks,
                      ...selectedPayerGroups,
                    ]);
                    setSelectedPayerGroups([]);
                    setAssociatedNetworksModalOpen(false);
                  }
                } catch (error) {
                  console.error("Error updating admin payerGroups:", error);
                  // Handle error (e.g., show a message to the user)
                }
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div>
        <LoadingScreen isOpen={isLoading} />
        <Table {...tableProps} />
      </div>
    </Modal>
  );
}
