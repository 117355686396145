import { useDispatch } from "react-redux";
import {
  dismissFlashBarItem,
  updateOrAddFlashBarItem,
} from "../redux/UI/actions";
import { NotificationProps } from "../redux/UI/types";
import { capitalize } from "lodash";

const AutoDismissNotificationInterval = 30000;

export default function useNotify() {
  const dispatch = useDispatch();
  const notifyFailed = ({
    name,
    action,
    id,
    content,
    header,
  }: NotificationProps) => {
    const actionCreator = updateOrAddFlashBarItem({
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      header: content
        ? `${action}`
        : capitalize(`Failed to ${action ?? "Update"} ${name}`),
      content:
        content ??
        "Your request couldn’t be processed because of an issue with the server. Please contact support",
      id: id,
    });
    dispatch(actionCreator);
    //create a delay to remove the message
    setTimeout(() => {
      dispatch(dismissFlashBarItem(id));
    }, AutoDismissNotificationInterval);
  };
  const notifySucess = ({ name, action, id }: NotificationProps) => {
    const content = capitalize(`Successfully ${action ?? "Updated"} ${name}.`);
    dispatch(
      updateOrAddFlashBarItem({
        type: "success",
        dismissible: true,
        dismissLabel: "Dismiss message",
        content,
        id: id,
      }),
    );
    //create a delay to remove the message
    setTimeout(() => {
      dispatch(dismissFlashBarItem(id));
    }, AutoDismissNotificationInterval);
  };
  const notifyInProgress = ({
    name: resourceName,
    action,
    id,
    content,
  }: NotificationProps) => {
    const actionCreator = updateOrAddFlashBarItem({
      loading: true,
      type: "info",
      dismissible: false,
      content:
        content ?? capitalize(`${action ?? "Updating"} ${resourceName}...`),
      id: id,
    });
    dispatch(actionCreator);
  };
  return { notifyFailed, notifyInProgress, notifySucess };
}
