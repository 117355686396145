import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../components/FormSection";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useNavigate, useParams } from "react-router-dom";
import InnerAppLayout from "../../components/InnerAppLayout";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import {
  useGetAllContractTypesQuery,
  useGetContractQuery,
  useUpdateContractMutation,
  useUploadContractAttachmentMutation,
} from "../../redux/api/contracts/contract";
import { find } from "lodash";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import Divider from "@mui/material/Divider";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { CONTRACT_TYPE } from "../../helpers/constants";
import { connect } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { captureException } from "@sentry/react";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { Attachment } from "../../redux/api/document/types";
import CommentActivies from "../../providersafe/document/versions/comments-activites";
import Comment from "../../redux/api/providerComments/types";
import {
  useAddCommentToContractMutation,
  useDeleteCommentFromContractMutation,
  useGetAllCommentsByContractIdQuery,
  useUpdateCommentToContractMutation,
} from "../../redux/api/contractComments/comment";
import RHFPhoneNumber from "../../components/RHF/RHFPhoneNumber";

type FormInputType = {
  title: string;
  alertDays: string;
  executionDate: string;
  expirationDate: string;
  lastRenewalDate: string;
  description: string;
  primaryContact: string;
  attachment: Attachment;
  facility: string;
  contractType: string;
  entityName: string;
  entityPhone: string;
  entityEmail: string;
  deleted: boolean;
};
interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditContract({ setModalName }: PropsFromDispatch) {
  const { id } = useParams();
  const { t } = useTranslation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contract.edit") ?? false),
  );
  const { data: contract, isLoading } = useGetContractQuery(
    !!id
      ? {
          contractId: id,
        }
      : skipToken,
  );
  const navigate = useNavigate();

  const [updateContract] = useUpdateContractMutation();

  const methods = useForm<FormInputType>();
  const [uploadFile, setUploadFile] = useState<File>();

  const [commentsList, setCommentsList] = useState<Comment[]>([]);
  const { data: comments } = useGetAllCommentsByContractIdQuery(
    !!id ? { contractId: id } : skipToken,
  );

  useEffect(() => {
    if (comments) {
      setCommentsList(comments);
    }
  }, [comments]);

  const [addCommentToContract] = useAddCommentToContractMutation();
  const [deleteCommentFromContract] = useDeleteCommentFromContractMutation();
  const [updateCommentToContract] = useUpdateCommentToContractMutation();

  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();
  const onSubmit = async (data: FormInputType) => {
    if (!!contract) {
      const notificationId = uuidv4();
      notifyInProgress({
        name: "contract",
        action: "updating",
        id: notificationId,
      });
      await updateContract({
        id: contract?.id,
        title: data?.title,
        alertDays: data?.alertDays,
        executionDate: data?.executionDate,
        expirationDate: data?.expirationDate,
        lastRenewalDate: data?.lastRenewalDate,
        description: data.description,
        primaryContact: { id: data.primaryContact },
        facility: { id: data.facility },
        contractType: { id: data.contractType, type: data.contractType },
        entityName: data.entityName,
        entityPhone: data.entityPhone,
        entityEmail: data.entityEmail,
        attachment: uploadFile
          ? {
              name: uploadFile?.name,
              contentType: uploadFile?.type,
              size: uploadFile?.size,
            }
          : null,
      })
        .unwrap()
        .then(async (contractResponse) => {
          if (!!uploadFile && contractResponse?.attachment?.key) {
            try {
              await fileAxios.put(
                getFileUrl(contractResponse?.attachment?.key),
                uploadFile,
              );
              notifySucess({
                name: "Contract",
                action: "update",
                id: notificationId,
              });
            } catch (error) {
              captureException(error);
              notifyFailed({
                name: "Contract",
                action: "add",
                id: notificationId,
              });
            }
          } else
            notifySucess({
              name: "Contract",
              action: "updated",
              id: notificationId,
            });
        })
        .catch((error) => {
          captureException(error);
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: "contract",
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: "contract",
              action: "update",
              id: notificationId,
            });
        });
    }
    navigate(-1);
  };
  const {
    data: facilites = [],
    fulfilledTimeStamp: facilitesFulfilledTimeStamp,
  } = useGetFacilitiesQuery();
  const {
    data: contractType = [],
    fulfilledTimeStamp: contractTypeFulfilledTimeStamp,
  } = useGetAllContractTypesQuery();
  const { data: members = [], fulfilledTimeStamp: membersFulfilledTimeStamp } =
    useGetAllMembersQuery();

  useEffect(() => {
    if (contract) {
      methods.setValue("title", contract.title);
      methods.setValue("expirationDate", contract.expirationDate);
      methods.setValue("executionDate", contract.executionDate);
      methods.setValue("lastRenewalDate", contract.lastRenewalDate);
      methods.setValue("description", contract.description);
      methods.setValue("alertDays", contract.alertDays);

      const selectedPrimaryContact = find(members, {
        id: contract?.primaryContact?.id,
      });
      if (!!selectedPrimaryContact) {
        methods.setValue("primaryContact", selectedPrimaryContact.id);
      }

      const selectedFacility = find(facilites, {
        id: contract?.facility?.id,
      });

      if (!!selectedFacility) {
        methods.setValue("facility", selectedFacility.id);
      }

      const selectedContractType = find(contractType, {
        id: contract?.contractType?.id,
      });

      if (!!selectedContractType) {
        methods.setValue("contractType", selectedContractType.id);
      }

      methods.setValue("entityName", contract.entityName);
      methods.setValue("entityEmail", contract.entityEmail);
      methods.setValue("entityPhone", contract.entityPhone);
    }
  }, [
    contract,
    members,
    membersFulfilledTimeStamp,
    contractType,
    contractTypeFulfilledTimeStamp,
    facilites,
    facilitesFulfilledTimeStamp,
    methods,
  ]);

  const addCommentForContract = async (message: string) => {
    if (!!id) {
      await addCommentToContract({
        contractId: id,
        message: message,
      });
    }
  };
  const deleteCommentForContract = async (commentId: string) => {
    if (!!id) {
      await deleteCommentFromContract({
        contractId: id,
        commentId: commentId,
      });
    }
  };
  const updateCommentForContract = async (
    commentId: string,
    message: string,
  ) => {
    if (!!id) {
      await updateCommentToContract({
        contractId: id,
        commentId: commentId,
        message: message,
      });
    }
  };

  //TODO: fix return null;
  if (!contract) return null;
  else
    return (
      <>
        <LoadingScreen isOpen={isLoading} />
        <SpaceBetween size="xxl">
          <InnerAppLayout
            breadcrumbGroup={
              <BreadcrumbGroup
                items={[
                  {
                    text: `${t("contracts.editContract.header.breadcrumb.text")}`,
                    href: `/contract`,
                  },
                  {
                    text: `${t("contracts.editContract.header.breadcrumb.currentPage")}`,
                    href: "#",
                  },
                ]}
                ariaLabel="Breadcrumbs"
              />
            }
            rightPanel={
              <AttachmentViewer
                attachment={contract.attachment}
                file={uploadFile}
                onFileChange={(uploadFile?: File) => {
                  setUploadFile(uploadFile);
                }}
              />
            }
            content={
              <Tabs
                tabs={[
                  {
                    label: "Details",
                    id: "details",
                    content: (
                      <Container
                        header={
                          <Header>
                            {t("contracts.editContract.header.label")}
                          </Header>
                        }
                      >
                        <FormProvider {...methods}>
                          <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Form
                              actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                  <Button
                                    onClick={() => {
                                      navigate(-1);
                                    }}
                                    formAction="none"
                                  >
                                    {t(
                                      "contracts.editContract.header.actions.cancel",
                                    )}
                                  </Button>
                                  <Button
                                    formAction="submit"
                                    variant="primary"
                                    disabled={!hasPermission}
                                  >
                                    {t(
                                      "contracts.editContract.header.actions.submit",
                                    )}
                                  </Button>
                                </SpaceBetween>
                              }
                            >
                              <SpaceBetween size={"m"}>
                                <FormSection
                                  gridDefinition={[
                                    { colspan: 12 },
                                    { colspan: 12 },
                                    { colspan: 12 },
                                  ]}
                                >
                                  <RHFFloatingSelect
                                    name="contractType"
                                    label={t(
                                      "contracts.editContract.header.fields.type.label",
                                    )}
                                    options={contractType.map((type) => ({
                                      label: type.type,
                                      value: "" + type.id,
                                    }))}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    stretch={false}
                                    action={{
                                      label: `${t("contracts.editContract.header.fields.type.actions.modify")}`,
                                      onClick: () => {
                                        setModalName(CONTRACT_TYPE);
                                      },
                                    }}
                                  />
                                  <RHFTextField
                                    label={t(
                                      "contracts.editContract.header.fields.title",
                                    )}
                                    name="title"
                                    stretch
                                    rules={{
                                      required: "This field is required",
                                    }}
                                  />
                                </FormSection>
                                <Divider />
                                <FormSection columns={3}>
                                  <RHFDatePicker
                                    label={t(
                                      "contracts.editContract.header.fields.executionDate",
                                    )}
                                    name={"executionDate"}
                                  />
                                  <RHFDatePicker
                                    label={t(
                                      "contracts.editContract.header.fields.expirationDate",
                                    )}
                                    name={"expirationDate"}
                                  />
                                  <RHFTextField
                                    label={t(
                                      "contracts.editContract.header.fields.alertDays",
                                    )}
                                    name="alertDays"
                                    stretch
                                  />
                                  <RHFDatePicker
                                    label={t(
                                      "contracts.editContract.header.fields.lastRenewalDate",
                                    )}
                                    name={"lastRenewalDate"}
                                  />
                                </FormSection>
                                <Divider />
                                <FormSection columns={2}>
                                  <RHFSelect
                                    label={t(
                                      "contracts.editContract.header.fields.facility",
                                    )}
                                    name={"facility"}
                                    filteringType="auto"
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    options={facilites.map((facility) => {
                                      return {
                                        label: facility?.name,
                                        value: facility?.id + "",
                                      };
                                    })}
                                  />

                                  <RHFSelect
                                    label={t(
                                      "contracts.editContract.header.fields.primaryContact",
                                    )}
                                    name={"primaryContact"}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    options={members.map((member) => {
                                      return {
                                        label: member.firstName,
                                        value: member.id,
                                      };
                                    })}
                                  />
                                </FormSection>
                                <Divider variant="middle" />
                                <ExpandableSection
                                  headerText={t(
                                    "contracts.editContract.header.headerText",
                                  )}
                                >
                                  <FormSection
                                    columns={2}
                                    headerDividerSpacing="xxxs"
                                  >
                                    <RHFTextField
                                      label={t(
                                        "contracts.editContract.header.fields.entityName",
                                      )}
                                      name="entityName"
                                    />
                                    <RHFPhoneNumber
                                      label={t(
                                        "contracts.addContract.header.fields.entityPhone",
                                      )}
                                      name="entityPhone"
                                    />
                                    <RHFTextField
                                      label={t(
                                        "contracts.editContract.header.fields.entityEmail",
                                      )}
                                      name="entityEmail"
                                      rules={{
                                        pattern: {
                                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                          message: "Invalid email",
                                        },
                                      }}
                                    />
                                  </FormSection>
                                </ExpandableSection>
                              </SpaceBetween>
                            </Form>
                          </form>
                        </FormProvider>
                      </Container>
                    ),
                  },
                  {
                    label: "Comments",
                    id: "comments",
                    content: (
                      <CommentActivies
                        commentsList={commentsList}
                        postComment={addCommentForContract}
                        editComment={updateCommentForContract}
                        deleteComment={deleteCommentForContract}
                      />
                    ),
                  },
                ]}
              />
            }
          />
        </SpaceBetween>
      </>
    );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditContract);
