import { FormProvider, useForm } from "react-hook-form";
import { ProviderLicenseRequestType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderLicenseMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { USStatesList } from "../../../../helpers/constants";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useTranslation } from "react-i18next";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";

export default function EditLicense() {
  const { t } = useTranslation();
  const methods = useForm<ProviderLicenseRequestType>();
  const { providerId, licenseId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderLicenseRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderLicense({
          providerId: providerId,
          ...data,
          documentId:
            selectedDocument.value !== "-1" ? selectedDocument.value : "",
        }),
        resourceName: "license",
        actionName: "upload",
      });

      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderLicense] = useAddProviderLicenseMutation();

  useEffect(() => {
    if (provider && provider.providerLicenses) {
      const license = provider.providerLicenses.find(
        (license) => license?.id + "" === licenseId + "",
      );

      if (license) {
        methods.setValue("id", license.id);
        methods.setValue("licenseType", license.licenseType);
        methods.setValue("licenseNumber", license.licenseNumber);
        methods.setValue("startDate", license.startDate);
        methods.setValue("endDate", license.endDate);
        methods.setValue("isCurrent", license.isCurrent);
        methods.setValue("state", license.state);
        methods.setValue("notes", license.notes);
        license?.document &&
          setSelectedDocument({
            label: license?.document?.name,
            value: license?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, licenseId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.license.editLicense.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=license`,
            },
            {
              text: `${t("providerProfiles.license.editLicense.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t("providerProfiles.license.editLicense.header.label")}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.license.editLicense.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.license.editLicense.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFSelect
                        name="licenseType"
                        label={t(
                          "providerProfiles.license.editLicense.header.fields.licenseType",
                        )}
                        options={["Medical", "State"].map((license) => ({
                          value: license,
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.license.editLicense.header.fields.licenseNumber",
                        )}
                        name="licenseNumber"
                        stretch
                        rules={{
                          required: "This field is required",
                        }}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.license.editLicense.header.fields.startDate",
                        )}
                        name="startDate"
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.license.editLicense.header.fields.expirationDate",
                        )}
                        name="endDate"
                      />
                      <RHFSelect
                        name="state"
                        label={t(
                          "providerProfiles.license.editLicense.header.fields.state",
                        )}
                        filteringType="auto"
                        options={USStatesList.map((state) => ({
                          value: state.value,
                          label: state.value,
                        }))}
                      />
                      {/* <div style={{ paddingTop: "12px" }}>
                        <RHFCheckBox
                          label={t(
                            "providerProfiles.license.editLicense.header.fields.current"
                          )}
                          name="isCurrent"
                          stretch
                        />
                      </div> */}
                    </FormSection>
                    <ExpandableSection headerText={"Notes"}>
                      <FormSection>
                        <RHFTextArea
                          label={"Notes"}
                          name="notes"
                          stretch={false}
                        />
                      </FormSection>
                    </ExpandableSection>

                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
