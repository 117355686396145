import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useESignatureFilters";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllDigitalFormsQuery } from "../../redux/api/digitalform/digitalforms";

interface eSignatureFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function ESignatureFilters({
  filter,
  setFilter,
  countText,
}: eSignatureFiltersProps) {
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "ID",
    value: "id",
  });
  const statusArray = [
    { label: "Pending", value: "pending" },
    { label: "Voided", value: "voided" },
    { label: "Completed", value: "completed" },
  ];
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: digitalforms = [] } = useGetAllDigitalFormsQuery();

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (!!filter.statusFilterOptions && filter.statusFilterOptions.length > 0)
      temp = [
        ...temp,
        {
          label: `Status = ${join(
            filter.statusFilterOptions.map(
              (statusFilterOption) => statusFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (
      !!filter.providerFilterOptions &&
      filter.providerFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Provider = ${join(
            filter.providerFilterOptions.map(
              (providerFilterOption) => providerFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (
      !!filter.digitalformFilterOptions &&
      filter.digitalformFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Ready Forms = ${join(
            filter.digitalformFilterOptions.map(
              (digitalformFilterOption) => digitalformFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterSubjectName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "ID", value: "id" },
            { label: "Subject", value: "subject" },
            { label: "Status", value: "status" },
            { label: "Provider", value: "provider" },
            { label: "Ready Form", value: "readyform" },
          ]}
        />
        <div>
          {selectedProperty.value === "id" && (
            <TextFilter
              filteringPlaceholder="Find by Signature Request ID"
              filteringText={filter.filterID}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterID: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "subject" && (
            <TextFilter
              filteringPlaceholder="Find Subject"
              filteringText={filter.filterSubjectName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterSubjectName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "status" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter.statusFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  statusFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={statusArray.map((status) => ({
                label: status.label,
                value: status.value,
              }))}
              placeholder="Choose Status"
            />
          )}
          {selectedProperty.value === "provider" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter?.providerFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  providerFilterOptions: [...detail?.selectedOptions],
                });
              }}
              options={providers.map((type) => ({
                label: type?.firstName + " " + type?.lastName,
                value: type?.id + "",
              }))}
              placeholder="Choose Provider"
            />
          )}
          {selectedProperty.value === "readyform" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter?.digitalformFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  digitalformFilterOptions: [...detail?.selectedOptions],
                });
              }}
              options={digitalforms.map((digitalform) => ({
                label: digitalform?.name,
                value: digitalform?.id + "",
              }))}
              placeholder="Choose Ready Form"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Status")) {
            setFilter({
              ...filter,
              statusFilterOptions: [],
            });
          }
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Provider")) {
            setFilter({
              ...filter,
              providerFilterOptions: [],
            });
          }
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Ready Forms")
          ) {
            setFilter({
              ...filter,
              digitalformFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
