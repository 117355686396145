import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllWorkflowQuery } from "../redux/api/workflow/workflow";
import { Workflow, WorkflowMetadata } from "../redux/api/workflow/types";

export type FilterType = {
  filterWorkflowName: string;
  assignedToFilterOptions: OptionDefinition[];
  providerFilterOptions: OptionDefinition[];
  statusFilterOptions: OptionDefinition[];
};

export default function useWorkflowFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllWorkflowQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterWorkflowName: "",
    assignedToFilterOptions: [],
    providerFilterOptions: [],
    statusFilterOptions: [],
  });

  const [filteredWorkflow, setFilteredWorkflow] =
    useState<WorkflowMetadata[]>(data);

  useEffect(() => {
    setFilteredWorkflow(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_workflows = [...data];
    if (filter.filterWorkflowName) {
      temp_filtered_workflows = temp_filtered_workflows.filter((workflow) =>
        workflow.name
          .toLowerCase()
          .includes(filter.filterWorkflowName.toLowerCase()),
      );
    }

    const selectedAssignedToId = filter.assignedToFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedAssignedToId && selectedAssignedToId.length > 0) {
      temp_filtered_workflows = temp_filtered_workflows.filter(
        (workflow) =>
          selectedAssignedToId.indexOf(workflow.admin?.id + "") >= 0,
      );
    }

    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_workflows = temp_filtered_workflows.filter(
        (workflow) =>
          selectedProviderId.indexOf(workflow?.provider?.id + "") >= 0,
      );
    }

    const selectedStatus = filter.statusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_workflows = temp_filtered_workflows.filter(
        (workflow) =>
          selectedStatus.indexOf(workflow?.workFlowStatus?.id + "") >= 0,
      );
    }
    setFilteredWorkflow(temp_filtered_workflows);
  }, [filter]);

  return {
    filteredWorkflow,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
