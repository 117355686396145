import { CATEGORY_MODAL, alertReminderOptions } from "../helpers/constants";
import FormSection from "./FormSection";
import { ApplicationState } from "../redux/store";
import { connect, useSelector } from "react-redux";
import RHFSelect from "./RHF/RHFSelect";
import RHFDatePicker from "./RHF/RHFDatePicker";
import RHFTextField from "./RHF/RHFTextField";
import { setModalName } from "../redux/UI/actions";
import { useTranslation } from "react-i18next";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import { Divider } from "antd";
import { ExpandableSection } from "@cloudscape-design/components";
import RHFTextArea from "./RHF/RHFTextArea";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
  disabled?: boolean;
}

function DocumentMetaData({ setModalName, disabled }: PropsFromDispatch) {
  const { t } = useTranslation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );

  const { data: categories = [] } = useGetAllCategoryQuery();

  return (
    <FormSection columns={2}>
      <RHFTextField
        label={t(
          "providerSafe.content.addNewDocument.header.fields.documentName.label",
        )}
        name="name"
        readOnly={disabled}
        placeholder={t(
          "providerSafe.content.addNewDocument.header.fields.documentName.placeHolder",
        )}
        rules={{ required: "This field is required" }}
      />
      <RHFSelect
        name="categoryId"
        disabled={disabled}
        label={t(
          "providerSafe.content.addNewDocument.header.fields.category.label",
        )}
        options={categories.map((category) => ({
          label: category.name,
          value: "" + category.id,
        }))}
        filteringType={"auto"}
        stretch={false}
      />
      <RHFDatePicker
        label={t(
          "providerSafe.content.addNewDocument.header.fields.expirationDate",
        )}
        name={"expirationDate"}
        disabled={disabled}
      />
      <RHFSelect
        disabled={disabled}
        label={t("providerSafe.content.addNewDocument.header.fields.alertDays")}
        name={"alertDays"}
        options={alertReminderOptions}
      />
      <ExpandableSection
        headerText={t(`providerSafe.content.addNewDocument.header.headerText`)}
      >
        <FormSection>
          <RHFTextArea
            label={t(`providerSafe.content.addNewDocument.header.fields.notes`)}
            name="notes"
            disabled={disabled}
          />
        </FormSection>
      </ExpandableSection>
    </FormSection>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DocumentMetaData);
