import {
  Button,
  Header,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState } from "react";
import InnerAppLayout from "../components/InnerAppLayout";
import { eSignatureResponse } from "../redux/api/signaturerequest/types";
import ChangeTimeZone from "../components/Timezone";
import StyledRouterLink from "../components/styled-route-link";
import {
  ESIGNATURE_CONTENT_DISPLAY_OPTIONS,
  ESIGNATURE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useTranslation } from "react-i18next";
import PDFTronViewer from "../components/PdftronViewerWrappers/PDFTronViewer";
import { RenderRecipient } from "./signature-detail";
import useESignatureFilter from "../hooks/useESignatureFilters";
import ESignatureFilters from "../components/filter/esignature-filters";
import {
  useResendSignatureMutation,
  useVoidSignatureMutation,
} from "../redux/api/signaturerequest/signaturerequest";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

export default function SignatureRequestsTable() {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<eSignatureResponse[]>([]);
  const [openFilePreview, OpenFilePreviewModal] = useState<
    eSignatureResponse | undefined
  >();
  const [voidSignature] = useVoidSignatureMutation();
  const [resendSignature] = useResendSignatureMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const {
    filteredSignature,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useESignatureFilter();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "e-Signatures-Table-Preferences",
    ESIGNATURE_TABLE_DEFAULT_PREFERENCES,
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredSignature,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  const tableProps: TableProps<eSignatureResponse> = {
    header: (
      <Header
        variant="h2"
        actions={
          <SpaceBetween size="m" direction="horizontal">
            <Button
              variant="normal"
              iconName="refresh"
              onClick={() => {
                refetch();
              }}
            ></Button>
            <Button
              variant="normal"
              disabled={!selectedItems || !selectedItems?.length}
              onClick={async () => {
                const temp = [...selectedItems];
                setSelectedItems([]);
                for (const selectedItem of temp) {
                  if (!!selectedItem && !!selectedItem?.id) {
                    await notifyWrapper({
                      promise: resendSignature({
                        signatureId: selectedItem?.id,
                      }),
                      resourceName: "signature",
                      actionName: "resend",
                    });
                  }
                }
              }}
            >
              Resend Signature
            </Button>
            <Button
              variant="normal"
              disabled={!selectedItems || !selectedItems?.length}
              onClick={async () => {
                const temp = [...selectedItems];
                setSelectedItems([]);
                for (const selectedItem of temp) {
                  if (!!selectedItem && !!selectedItem?.id) {
                    await notifyWrapper({
                      promise: voidSignature({
                        signatureId: selectedItem?.id,
                      }),
                      resourceName: "signature",
                      actionName: "void",
                    });
                  }
                }
              }}
            >
              Void Signature
            </Button>
          </SpaceBetween>
        }
        counter={`(${filteredSignature.length})`}
      >
        {t("eSignatures.header.label")}
      </Header>
    ),
    filter: <ESignatureFilters filter={filter} setFilter={setFilter} />,
    preferences: (
      <TablePreferences
        title="e-Signatures table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={ESIGNATURE_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "multi",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    loadingText: "Loading...",
    empty: <p>No results to display.</p>,
    selectedItems: selectedItems,
    onSelectionChange: (selectionChangeDetail) => {
      setSelectedItems(selectionChangeDetail.detail.selectedItems);
    },
    /*  onRowClick: (onRowClickDetail) => {
      if (!!onRowClickDetail.detail.item)
        setSelectedItems(onRowClickDetail.detail.item);
    }, */
    trackBy: (item) => item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>{t("eSignatures.header.fields.id")}</div>,
        cell: (item) => (
          <StyledRouterLink
            className={"edit_link"}
            to={`/esignatures/${item.id}`}
            label={item.id}
          />
        ),
      },
      {
        id: "subject",
        header: <div>{t("eSignatures.header.fields.subject")}</div>,
        cell: (item) => (
          <StyledRouterLink
            className={"edit_link"}
            to={`/esignatures/${item.id}`}
            label={item.subject}
          />
        ),
      },
      {
        id: "sender",
        header: <div>{t("eSignatures.header.fields.sender")}</div>,
        cell: (item) => (
          <span>
            {item?.admin?.firstName} {item?.admin?.lastName}
          </span>
        ),
        sortingField: "sender",
      },
      {
        id: "provider",
        header: <div>{t("eSignatures.header.fields.provider")}</div>,
        cell: (item) => (
          <RenderRecipient
            recipeint={item?.recipients?.find((r) => r.primary === true)}
          />
        ),
        sortingField: "provider",
      },
      {
        id: "status",
        header: <div>{t("eSignatures.header.fields.status")}</div>,
        cell: (item) => (
          <>
            {item?.status === "pending" && (
              <StatusIndicator type="pending">Pending</StatusIndicator>
            )}
            {item?.status === "completed" && (
              <StatusIndicator>Completed</StatusIndicator>
            )}
            {item?.status === "voided" && (
              <StatusIndicator type="stopped">Void</StatusIndicator>
            )}
          </>
        ),
        sortingField: "status",
      },
      {
        id: "completed_date",
        header: <div>Completed Date</div>,
        cell: (item) => (
          <span>{ChangeTimeZone(item.completedDate) ?? "-"}</span>
        ),
        sortingField: "completedDate",
      },
      {
        id: "current_recipient",
        header: <div>Current Recipient</div>,
        cell: (item) => (
          <RenderRecipient recipeint={item?.recipientGroupByStatus?.current} />
        ),
      },
      {
        id: "readyform",
        header: <span>Ready Form</span>,
        cell: (item) => (
          <>
            {!!item?.digitalForm ? (
              <Link
                variant="secondary"
                href={`/digitalforms/${item?.digitalForm?.id}/templatedesigner`}
                external
              >
                {item?.digitalForm?.name || "-"}
              </Link>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        id: "attachment",
        header: <div>{t("eSignatures.header.fields.fileName")}</div>,
        cell: (item) => (
          <div>
            <Link
              variant="secondary"
              href="#"
              onFollow={() => {
                OpenFilePreviewModal(item);
              }}
            >
              {item?.attachment?.name || "-"}
            </Link>
          </div>
        ),
      },
      {
        id: "primary_recipient",
        header: <div>Primary Recipient</div>,
        cell: (item) => (
          <RenderRecipient
            recipeint={item?.recipients?.find((r) => r.primary === true)}
          />
        ),
        sortingField: "createdDate",
      },
      {
        id: "created_date",
        header: <div>{t("eSignatures.header.fields.createdDate")}</div>,
        cell: (item) => <span>{ChangeTimeZone(item.createdDate)}</span>,
        sortingField: "createdDate",
      },
      {
        id: "recipients",
        header: <div>{t("eSignatures.header.fields.recipients")}</div>,
        cell: (item) => <span>{item?.recipients?.length}</span>,
      },
    ],
    items,
    pagination: <Pagination {...paginationProps} />,
  };

  return (
    <div>
      <InnerAppLayout content={<Table {...tableProps} />} />
      {!!openFilePreview && (
        <Modal
          onDismiss={() => OpenFilePreviewModal(undefined)}
          visible={!!openFilePreview}
          header="e-Signature File Preview"
          size="max"
        >
          <div>
            <PDFTronViewer
              attachmentKey={openFilePreview?.attachment?.key}
              replaceFile={undefined}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
