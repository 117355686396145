import {
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useMemberActiveFilter";

interface MemberActiveFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function MemberActiveFilters({
  filter,
  setFilter,
  countText,
}: MemberActiveFiltersProps) {
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "First Name",
    value: "firstName",
  });

  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterFirstName: "",
              filterLastName: "",
              filterEmail: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "First Name", value: "firstName" },
            { label: "Last Name", value: "lastName" },
            { label: "Email", value: "email" },
          ]}
        />
        <div>
          {selectedProperty.value === "firstName" && (
            <TextFilter
              filteringPlaceholder="Find First Name"
              filteringText={filter.filterFirstName}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterFirstName: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "lastName" && (
            <TextFilter
              filteringPlaceholder="Find Last Name"
              filteringText={filter.filterLastName}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterLastName: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "email" && (
            <TextFilter
              filteringPlaceholder="Find Email"
              filteringText={filter.filterEmail}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterEmail: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
        </div>
      </Grid>
    </SpaceBetween>
  );
}
