import { FormProvider, useForm } from "react-hook-form";
import { useAddLicenseTypeMutation } from "../redux/api/licensetype/licensetype";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../hooks/useIsLoading";
import { useNavigate } from "react-router";
import InnerAppLayout from "../components/InnerAppLayout";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../components/FormSection";
import RHFTextField from "../components/RHF/RHFTextField";
import RHFFloatingSelect from "../components/RHF/RHFFloatingSelect";
import { connect } from "react-redux";
import { setModalName } from "../redux/UI/actions";
import { useTranslation } from "react-i18next";
import RHFSelect from "../components/RHF/RHFSelect";
import { LicenseTypeRequest } from "../redux/api/licensetype/types";
import { PATH_DASHBOARD } from "../routes/paths";
import RHFTextArea from "../components/RHF/RHFTextArea";

export default function AddLicenseType() {
  const { t } = useTranslation();
  const methods = useForm<LicenseTypeRequest>();
  const [addLicenseType, { isLoading: isCreating }] =
    useAddLicenseTypeMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();

  useIsLoading(isCreating);

  const onSubmit = async (data: LicenseTypeRequest) => {
    await notifyWrapper({
      promise: addLicenseType({
        name: data?.name,
        description: data?.description,
        code: data?.code,
      }),
      resourceName: "license type",
      actionName: "add",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: "License Types",
              href: PATH_DASHBOARD.manage.licensetype.list,
            },
            {
              text: "Add",
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Add License Type</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(PATH_DASHBOARD.manage.licensetype.list);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <RHFTextField
                    label={"Name"}
                    name="name"
                    stretch={false}
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField label={"Code"} name="code" stretch={false} />
                  <RHFTextArea
                    label={"Description"}
                    name="description"
                    stretch={false}
                  />
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
