import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import useIsLoading from "../hooks/useIsLoading";
import {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationSettingsMutation,
} from "../redux/api/organization/organization";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import KeyValue from "../components/KeyValue";
import FormSection from "../components/FormSection";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import {
  Button as AntButton,
  Typography,
  Card,
  Space,
  Radio,
  RadioChangeEvent,
} from "antd";
import { useEffect, useState } from "react";
import { OrganizationSettingsType } from "../redux/api/organization/types";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { AsYouType } from "libphonenumber-js";

const { Title } = Typography;

function OrganizationSettings() {
  const {
    data: organization,
    fulfilledTimeStamp,
    isLoading,
  } = useGetOrganizationDetailsQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const { t } = useTranslation();

  const [orgSettings, setOrgSettings] = useState<OrganizationSettingsType>();

  const [updateOrganizationSettings, { isLoading: isUpdating }] =
    useUpdateOrganizationSettingsMutation();

  const onSubmit = async () => {
    if (organization?.id && !!orgSettings)
      await notifyWrapper({
        promise: updateOrganizationSettings({
          id: organization?.id,
          ...orgSettings,
        }),
        resourceName: "organization",
        actionName: "update",
      });
  };

  useEffect(() => {
    setOrgSettings(organization?.settings);
  }, [organization, fulfilledTimeStamp]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("organization_settings.edit") ?? false),
  );

  useIsLoading(isLoading);

  const navigate = useNavigate();

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <SpaceBetween size={"s"}>
        <Header>{t("organization.header.label")}</Header>
        <SpaceBetween size={"xl"}>
          <Container
            header={
              <Header
                actions={
                  <AntButton
                    disabled={!hasPermission}
                    type="primary"
                    onClick={() => {
                      navigate("details/edit");
                    }}
                  >
                    {t("organization.header.content.header.actions.edit")}
                  </AntButton>
                }
              >
                {t("organization.header.content.header.label1")}
              </Header>
            }
          >
            <SpaceBetween size={"l"}>
              <FormSection columns={2}>
                <KeyValue
                  label={t("organization.header.content.header.fields.name")}
                  value={organization?.name}
                />
                <KeyValue
                  label={t(
                    "organization.header.content.header.fields.phoneNumber",
                  )}
                  value={
                    new AsYouType("US").input(organization?.phone ?? "") ?? "-"
                  }
                />
              </FormSection>
              <FormSection
                columns={2}
                header={t("organization.header.content.header.label2")}
              >
                <KeyValue
                  label={t(
                    "organization.header.content.header.fields.addressLane1",
                  )}
                  value={organization?.organizationAddress?.addressLane1}
                />
                <KeyValue
                  label={t(
                    "organization.header.content.header.fields.addressLane2",
                  )}
                  value={organization?.organizationAddress?.addressLane2}
                />
                <KeyValue
                  label={t("organization.header.content.header.fields.city")}
                  value={organization?.organizationAddress?.city}
                />
                <KeyValue
                  label={t("organization.header.content.header.fields.state")}
                  value={organization?.organizationAddress?.state}
                />
                <KeyValue
                  label={t("organization.header.content.header.fields.zipcode")}
                  value={organization?.organizationAddress?.zipcode}
                />
              </FormSection>
            </SpaceBetween>
          </Container>

          <Card
            title="Settings"
            extra={
              <AntButton type="primary" onClick={onSubmit}>
                Save
              </AntButton>
            }
          >
            <Space size="middle">
              <Title level={5} style={{ margin: 0 }}>
                Facility based user access
              </Title>
              <Radio.Group
                value={!!orgSettings?.enableFacilityBasedAccess}
                onChange={(e: RadioChangeEvent) => {
                  setOrgSettings({
                    ...orgSettings,
                    enableFacilityBasedAccess: !!e.target.value,
                  });
                }}
              >
                <Radio value={true}>Enable</Radio>
                <Radio value={false}>Disable</Radio>
              </Radio.Group>
            </Space>
          </Card>
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
}

export default OrganizationSettings;
