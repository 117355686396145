import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useProviderSafeFilter";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";

interface ProviderSafeFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function ProviderSafeFilters({
  filter,
  setFilter,
  countText,
}: ProviderSafeFiltersProps) {
  const { data: category = [] } = useGetAllCategoryQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Document Name",
    value: "documentName",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.categoryFilterOptions &&
      filter.categoryFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Category = ${join(
            filter.categoryFilterOptions.map(
              (categoryFilterOptions) => categoryFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 7 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterDocumentName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Document Name", value: "documentName" },
            { label: "Category", value: "category" },
          ]}
        />
        <div>
          {selectedProperty.value === "documentName" && (
            <TextFilter
              filteringPlaceholder="Find Document Name"
              filteringText={filter.filterDocumentName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterDocumentName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "category" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter.categoryFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  categoryFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={category.map((category) => ({
                label: category.name,
                value: category.id + "",
              }))}
              placeholder="Choose Category"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Category")) {
            setFilter({
              ...filter,
              categoryFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
