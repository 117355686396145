import { FormProvider, useForm } from "react-hook-form";
import { ProviderSpecialtyInfoRequestType } from "../../../../redux/api/provider/section/types";
import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderSpecialtyInfoMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./edit-specialty-info.css";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useTranslation } from "react-i18next";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../../../redux/UI/actions";
import { connect } from "react-redux";
import { BOARD_NAME, SPECIALTY } from "../../../../helpers/constants";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import { useGetAllSpecialtyQuery } from "../../../../redux/api/specialty/specialty";
import { useGetAllBoardNameQuery } from "../../../../redux/api/boardName/board";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditSpecialtyInfo({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<ProviderSpecialtyInfoRequestType>();
  const { providerId, specialtyinfoId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const { data: all_specialty = [] } = useGetAllSpecialtyQuery();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderSpecialtyInfoRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderSpecialtyInfo({
          providerId: providerId,
          ...data,
          specialtyId: data.specialtyId,
          documentId:
            selectedDocument.value !== "-1" ? selectedDocument.value : "",
        }),
        resourceName: "specialty info",
        actionName: "upload",
      });
      navigate(-1);
    }
  };
  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const { data: all_board_name = [] } = useGetAllBoardNameQuery();

  const [addProviderSpecialtyInfo] = useAddProviderSpecialtyInfoMutation();

  useEffect(() => {
    if (provider && provider.providerSpecialties) {
      const specialtyInfo = provider.providerSpecialties.find(
        (specialtyinfo) => specialtyinfo?.id + "" === specialtyinfoId + "",
      );

      if (!!specialtyInfo) {
        methods.setValue("id", specialtyInfo.id);
        methods.setValue("isBoardCertified", specialtyInfo.isBoardCertified);
        methods.setValue("boardCertNumber", specialtyInfo.boardCertNumber);
        methods.setValue("boardName", specialtyInfo.boardName);
        methods.setValue(
          "initialCertificationDate",
          specialtyInfo.initialCertificationDate,
        );
        methods.setValue("expirationDate", specialtyInfo.expirationDate);
        methods.setValue(
          "reCertificationDate",
          specialtyInfo.reCertificationDate,
        );
        !!specialtyInfo?.specialty?.id &&
          methods.setValue("specialtyId", specialtyInfo?.specialty?.id);
        !!specialtyInfo?.document &&
          setSelectedDocument({
            label: specialtyInfo?.document?.name,
            value: specialtyInfo?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, specialtyinfoId, all_specialty]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.specialityInfo.editSpecialityInfo.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=specialtyinfo`,
            },
            {
              text: `${t("providerProfiles.specialityInfo.editSpecialityInfo.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.specialityInfo.editSpecialityInfo.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={3}>
                      <RHFFloatingSelect
                        label={t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.fields.boardName",
                        )}
                        name="boardName"
                        options={all_board_name.map((board) => ({
                          value: board.name,
                          label: board.name,
                        }))}
                        stretch={false}
                        action={{
                          label: `${t("providerProfiles.affiliations.editAffiliation.header.fields.affiliationStatus.actions.modify")}`,
                          onClick: () => {
                            setModalName(BOARD_NAME);
                          },
                        }}
                      />
                      <RHFFloatingSelect
                        label={t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.fields.specialty",
                        )}
                        name="specialtyId"
                        options={all_specialty.map((specialty) => ({
                          value: specialty?.id ?? "",
                          label: specialty.name,
                        }))}
                        stretch={false}
                        action={{
                          label: `${t("providerProfiles.affiliations.editAffiliation.header.fields.affiliationStatus.actions.modify")}`,
                          onClick: () => {
                            setModalName(SPECIALTY);
                          },
                        }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.fields.boardCertNumber",
                        )}
                        name="boardCertNumber"
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.fields.initialCertificationDate",
                        )}
                        name="initialCertificationDate"
                        rules={{ required: false }}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.fields.expirationDate",
                        )}
                        name="expirationDate"
                        rules={{ required: false }}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.specialityInfo.editSpecialityInfo.header.fields.reCertificationDate",
                        )}
                        name="reCertificationDate"
                        rules={{ required: false }}
                      />
                    </FormSection>
                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditSpecialtyInfo);
