import {
  Table,
  TableProps,
  Box,
  Header,
  Button,
  ButtonDropdown,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import StyledRouterLink from "../../components/styled-route-link";

import { useNavigate, useParams } from "react-router";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

import { TableEmptyState } from "../../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../../components/LoadingScreen";
import { useDeleteTaskMutation } from "../../redux/api/task";
import ChangeTimeZone from "../../components/Timezone";
import { WorkFlowTask } from "../../redux/api/workflow/types";
import { useGetWorkflowQuery } from "../../redux/api/workflow/workflow";
import { skipToken } from "@reduxjs/toolkit/query";

export default function WorkFlowTasks() {
  const { workflowId } = useParams();
  const {
    data: workflow,
    isLoading,
    isFetching,
    refetch,
  } = useGetWorkflowQuery(!!workflowId ? { id: workflowId } : skipToken);
  const [deleteTask] = useDeleteTaskMutation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const { items, collectionProps } = useCollection(
    !!workflow ? workflow?.tasks : [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Task"}
            action={() => {
              navigate(`add`);
            }}
          />
        ),
      },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedTask, setSelectedTask] = useState<WorkFlowTask>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  const tableProps: TableProps<WorkFlowTask> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${!!workflow ? workflow?.tasks?.length : 0})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!setSelectedTask}
                  items={[
                    {
                      id: "edit_form",
                      text: "Edit",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedTask) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedTask?.id)
                        navigate(
                          `/workflows/${workflowId}/task/${selectedTask?.id}`,
                        );
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    if (!!workflowId)
                      navigate(`/workflows/${workflowId}/addtask`);
                  }}
                  variant="primary"
                  data-test="add_task"
                >
                  Add Task
                </Button>
              </span>
            </div>
          }
        >
          Tasks
        </Header>
      ),
      items,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedTask(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedTask(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedTask ? [selectedTask] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>Task ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "taskType",
          header: <div>Task Type</div>,
          cell: (item) => (
            <StyledRouterLink
              className={"edit_link"}
              to={`task/${item.id}`}
              label={item?.taskType?.name}
            />
          ),
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{item?.name ?? "-"}</span>,
          sortingField: "name",
        },
        {
          id: "taskStatus",
          header: <div>Task Status</div>,
          cell: (item) => <span>{item?.taskStatus?.name}</span>,
        },
        {
          id: "admin",
          header: <div>Assigned To</div>,
          cell: (item) => (
            <span>
              {!!item?.admin
                ? `${item?.admin?.firstName} ${item?.admin?.lastName}`
                : "-"}
            </span>
          ),
        },
        {
          id: "provider",
          header: <div>Provider</div>,
          cell: (item) => (
            <span>
              {!!item?.provider
                ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                : "-"}
            </span>
          ),
        },
        {
          id: "facility",
          header: <div>Facility</div>,
          cell: (item) => (
            <span>{!!item?.facility ? `${item?.facility?.name} ` : "-"}</span>
          ),
        },
        {
          id: "startDate",
          header: <div>Start Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.startDate) ?? "-"}</span>,
        },
        {
          id: "dueDate",
          header: <div>Due Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.dueDate) ?? "-"}</span>,
        },
        {
          id: "completedDate",
          header: <div>Completed Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.completedDate) ?? "-"}</span>
          ),
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <span>{item?.description}</span>,
        },
        {
          id: "createdDate",
          header: <div>Created Date</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.createdDate)}</span>,
        },
      ],
    };
  }, [items, selectedTask]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedTask?.id)
            await notifyWrapper({
              promise: deleteTask({ id: selectedTask?.id }),
              resourceName: "Task",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header="Delete Task"
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedTask?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this task?"}
      />
    </div>
  );
}
