import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

import InnerAppLayout from "../components/InnerAppLayout";
import RHFTextField from "../components/RHF/RHFTextField";
import RHFFloatingSelect from "../components/RHF/RHFFloatingSelect";
import { setModalName } from "../redux/UI/actions";
import { connect } from "react-redux";
import { WORFLOW_STATUS } from "../helpers/constants";
import RHFSelect from "../components/RHF/RHFSelect";
import RHFDatePicker from "../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../redux/api/provider/provider";
import FormSection from "../components/FormSection";
import { WorkflowUpdateRequest } from "../redux/api/workflow/types";
import {
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
  useGetWorkflowQuery,
  useUpdateWorkflowMutation,
} from "../redux/api/workflow/workflow";
import RHFTextArea from "../components/RHF/RHFTextArea";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";
import { Divider } from "antd";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import WorkFlowTasks from "./workflowtasks";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditWorflow({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<WorkflowUpdateRequest>();
  const { workflowId } = useParams();
  const { data: workflow, fulfilledTimeStamp } = useGetWorkflowQuery(
    !!workflowId ? { id: workflowId } : skipToken,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: workflowTemplate = [] } = useGetAllWorkflowTemplateQuery();
  const [updateWorkflow] = useUpdateWorkflowMutation();
  useEffect(() => {
    if (!!workflow) {
      methods.setValue("name", workflow.name ?? "");
      methods.setValue("workflowStatusId", workflow.workFlowStatus?.id ?? "");
      methods.setValue(
        "workflowTemplateId",
        workflow.workflowTemplate?.id ?? "",
      );
      methods.setValue("adminId", workflow.admin?.id ?? "");
      methods.setValue("providerId", workflow.provider?.id ?? "");
      methods.setValue("facilityId", workflow.facility?.id ?? "");
      methods.setValue("startDate", workflow.startDate ?? "");
      methods.setValue("dueDate", workflow.dueDate ?? "");
      methods.setValue("completedDate", workflow.completedDate ?? "");
      methods.setValue("notes", workflow.notes ?? "");
    }
  }, [fulfilledTimeStamp, methods, workflow]);
  const { data: workflowStatus = [] } = useGetAllWorkflowStatusQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  const notes = methods.watch("notes");
  const onSubmit = async (data: WorkflowUpdateRequest) => {
    if (!!workflowId) {
      await notifyWrapper({
        promise: updateWorkflow({ id: workflowId, ...data }),
        resourceName: "workflow",
        actionName: "update",
      });
    }
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("workflow.editWorkflow.header.breadcrumb.text")}`,
                href: `/workflows`,
              },
              {
                text: workflow?.name ?? "",
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <SpaceBetween size="xxl">
          <Tabs
            activeTabId={searchParams.get("activeTabId") ?? "details"}
            onChange={({ detail }) => {
              setSearchParams({ activeTabId: detail.activeTabId });
            }}
            tabs={[
              {
                label: "Details",
                id: "details",
                content: (
                  <Container>
                    <FormProvider {...methods}>
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Form
                          actions={
                            <Box float="right">
                              <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                  onClick={() => {
                                    navigate(-1);
                                  }}
                                  formAction="none"
                                >
                                  {t(
                                    `workflow.editWorkflow.header.actions.cancel`,
                                  )}
                                </Button>
                                <Button formAction="submit" variant="primary">
                                  {t(
                                    `workflow.editWorkflow.header.actions.submit`,
                                  )}
                                </Button>
                              </SpaceBetween>
                            </Box>
                          }
                        >
                          <SpaceBetween size="l">
                            <FormSection columns={3}>
                              <RHFTextField
                                label={t(`workflow.editWorkflow.fields.name`)}
                                name="name"
                                stretch={true}
                                rules={{ required: "This field is required" }}
                              />
                              <RHFSelect
                                label={t(
                                  `workflow.editWorkflow.fields.assignedTo`,
                                )}
                                name={"adminId"}
                                stretch={true}
                                filteringType="auto"
                                options={members.map((member) => ({
                                  label:
                                    member.firstName + " " + member.lastName,
                                  value: "" + member.id,
                                }))}
                                rules={{ required: "This field is required" }}
                              />
                              <RHFFloatingSelect
                                name="workflowStatusId"
                                label={t(
                                  `workflow.editWorkflow.fields.workflowStatus.label`,
                                )}
                                options={workflowStatus.map((status) => ({
                                  label: status.name,
                                  value: "" + status.id,
                                }))}
                                stretch={true}
                                action={{
                                  label: `${t("workflow.editWorkflow.fields.workflowStatus.actions.modify")}`,
                                  onClick: () => {
                                    setModalName(WORFLOW_STATUS);
                                  },
                                }}
                              />
                            </FormSection>
                            <FormSection columns={3}>
                              <RHFSelect
                                disabled
                                name="workflowTemplateId"
                                label={t(
                                  `workflow.editWorkflow.fields.workflowTemplate`,
                                )}
                                options={workflowTemplate.map((temp) => ({
                                  label: temp.name,
                                  value: "" + temp.id,
                                }))}
                                stretch={false}
                              />
                              <RHFSelect
                                disabled
                                label={t(
                                  `workflow.editWorkflow.fields.provider`,
                                )}
                                name={"providerId"}
                                stretch={true}
                                filteringType="auto"
                                options={providers.map((provider) => ({
                                  label:
                                    provider.firstName +
                                    " " +
                                    provider.lastName,
                                  value: "" + provider.id,
                                }))}
                              />
                              <RHFSelect
                                disabled
                                label={"Facility"}
                                name={"facilityId"}
                                stretch={true}
                                filteringType="auto"
                                options={facilities.map((facility) => ({
                                  label: facility?.name + "",
                                  value: "" + facility.id,
                                }))}
                              />
                            </FormSection>
                            <FormSection columns={3}>
                              <RHFDatePicker
                                label={t(
                                  "workflow.editWorkflow.fields.startDate",
                                )}
                                name={"startDate"}
                                stretch={true}
                              />
                              <RHFDatePicker
                                label={t(
                                  "workflow.editWorkflow.fields.dueDate",
                                )}
                                name={"dueDate"}
                                stretch={true}
                              />
                              <RHFDatePicker
                                label={t(
                                  "workflow.editWorkflow.fields.completedDate",
                                )}
                                name={"completedDate"}
                                stretch={true}
                              />
                            </FormSection>
                          </SpaceBetween>
                          <Divider />
                          <ExpandableSection
                            headerText={t(
                              "workflow.editWorkflow.header.headerText",
                            )}
                            defaultExpanded={!!notes}
                          >
                            <RHFTextArea
                              label={t("workflow.editWorkflow.fields.notes")}
                              name="notes"
                              stretch={true}
                            />
                          </ExpandableSection>
                        </Form>
                      </form>
                    </FormProvider>
                  </Container>
                ),
              },
              {
                label: "Tasks",
                id: "tasks",
                content: <WorkFlowTasks />,
              },
            ]}
          />
        </SpaceBetween>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditWorflow);
