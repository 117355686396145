import LoadingScreen from "../../components/LoadingScreen";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";

import { PayerGroup } from "../../redux/api/payergroup/types";
import usePayerGroupFilter from "../../hooks/usePayerGroupFilter";
import { useDeleteAssociatedNetworkMutation } from "../../redux/api/payers/payers";
import PayerGroupFilters from "../../components/filter/payergroup-filter";
import AssociatedNetworksModals from "./associated-network-model";

export default function AssoicatedNetworksTable({
  associatedNetworks,
  setAssociatedNetworks,
}: {
  associatedNetworks: PayerGroup[];
  setAssociatedNetworks: Dispatch<SetStateAction<PayerGroup[]>>;
}) {
  const [isAssociatedNetworksModalOpen, setAssociatedNetworksModalOpen] =
    useState<boolean>(false);

  const { id: payerId } = useParams();

  const { t } = useTranslation();
  const { filteredPayerGroup, filter, isLoading, isFetching, setFilter } =
    usePayerGroupFilter(associatedNetworks);

  const [deleteAssociatedNetwork] = useDeleteAssociatedNetworkMutation();

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerGroup,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Associated Networks"}
            action={() => {
              setAssociatedNetworksModalOpen(true);
            }}
          />
        ),
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedPayerGroup, setSelectedPayerGroup] = useState<PayerGroup[]>(
    [],
  );

  const tableProps: TableProps<PayerGroup> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredPayerGroup?.length})`}
          actions={
            <SpaceBetween size="l" direction="horizontal">
              <Button
                onClick={async () => {
                  try {
                    if (!payerId) return;
                    const payerToRemove =
                      selectedPayerGroup?.map((group) => group?.id + "") || [];

                    await deleteAssociatedNetwork({
                      payerId: payerId,
                      networks: payerToRemove,
                    });

                    const temp = associatedNetworks.filter(
                      (network) => !payerToRemove.includes(network?.id + ""),
                    );
                    setAssociatedNetworks(temp);
                    setSelectedPayerGroup([]);
                  } catch (error) {
                    console.error("Error deleting payer:", error);
                    // Handle error (e.g., show a message to the user)
                  }
                }}
              >
                Remove
              </Button>

              <Button
                onClick={() => {
                  setAssociatedNetworksModalOpen(true);
                }}
                variant="primary"
                data-test="add_payer"
              >
                Associate Network
              </Button>
            </SpaceBetween>
          }
        >
          Associated Networks
        </Header>
      ),
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <PayerGroupFilters filter={filter} setFilter={setFilter} />,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: true,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedPayerGroup(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedPayerGroup;
        if (!detail.item) return;
        if (temp?.includes(detail.item))
          setSelectedPayerGroup(temp.filter((item) => item !== detail.item));
        else setSelectedPayerGroup([detail.item, ...temp]);
      },
      selectedItems: selectedPayerGroup,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "groupCode", visible: true },
        { id: "description", visible: true },
      ],
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("payergroup.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>Network name</div>,
          cell: (item) => (
            <Link external href={`/payergroup/${item.id}`} variant="primary">
              {item?.name ?? "-"}
            </Link>
          ),
          sortingField: "name",
        },
        {
          id: "groupCode",
          header: "Network id",
          cell: (item) => <Link href="#">{item.groupCode || "-"}</Link>,
          sortingField: "groupCode",
          isRowHeader: true,
        },
        {
          id: "description",
          header: <span>Notes</span>,
          cell: (item) => <div>{item?.description ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedPayerGroup]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <AssociatedNetworksModals
        associatedNetworks={associatedNetworks}
        isAssociatedNetworksModalOpen={isAssociatedNetworksModalOpen}
        setAssociatedNetworks={setAssociatedNetworks}
        setAssociatedNetworksModalOpen={setAssociatedNetworksModalOpen}
      />
    </div>
  );
}
