import {
  BreadcrumbGroup,
  Button,
  ButtonDropdown,
  Container,
  Form,
  Header,
  Tabs,
} from "@cloudscape-design/components";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DocumentMetaData from "../../components/DocumentMetaData";
import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import InnerAppLayout from "../../components/InnerAppLayout";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { FormProvider, useForm } from "react-hook-form";
import useIsLoading from "../../hooks/useIsLoading";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import useNotify from "../../hooks/useNotify";
import { v4 as uuidv4 } from "uuid";
import { fileAxios } from "../../context/axios";
import { captureException } from "@sentry/react";
import DocumentActivies from "./versions/document-activities";
import { getFileUrl } from "../../config";
import DocumentVersions from "./versions/document-versions";
import CommentActivies from "./versions/comments-activites";
import {
  useAddCommentToDocumentMutation,
  useDeleteCommentFromDocumentMutation,
  useGetDocumentCommentsQuery,
  useUpdateCommentInDocumentMutation,
} from "../../redux/api/providerComments/comment";
import Comment from "../../redux/api/providerComments/types";

type FormInputType = {
  name: string;
  expirationDate?: string | Date;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
  isVersioned: boolean;
};

export default function EditDocument() {
  const navigate = useNavigate();
  const { providerId, documentId } = useParams();
  const { t } = useTranslation();

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const [updateDocument] = useUpdateDocumentMutation();
  const {
    data: document,
    fulfilledTimeStamp,
    isLoading,
  } = useGetDocumentQuery(
    documentId && providerId ? { documentId, providerId } : skipToken,
  );

  const methods = useForm<FormInputType>();

  const [uploadFile, setUploadFile] = useState<File>();
  const documentName = methods.watch("name");

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );
  const [addCommentMutation] = useAddCommentToDocumentMutation();
  const [deleteCommentMutation] = useDeleteCommentFromDocumentMutation();
  const [updateCommentMutation] = useUpdateCommentInDocumentMutation();

  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();
  const [commentsList, setCommentsList] = useState<Comment[]>([]);

  const { data: comments } = useGetDocumentCommentsQuery(
    documentId && providerId ? { documentId, providerId } : skipToken,
  );

  useEffect(() => {
    if (comments) {
      setCommentsList(comments);
    }
  }, [comments]);

  const postComment = async (message: string) => {
    try {
      if (!!providerId && !!documentId) {
        await addCommentMutation({
          providerId: providerId,
          documentId: documentId,
          message: message,
        });
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };
  const editComment = async (commentId: string, message: string) => {
    try {
      if (!!providerId && !!documentId) {
        await updateCommentMutation({
          providerId: providerId,
          documentId: documentId,
          commentId: commentId,
          message: message,
        });
      }
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const deleteComment = async (commentId: string) => {
    try {
      if (!!providerId && !!documentId) {
        await deleteCommentMutation({
          providerId: providerId,
          documentId: documentId,
          commentId: commentId,
        });
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleSave = async (data: FormInputType) => {
    if (!hasPermission || !providerId || !document?.id) return;
    const notificationId = uuidv4();
    try {
      notifyInProgress({
        name: "Document",
        action: "updating",
        id: notificationId,
      });
      await updateDocument({
        providerId: providerId,
        documentId: document.id,
        isVersioned: !!data.isVersioned,
        document: {
          name: data?.name,
          alertDays: data?.alertDays,
          expirationDate: data?.expirationDate,
          categoryId: data.categoryId,
          file: uploadFile,
          notes: data.notes,
        },
      })
        .unwrap()
        .then(async (result) => {
          if (!!uploadFile && result?.attachment?.key) {
            try {
              await fileAxios.put(
                getFileUrl(result?.attachment?.key),
                uploadFile,
              );
              notifySucess({
                name: "document",
                action: "updated",
                id: notificationId,
              });
            } catch (error) {
              captureException(error);
              notifyFailed({
                name: "Document",
                action: "update",
                id: notificationId,
              });
            }
          } else
            notifySucess({
              name: "document",
              action: "updated",
              id: notificationId,
            });
        })
        .catch((error) => {
          captureException(error);
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: "Document",
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: "Document",
              action: "update",
              id: notificationId,
            });
        });
    } catch (error) {
      captureException(error);
      notifyFailed({
        name: "Document",
        action: "add",
        id: notificationId,
      });
    }
    navigate(-1);
  };

  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: document?.name,
      alertDays: document?.alertDays,
      expirationDate: document?.expirationDate,
      notes: document?.notes,
      categoryId: document?.category?.id,
    });
  }, [document, fulfilledTimeStamp, methods]);

  useIsLoading(isLoading);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("providerSafe.content.editDocument.header.breadcrumb.text")}`,
                href: "/manageprovider",
              },
              {
                text: displayName,
                href: `/manageprovider/${providerId}/profile`,
              },
              {
                text: `${t("providerSafe.content.editDocument.header.breadcrumb.nextPage")}`,
                href: `/safe?providerId=${providerId}`,
              },
              {
                text: `${t("providerSafe.content.editDocument.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        rightPanel={
          <AttachmentViewer
            attachment={document?.attachment}
            file={uploadFile}
            onFileChange={(uploadFile?: File) => {
              setUploadFile(uploadFile);
            }}
          />
        }
        content={
          <Tabs
            tabs={[
              {
                label: "Details",
                id: "details",
                content: (
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSave)}>
                      <Form>
                        <Container
                          header={<Header>{documentName}</Header>}
                          footer={
                            <Grid
                              container
                              justifyContent="flex-end"
                              spacing={2}
                            >
                              <Grid item>
                                <Button
                                  formAction="none"
                                  onClick={() => {
                                    navigate(-1);
                                  }}
                                >
                                  {t(
                                    "providerSafe.content.editDocument.header.actions.cancel",
                                  )}
                                </Button>
                              </Grid>
                              <Grid item>
                                <ButtonDropdown
                                  items={[
                                    {
                                      text: "Add New Version",
                                      id: "save_version",
                                    },
                                  ]}
                                  onItemClick={({ detail }) => {
                                    if ((detail.id = "save_version")) {
                                      methods.setValue("isVersioned", true);
                                    }
                                    methods.handleSubmit(handleSave)();
                                  }}
                                  mainAction={{
                                    text: t(
                                      "providerSafe.content.editDocument.header.actions.save",
                                    ),
                                    onClick: () => {
                                      methods.handleSubmit(handleSave)();
                                    },
                                  }}
                                  variant="primary"
                                />
                              </Grid>
                            </Grid>
                          }
                        >
                          <div>
                            <DocumentMetaData />
                          </div>
                        </Container>
                      </Form>
                    </form>
                  </FormProvider>
                ),
              },
              {
                label: "Versions",
                id: "versions",
                content: <DocumentVersions />,
              },
              {
                label: "Audit Logs",
                id: "activities",
                content: <DocumentActivies />,
              },
              {
                label: "Comments",
                id: "comments",
                content: (
                  <CommentActivies
                    commentsList={commentsList}
                    postComment={postComment}
                    editComment={editComment}
                    deleteComment={deleteComment}
                  />
                ),
              },
            ]}
          />
        }
      />
    </>
  );
}
