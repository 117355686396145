import { connect } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import { ApplicationState } from "./redux/store";
import Router, { InvalidPathRoute } from "./routes";
import * as Sentry from "@sentry/react";
import "react-phone-number-input/style.css";
import { Alert } from "@cloudscape-design/components";

console.log(`current version ${process.env.REACT_APP_VERSION}`);
console.log(`env ${process.env.REACT_APP_ENV}`);

interface PropsFromState {
  is_loading: boolean;
}

function App({ is_loading }: PropsFromState) {
  return (
    <Sentry.ErrorBoundary fallback={<InvalidPathRoute />}>
      <LoadingScreen isOpen={is_loading} />
      <Router />
    </Sentry.ErrorBoundary>
  );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    is_loading: state.ui.is_loading,
  };
};

export default connect(mapStateToProps, {})(App);
