import { FormProvider, useForm } from "react-hook-form";
import { ProviderPeerReferenceRequestType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import { Divider } from "@mui/material";
import {
  useAddProviderPeerReferenceMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import { Countries, USStatesList } from "../../../../helpers/constants";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";
import { useGetAllSpecialtyQuery } from "../../../../redux/api/specialty/specialty";

export default function EditPeerReference() {
  const { t } = useTranslation();
  const methods = useForm<ProviderPeerReferenceRequestType>();
  const { providerId, peerreferenceId } = useParams();
  const [uploadFile, setUploadFile] = useState<File>();
  const navigate = useNavigate();

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const { data: degrees = [] } = useGetAllDegreeQuery();
  const { data: specialty = [] } = useGetAllSpecialtyQuery();
  const [addProviderPeerReference] = useAddProviderPeerReferenceMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const onSubmit = async (data: ProviderPeerReferenceRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderPeerReference({
          providerId: providerId,
          ...data,
        }),
        resourceName: "peer reference",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  useEffect(() => {
    if (provider && provider.providerPeerReferences) {
      const peerreference = provider.providerPeerReferences.find(
        (peerreference) => peerreference?.id + "" === peerreferenceId + "",
      );
      if (peerreference) {
        methods.setValue("id", peerreference.id);
        methods.setValue("name", peerreference.name);
        peerreference.degree?.id &&
          methods.setValue("degreeId", peerreference.degree?.id);
        peerreference.specialty?.id &&
          methods.setValue("specialtyId", peerreference.specialty?.id);
        methods.setValue(
          "timeWithReferenceValue",
          peerreference.timeWithReferenceValue,
        );
        methods.setValue(
          "timeWithReferenceUnit",
          peerreference.timeWithReferenceUnit,
        );
        methods.setValue("email", peerreference.email);
        methods.setValue("phone", peerreference.phone);
        methods.setValue("fax", peerreference.fax);
        methods.setValue("addressLane1", peerreference.addressLand1);
        methods.setValue("addressLane2", peerreference.addressLane2);
        methods.setValue("city", peerreference.city);
        methods.setValue("state", peerreference.state);
        methods.setValue("zip", peerreference.zip);
        methods.setValue("country", peerreference.country);
      }
    }
  }, [fulfilledTimeStamp, provider, peerreferenceId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.peerReference.editPeerReference.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=peerreference`,
            },
            {
              text: `${t("providerProfiles.peerReference.editPeerReference.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.peerReference.editPeerReference.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.peerReference.editPeerReference.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.peerReference.editPeerReference.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.name",
                        )}
                        name="name"
                        stretch
                        rules={{ required: "This field is required" }}
                      />

                      <RHFSelect
                        name="degreeId"
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.degree",
                        )}
                        options={degrees.map((degreeId) => ({
                          value: degreeId.id,
                          label: degreeId.name,
                        }))}
                      />
                      <RHFSelect
                        name="specialtyId"
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.specialty",
                        )}
                        options={specialty.map((specialtyId) => ({
                          value: specialtyId.id,
                          label: specialtyId.name,
                        }))}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.timeWithReferenceValue",
                        )}
                        name="timeWithReferenceValue"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.timeWithReferenceUnit",
                        )}
                        name="timeWithReferenceUnit"
                      />
                      <RHFTextField
                        label="Email"
                        name="email"
                        rules={{
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email",
                          },
                        }}
                      />
                      <RHFPhoneNumber
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.phone",
                        )}
                        name="phone"
                      />

                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.fax",
                        )}
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.addressLane1",
                        )}
                        name="addressLane1"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.addressLane2",
                        )}
                        name="addressLane2"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.city",
                        )}
                        name="city"
                        stretch
                      />
                      <RHFSelect
                        name="state"
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.state",
                        )}
                        options={USStatesList.map((state) => ({
                          value: state.value,
                        }))}
                        stretch
                      />
                      <RHFSelect
                        name="country"
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.country",
                        )}
                        options={Countries.map((country) => ({
                          value: country.value,
                        }))}
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.peerReference.editPeerReference.header.fields.zipcode",
                        )}
                        name="zip"
                        stretch
                      />
                    </FormSection>
                    {/* <DocumentSection
                      uploadFile={uploadFile}
                      setUploadFile={setUploadFile}
                      providerId={providerId}
                    /> */}
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
