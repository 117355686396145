import {
  Box,
  Button,
  Link,
  Modal,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAllDigitalFormsQuery } from "../../../redux/api/digitalform/digitalforms";
import {
  useGetAllCredentialPackageFormsQuery,
  useUpdateCredentialPackageFormsMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import { useTranslation } from "react-i18next";

type CredentailPackageAddFormModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};
export default function CredentailPackageAddFormModal({
  visible,
  closeModal,
}: CredentailPackageAddFormModalProps) {
  const { t } = useTranslation();
  const { data: digitalForms = [] } = useGetAllDigitalFormsQuery();
  const { id: credentialPackageId } = useParams();
  const { data: packageforms = [] } = useGetAllCredentialPackageFormsQuery(
    credentialPackageId ? { credentialPackageId } : skipToken,
  );
  const [selectedForms, setSelectedForms] = useState<DigitalForm[]>([]);
  const [updateCredentialPackageForms] =
    useUpdateCredentialPackageFormsMutation();

  useEffect(() => {
    setSelectedForms([]);
  }, [closeModal]);

  const packageFormIdList = useMemo(() => {
    return packageforms.map((forms) => forms.id + "");
  }, [packageforms]);

  return (
    <Modal
      onDismiss={closeModal}
      size="large"
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={closeModal}>
              {t(
                "credentialPackages.packageDetails.header.readyForms.addReadyForm.header.actions.cancel",
              )}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (
                  !selectedForms ||
                  !credentialPackageId ||
                  selectedForms?.length === 0
                ) {
                  closeModal();
                  return;
                }
                const formIds = selectedForms.map((form) => form.id + "");
                updateCredentialPackageForms({
                  id: credentialPackageId,
                  forms: formIds,
                });
                closeModal();
              }}
            >
              {t(
                "credentialPackages.packageDetails.header.readyForms.addReadyForm.header.actions.save",
              )}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t(
        "credentialPackages.packageDetails.header.readyForms.addReadyForm.header.label",
      )}
    >
      <Table
        variant="embedded"
        columnDefinitions={[
          {
            id: "name",
            header: (
              <div>
                {t(
                  "credentialPackages.packageDetails.header.readyForms.addReadyForm.header.fields.name",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "type",
            header: (
              <div>
                {t(
                  "credentialPackages.packageDetails.header.readyForms.addReadyForm.header.fields.type",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.digitalFormType?.type || ""}</div>,
            sortingField: "type",
          },
        ]}
        isItemDisabled={(item) => packageFormIdList?.includes(item?.id + "")}
        items={digitalForms}
        selectionType="multi"
        selectedItems={selectedForms ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectedForms(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedForms;
          if (!detail.item || packageFormIdList?.includes(detail.item.id + ""))
            return;
          if (temp?.includes(detail.item))
            setSelectedForms(temp.filter((item) => item !== detail.item));
          else setSelectedForms([detail.item, ...temp]);
        }}
        stickyHeader
        loadingText="Loading resources"
        sortingDisabled
      />
    </Modal>
  );
}
