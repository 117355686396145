import {
  Button,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useState } from "react";
import CredentailTemplateAddFormModal from "./credentialtemplate-add-form-modal";
import { DigitalForm } from "../../redux/api/digitalform/types";
import {
  useDeleteCredentialTemplateFormsMutation,
  useGetAllCredentialTemplateFormsQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

export default function FormsList({
  selectedForms,
  setSelectForms,
}: {
  forms: DigitalForm[];
  selectedForms: DigitalForm[];
  setSelectForms: (selected: DigitalForm[]) => void;
}) {
  const { id: credentialTemplateId } = useParams();
  const { t } = useTranslation();
  const {
    data: digitalforms = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllCredentialTemplateFormsQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );

  const [isAddFormModalVisible, setIsAddFormModalVisible] =
    useState<boolean>(false);
  const [deleteCredentialTemplateForms] =
    useDeleteCredentialTemplateFormsMutation();
  return (
    <>
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: (
              <div>
                {t(
                  "credentialTemplates.templateDetails.readyForms.table.header.fields.name",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "type",
            header: (
              <div>
                {t(
                  "credentialTemplates.templateDetails.readyForms.table.header.fields.type",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.digitalFormType?.type || ""}</div>,
            sortingField: "type",
          },
        ]}
        items={digitalforms}
        selectionType="multi"
        selectedItems={selectedForms ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectForms(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedForms;
          if (!detail.item) return;
          if (temp?.includes(detail.item))
            setSelectForms(temp.filter((item) => item !== detail.item));
          else setSelectForms([detail.item, ...temp]);
        }}
        stickyHeader
        loadingText="Loading..."
        loading={isLoading || isFetching}
        sortingDisabled
        header={
          <Header
            variant="h3"
            counter={`(${digitalforms?.length})`}
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
                <Button
                  onClick={() => {
                    if (!selectedForms || !credentialTemplateId) return;
                    const formsId = selectedForms.map((form) => form.id + "");
                    deleteCredentialTemplateForms({
                      credentialTemplateId: credentialTemplateId,
                      forms: formsId,
                    });
                  }}
                >
                  {t(
                    "credentialTemplates.templateDetails.readyForms.table.header.actions.remove",
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setIsAddFormModalVisible(true);
                  }}
                >
                  {t(
                    "credentialTemplates.templateDetails.readyForms.table.header.actions.addReadyForm",
                  )}
                </Button>
              </SpaceBetween>
            }
          >
            {t(
              "credentialTemplates.templateDetails.readyForms.table.header.label",
            )}
          </Header>
        }
      />
      <CredentailTemplateAddFormModal
        closeModal={() => {
          setIsAddFormModalVisible(false);
        }}
        visible={isAddFormModalVisible}
      />
    </>
  );
}
