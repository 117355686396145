import { Pagination, Table, TableProps } from "@cloudscape-design/components";
import { useEffect, useState, useMemo } from "react";
import { DigitalForm } from "../../redux/api/digitalform/types";
import ChangeTimeZone from "../../components/Timezone";
import DigitalFormTableHeader from "./header";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../common/localStorage";
import {
  TEMPLATE_CONTENT_DISPLAY_OPTIONS,
  TEMPLATE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import StyledRouterLink from "../../components/styled-route-link";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import useReadyFormFilter from "../../hooks/useReadyFormFilter";
import ReadyFormFilters from "../../components/filter/readyform-filters";

function Digitalforms() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [selectDigitalform, setSelectDigitalform] = useState<DigitalForm>();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Template-Table-Preferences",
    TEMPLATE_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("readyforms.edit") ?? false),
  );
  const navigate = useNavigate();
  const {
    filteredReadyForm,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useReadyFormFilter();
  const { items, collectionProps, paginationProps } = useCollection(
    filteredReadyForm,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("readyForms.table.header.label")}
            action={() => {
              navigate(`addNew`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  useEffect(() => {
    const temp = filteredReadyForm.find((form) => "" + form.id === "" + id);
    if (temp) setSelectDigitalform(temp);
  }, [id, filteredReadyForm]);

  const tableProps: TableProps<DigitalForm> = useMemo(() => {
    return {
      header: (
        <DigitalFormTableHeader
          counter={filteredReadyForm.length?.toString()}
          selectDigitalform={selectDigitalform}
          refetch={refetch}
        />
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Ready Form Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={TEMPLATE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      filter: <ReadyFormFilters filter={filter} setFilter={setFilter} />,
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectedItems: selectDigitalform ? [selectDigitalform] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectDigitalform(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectDigitalform(detail.item);
      },
      trackBy: (item) => item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("readyForms.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>{t("readyForms.table.header.fields.name")}</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `/digitalforms/${item.id}` : `#`}
              label={item.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "digitalFormType",
          header: <div>{t("readyForms.table.header.fields.type")}</div>,
          cell: (item) => <span>{item?.digitalFormType?.type}</span>,
          sortingField: "digitalFormType.name",
          sortingComparator: (a, b) =>
            (a?.digitalFormType?.type ?? "").localeCompare(
              b?.digitalFormType?.type ?? "",
            ),
        },
        {
          id: "description",
          header: <div>{t("readyForms.table.header.fields.description")}</div>,
          cell: (item) => <span>{item.description}</span>,
        },
        {
          id: "template.name",
          header: (
            <div>{t("readyForms.table.header.fields.attachmentFileName")}</div>
          ),
          cell: (item) => <span>{item.template?.name ?? "-"}</span>,
        },
        {
          id: "datecreated",
          header: <div>{t("readyForms.table.header.fields.dateCreated")}</div>,
          cell: (item) => <span>{ChangeTimeZone(item.createdDate)}</span>,
        },
      ],
    };
  }, [
    filteredReadyForm.length,
    selectDigitalform,
    items,
    paginationProps,
    tablePreferences,
    collectionProps,
    isLoading,
    isFetching,
    setTablePreferences,
    hasPermission,
  ]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
    </div>
  );
}

export default Digitalforms;
