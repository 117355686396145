import { FormProvider, useForm } from "react-hook-form";
import {
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "../redux/api/category/category";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../hooks/useIsLoading";
import { useNavigate } from "react-router";
import InnerAppLayout from "../components/InnerAppLayout";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../components/FormSection";
import RHFTextField from "../components/RHF/RHFTextField";
import { PATH_DASHBOARD } from "../routes/paths";
import RHFTextArea from "../components/RHF/RHFTextArea";
import { CategoryRequest } from "../redux/api/category/types";
import RHFToggle from "../components/RHF/RHFToggle";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export default function EditCategory() {
  const { id: categoryId } = useParams();

  const { data: category, fulfilledTimeStamp } = useGetCategoryQuery(
    !!categoryId
      ? {
          id: categoryId,
        }
      : skipToken,
  );

  const methods = useForm<CategoryRequest>();
  const description = methods.watch("description");
  const [updateCategory, { isLoading: isCreating }] =
    useUpdateCategoryMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();

  useIsLoading(isCreating);
  useEffect(() => {
    if (!!category) {
      methods.setValue("name", category.name ?? "");
      methods.setValue("stateRequired", category.stateRequired ?? "");
      methods.setValue("expirationRequired", category.expirationRequired ?? "");
      methods.setValue("description", category.description ?? "");
    }
  }, [fulfilledTimeStamp, methods, category]);

  const onSubmit = async (data: CategoryRequest) => {
    if (!categoryId) return;

    await notifyWrapper({
      promise: updateCategory({
        id: categoryId,
        name: data?.name,
        description: data?.description,
        stateRequired: data?.stateRequired,
        expirationRequired: data?.expirationRequired,
      }),
      resourceName: "category",
      actionName: "update",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: "Categoires",
              href: PATH_DASHBOARD.manage.categories.list,
            },
            {
              text: "Add",
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Add Category</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(PATH_DASHBOARD.manage.categories.list);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <RHFTextField
                    label={"Name"}
                    name="name"
                    stretch={false}
                    rules={{ required: "This field is required" }}
                  />
                  <RHFToggle
                    label={"Is State Mandatory"}
                    name="stateRequired"
                    stretch={false}
                  />
                  <RHFToggle
                    label={"Is Expiration Mandatory"}
                    name="expirationRequired"
                    stretch={false}
                  />
                  <ExpandableSection
                    headerText="Description"
                    defaultExpanded={!!description}
                  >
                    <RHFTextArea
                      label={"Description"}
                      name="description"
                      stretch={false}
                    />
                  </ExpandableSection>
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
