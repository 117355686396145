// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { Box, Button, Header, PieChart } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllWorkflowQuery } from "../../../redux/api/workflow/workflow";
import { useMemo } from "react";
import {
  useGetAllTaskStatusQuery,
  useGetAllTasksQuery,
} from "../../../redux/api/task";

export const taskStatus: WidgetConfig = {
  title: "Task Status",
  description: "Overview of all your task",
  header: TaskStatusHeader,
  content: TaskStatusContent,
  staticMinHeight: 560,
};

function TaskStatusHeader() {
  return (
    <Header variant="h2" description={<i>{taskStatus.description}</i>}>
      {taskStatus.title}
    </Header>
  );
}

function TaskStatusContent() {
  const { data = [] } = useGetAllTasksQuery();
  return (
    <PieChart
      data={[
        {
          title: "Completed",
          percentage: data?.filter((task) => !!task.completedDate).length ?? 0,
          value: data?.filter((task) => !!task.completedDate).length ?? 0,
        },
        {
          title: "In Progress",
          percentage: data?.filter((task) => !task.completedDate).length ?? 0,
          value: data?.filter((task) => !task.completedDate).length ?? 0,
        },
      ]}
      segmentDescription={(datum, sum) =>
        `${datum.value} tasks, ${((datum.value / sum) * 100).toFixed(0)}%`
      }
      detailPopoverContent={(datum, sum) => [
        {
          key: "Tasks count",
          value: datum.value,
        },
        {
          key: "Percentage",
          value: `${((datum.value / sum) * 100).toFixed(0)}%`,
        },
      ]}
      ariaDescription="Donut chart showing generic example data."
      ariaLabel="Donut chart"
      innerMetricDescription="Total Tasks"
      innerMetricValue={`${data?.length}` ?? "0"}
      size="medium"
      fitHeight
      variant="donut"
      hideFilter
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
    />
  );
}
