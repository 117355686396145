import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Input,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
  BreadcrumbGroup,
  ProgressBar,
} from "@cloudscape-design/components";
import { useEffect, useState, useMemo } from "react";
import "./BulkUpload.css";
import { Accept, useDropzone } from "react-dropzone";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { differenceWith, set } from "lodash";
import prettyBytes from "pretty-bytes";
import { DocumentRequest } from "../../redux/api/document/types";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import { useSaveDocumentMutation } from "../../redux/api/document/document";
import { captureException } from "@sentry/react";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";

dayjs.extend(dayjsPluginUTC);

function BulkUpload({ accept }: { accept?: Accept }) {
  const [selectedItems, setSelectedItems] = useState<DocumentRequest[]>([]);
  const [documents, setDocuments] = useState<DocumentRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtertedDocuments, setFiltertedDocuments] = useState<
    DocumentRequest[]
  >([]);
  const [filterText, setFilterText] = useState<string>("");
  const { data: categories = [] } = useGetAllCategoryQuery();
  const { providerId } = useParams();
  const navigate = useNavigate();
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const { t } = useTranslation();
  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 100,
    accept: accept,
    validator: (file) => {
      const index = documents.findIndex(
        (item) =>
          !!item &&
          compareItems(item, {
            name: file.name,
            file: file,
          }),
      );
      if (index === -1) return null;
      return {
        code: "duplicate-file",
        message: `File Already Selected`,
      };
    },
  });

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  useEffect(() => {
    if (!!filterText) {
      const temp = documents.filter(
        (document) =>
          !!document.name &&
          document.name.toLowerCase().includes(filterText.toLowerCase()),
      );
      setFiltertedDocuments(temp);
    } else setFiltertedDocuments(documents);
  }, [filterText, documents]);

  useEffect(() => {
    if (!!acceptedFiles && !!acceptedFiles.length) {
      const temp = acceptedFiles?.map((acceptedFile) => {
        const doc: DocumentRequest = {
          name: acceptedFile?.name,
          file: acceptedFile,
        };
        return doc;
      });
      setDocuments([...documents, ...temp]);
    }
  }, [acceptedFiles]);

  const { items, collectionProps, paginationProps } = useCollection(
    filtertedDocuments,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="s">
              <b>No files selected to upload.</b>
              <Button onClick={open}>Click to Add Files</Button>
            </SpaceBetween>
          </Box>
        ),
      },
      sorting: {
        defaultState: {
          isDescending: true,
          sortingColumn: {
            sortingField: "createdDate",
          },
        },
      },
      pagination: {
        pageSize: preferences.pageSize,
      },
    },
  );

  const [saveDocument] = useSaveDocumentMutation();

  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();

  const handleSave = async () => {
    if (!providerId || !documents.length) return;
    setIsLoading(true);
    const sortedDocuments = documents.sort(
      (a, b) => (a.file?.size ?? 0) - (b.file?.size ?? 0),
    );

    for (let index = 0; index < sortedDocuments.length; ++index) {
      const document = sortedDocuments[index];
      const notificationId = uuidv4();
      try {
        notifyInProgress({
          name: "",
          action: "uploading",
          content: (
            <ProgressBar
              label="Uploading"
              value={0}
              additionalInfo={`Total files remaining: ${documents.length}`}
              variant="flash"
            />
          ),
          id: notificationId,
        }); // Initial notification

        await saveDocument({ providerId, document })
          .unwrap()
          .then(async (result) => {
            if (document.file && result?.attachment?.key) {
              try {
                await fileAxios.put(
                  getFileUrl(result.attachment.key),
                  document.file,
                  {
                    onUploadProgress: (progressEvent) => {
                      if (!progressEvent.total) return;
                      // Calculate the percentage of upload completed
                      const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                      );
                      // You can also update the state or UI with the progress here
                      notifyInProgress({
                        name: "",
                        action: "uploading",
                        content: (
                          <ProgressBar
                            label="Uploading"
                            description={`File name : ${document.name}`}
                            value={percentCompleted}
                            additionalInfo={`${documents.length - index} files(s) remaining, ${index} files(s) completed.`}
                            variant="flash"
                          />
                        ),
                        id: notificationId,
                      });
                    },
                  },
                );

                notifySucess({
                  name: document.name,
                  action: "uploaded file :",
                  id: notificationId,
                }); // Success notification
              } catch (error) {
                captureException(error);
                // Failure notification for file upload
              }
            }
          })
          .catch((error) => {
            captureException(error);
            if (error.status < 500 && error.status >= 400) {
              notifyFailed({
                name: document.name,
                action: "upload",
                content: error.data.errorMessage,
                id: notificationId,
              });
            } else
              notifyFailed({
                name: document.name,
                action: "upload",
                id: notificationId,
              }); // Failure notification for document save
          });
      } catch (error) {
        captureException(error);
      }
    }
  };

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("providerSafe.content.addNewDocument.header.breadcrumb.text")}`,
            href: "/manageprovider",
          },
          {
            text: `${t("providerSafe.content.addNewDocument.header.breadcrumb.nextPage")}`,
            href: `/safe?providerId=${providerId}`,
          },
          {
            text: displayName,
            href: `/safe?providerId=${providerId}`,
          },
          {
            text: "Bulk upload",
            href: "#",
          },
        ]}
      />
      <Header
        description="Add the files you want to upload"
        actions={
          !!isLoading && (
            <Button
              variant="primary"
              onClick={() => {
                navigate(`/safe?providerId=${providerId}`);
              }}
            >
              Close
            </Button>
          )
        }
      >
        Bulk Upload
      </Header>
      {!isLoading && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Drag & drop file here, or click to select file.</p>
        </div>
      )}
      <Table
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => {
          if (!!detail && !!detail.selectedItems)
            setSelectedItems(detail.selectedItems);
        }}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => item.name,
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.name}
                        onChange={(event) => {
                          setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
          },
          {
            id: "categoryId",
            header: "Category",
            cell: (item) =>
              categories.find(
                (category) => category.id + "" === item?.categoryId + "",
              )?.name ?? "-",
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item.categoryId;
                    return (
                      <Select
                        autoFocus={true}
                        filteringType="auto"
                        expandToViewport={true}
                        selectedOption={{
                          label: categories.find(
                            (category) => category.id + "" === value + "",
                          )?.name,
                          value: categories.find(
                            (category) => category.id + "" === value + "",
                          )?.id,
                        }}
                        onChange={(event) => {
                          setValue(
                            event.detail.selectedOption.value ??
                              item.categoryId,
                          );
                        }}
                        options={categories.map((category) => ({
                          label: category?.name,
                          value: category?.id + "",
                        }))}
                      />
                    );
                  },
                },
          },
          {
            id: "name",
            header: "Name",
            cell: (item) => item.name,
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.name}
                        onChange={(event) => {
                          setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
          },
          {
            id: "size",
            header: "Size",
            cell: (item) =>
              !!item?.file?.size ? prettyBytes(item?.file?.size) : "-",
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => item.notes,
            editConfig: isLoading
              ? undefined
              : {
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.notes}
                        onChange={(event) => {
                          setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
          },
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "categoryId", visible: true },
          { id: "notes", visible: true },
          { id: "size", visible: true },
          { id: "type", visible: true },
        ]}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        wrapLines={true}
        trackBy={(item) => item?.name + item?.file?.size + item?.file?.type}
        stripedRows={true}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => {
              if (!!detail) setPreferences(detail);
            }}
            preferences={preferences}
            pageSizePreference={{
              options: [
                { value: 10, label: "10 items" },
                { value: 30, label: "30 items" },
                { value: 50, label: "50 items" },
              ],
            }}
          />
        }
        {...collectionProps}
        submitEdit={async (editedItem, column, newValue) => {
          let editItemIndex = documents.findIndex((document) =>
            compareItems(document, editedItem),
          );
          if (editItemIndex === -1 && !column.id) return;
          const propertyPath = column.id ?? "";
          set(editedItem, propertyPath, newValue);
          const temp = [...documents];
          temp[editItemIndex] = { ...editedItem };
          setDocuments(temp);
        }}
        filter={
          <TextFilter
            filteringPlaceholder="Find by name"
            filteringText={filterText}
            onChange={({ detail }) => {
              setFilterText(detail.filteringText);
            }}
            countText={
              !!filterText ? `${filtertedDocuments?.length} matches` : ""
            }
          />
        }
        header={
          <Header
            counter={"(" + documents.length.toString() + ")"}
            description="All the files in this table will be uploaded."
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  disabled={!selectedItems || !!isLoading}
                  onClick={() => {
                    if (!!selectedItems && !!selectedItems?.length) {
                      const temp = differenceWith(
                        documents,
                        selectedItems,
                        compareItems,
                      );
                      setDocuments(temp);
                    }
                  }}
                >
                  Remove
                </Button>
                <Button onClick={open} disabled={!!isLoading}>
                  Add Files
                </Button>
                <Button
                  variant="primary"
                  disabled={!!isLoading}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            Files
          </Header>
        }
      />
    </SpaceBetween>
  );
}
export default BulkUpload;

const compareItems = (a: DocumentRequest, b: DocumentRequest) => {
  return (
    a.name === b.name &&
    a?.file?.size === b?.file?.size &&
    a?.file?.type === b?.file?.type
  );
};
