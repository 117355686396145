import { Core } from "@pdftron/webviewer";
import { get } from "lodash";
import { Provider } from "../redux/api/provider/types";
import { captureException } from "@sentry/react";

export const selectValueFromData = (
  data: Provider | undefined,
  annot: Core.Annotations.Annotation,
): string | undefined => {
  let defaultValue = undefined; //annot.getCustomData("name");

  if (!data || !annot.getCustomData("key")) return defaultValue;
  else {
    try {
      let property_path = annot.getCustomData("key")?.split(".");

      let index = annot.getCustomData("index");
      if (!!index) {
        property_path.splice(1, 0, index);
      }

      const value = get(data, property_path, defaultValue);

      if (annot.getCustomData("type") === "bool")
        return value === "true" || value === true ? "On" : "Off";
      else return value;
    } catch (error) {
      captureException(error);
      return defaultValue;
    }
  }
};
