import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllTaskTypeQuery,
  useSaveAllTaskTypeMutation,
} from "../../../redux/api/task";
import { TaskType } from "../../../redux/api/task/types";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function TaskTypeModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllTaskTypeQuery();
  const [taskType, setTaskType] = useState<TaskType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllTaskType] = useSaveAllTaskTypeMutation();

  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...taskType].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllTaskType(tmp),
      resourceName: "Task Type",
      actionName: "save",
    });
  };

  useEffect(() => {
    const tmpItems = !!data ? [...data] : [];
    setTaskType(tmpItems);
  }, [fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>Task Type</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              Cancel
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setTaskType([
                      ...taskType,
                      { id: "", name: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...taskType];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      deleted: true,
                    };
                    setTaskType(tmp);
                  }}
                  items={taskType.filter((item) => item.deleted !== true)}
                  addButtonText={"Add"}
                  definition={[
                    {
                      label: "Name",
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...taskType];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              deleted: item.deleted,
                            };
                            setTaskType(tmp);
                          }}
                          placeholder={"Enter task type name"}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(TaskTypeModal);
