import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetCredentialTemplateQuery,
  useUpdateCredentialTemplateMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { CredentialTemplateRequest } from "../../../redux/api/credentialpackages/types";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { useGetAllLicenseTypesQuery } from "../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router";
import { Grid } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditCredentialTemplateModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<CredentialTemplateRequest>();
  const { t } = useTranslation();
  const { id: credentialTemplateId } = useParams();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );
  const {
    data: credentialTemplate,
    fulfilledTimeStamp,
    isLoading,
  } = useGetCredentialTemplateQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: licenses = [] } = useGetAllLicenseTypesQuery();

  const [updateCredentialTemplate] = useUpdateCredentialTemplateMutation();

  const onSubmit = async (data: CredentialTemplateRequest) => {
    if (!!credentialTemplateId) {
      await notifyWrapper({
        promise: updateCredentialTemplate({
          id: credentialTemplateId,
          ...data,
        }),
        resourceName: "Credential template",
        actionName: "update",
      });
      setModalName("");
    }
  };

  useEffect(() => {
    if (credentialTemplate) {
      methods.setValue("name", credentialTemplate?.name);
      methods.setValue("licenseTypeId", credentialTemplate?.licenseType?.id);
    }
  }, [fulfilledTimeStamp, methods, credentialTemplate]);
  return (
    <Modal
      header={
        <Header>
          {t("credentialTemplates.editCredentialTemplate.header.label")}
        </Header>
      }
      visible={true}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <SpaceBetween size={"s"}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SpaceBetween size={"s"}>
              <FormSection>
                <RHFTextField
                  label={t(
                    "credentialTemplates.editCredentialTemplate.header.fields.name",
                  )}
                  name="name"
                  stretch={false}
                  rules={{ required: "This field is required" }}
                />
                <FormSection>
                  <RHFSelect
                    name="licenseTypeId"
                    label={t(
                      "credentialTemplates.editCredentialTemplate.header.fields.licenseType",
                    )}
                    options={licenses.map((license) => {
                      return {
                        value: license.id,
                        label: license.name,
                      };
                    })}
                    stretch={false}
                  />
                </FormSection>
              </FormSection>

              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    formAction="none"
                    onClick={() => {
                      setModalName("");
                    }}
                  >
                    {t(
                      "credentialTemplates.editCredentialTemplate.header.actions.cancel",
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button formAction="submit" variant="primary">
                    {t(
                      "credentialTemplates.editCredentialTemplate.header.actions.save",
                    )}
                  </Button>
                </Grid>
              </Grid>
            </SpaceBetween>
          </form>
        </FormProvider>
      </SpaceBetween>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditCredentialTemplateModal);
