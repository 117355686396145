import {
  Table,
  TableProps,
  Box,
  Header,
  Button,
  ButtonDropdown,
  Pagination,
} from "@cloudscape-design/components";
import { Category } from "../redux/api/category/types";
import { useState, useMemo } from "react";
import StyledRouterLink from "../components/styled-route-link";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useNavigate } from "react-router";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import {
  CATEGORY_CONTENT_DISPLAY_OPTIONS,
  CATEGORY_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../components/LoadingScreen";
import { PATH_DASHBOARD } from "../routes/paths";
import {
  useGetAllCategoryQuery,
  useDeleteCategoryMutation,
} from "../redux/api/category/category";
import useCategoryFilter from "../hooks/useCategoryFilter";
import CategoryFilters from "../components/filter/category-filters";

export default function ManageCategory() {
  const {
    filteredCategory,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useCategoryFilter();
  const { data = [] } = useGetAllCategoryQuery();
  const [deleteCategory] = useDeleteCategoryMutation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CATEGORY-Table-Preferences",
    CATEGORY_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(
    filteredCategory,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Category"}
            action={() => {
              navigate(`add`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  const tableProps: TableProps<Category> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${data.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedCategory}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission ? "Edit" : "View",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedCategory) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedCategory?.id)
                        navigate(
                          PATH_DASHBOARD.manage.categories.edit(
                            selectedCategory?.id,
                          ),
                        );
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  disabled={!hasPermission}
                  onClick={() => {
                    navigate(`add`);
                  }}
                  variant="primary"
                  data-test="add_caetgory"
                >
                  Add Category
                </Button>
              </span>
            </div>
          }
        >
          Categories
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      filter: <CategoryFilters filter={filter} setFilter={setFilter} />,
      preferences: (
        <TablePreferences
          title="Category Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={CATEGORY_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedCategory(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedCategory(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedCategory ? [selectedCategory] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>Category ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `${item.id}` : `#`}
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <span>{item?.description}</span>,
        },
        {
          id: "stateRequired",
          header: <div>State Required</div>,
          cell: (item) => <span>{item?.stateRequired ? "Yes" : "-"}</span>,
        },
        {
          id: "expirationRequired",
          header: <div>Expiration Required</div>,
          cell: (item) => <span>{item?.expirationRequired ? "Yes" : "-"}</span>,
        },
      ],
    };
  }, [tablePreferences, items, selectedCategory]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!selectedCategory?.id)
            await notifyWrapper({
              promise: deleteCategory({ id: selectedCategory?.id }),
              resourceName: "category",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header="Delete Category"
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedCategory?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this category?"}
      />
    </div>
  );
}
