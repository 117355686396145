import { useParams, useSearchParams } from "react-router-dom";
import useIsLoading from "../../hooks/useIsLoading";
import LoadingScreen from "../../components/LoadingScreen";
import {
  Box,
  BreadcrumbGroup,
  Button,
  ButtonDropdown,
  Container,
  Header,
  SpaceBetween,
  Tabs,
  TabsProps,
} from "@cloudscape-design/components";
import FormSection from "../../components/FormSection";
import KeyValue from "../../components/KeyValue";
import { useEffect, useMemo, useState } from "react";
import {
  useDeleteRoleMutation,
  useGetAllRoleQuery,
  useGetRoleQuery,
  useUpdateRolePermissionsMutation,
} from "../../redux/api/role/role";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_ROLE } from "../../helpers/constants";
import AssociatedFacilities from "../associated-facilities";
import AssociatedProviders from "../associated-providers";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { AssociatedAdmins } from "../associated-admins";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import {
  Role,
  RolePermissions,
  findLowerPermissionKeys,
} from "../../redux/api/role/types";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { remove, uniq } from "lodash";
import PermissionsTabContent from "../manage-permissions/permissions-tab-content";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function RoleDetails({ openModalName }: PropsFromDispatch) {
  const { id: roleId } = useParams();
  const { t } = useTranslation();
  const { data: role, isLoading } = useGetRoleQuery(
    !!roleId
      ? {
          id: roleId,
        }
      : skipToken,
  );
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false),
  );

  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false),
  );

  const [activeTabId, setActiveTabId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [deleteRole] = useDeleteRoleMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  useEffect(() => {
    const searchParam_activeTabId = searchParams.get("activeTabId");
    if (searchParam_activeTabId)
      setActiveTabId(searchParam_activeTabId.toString());
  }, []);
  useIsLoading(isLoading);

  if (!role) return <LoadingScreen isOpen={true} />;
  else
    return (
      <div>
        <SpaceBetween size={"s"}>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("rolesandpermissions.roles.roleDetails.breadcrumb.text")}`,
                href: "/settings/roles",
              },
              {
                text: role.name ?? "",
                href: "#",
              },
            ]}
            ariaLabel="Breadcrumbs"
          />
          <SpaceBetween size={"xxl"}>
            <Container
              header={
                <Header
                  actions={
                    <ButtonDropdown
                      expandableGroups
                      disabled={!hasPermission || role.default}
                      items={[
                        {
                          id: "edit_form",
                          text: `${t("rolesandpermissions.roles.roleDetails.content.header.actions.edit")}`,
                        },
                        {
                          id: "delete_form",
                          disabled: !hasDeletePermission,
                          text: `${t("rolesandpermissions.roles.roleDetails.content.header.actions.delete")}`,
                        },
                      ]}
                      onItemClick={(itemClickDetails) => {
                        const { id } = itemClickDetails.detail;
                        if (id === "delete_form") {
                          if (roleId) {
                            OpenDeleteModal(true);
                          }
                        } else if (id === "edit_form") {
                          openModalName(EDIT_ROLE);
                        }
                      }}
                    >
                      {t(
                        "rolesandpermissions.roles.roleDetails.content.header.actions.roleActions",
                      )}
                    </ButtonDropdown>
                  }
                >
                  {t(
                    "rolesandpermissions.roles.roleDetails.content.header.label",
                  )}
                </Header>
              }
            >
              <FormSection columns={3}>
                <KeyValue
                  label={t(
                    "rolesandpermissions.roles.roleDetails.content.header.fields.roleName",
                  )}
                  value={role.name}
                />
                <KeyValue
                  label={t(
                    "rolesandpermissions.roles.roleDetails.content.header.fields.description",
                  )}
                  value={role.description ?? "-"}
                />
              </FormSection>
            </Container>
            <AssociatedPermissions />
            <AssociatedAdmins admins={role.admins} />
          </SpaceBetween>
        </SpaceBetween>
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (roleId)
              await notifyWrapper({
                promise: deleteRole({ roleId: roleId }),
                resourceName: "role",
                actionName: "delete",
              });
            OpenDeleteModal(false);
          }}
          closeModal={() => OpenDeleteModal(false)}
          header={t("rolesandpermissions.roles.delete.header.label")}
          content={
            <>
              <Box>
                {t("rolesandpermissions.roles.delete.header.content.label")}
              </Box>
              <Box variant="awsui-key-label">{role?.name}</Box>
            </>
          }
          description={t(
            "rolesandpermissions.roles.delete.header.content.description",
          )}
        />
      </div>
    );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(RoleDetails);

export type PermissionsTableProps = {
  roles: RolePermissions[];
  setRoles: React.Dispatch<React.SetStateAction<RolePermissions[]>>;
};

function AssociatedPermissions() {
  const { id: roleId } = useParams();
  const permissions = permissionsData;
  const { t } = useTranslation();
  const [updateRolePermission] = useUpdateRolePermissionsMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: role } = useGetRoleQuery(
    !!roleId
      ? {
          id: roleId,
        }
      : skipToken,
  );
  const { data } = useGetAllRoleQuery();

  const [rolePermissions, setRolePermissions] = useState<RolePermissions[]>([]);

  useEffect(() => {
    if (!!data)
      setRolePermissions(
        data
          ?.filter((role) => role.id + "" === roleId)
          .map((role) => {
            return {
              id: role.id,
              name: role.name,
              default: role.default,
              permissions: role.permissions.map((permission) => permission.key),
            };
          }),
      );
  }, [data, roleId]);

  const onRolePermissionChange = ({
    role,
    key,
    checked,
  }: {
    checked: boolean;
    role: RolePermissions;
    key: string;
  }) => {
    if (!!rolePermissions && !!permissions) {
      let temp_role_permissions = [...rolePermissions];
      let role_index = temp_role_permissions.findIndex(
        (temp_role_permission) => temp_role_permission.id === role.id,
      );

      if (role_index === -1) {
        return;
      } else {
        if (checked) {
          temp_role_permissions[role_index].permissions = uniq([
            ...findLowerPermissionKeys(permissions, key),
            ...temp_role_permissions[role_index].permissions,
          ]);
        } else {
          remove(temp_role_permissions[role_index].permissions, (permission) =>
            findLowerPermissionKeys(permissions, key).includes(permission),
          );
        }
      }
      setRolePermissions(temp_role_permissions);
    }
  };

  const tabs: TabsProps.Tab[] = useMemo(() => {
    if (!rolePermissions) return [];
    else {
      return [
        ...Object.entries(PermissionsGroup).map(([key, permissions]) => {
          return {
            label: key,
            id: key,
            content: (
              <PermissionsTabContent
                rolePermissions={rolePermissions}
                group={key}
                permissions={permissions}
                onRolePermissionChange={onRolePermissionChange}
              />
            ),
          };
        }),
      ];
    }
  }, [rolePermissions]);

  const [activeTabId, setActiveTabId] = useState<string>();

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <Button
              variant="primary"
              disabled={role?.default}
              onClick={async (clickDetails) => {
                // remove owner role
                const temp = rolePermissions.filter(
                  (rolePermission) => !rolePermission.default,
                );
                await notifyWrapper({
                  promise: updateRolePermission(rolePermissions),
                  resourceName: "Permissions",
                  actionName: "update",
                });
              }}
            >
              {t("rolesandpermissions.permissions.header.actions.save")}
            </Button>
          }
        >
          {t("rolesandpermissions.permissions.header.label")}
        </Header>
      }
    >
      <Tabs
        activeTabId={activeTabId}
        onChange={(changeDetail) => {
          setActiveTabId(changeDetail.detail.activeTabId);
        }}
        tabs={tabs}
      />
    </Container>
  );
}

export const permissionsData = [
  { id: "1", key: "provider.view" },
  { id: "2", key: "provider.edit" },
  { id: "3", key: "provider.delete" },
  { id: "4", key: "provider.all" },
  { id: "5", key: "facility.view" },
  { id: "6", key: "facility.edit" },
  { id: "7", key: "facility.delete" },
  { id: "8", key: "facility.all" },
  { id: "9", key: "readyforms.view" },
  { id: "10", key: "readyforms.edit" },
  { id: "11", key: "readyforms.delete" },
  { id: "12", key: "readyforms.all" },
  { id: "13", key: "privileges.view" },
  { id: "14", key: "privileges.edit" },
  { id: "15", key: "privileges.delete" },
  { id: "16", key: "privileges.all" },
  { id: "17", key: "signature.view" },
  { id: "18", key: "signature.request" },
  { id: "19", key: "signature.void" },
  { id: "20", key: "signature.all" },
  { id: "21", key: "contract.view" },
  { id: "22", key: "contract.edit" },
  { id: "23", key: "contract.delete" },
  { id: "24", key: "contract.all" },
  { id: "25", key: "payer.view" },
  { id: "26", key: "payer.edit" },
  { id: "27", key: "payer.delete" },
  { id: "28", key: "payer.all" },
  { id: "29", key: "credentialpackage.view" },
  { id: "30", key: "credentialpackage.edit" },
  { id: "31", key: "credentialpackage.delete" },
  { id: "32", key: "credentialpackage.all" },
  { id: "33", key: "roles.view" },
  { id: "34", key: "roles.edit" },
  { id: "35", key: "roles.delete" },
  { id: "36", key: "roles.all" },
  { id: "37", key: "member.view" },
  { id: "38", key: "member.edit" },
  { id: "39", key: "member.delete" },
  { id: "40", key: "member.all" },
  { id: "41", key: "reports.view" },
  { id: "42", key: "support.all" },
  { id: "43", key: "organization_settings.view" },
  { id: "44", key: "organization_settings.edit" },
];

const PermissionsGroup = {
  Provider: [
    {
      id: "1",
      key: "provider.view",
    },
    {
      id: "2",
      key: "provider.edit",
    },
    {
      id: "3",
      key: "provider.delete",
    },
    {
      id: "4",
      key: "provider.all",
    },
  ],
  Facility: [
    {
      id: "5",
      key: "facility.view",
    },
    {
      id: "6",
      key: "facility.edit",
    },
    {
      id: "7",
      key: "facility.delete",
    },
    {
      id: "8",
      key: "facility.all",
    },
  ],
  "Ready Forms": [
    {
      id: "9",
      key: "readyforms.view",
    },
    {
      id: "10",
      key: "readyforms.edit",
    },
    {
      id: "11",
      key: "readyforms.delete",
    },
    {
      id: "12",
      key: "readyforms.all",
    },
  ],
  Privileges: [
    {
      id: "13",
      key: "privileges.view",
    },
    {
      id: "14",
      key: "privileges.edit",
    },
    {
      id: "15",
      key: "privileges.delete",
    },
    {
      id: "16",
      key: "privileges.all",
    },
  ],
  Signature: [
    {
      id: "17",
      key: "signature.view",
    },
    {
      id: "18",
      key: "signature.request",
    },
    {
      id: "19",
      key: "signature.void",
    },
    {
      id: "20",
      key: "signature.all",
    },
  ],
  Contract: [
    {
      id: "21",
      key: "contract.view",
    },
    {
      id: "22",
      key: "contract.edit",
    },
    {
      id: "23",
      key: "contract.delete",
    },
    {
      id: "24",
      key: "contract.all",
    },
  ],
  Payer: [
    {
      id: "25",
      key: "payer.view",
    },
    {
      id: "26",
      key: "payer.edit",
    },
    {
      id: "27",
      key: "payer.delete",
    },
    {
      id: "28",
      key: "payer.all",
    },
  ],
  "Credential Packages": [
    {
      id: "29",
      key: "credentialpackage.view",
    },
    {
      id: "30",
      key: "credentialpackage.edit",
    },
    {
      id: "31",
      key: "credentialpackage.delete",
    },
    {
      id: "32",
      key: "credentialpackage.all",
    },
  ],
  Roles: [
    {
      id: "33",
      key: "roles.view",
    },
    {
      id: "34",
      key: "roles.edit",
    },
    {
      id: "35",
      key: "roles.delete",
    },
    {
      id: "36",
      key: "roles.all",
    },
  ],
  Member: [
    {
      id: "37",
      key: "member.view",
    },
    {
      id: "38",
      key: "member.edit",
    },
    {
      id: "39",
      key: "member.delete",
    },
    {
      id: "40",
      key: "member.all",
    },
  ],
  Reports: [
    {
      id: "41",
      key: "reports.view",
    },
  ],
  Support: [
    {
      id: "42",
      key: "support.all",
    },
  ],
  "Organization Settings": [
    {
      id: "43",
      key: "organization_settings.view",
    },
    {
      id: "44",
      key: "organization_settings.edit",
    },
  ],
};
