// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import Header from "@cloudscape-design/components/header";
import PieChart from "@cloudscape-design/components/pie-chart";
import { Box } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import {
  colorChartsPaletteCategorical40,
  colorChartsPaletteCategorical41,
  colorChartsStatusPositive,
} from "@cloudscape-design/design-tokens";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";
import { useTranslation } from "react-i18next";

export const eSignatureStatus: WidgetConfig = {
  title: "e-Signatures Status",
  description: "Overview of all your eSignatures",
  header: eSignatureStatusHeader,
  content: ESignatureStatusWidget,
};

function eSignatureStatusHeader() {
  return <Header variant="h2">{eSignatureStatus.title}</Header>;
}

function ESignatureStatusWidget() {
  const { data } = useGetAllDashboardQuery();
  const { t } = useTranslation();
  return (
    <PieChart
      data={[
        {
          title: "Completed",
          value: data?.esignatureStatus?.completed ?? 0,
          color: colorChartsStatusPositive,
        },
        {
          title: "Pending",
          color: colorChartsPaletteCategorical40,
          value: data?.esignatureStatus?.pending ?? 0,
        },
        // {
        //   title: "Voided",
        //   color: colorChartsPaletteCategorical41,
        //   value: data?.esignatureStatus?.voided ?? 0,
        // },
      ]}
      detailPopoverContent={(datum, sum) => [
        { key: "Count", value: datum.value },
        {
          key: "Percentage",
          value: `${((datum.value / sum) * 100).toFixed(0)}%`,
        },
      ]}
      segmentDescription={(datum, sum) =>
        `${((datum.value / sum) * 100).toFixed(0)}% (${datum.value})`
      }
      ariaDescription={t("dashboard.eSignatures.ariaDescription")}
      ariaLabel="Pie chart"
      hideFilter
      empty={
        <Box textAlign="center" color="inherit">
          <b>{t("dashboard.eSignatures.empty")}</b>
          <Box variant="p" color="inherit">
            {t("dashboard.eSignatures.emptyDescription")}
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>{t("dashboard.eSignatures.noMatch")}</b>
          <Box variant="p" color="inherit">
            {t("dashboard.eSignatures.noMatchDescription")}
          </Box>
        </Box>
      }
    />
  );
}
