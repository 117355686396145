import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import Input, { InputProps } from "@cloudscape-design/components/input";
import {
  useFormContext,
  Controller,
  RegisterOptions,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { Rifm } from "rifm";
import { AsYouType } from "libphonenumber-js";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  type?: InputProps.Type;
  defaultValue?: any;
  readOnly?: boolean;
  testid?: string;
  placeholder?: string;
  hideAsterisk?: boolean;
};

type RFimProps = {
  accept?: RegExp;
  format?: (v: string) => string;
  replace?: (str: string) => string;
};

type Props = IProps & FormFieldProps & RFimProps;

export default function RHFPhoneNumber({
  name,
  rules,
  label,
  errorText,
  description,
  defaultValue,
  stretch,
  type,
  readOnly,
  placeholder,
  accept,
  testid,
  hideAsterisk = false,
  format = defaultFormat,
  replace,
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <FormField
            label={
              <span>
                {label}{" "}
                {rules?.required && !!label && !hideAsterisk && (
                  <span style={{ color: "#fc0a37" }}>*</span>
                )}
              </span>
            }
            /*  info={
                description && (
                  <span>
                    <i>{description}</i>
                  </span>
                )
              } */
            description={<i>{description}</i>}
            errorText={errorText ?? getErrorMessage(fieldState.error)}
            stretch={stretch ?? true}
          >
            <Rifm
              accept={accept}
              format={format}
              replace={replace}
              value={field.value}
              onChange={(str) => {
                field.onChange(str);
              }}
            >
              {({ value: rifm_value, onChange }) => (
                <Input
                  type={type}
                  value={new AsYouType("US").input(rifm_value ?? "")}
                  readOnly={readOnly}
                  data-testid={testid}
                  onChange={(changeDetail) => {
                    field.onChange(changeDetail.detail.value);
                  }}
                  placeholder={placeholder}
                />
              )}
            </Rifm>
          </FormField>
        );
      }}
      rules={{ maxLength: 256, ...rules }}
    />
  );
}
const getErrorMessage = (error?: FieldError | undefined) => {
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};
const defaultFormat = (v: string) => v;
