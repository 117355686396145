import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useWorkflowFilter";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllWorkflowStatusQuery } from "../../redux/api/workflow/workflow";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";

interface WorkflowFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function WorkflowFilters({
  filter,
  setFilter,
  countText,
}: WorkflowFiltersProps) {
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { data: workFlowStatus = [] } = useGetAllWorkflowStatusQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Name",
    value: "name",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.providerFilterOptions &&
      filter.providerFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Provider = ${join(
            filter.providerFilterOptions.map(
              (providerFilterOptions) => providerFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (!!filter.statusFilterOptions && filter.statusFilterOptions.length > 0)
      temp = [
        ...temp,
        {
          label: `Status = ${join(
            filter.statusFilterOptions.map(
              (statusFilterOption) => statusFilterOption?.label,
            ),
            ", ",
          )}`,
        },
      ];
    if (
      !!filter.assignedToFilterOptions &&
      filter.assignedToFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Assigned To = ${join(
            filter.assignedToFilterOptions.map(
              (assignedToFilterOptions) => assignedToFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterWorkflowName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Name", value: "name" },
            { label: "Assigned To", value: "assignedTo" },
            { label: "Provider", value: "provider" },
            { label: "Status", value: "status" },
          ]}
        />
        <div>
          {selectedProperty.value === "name" && (
            <TextFilter
              filteringPlaceholder="Find Name"
              filteringText={filter.filterWorkflowName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterWorkflowName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "provider" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.providerFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  providerFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={provider.map((provider) => ({
                label: provider.firstName + " " + provider.lastName,
                value: provider.id + "",
              }))}
              placeholder="Choose Providers"
            />
          )}
          {selectedProperty.value === "assignedTo" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.assignedToFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  assignedToFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={members.map((member) => ({
                label: member.firstName + " " + member.lastName,
                value: member.id,
              }))}
              placeholder="Choose Assigned To"
            />
          )}
          {selectedProperty.value === "status" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.statusFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  statusFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={workFlowStatus.map((status) => ({
                label: status.name,
                value: status.id,
              }))}
              placeholder="Choose Status"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Provider")) {
            setFilter({
              ...filter,
              providerFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Status")
          ) {
            setFilter({
              ...filter,
              statusFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Assigned To")
          ) {
            setFilter({
              ...filter,
              assignedToFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
