import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useContractFilters";
import { useGetAllContractTypesQuery } from "../../redux/api/contracts/contract";

interface ContractFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function ContractFilters({
  filter,
  setFilter,
  countText,
}: ContractFiltersProps) {
  const { data: contractType = [] } = useGetAllContractTypesQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Company",
    value: "company",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.contractTypeFilterOptions &&
      filter.contractTypeFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Contract Type = ${join(
            filter.contractTypeFilterOptions.map(
              (contractTypeFilterOptions) => contractTypeFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterCompanyName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Company", value: "company" },
            { label: "Contract Type", value: "contractType" },
          ]}
        />
        <div>
          {selectedProperty.value === "company" && (
            <TextFilter
              filteringPlaceholder="Find Company"
              filteringText={filter.filterCompanyName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterCompanyName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "contractType" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter.contractTypeFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  contractTypeFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={contractType.map((type) => ({
                label: type.type,
                value: type.id + "",
              }))}
              placeholder="Choose Contract Type"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Contract Type")
          ) {
            setFilter({
              ...filter,
              contractTypeFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
