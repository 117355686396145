import { remove, uniq } from "lodash";
import { useUpdateRolePermissionsMutation } from "../../redux/api/role/role";
import { useMemo, useState } from "react";
import {
  Button,
  Container,
  Header,
  Tabs,
  TabsProps,
} from "@cloudscape-design/components";
import PermissionsTabContent from "./permissions-tab-content";
import {
  RolePermissions,
  findLowerPermissionKeys,
} from "../../redux/api/role/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";

export type PermissionsTableProps = {
  roles: RolePermissions[];
  setRoles: React.Dispatch<React.SetStateAction<RolePermissions[]>>;
};
export default function PermissionsTable({
  roles: rolePermissions,
  setRoles,
}: PermissionsTableProps) {
  // const { data: permissions, isLoading } = useGetAllPermissionsQuery();
  const permissions = permissionsData;
  const { t } = useTranslation();
  const [updateRolePermission, { isLoading: isUpdating }] =
    useUpdateRolePermissionsMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const onRolePermissionChange = ({
    role,
    key,
    checked,
  }: {
    checked: boolean;
    role: RolePermissions;
    key: string;
  }) => {
    if (!!rolePermissions && !!permissions) {
      let temp_role_permissions = [...rolePermissions];
      let role_index = temp_role_permissions.findIndex(
        (temp_role_permission) => role.id === temp_role_permission.id,
      );
      if (role_index === -1) return;
      if (!!checked) {
        temp_role_permissions[role_index].permissions = uniq([
          ...findLowerPermissionKeys(permissions, key),
          ...temp_role_permissions[role_index].permissions,
        ]);
      } else {
        remove(temp_role_permissions[role_index].permissions, (permission) =>
          findLowerPermissionKeys(permissions, key).includes(permission),
        );
      }
      setRoles(temp_role_permissions);
    }
  };

  const tabs: TabsProps.Tab[] = useMemo(() => {
    if (!rolePermissions) return [];
    else {
      return [
        ...Object.entries(PermissionsGroup).map(([key, permissions]) => {
          return {
            label: key,
            id: key,
            content: (
              <PermissionsTabContent
                rolePermissions={rolePermissions}
                group={key}
                permissions={permissions}
                onRolePermissionChange={onRolePermissionChange}
              />
            ),
          };
        }),
      ];
    }
  }, [rolePermissions]);

  const [activeTabId, setActiveTabId] = useState<string>();

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <Button
              variant="primary"
              onClick={async (clickDetails) => {
                // remove owner role
                const temp = rolePermissions.filter(
                  (rolePermission) => !rolePermission.default,
                );
                await notifyWrapper({
                  promise: updateRolePermission(temp),
                  resourceName: "Permissions",
                  actionName: "update",
                });
              }}
            >
              {t("rolesandpermissions.permissions.header.actions.save")}
            </Button>
          }
        >
          {t("rolesandpermissions.permissions.header.label")}
        </Header>
      }
    >
      <Tabs
        activeTabId={activeTabId}
        onChange={(changeDetail) => {
          setActiveTabId(changeDetail.detail.activeTabId);
        }}
        tabs={tabs}
      />
    </Container>
  );
}

export const permissionsData = [
  { id: "1", key: "provider.view" },
  { id: "2", key: "provider.edit" },
  { id: "3", key: "provider.delete" },
  { id: "4", key: "provider.all" },
  { id: "5", key: "facility.view" },
  { id: "6", key: "facility.edit" },
  { id: "7", key: "facility.delete" },
  { id: "8", key: "facility.all" },
  { id: "9", key: "readyforms.view" },
  { id: "10", key: "readyforms.edit" },
  { id: "11", key: "readyforms.delete" },
  { id: "12", key: "readyforms.all" },
  { id: "13", key: "privileges.view" },
  { id: "14", key: "privileges.edit" },
  { id: "15", key: "privileges.delete" },
  { id: "16", key: "privileges.all" },
  { id: "17", key: "signature.view" },
  { id: "18", key: "signature.request" },
  { id: "19", key: "signature.void" },
  { id: "20", key: "signature.all" },
  { id: "21", key: "contract.view" },
  { id: "22", key: "contract.edit" },
  { id: "23", key: "contract.delete" },
  { id: "24", key: "contract.all" },
  { id: "25", key: "payer.view" },
  { id: "26", key: "payer.edit" },
  { id: "27", key: "payer.delete" },
  { id: "28", key: "payer.all" },
  { id: "29", key: "credentialpackage.view" },
  { id: "30", key: "credentialpackage.edit" },
  { id: "31", key: "credentialpackage.delete" },
  { id: "32", key: "credentialpackage.all" },
  { id: "33", key: "roles.view" },
  { id: "34", key: "roles.edit" },
  { id: "35", key: "roles.delete" },
  { id: "36", key: "roles.all" },
  { id: "37", key: "member.view" },
  { id: "38", key: "member.edit" },
  { id: "39", key: "member.delete" },
  { id: "40", key: "member.all" },
  { id: "41", key: "reports.view" },
  { id: "42", key: "support.all" },
  { id: "43", key: "organization_settings.view" },
  { id: "44", key: "organization_settings.edit" },
  { id: "45", key: "task.view" },
  { id: "46", key: "task.edit" },
  { id: "47", key: "task.delete" },
  { id: "48", key: "task.all" },
  { id: "49", key: "workflow.view" },
  { id: "50", key: "workflow.edit" },
  { id: "51", key: "workflow.delete" },
  { id: "52", key: "workflow.all" },
  { id: "53", key: "ssn.view" },
  { id: "54", key: "ssn.edit" },
];

const PermissionsGroup = {
  Provider: [
    {
      id: "1",
      key: "provider.view",
    },
    {
      id: "2",
      key: "provider.edit",
    },
    {
      id: "3",
      key: "provider.delete",
    },
    {
      id: "4",
      key: "provider.all",
    },
  ],
  Facility: [
    {
      id: "5",
      key: "facility.view",
    },
    {
      id: "6",
      key: "facility.edit",
    },
    {
      id: "7",
      key: "facility.delete",
    },
    {
      id: "8",
      key: "facility.all",
    },
  ],
  "Ready Forms": [
    {
      id: "9",
      key: "readyforms.view",
    },
    {
      id: "10",
      key: "readyforms.edit",
    },
    {
      id: "11",
      key: "readyforms.delete",
    },
    {
      id: "12",
      key: "readyforms.all",
    },
  ],
  Privileges: [
    {
      id: "13",
      key: "privileges.view",
    },
    {
      id: "14",
      key: "privileges.edit",
    },
    {
      id: "15",
      key: "privileges.delete",
    },
    {
      id: "16",
      key: "privileges.all",
    },
  ],
  Signature: [
    {
      id: "17",
      key: "signature.view",
    },
    {
      id: "18",
      key: "signature.request",
    },
    {
      id: "19",
      key: "signature.void",
    },
    {
      id: "20",
      key: "signature.all",
    },
  ],
  Contract: [
    {
      id: "21",
      key: "contract.view",
    },
    {
      id: "22",
      key: "contract.edit",
    },
    {
      id: "23",
      key: "contract.delete",
    },
    {
      id: "24",
      key: "contract.all",
    },
  ],
  Payer: [
    {
      id: "25",
      key: "payer.view",
    },
    {
      id: "26",
      key: "payer.edit",
    },
    {
      id: "27",
      key: "payer.delete",
    },
    {
      id: "28",
      key: "payer.all",
    },
  ],
  "Credential Packages": [
    {
      id: "29",
      key: "credentialpackage.view",
    },
    {
      id: "30",
      key: "credentialpackage.edit",
    },
    {
      id: "31",
      key: "credentialpackage.delete",
    },
    {
      id: "32",
      key: "credentialpackage.all",
    },
  ],
  Task: [
    {
      id: "45",
      key: "task.view",
    },
    {
      id: "46",
      key: "task.edit",
    },
    {
      id: "47",
      key: "task.delete",
    },
    {
      id: "48",
      key: "task.all",
    },
  ],
  Workflow: [
    {
      id: "49",
      key: "workflow.view",
    },
    {
      id: "50",
      key: "workflow.edit",
    },
    {
      id: "51",
      key: "workflow.delete",
    },
    {
      id: "52",
      key: "workflow.all",
    },
  ],
  Roles: [
    {
      id: "33",
      key: "roles.view",
    },
    {
      id: "34",
      key: "roles.edit",
    },
    {
      id: "35",
      key: "roles.delete",
    },
    {
      id: "36",
      key: "roles.all",
    },
  ],
  Member: [
    {
      id: "37",
      key: "member.view",
    },
    {
      id: "38",
      key: "member.edit",
    },
    {
      id: "39",
      key: "member.delete",
    },
    {
      id: "40",
      key: "member.all",
    },
  ],
  Reports: [
    {
      id: "41",
      key: "reports.view",
    },
  ],
  "Organization Settings": [
    {
      id: "43",
      key: "organization_settings.view",
    },
    {
      id: "44",
      key: "organization_settings.edit",
    },
  ],
  "Confidential Info": [
    {
      id: "53",
      key: "ssn.view",
    },
    {
      id: "54",
      key: "ssn.edit",
    },
  ],
};
