import { Checkbox } from "@cloudscape-design/components";
import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import { useFormContext, Controller } from "react-hook-form";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  defaultValue?: string;
  stringfy?: boolean;
  disabled?: boolean;
  rules?: { required: string | boolean };
  testid?: string;
};

type Props = IProps & FormFieldProps;

export default function RHFCheckBox({
  name,
  disabled,
  rules,
  label,
  defaultValue,
  description,
  errorText,
  stretch,
  stringfy = true,
  testid,
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <FormField
            errorText={errorText ?? fieldState.error?.message}
            stretch={stretch ?? true}
          >
            <Checkbox
              data-testid={testid}
              checked={field.value === "true" || field.value === true}
              onChange={(changeDetail) => {
                if (stringfy)
                  field.onChange(changeDetail.detail.checked.toString());
                else field.onChange(changeDetail.detail.checked);
              }}
              description={description}
              disabled={disabled}
            >
              <b>{label}</b>
            </Checkbox>
          </FormField>
        );
      }}
      rules={rules}
    />
  );
}
