import { FormProvider, useForm } from "react-hook-form";
import { ProviderLiabilityInsuranceRequestType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useTranslation } from "react-i18next";
import { Countries, USStatesList } from "../../../../helpers/constants";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";

export default function EditLiabilityInsurance() {
  const { t } = useTranslation();
  const methods = useForm<ProviderLiabilityInsuranceRequestType>();
  const { providerId, liabilityinsuranceId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderLiabilityInsuranceRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderLiabilityInsurance({
          providerId: providerId,
          ...data,
          ...(selectedDocument.value !== "-1" && {
            documentId: selectedDocument?.value,
          }),
        }),
        resourceName: "liability insurance",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderLiabilityInsurance] =
    useAddProviderLiabilityInsuranceMutation();

  useEffect(() => {
    if (provider && provider.providerLiabilityInsurances) {
      const liabilityinsurance = provider.providerLiabilityInsurances.find(
        (liabilityinsurance) =>
          liabilityinsurance?.id + "" === liabilityinsuranceId + "",
      );

      if (liabilityinsurance) {
        methods.setValue("id", liabilityinsurance.id);
        methods.setValue("isCurrent", liabilityinsurance?.isCurrent);
        methods.setValue("name", liabilityinsurance?.name);
        methods.setValue("policyNumber", liabilityinsurance?.policyNumber);
        methods.setValue("selfInsured", liabilityinsurance?.selfInsured);
        methods.setValue("effectiveDate", liabilityinsurance?.effectiveDate);
        methods.setValue("expirationDate", liabilityinsurance?.expirationDate);
        methods.setValue("email", liabilityinsurance?.email);
        methods.setValue("phone", liabilityinsurance.phone);
        methods.setValue("fax", liabilityinsurance?.fax);
        methods.setValue("typeOfCoverage", liabilityinsurance?.typeOfCoverage);
        methods.setValue(
          "timeWithCurrentCarrierValue",
          liabilityinsurance?.timeWithCurrentCarrierValue,
        );
        methods.setValue(
          "timeWithCurrentCarrierUnit",
          liabilityinsurance?.timeWithCurrentCarrierUnit,
        );
        methods.setValue(
          "coveragePerOccurrence",
          liabilityinsurance?.coveragePerOccurrence,
        );
        methods.setValue(
          "aggregateCoverage",
          liabilityinsurance?.aggregateCoverage,
        );
        methods.setValue("addressLane1", liabilityinsurance?.addressLane1);
        methods.setValue("addressLane2", liabilityinsurance?.addressLane2);
        methods.setValue("city", liabilityinsurance?.city);
        methods.setValue("state", liabilityinsurance?.state);
        methods.setValue("zip", liabilityinsurance?.zip);
        methods.setValue("country", liabilityinsurance?.country);
        liabilityinsurance?.document &&
          setSelectedDocument({
            label: liabilityinsurance?.document?.name,
            value: liabilityinsurance?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, liabilityinsuranceId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.liabilityInsurance.editLiabilityInsurance.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=LiabilityInsurance`,
            },
            {
              text: `${t("providerProfiles.liabilityInsurance.editLiabilityInsurance.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.name",
                        )}
                        name="name"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.policyNumber",
                        )}
                        name="policyNumber"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.effectiveDate",
                        )}
                        name="effectiveDate"
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.expirationDate",
                        )}
                        name="expirationDate"
                      />
                      <RHFSelect
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.typeOfCoverage",
                        )}
                        name="typeOfCoverage"
                        options={["Individual", "Shared"].map((type) => ({
                          value: type,
                        }))}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.timeWithCurrentCarrierValue",
                        )}
                        name="timeWithCurrentCarrierValue"
                        stretch
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.timeWithCurrentCarrierUnit",
                        )}
                        name="timeWithCurrentCarrierUnit"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.coveragePerOccurrence",
                        )}
                        name="coveragePerOccurrence"
                        stretch
                      />

                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.aggregateCoverage",
                        )}
                        name="aggregateCoverage"
                        stretch
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.email",
                        )}
                        name="email"
                        rules={{
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email",
                          },
                        }}
                      />
                      <RHFPhoneNumber
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.phone",
                        )}
                        name="phone"
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.fax",
                        )}
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.addressLane1",
                        )}
                        name="addressLane1"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.addressLane2",
                        )}
                        name="addressLane2"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.city",
                        )}
                        name="city"
                        stretch
                      />
                      <RHFSelect
                        name="state"
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.state",
                        )}
                        options={USStatesList.map((state) => ({
                          value: state.value,
                        }))}
                        stretch
                      />
                      <RHFSelect
                        name="country"
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.country",
                        )}
                        options={Countries.map((country) => ({
                          value: country.value,
                        }))}
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.zipcode",
                        )}
                        name="zip"
                        stretch
                      />

                      <div style={{ paddingTop: "12px" }}>
                        <RHFCheckBox
                          label={t(
                            "providerProfiles.liabilityInsurance.editLiabilityInsurance.header.fields.selfInsured",
                          )}
                          name="selfInsured"
                          stretch
                        />
                      </div>
                    </FormSection>
                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
