import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import ChangeTimeZone from "../../components/Timezone";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useDeletePsvMutation,
  useGetAllDocumentPsvQuery,
} from "../../redux/api/document/document";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import { useEffect, useMemo, useState } from "react";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../common/localStorage";
import { Psv } from "../../redux/api/document/types";
import {
  PSV_CONTENT_DISPLAY_OPTIONS,
  PSV_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { ADD_AUTO_PSV, EDIT_PSV } from "../../helpers/constants";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}
function PSVTable({ openModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { providerId, documentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );

  const {
    data: psv = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllDocumentPsvQuery(documentId ? { documentId } : skipToken);

  const [selectedItem, setSelectedItem] = useState<Psv>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deletePsv] = useDeletePsvMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Psv-Table-Preferences",
    PSV_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(psv, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <TableEmptyState
          resourceName={t(
            "providerSafe.content.details.psv.table.header.resourceName",
          )}
          action={() => {
            openModalName(ADD_AUTO_PSV);
          }}
        />
      ),
    },
    pagination: { pageSize: tablePreferences.pageSize },
  });
  useEffect(() => {
    if (!searchParams.has("psv")) return;
    const temp = psv.find((a) => "" + a.id === "" + searchParams.get("psv"));
    if (temp) setSelectedItem(temp);
  }, [psv, searchParams, selectedItem]);

  const tableProps: TableProps<Psv> = useMemo(() => {
    return {
      header: (
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                variant="icon"
                formAction="none"
                onClick={() => {
                  refetch();
                }}
              />
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission
                      ? `${t("providerSafe.content.details.psv.table.header.actions.edit")}`
                      : `${t("providerSafe.content.details.psv.table.header.actions.view")}`,
                  },
                  {
                    id: "delete_form",
                    text: `${t("providerSafe.content.details.psv.table.header.actions.delete")}`,
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  if (
                    itemClickDetails.detail.id === "delete_form" &&
                    providerId &&
                    documentId
                  )
                    OpenDeleteModal(true);
                  if (
                    itemClickDetails.detail.id === "edit_form" &&
                    providerId &&
                    documentId
                  )
                    openModalName(EDIT_PSV);
                }}
              >
                {t(
                  "providerSafe.content.details.psv.table.header.actions.psvActions",
                )}
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={(details) => {
                  openModalName(ADD_AUTO_PSV);
                }}
              >
                {t(
                  "providerSafe.content.details.psv.table.header.actions.addVerification",
                )}
              </Button>
            </SpaceBetween>
          }
        >
          {t("providerSafe.content.details.psv.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="PSV Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PSV_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      stickyHeader: true,
      loading: isFetching || isLoading,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        const temp = detail.selectedItems && detail.selectedItems.at(0)?.id;
        if (!!temp) setSearchParams({ psv: temp });
      },
      onRowClick: ({ detail }) => {
        const temp = detail.item?.id;
        if (!!temp) setSearchParams({ psv: temp });
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>
              {t("providerSafe.content.details.psv.table.header.fields.id")}
            </div>
          ),
          cell: (item) => item.id || "-",
        },
        {
          id: "primarySource",
          header: (
            <div>
              {t(
                "providerSafe.content.details.psv.table.header.fields.primarySource",
              )}
            </div>
          ),
          cell: (item) => (
            <Link
              onFollow={() => {
                openModalName(EDIT_PSV);
              }}
            >
              {item.primarySource || "-"}
            </Link>
          ),
          isRowHeader: true,
        },
        {
          id: "sourceType",
          header: (
            <div>
              {t(
                "providerSafe.content.details.psv.table.header.fields.sourceType",
              )}
            </div>
          ),
          cell: (item) => item.sourceType,
          sortingField: "sourceType",
        },
        {
          id: "status",
          header: (
            <div>
              {t("providerSafe.content.details.psv.table.header.fields.status")}
            </div>
          ),
          cell: (item) => <PSVStatus status={item.status} />,
        },
        {
          id: "expirationDate",
          header: (
            <div>
              {t(
                "providerSafe.content.details.psv.table.header.fields.expirationDate",
              )}
            </div>
          ),
          cell: (item) => <div>{ChangeTimeZone(item.expiration) || "-"}</div>,
        },
        {
          id: "alertDays",
          header: (
            <div>
              {t(
                "providerSafe.content.details.psv.table.header.fields.alertDays",
              )}
            </div>
          ),
          cell: (item) => (!!item.alertDays ? item.alertDays + " days" : "-"),
        },
        {
          id: "attachmentName",
          header: (
            <div>
              {t(
                "providerSafe.content.details.psv.table.header.fields.attachmentName",
              )}
            </div>
          ),
          cell: (item) => item?.attachment?.name || "-",
        },
        {
          id: "createdDate",
          header: (
            <div>
              {t(
                "providerSafe.content.details.psv.table.header.fields.createdDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item.createdDate) || "-"}</span>
          ),
        },
      ],
    };
  }, [selectedItem, psv, tablePreferences, items]);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (documentId && selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePsv({
                documentId: documentId,
                psvId: selectedItem?.id,
              }),
              resourceName: "Verification",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("providerSafe.content.details.psv.delete.header.label")}
        content={
          <>
            <Box>
              {t(
                "providerSafe.content.details.psv.delete.header.content.label",
              )}
            </Box>
            <Box variant="awsui-key-label">{selectedItem?.sourceType}</Box>
          </>
        }
        description={t(
          "providerSafe.content.details.psv.delete.header.content.description",
        )}
      />
    </>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(PSVTable);

function PSVStatus({ status }: { status: string }) {
  if (status === "in progress")
    return <StatusIndicator type="in-progress">In progress</StatusIndicator>;
  else if (status === "Success")
    return <StatusIndicator>Success</StatusIndicator>;
  else if (status === "Failed")
    return <StatusIndicator type="error">Failed</StatusIndicator>;
  else return <Badge color="blue">{status}</Badge>;
}
