import { FormProvider, useForm } from "react-hook-form";
import { ProviderAffiliationTypeRequest } from "../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import FormSection from "../../components/FormSection";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderAffiliationMutation,
  useGetAllAffiliationStatusQuery,
  useGetAllProvidersQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import {
  AFFILIATION_STATUS,
  AFFILIATION_TYPE,
  alertReminderOptions,
} from "../../helpers/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import DocumentSection from "../../provider/provider-profile/document-section";
import RHFPhoneNumber from "../../components/RHF/RHFPhoneNumber";
import { useGetAllAffiliationTypeQuery } from "../../redux/api/affiliationType/affiliation";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useFindAffiliationByIdQuery } from "../../redux/api/affiliation/affiliation";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddAffiliation({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<ProviderAffiliationTypeRequest>();
  const { affiliationId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllAffiliationStatusQuery();
  const { data: type = [] } = useGetAllAffiliationTypeQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const onSubmit = async (data: ProviderAffiliationTypeRequest) => {
    await notifyWrapper({
      promise: addProviderAffiliation({
        ...data,
        documentId:
          selectedDocument.value !== "-1" ? selectedDocument.value : "",
      }),
      resourceName: "affiliation",
      actionName: "upload",
    });
    navigate(-1);
  };

  const { data: affiliation, fulfilledTimeStamp } = useFindAffiliationByIdQuery(
    affiliationId ? { affiliationId } : skipToken,
  );

  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const { data: facility = [] } = useGetFacilitiesQuery();
  const [addProviderAffiliation] = useAddProviderAffiliationMutation();

  useEffect(() => {
    if (affiliation) {
      methods.setValue("id", affiliation.id);
      methods.setValue("name", affiliation?.name);
      methods.setValue("startDate", affiliation?.startDate);
      methods.setValue("endDate", affiliation?.endDate);
      methods.setValue("facilityId", affiliation?.facility?.id);
      methods.setValue(
        "affiliationStatusId",
        affiliation?.affiliationStatus?.id,
      );
      methods.setValue("affiliationTypeId", affiliation?.affiliationType?.id);
      methods.setValue("email", affiliation?.email);
      methods.setValue("phone", affiliation?.phone);
      methods.setValue("fax", affiliation?.fax);
      methods.setValue("alertDays", affiliation?.alertDays);
      methods.setValue("documentId", affiliation?.document?.id);
      methods.setValue("providerId", affiliation?.provider?.id);
      affiliation?.document &&
        setSelectedDocument({
          label: affiliation?.document?.name,
          value: affiliation?.document?.id,
        });
    }
  }, [fulfilledTimeStamp, affiliationId]);

  return (
    <div>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("affiliations.editAffiliation.header.label")}`,
              href: `/affiliations`,
            },
            {
              text: `${t("affiliations.editAffiliation.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("affiliations.editAffiliation.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "affiliations.editAffiliation.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "affiliations.editAffiliation.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={2}>
                      <RHFFloatingSelect
                        name="affiliationTypeId"
                        label={t(
                          "affiliations.editAffiliation.header.fields.affiliationType.label",
                        )}
                        options={type?.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                        stretch={false}
                        action={{
                          label: `${t("affiliations.editAffiliation.header.fields.affiliationType.actions.modify")}`,
                          onClick: () => {
                            setModalName(AFFILIATION_TYPE);
                          },
                        }}
                      />
                      <RHFFloatingSelect
                        name="affiliationStatusId"
                        label={t(
                          "affiliations.editAffiliation.header.fields.affiliationStatus.label",
                        )}
                        options={status?.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                        stretch={false}
                        action={{
                          label: `${t("affiliations.editAffiliation.header.fields.affiliationStatus.actions.modify")}`,
                          onClick: () => {
                            setModalName(AFFILIATION_STATUS);
                          },
                        }}
                      />
                      <RHFSelect
                        label={t(
                          "affiliations.editAffiliation.header.fields.facility",
                        )}
                        name={"facilityId"}
                        stretch={false}
                        filteringType="auto"
                        options={facility?.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFSelect
                        label={t(
                          "credentialPackages.addPackage.header.fields.provider",
                        )}
                        name={"providerId"}
                        stretch={false}
                        filteringType="auto"
                        options={providers?.map((temp) => ({
                          label: temp.firstName + " " + temp.lastName,
                          value: "" + temp.id,
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                    </FormSection>
                    <FormSection columns={3}>
                      <RHFDatePicker
                        label={t(
                          "affiliations.editAffiliation.header.fields.startDate",
                        )}
                        name="startDate"
                      />
                      <RHFDatePicker
                        label={t(
                          "affiliations.editAffiliation.header.fields.endDate",
                        )}
                        name="endDate"
                      />
                      <RHFSelect
                        label={"Expiration Reminder"}
                        name={"alertDays"}
                        stretch={false}
                        options={alertReminderOptions}
                      />
                    </FormSection>
                    <FormSection
                      header="Contact Info"
                      columns={2}
                      headerDividerBottom
                    >
                      <RHFTextField
                        label={t(
                          "affiliations.editAffiliation.header.fields.name",
                        )}
                        name="name"
                        stretch
                      />
                      <RHFTextField
                        label={t(
                          "affiliations.editAffiliation.header.fields.email",
                        )}
                        name="email"
                        rules={{
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email",
                          },
                        }}
                      />
                      <RHFPhoneNumber
                        label={t(
                          "affiliations.editAffiliation.header.fields.phone",
                        )}
                        name="phone"
                      />

                      <RHFTextField
                        label={t(
                          "affiliations.editAffiliation.header.fields.fax",
                        )}
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                    </FormSection>
                    <FormSection gridDefinition={[{ colspan: 12 }]}>
                      <RHFTextArea
                        label={"Notes"}
                        name="notes"
                        stretch={true}
                      />
                    </FormSection>
                    <DocumentSection
                      providerId={affiliationId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddAffiliation);
