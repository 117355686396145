import { SideNavigation } from "@cloudscape-design/components";
import { PATH_DASHBOARD } from "../routes/paths";
import { useTranslation } from "react-i18next";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

type active = {
  activeHref: string;
};

export default function SideBar({ activeHref }: active) {
  const { t } = useTranslation();
  const { data: organization } = useGetOrganizationDetailsQuery();
  return (
    <SideNavigation
      header={{
        text: "Ready Doc",
        href: "/dashboard",
      }}
      activeHref={activeHref}
      items={[
        {
          type: "link" as "link",
          text: `${t("layout.sidebar.header.fields.dashboard")}`,
          href: PATH_DASHBOARD.dashboard.list,
        },
        {
          type: "link-group",
          text: `${t("layout.sidebar.header.fields.providers")}`,
          href: PATH_DASHBOARD.provider.manageprovider.list,
          items: [
            {
              type: "link",
              text: `${t("layout.sidebar.header.fields.safe")}`,
              href: PATH_DASHBOARD.safe.list,
            },
          ],
        },
        {
          type: "link",
          text: `${t("layout.sidebar.header.fields.facilities")}`,
          href: PATH_DASHBOARD.managefacilities.list,
        },
        {
          type: "link" as "link",
          text: `${t("layout.sidebar.header.fields.affiliations")}`,
          href: PATH_DASHBOARD.affiliations.list,
        },
        ...(!!organization?.organizationServiceLimits?.digitalForms &&
        !!organization?.organizationServiceLimits?.eSignatures
          ? [
              {
                type: "link" as "link",
                text: `${t("layout.sidebar.header.fields.readyForms")}`,
                href: PATH_DASHBOARD.digitalforms.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.privileges
          ? [
              {
                type: "link" as "link",
                text: `${t("layout.sidebar.header.fields.privileges")}`,
                href: PATH_DASHBOARD.privileges.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.digitalForms &&
        !!organization?.organizationServiceLimits?.eSignatures
          ? [
              {
                type: "link" as "link",
                text: `${t("layout.sidebar.header.fields.e-signatures")}`,
                href: PATH_DASHBOARD.eSignatures.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.contracts
          ? [
              {
                type: "link" as "link",
                text: `${t("layout.sidebar.header.fields.contracts")}`,
                href: PATH_DASHBOARD.contracts.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.payerEnrollments
          ? [
              {
                type: "link" as "link",
                text: `${t("layout.sidebar.header.fields.payerEnrollments")}`,
                href: PATH_DASHBOARD.payerenrollment.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.credentialPackages
          ? [
              {
                type: "link" as "link",
                text: `${t("layout.sidebar.header.fields.credentialPackages")}`,
                href: PATH_DASHBOARD.credential.packages.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.workflows
          ? [
              {
                type: "link" as "link",
                text: "Workflows",
                href: PATH_DASHBOARD.manage.workflows.list,
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.tasks
          ? [
              {
                type: "link" as "link",
                text: "Tasks",
                href: PATH_DASHBOARD.tasks.list,
              },
            ]
          : []),
        {
          type: "expandable-link-group",
          text: `${t("layout.sidebar.header.fields.reports")}`,
          href: PATH_DASHBOARD.reports.list,
          items: [
            {
              type: "link",
              text: `Providers Report`,
              href: PATH_DASHBOARD.reports.providerroatser.list,
            },
            ...(!!organization?.organizationServiceLimits?.payerEnrollments
              ? [
                  {
                    type: "link" as "link",
                    text: `Payer Enrollments`,
                    href: PATH_DASHBOARD.reports.payerenrollment.list,
                  },
                ]
              : []),
            {
              type: "link",
              text: `Provider Affiliations Report`,
              href: PATH_DASHBOARD.reports.provideraffiliation.list,
            },
            {
              type: "link",
              text: `All Documents`,
              href: PATH_DASHBOARD.reports.alldocuments.list,
            },
            {
              type: "link",
              text: `${t("layout.sidebar.header.fields.expiringDocuments")}`,
              href: PATH_DASHBOARD.reports.expiringdocuments.list,
            },
            {
              type: "link",
              text: `${t("layout.sidebar.header.fields.expiredDocuments")}`,
              href: PATH_DASHBOARD.reports.expireddocuments.list,
            },
          ],
        },
        {
          type: "expandable-link-group",
          text: "Manage",
          href: "#",
          items: [
            ...(!!organization?.organizationServiceLimits?.payerEnrollments
              ? [
                  {
                    type: "link" as "link",
                    text: `${t("layout.sidebar.header.fields.payerSetup")}`,
                    href: PATH_DASHBOARD.payers.list,
                  },
                ]
              : []),
            ...(!!organization?.organizationServiceLimits?.payerEnrollments
              ? [
                  {
                    type: "link" as "link",
                    text: `${t("layout.sidebar.header.fields.payerGroupSetup")}`,
                    href: PATH_DASHBOARD.payergroup.list,
                  },
                ]
              : []),
            {
              type: "link" as "link",
              text: `${t("layout.sidebar.header.fields.facilitySetup")}`,
              href: PATH_DASHBOARD.facilitygroup.list,
            },
            ...(!!organization?.organizationServiceLimits?.workflows
              ? [
                  {
                    type: "link" as "link",
                    text: "Workflow Templates",
                    href: PATH_DASHBOARD.manage.workflowTemplates.list,
                  },
                ]
              : []),
            {
              type: "link",
              text: "Provider Licenses",
              href: PATH_DASHBOARD.manage.licensetype.list,
            },
            {
              type: "link",
              text: "Document Categories",
              href: PATH_DASHBOARD.manage.categories.list,
            },
            ...(!!organization?.organizationServiceLimits?.credentialPackages
              ? [
                  {
                    type: "link" as "link",
                    text: `${t("layout.sidebar.header.fields.credentialTemplates")}`,
                    href: PATH_DASHBOARD.credential.templates.list,
                  },
                ]
              : []),
          ],
        },
      ]}
    />
  );
}
