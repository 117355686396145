import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InnerAppLayout from "../../components/InnerAppLayout";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";
import { useCreatePayerGroupMutation } from "../../redux/api/payergroup/payergroup";
import RHFTextArea from "../../components/RHF/RHFTextArea";

type FormInputProps = {
  name: string;
  description: string;
  groupCode: string;
};

export default function AddPayerGroup() {
  const methods = useForm<FormInputProps>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addPayer] = useCreatePayerGroupMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const onSubmit = async (data: FormInputProps) => {
    await notifyWrapper({
      promise: addPayer({
        name: data?.name,
        description: data?.description,
        groupCode: data?.groupCode,
      }),
      resourceName: "Network",
      actionName: "add",
      callback: (id) => {
        if (!!id) navigate(`/payergroup/${id}`);
      },
    });
  };

  return (
    <>
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("payergroup.addPayergroup.header.breadcrumb.text")}`,
                href: `/payergroup`,
              },

              {
                text: `${t("payergroup.addPayergroup.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <SpaceBetween size="m">
            <Container
              header={
                <SpaceBetween size="xxs">
                  <Header>{t("payergroup.addPayergroup.header.label")}</Header>
                  <Divider></Divider>
                </SpaceBetween>
              }
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate("/payergroup");
                          }}
                          formAction="none"
                        >
                          {t("payergroup.addPayergroup.header.actions.cancel")}
                        </Button>
                        <Button formAction="submit" variant="primary">
                          Next
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={2}>
                      <RHFTextField
                        label="Network name"
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />

                      <RHFTextField
                        label="Network id"
                        name="groupCode"
                        stretch={false}
                      />
                      <ExpandableSection headerText="Notes">
                        <FormSection>
                          <RHFTextArea
                            label={"Notes"}
                            name="description"
                            stretch={true}
                          />
                        </FormSection>
                      </ExpandableSection>
                    </FormSection>
                  </Form>
                </form>
              </FormProvider>
            </Container>
          </SpaceBetween>
        }
      />
    </>
  );
}
