import { FormProvider, useForm } from "react-hook-form";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setModalName } from "../../../../redux/UI/actions";
import { PayerEnrollmentRequest } from "../../../../redux/api/payerenrollment/types";
import { useTranslation } from "react-i18next";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetPayerEnrollmentQuery,
  useUpdatePayerEnrollmentMutation,
} from "../../../../redux/api/payerenrollment/payerenrollment";
import { skipToken } from "@reduxjs/toolkit/query";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useGetAllPayerQuery } from "../../../../redux/api/payers/payers";
import {
  useGetAllProvidersQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../../../redux/api/facility/facility";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import LoadingScreen from "../../../../components/LoadingScreen";
import { Divider } from "semantic-ui-react";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import {
  PAYER_ENROLLMENT_STATUS,
  alertReminderOptions,
} from "../../../../helpers/constants";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";
import { useGetAllPayerGroupQuery } from "../../../../redux/api/payergroup/payergroup";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import CommentActivies from "../../../../providersafe/document/versions/comments-activites";
import {
  useAddPayerEnrollmentCommentMutation,
  useDeletePayerEnrollmentCommentMutation,
  useGetPayerEnrolmentCommentsQuery,
  useUpdatePayerEnrollmentCommentMutation,
} from "../../../../redux/api/providerComments/comment";
import Comment from "../../../../redux/api/providerComments/types";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditEnrolledPayer({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PayerEnrollmentRequest>();
  const { t } = useTranslation();
  const { providerId, enrollmentId } = useParams();
  const { data: providerName } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const {
    data: payerEnrollment,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPayerEnrollmentQuery(
    !!enrollmentId
      ? {
          payerEnrollmentId: enrollmentId,
        }
      : skipToken,
  );
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: payergroup = [] } = useGetAllPayerGroupQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { data: facility = [] } = useGetFacilitiesQuery();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );

  const [addPayerEnrollmentCommentMutation] =
    useAddPayerEnrollmentCommentMutation();
  const [deletePayerEnrollmentCommentMutation] =
    useDeletePayerEnrollmentCommentMutation();
  const [updatePayerEnrollmentCommentMutation] =
    useUpdatePayerEnrollmentCommentMutation();

  const [commentsList, setCommentsList] = useState<Comment[]>([]);

  const { data: comments } = useGetPayerEnrolmentCommentsQuery(
    !!enrollmentId ? { enrollmentId } : skipToken,
  );
  useEffect(() => {
    if (comments) {
      setCommentsList(comments);
    }
  }, [comments]);

  const addCommentForPayerEnrolment = async (message: string) => {
    if (!!enrollmentId) {
      await addPayerEnrollmentCommentMutation({
        enrollmentId: enrollmentId,
        message: message,
      });
    }
  };
  const deleteCommentForPayerEnrollment = async (commentId: string) => {
    if (!!enrollmentId) {
      await deletePayerEnrollmentCommentMutation({
        enrollmentId: enrollmentId,
        commentId: commentId,
      });
    }
  };
  const updateCommentForPayerEnrollment = async (
    commentId: string,
    message: string,
  ) => {
    if (!!enrollmentId) {
      await updatePayerEnrollmentCommentMutation({
        enrollmentId: enrollmentId,
        commentId: commentId,
        message: message,
      });
    }
  };

  const onSubmit = async (data: PayerEnrollmentRequest) => {
    if (!!enrollmentId) {
      await notifyWrapper({
        promise: updatePayerEnrollment({
          id: enrollmentId,
          ...data,
        }),
        resourceName: "enrolled payer",
        actionName: "update",
      });
      navigate(-1);
    }
  };

  const [updatePayerEnrollment] = useUpdatePayerEnrollmentMutation();

  useEffect(() => {
    if (payerEnrollment) {
      methods.setValue("description", payerEnrollment?.description);
      !!payerEnrollment?.status?.id &&
        methods.setValue("enrollmentStatusId", payerEnrollment?.status?.id);
      !!payerEnrollment?.provider?.id &&
        methods.setValue("providerId", payerEnrollment?.provider?.id);
      !!payerEnrollment?.facility?.id &&
        methods.setValue("facilityId", payerEnrollment?.facility?.id);
      !!payerEnrollment?.payer?.id &&
        methods.setValue("payerId", payerEnrollment?.payer?.id);
      !!payerEnrollment?.payer?.id &&
        methods.setValue("payerGroupId", payerEnrollment?.payerGroup?.id);
      methods.setValue("submittedDate", payerEnrollment?.submittedDate);
      methods.setValue("approvedDate", payerEnrollment?.approvedDate);
      methods.setValue("effectiveDate", payerEnrollment?.effectiveDate);
      methods.setValue("payerProviderId", payerEnrollment?.payerProviderId);

      methods.setValue(
        "recredentialingDate",
        payerEnrollment?.recredentialingDate,
      );
      methods.setValue("alertDays", payerEnrollment?.alertDays);
    }
  }, [fulfilledTimeStamp, payerEnrollment, enrollmentId]);

  const selectedPayerId = methods.watch("payerId");

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <SpaceBetween size="xxl">
        <SpaceBetween size={"l"}>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("providerProfiles.enrolledPayers.editEnrolledPayer.header.breadcrumb.text")}`,
                href: `/manageprovider/${providerId}`,
              },
              {
                text: providerName?.firstName + " " + providerName?.lastName,
                href: `/manageprovider/${providerId}/profile?activeTabId=enrolledpayers`,
              },
              {
                text: `${t("providerProfiles.enrolledPayers.editEnrolledPayer.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
            ariaLabel="Breadcrumbs"
          />
          <Container
            header={
              <SpaceBetween size="xxs">
                <Header>
                  {t(
                    "providerProfiles.enrolledPayers.editEnrolledPayer.header.label",
                  )}
                </Header>
                <Divider></Divider>
              </SpaceBetween>
            }
          >
            <div style={{ paddingTop: "12px" }}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate(-1);
                          }}
                          formAction="none"
                        >
                          {t(
                            "providerProfiles.enrolledPayers.editEnrolledPayer.header.actions.cancel",
                          )}
                        </Button>
                        <Button
                          formAction="submit"
                          variant="primary"
                          disabled={!hasPermission}
                        >
                          {t(
                            "providerProfiles.enrolledPayers.editEnrolledPayer.header.actions.submit",
                          )}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={4}>
                      <RHFSelect
                        name="payerId"
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.payer",
                        )}
                        options={payer.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFSelect
                        name="payerGroupId"
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.payergroup",
                        )}
                        options={payer
                          ?.find(
                            (payer) => "" + payer?.id === selectedPayerId + "",
                          )
                          ?.payerGroup?.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                        stretch={false}
                      />

                      <RHFSelect
                        disabled
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.provider",
                        )}
                        name={"providerId"}
                        stretch={false}
                        options={provider.map((temp) => ({
                          label: temp.firstName + " " + temp.lastName,
                          value: "" + temp.id,
                          tags: temp?.providerLicenses?.map(
                            (type) => type.licenseType,
                          ),
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFFloatingSelect
                        name="enrollmentStatusId"
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.status.label",
                        )}
                        options={status.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                        stretch={false}
                        action={{
                          label: `${t("payerenrollments.addPayerEnrollment.header.fields.status.actions.modify")}`,
                          onClick: () => {
                            setModalName(PAYER_ENROLLMENT_STATUS);
                          },
                        }}
                      />
                      <RHFSelect
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.facility",
                        )}
                        name={"facilityId"}
                        disabled={!payerEnrollment?.provider}
                        stretch={false}
                        options={facility.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.submittedDate",
                        )}
                        name={"submittedDate"}
                        stretch={false}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.approvedDate",
                        )}
                        name="approvedDate"
                        stretch={false}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.effectiveDate",
                        )}
                        name="effectiveDate"
                        stretch={false}
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.recredentialingDate",
                        )}
                        name={"recredentialingDate"}
                        stretch={false}
                      />
                      <RHFSelect
                        label={t(
                          "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.followupDate",
                        )}
                        name={"alertDays"}
                        stretch={false}
                        options={alertReminderOptions}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.payerProviderId",
                        )}
                        name="payerProviderId"
                        stretch
                      />
                    </FormSection>
                    <ExpandableSection
                      headerText={t(
                        "providerProfiles.enrolledPayers.editEnrolledPayer.header.headerText",
                      )}
                    >
                      <FormSection>
                        <RHFTextArea
                          label={t(
                            "providerProfiles.enrolledPayers.editEnrolledPayer.header.fields.description",
                          )}
                          name="description"
                          stretch={false}
                        />
                      </FormSection>
                    </ExpandableSection>
                  </Form>
                </form>
              </FormProvider>
            </div>
          </Container>
        </SpaceBetween>
        <SpaceBetween size="xxl" direction="vertical">
          <CommentActivies
            commentsList={commentsList}
            postComment={addCommentForPayerEnrolment}
            editComment={updateCommentForPayerEnrollment}
            deleteComment={deleteCommentForPayerEnrollment}
          />
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditEnrolledPayer);
