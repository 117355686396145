import { api } from "..";
import { ProviderAffiliation } from "./types";

const affiliationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAffiliation: build.query<ProviderAffiliation[], void>({
      query: () => `affiliation/all`,
      providesTags: ["Affiliation"],
    }),
    findAffiliationById: build.query<
      ProviderAffiliation,
      { affiliationId: string }
    >({
      query: ({ affiliationId }) => `/affiliation/${affiliationId}`,
      providesTags: ["Affiliation"],
    }),
    findAllAffiliationByProvider: build.query<
      ProviderAffiliation[],
      { providerId: string }
    >({
      query: ({ providerId }) => `/affiliation/${providerId}/all`,
      providesTags: ["Affiliation"],
    }),
  }),
});

export const {
  useGetAllAffiliationQuery,
  useFindAffiliationByIdQuery,
  useFindAllAffiliationByProviderQuery,
} = affiliationApi;
