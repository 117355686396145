import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/browser";

// routes
import { PATH_AUTH } from "../routes/paths";

import axios from "./axios";
import Cookies from "js-cookie";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getTokenScope = (
  accessToken: string,
): { permissions: string[]; is_owner: boolean } => {
  if (!accessToken) {
    return { permissions: [], is_owner: false };
  }
  const decoded = jwtDecode<{ scope: string; role: string }>(accessToken);
  if (!decoded) {
    return { permissions: [], is_owner: false };
  } else {
    decoded.scope = decoded.scope;
  }

  return {
    permissions: decoded.scope.split(" ") ?? [],
    is_owner: decoded.role?.toLowerCase() === "OWNER".toLowerCase(),
  };
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    localStorage.removeItem("accessToken");
    window.location.href = PATH_AUTH.login;
    alert("Token expired");
  }, timeLeft);
};
interface DecodedToken {
  exp: number;
  sub: string;
  scope?: string;
  role?: string;
}
const setSession = async (accessToken: string | null) => {
  if (!!accessToken) {
    // The Authorization token is only set in the header when the application is running locally on localhost:3000.
    // Once deployed on the server, authorization is handled through cookie-based authentication.
    if (!!accessToken && window.location.host === "localhost:3000") {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
    // This function below will handle when token is expired
    const decoded = jwtDecode<DecodedToken>(accessToken);
    const { exp, sub } = decoded;
    Sentry.setUser({ username: sub });
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    Cookies.remove("accessToken");
    Cookies.remove("orgId");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, getTokenScope };
