import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { ApplicationState } from "../../redux/store";
import {
  useGetAllLicenseTypesQuery,
  useGetProviderQuery,
  useUpdateProviderMutation,
} from "../../redux/api/provider/provider";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../components/FormSection";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useParams } from "react-router-dom";
import useIsLoading from "../../hooks/useIsLoading";
import { useSelector } from "react-redux";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { Facility } from "../../redux/api/facility/types";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

type FormInputType = {
  firstName: string;
  lastName: string;
  email: string;
  npiNumber: string;
  facility: string;
  licenseType: string;
  facilityGroup: string;
};

function ManageProviderEditModal({ openModalName }: PropsFromDispatch) {
  const { providerId } = useParams();

  const { data: provider, isLoading } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );

  useIsLoading(isLoading);

  const methods = useForm<FormInputType>();
  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: updateProvider({
        id: providerId ?? "",
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        npiNumber: data.npiNumber,
        facilityId: data.facility,
        licenseTypeId: data.licenseType,
        facilityGroupId: data.facilityGroup,
      }),
      resourceName: "provider",
      actionName: "update",
    });

    openModalName("");
  };

  useEffect(() => {
    if (provider) {
      methods.setValue("firstName", provider.firstName);
      methods.setValue("lastName", provider.lastName);
      methods.setValue("email", provider.email);
      methods.setValue("npiNumber", provider.npiNumber);
      methods.setValue("facility", provider.facility?.id);
      methods.setValue("licenseType", provider.licenseType?.id);
      methods.setValue("facilityGroup", provider.facilityGroup?.id);
    }
  }, [provider]);

  const [updateProvider] = useUpdateProviderMutation();

  const { data: facilityGroups = [] } = useGetAllFacilityGroupQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  const { data: licenseType = [] } = useGetAllLicenseTypesQuery();

  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);

  const selectedFacilityGroupId = methods.watch("facilityGroup");

  useEffect(() => {
    if (!!selectedFacilityGroupId) {
      const selectedFacilityGroup = facilityGroups.find(
        (fg) => fg.id + "" === selectedFacilityGroupId + "",
      );
      setFilteredFacilities(selectedFacilityGroup?.facilities ?? []);
    } else setFilteredFacilities(facilities);
  }, [selectedFacilityGroupId, facilityGroups, facilities]);

  return (
    <Modal
      visible={true}
      size={"medium"}
      data-testid="editProvider"
      header={<Header variant="h2">Edit Provider</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <LoadingScreen isOpen={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <FormSection columns={1}>
              <FormSection
                gridDefinition={[
                  { colspan: { default: 6, m: 6 } },
                  { colspan: { default: 6, m: 6 } },
                ]}
              >
                <RHFTextField
                  testid="firstName"
                  label="First Name"
                  name="firstName"
                  stretch
                  rules={{ required: "This field is required" }}
                  readOnly={!hasPermission}
                />
                <RHFTextField
                  testid="lastName"
                  label="Last Name"
                  name="lastName"
                  stretch
                  rules={{ required: "This field is required" }}
                  readOnly={!hasPermission}
                />
              </FormSection>
              <RHFTextField
                testid="email"
                label="Email"
                name="email"
                stretch
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email",
                  },
                }}
                readOnly={!hasPermission}
              />
              <RHFTextField
                testid="npiNumber"
                label="NPI Number"
                name="npiNumber"
                stretch
                readOnly={!hasPermission}
              />
              <FormSection columns={2}>
                <RHFSelect
                  testid="facilityGroup"
                  label="Facility Group"
                  name={"facilityGroup"}
                  filteringType="auto"
                  options={facilityGroups?.map((facilityGroup) => {
                    return {
                      label: facilityGroup?.name,
                      value: facilityGroup?.id + "",
                    };
                  })}
                />
                <RHFSelect
                  testid="facility"
                  label="Facility"
                  name={"facility"}
                  filteringType="auto"
                  rules={{ required: "This field is required" }}
                  options={filteredFacilities?.map((facility) => {
                    return {
                      label: facility?.name,
                      value: facility?.id + "",
                    };
                  })}
                  disabled={!hasPermission}
                />
              </FormSection>
              <RHFSelect
                testid="licenseType"
                label="License Type"
                name={"licenseType"}
                filteringType="auto"
                rules={{ required: "This field is required" }}
                options={licenseType?.map((licenseType) => {
                  return {
                    label: licenseType?.name,
                    value: licenseType?.id + "",
                  };
                })}
                disabled={!hasPermission}
              />
            </FormSection>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  data-testid="cancel"
                  formAction="none"
                  onClick={() => {
                    openModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid="save"
                  variant="primary"
                  disabled={!hasPermission}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageProviderEditModal);
