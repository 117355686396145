import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { Payers } from "../redux/api/payers/types";
import { useGetAllPrivilegesQuery } from "../redux/api/privileges/privileges";
import { Privileges } from "../redux/api/privileges/types";

export type FilterType = {
  filterPrivilegeName: string;
  privilegeTypeFilterOptions: OptionDefinition[];
  providerFilterOptions: OptionDefinition[];
};

export default function usePrivilegeFilter() {
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useGetAllPrivilegesQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterPrivilegeName: "",
    privilegeTypeFilterOptions: [],
    providerFilterOptions: [],
  });

  const [filteredPrivilege, setFilteredPrivilege] =
    useState<Privileges[]>(data);

  useEffect(() => {
    setFilteredPrivilege(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_privileges = [...data];
    if (filter.filterPrivilegeName) {
      temp_filtered_privileges = temp_filtered_privileges.filter((privilege) =>
        privilege?.name
          ?.toLowerCase()
          .includes(filter?.filterPrivilegeName?.toLowerCase()),
      );
    }

    const selectedPrivilegeTypeId = filter.privilegeTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedPrivilegeTypeId && selectedPrivilegeTypeId.length > 0) {
      temp_filtered_privileges = temp_filtered_privileges.filter(
        (privilege) =>
          selectedPrivilegeTypeId.indexOf(privilege?.privilegeType?.id + "") >=
          0,
      );
    }

    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_privileges = temp_filtered_privileges.filter(
        (privilege) =>
          selectedProviderId.indexOf(privilege?.provider?.id + "") >= 0,
      );
    }
    setFilteredPrivilege(temp_filtered_privileges);
  }, [filter]);

  return {
    filteredPrivilege,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
