import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import { Facility } from "../redux/api/facility/types";

export type FilterType = {
  filterName: string;
  facilityFilterOptions: OptionDefinition[];
  filterTaxId: string;
  filterCliaId: string;
  facilityFilterStateOptions: OptionDefinition[];
};

export default function useFacilityFilter(items?: Facility[]) {
  const { data = [], isLoading, isFetching, refetch } = useGetFacilitiesQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterName: "",
    facilityFilterOptions: [],
    filterTaxId: "",
    filterCliaId: "",
    facilityFilterStateOptions: [],
  });

  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>(
    items ?? data,
  );

  useEffect(() => {
    setFilteredFacilities(items ?? data);
  }, [data, items]);

  useEffect(() => {
    let temp_filtered_facilities = !!items ? [...items] : [...data];
    if (filter.filterName) {
      temp_filtered_facilities = temp_filtered_facilities.filter((facility) =>
        facility?.name?.toLowerCase().includes(filter.filterName.toLowerCase()),
      );
    }

    if (filter.filterTaxId) {
      temp_filtered_facilities = temp_filtered_facilities.filter(
        (facility) =>
          facility?.taxId && facility?.taxId.includes(filter.filterTaxId),
      );
    }

    if (filter.filterCliaId) {
      temp_filtered_facilities = temp_filtered_facilities.filter(
        (facility) =>
          facility?.cliaId && facility?.cliaId.includes(filter.filterCliaId),
      );
    }

    const selectedFaciltiesStateId = filter.facilityFilterStateOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesStateId && selectedFaciltiesStateId.length > 0) {
      temp_filtered_facilities = temp_filtered_facilities.filter(
        (facility) =>
          selectedFaciltiesStateId.indexOf(
            facility?.facilityAddress?.state + "",
          ) >= 0,
      );
    }

    const selectedFaciltiesTypeId = filter.facilityFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesTypeId && selectedFaciltiesTypeId.length > 0) {
      temp_filtered_facilities = temp_filtered_facilities.filter(
        (facility) =>
          selectedFaciltiesTypeId.indexOf(facility.facilityType?.id + "") >= 0,
      );
    }

    setFilteredFacilities(temp_filtered_facilities);
  }, [filter]);

  return {
    filteredFacilities,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
