import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { FilterType } from "../../hooks/useFilter";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGetAllLicenseTypesQuery } from "../../redux/api/provider/provider";
import { join } from "lodash";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";

interface ProviderFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function ProviderFilters({
  filter,
  setFilter,
  countText,
}: ProviderFiltersProps) {
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: facilityGroups = [] } = useGetAllFacilityGroupQuery();
  const { data: licenseType = [] } = useGetAllLicenseTypesQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Name",
    value: "name",
  });
  const statusOptionsArray = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  // side effect, to display the token groups
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.facilityFilterOptions &&
      filter.facilityFilterOptions.length > 0
    )
      temp = [
        {
          label: `Facilities = ${join(
            filter.facilityFilterOptions.map(
              (facilityFilterOption) => facilityFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];
    if (
      !!filter.facilityGroupFilterOptions &&
      filter.facilityGroupFilterOptions.length > 0
    )
      temp = [
        {
          label: `Facility Groups = ${join(
            filter.facilityGroupFilterOptions.map(
              (facilityGroupFilterOption) => facilityGroupFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];
    if (
      !!filter.licenseTypeFilterOptions &&
      filter.licenseTypeFilterOptions.length > 0
    )
      temp = [
        {
          label: `License Types = ${join(
            filter.licenseTypeFilterOptions.map(
              (licenseTypeFilterOption) => licenseTypeFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];

    if (!!filter.statusFilterOptions && filter.statusFilterOptions.length > 0)
      temp = [
        {
          label: `Status = ${join(
            filter.statusFilterOptions.map(
              (statusFilterOption) => statusFilterOption?.label,
            ),
            ", ",
          )}`,
        },
        ...temp,
      ];
    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterProviderName: "",
              filterEmail: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Name", value: "name" },
            { label: "Email", value: "email" },
            { label: "Facility Group", value: "facilityGroup" },
            { label: "Facility", value: "facility" },
            { label: "License Type", value: "license_type" },
            { label: "Status", value: "status" },
          ]}
        />
        <div>
          {selectedProperty.value === "name" && (
            <TextFilter
              filteringPlaceholder="Find Provider"
              filteringText={filter.filterProviderName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterProviderName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "email" && (
            <TextFilter
              filteringPlaceholder="Find Email"
              filteringText={filter.filterEmail}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterEmail: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "facilityGroup" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.facilityGroupFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityGroupFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={facilityGroups.map((facilityGroup) => ({
                label: facilityGroup?.name ?? "",
                value: facilityGroup?.id ?? "",
              }))}
              placeholder="Choose Facility Group"
            />
          )}
          {selectedProperty.value === "facility" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.facilityFilterOptions}
              filteringType="auto"
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={facilities.map((facility) => ({
                label: facility?.name ?? "",
                value: facility?.id ?? "",
              }))}
              placeholder="Choose Facilites"
            />
          )}
          {selectedProperty.value === "license_type" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.licenseTypeFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  licenseTypeFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={licenseType.map((type) => ({
                label: type?.name ?? "",
                value: type?.id ?? "",
              }))}
              placeholder="Choose License Types"
              filteringType="auto"
            />
          )}
          {selectedProperty.value === "status" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.statusFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  statusFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={statusOptionsArray.map((status) => ({
                label: status?.label ?? "",
                value: status?.value ?? "",
              }))}
              placeholder="Choose Status"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Facilities")
          ) {
            setFilter({
              ...filter,
              facilityFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("License Types")
          ) {
            setFilter({
              ...filter,
              licenseTypeFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Status")
          ) {
            setFilter({
              ...filter,
              statusFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Facility Group")
          ) {
            setFilter({
              ...filter,
              facilityGroupFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
