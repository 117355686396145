import { api } from "..";
import { AffiliationType } from "./types";

const affiliationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAffiliationType: build.query<AffiliationType[], void>({
      query: () => `affiliationtype/all`,
      providesTags: ["Affiliation"],
    }),
    saveAllAffiliationType: build.mutation<void, AffiliationType[]>({
      query: (types) => {
        return {
          url: `affiliationtype`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Affiliation"],
    }),
    deleteAffiliationType: build.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `affiliationtype/${id}`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["Affiliation"],
    }),
  }),
});

export const {
  useGetAllAffiliationTypeQuery,
  useSaveAllAffiliationTypeMutation,
  useDeleteAffiliationTypeMutation,
} = affiliationApi;
