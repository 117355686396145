import { useEffect, useState } from "react";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";
import { MemberType } from "../redux/api/manageadmin/types";

export type FilterType = {
  filterFirstName: string;
  filterLastName: string;
  filterEmail: string;
};

export default function useMemberActiveFilter() {
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
    fulfilledTimeStamp,
  } = useGetAllMembersQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterFirstName: "",
    filterLastName: "",
    filterEmail: "",
  });

  const [filteredMemberActive, setFilteredMemberActive] =
    useState<MemberType[]>(data);

  useEffect(() => {
    setFilteredMemberActive(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_active = [...data];
    if (filter.filterFirstName) {
      temp_filtered_active = temp_filtered_active.filter((active) =>
        active?.firstName
          ?.toLowerCase()
          .includes(filter.filterFirstName.toLowerCase()),
      );
    }
    if (filter.filterLastName) {
      temp_filtered_active = temp_filtered_active.filter((active) =>
        active?.lastName
          ?.toLowerCase()
          .includes(filter.filterLastName.toLowerCase()),
      );
    }
    if (filter.filterEmail) {
      temp_filtered_active = temp_filtered_active.filter((active) =>
        active?.email?.toLowerCase().includes(filter.filterEmail.toLowerCase()),
      );
    }

    setFilteredMemberActive(temp_filtered_active);
  }, [filter]);

  return {
    filteredMemberActive,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
    fulfilledTimeStamp,
  };
}
