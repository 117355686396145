import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import {
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
} from "../../redux/api/workflow/workflow";
import { useTranslation } from "react-i18next";
import {
  Container,
  FormField,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { WORFLOW_STATUS } from "../../helpers/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import FormSection from "../../components/FormSection";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function StepOne({ setModalName }: PropsFromDispatch) {
  const { data: workflowStatus = [] } = useGetAllWorkflowStatusQuery();
  const { data: workflowTemplates = [] } = useGetAllWorkflowTemplateQuery();
  const { t } = useTranslation();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  return (
    <SpaceBetween size="xs">
      <Header>Details</Header>
      <Container>
        <SpaceBetween direction="vertical" size="l">
          <FormSection columns={2}>
            <RHFSelect
              label={t(`workflow.editWorkflow.fields.provider`)}
              name={"providerId"}
              filteringType="auto"
              options={providers.map((provider) => ({
                label: provider.firstName + " " + provider.lastName,
                value: "" + provider.id,
              }))}
            />
            <RHFSelect
              name="workflowTemplateId"
              label={t(`workflow.editWorkflow.fields.workflowTemplate`)}
              options={workflowTemplates.map((temp) => ({
                label: temp.name,
                value: "" + temp.id,
              }))}
              stretch={false}
              filteringType="auto"
            />
            <RHFTextField
              label={"Workflow Name"}
              name="name"
              stretch={false}
              rules={{ required: "This field is required" }}
            />
            <RHFFloatingSelect
              name="workflowStatusId"
              stretch={false}
              label={t(`workflow.editWorkflow.fields.workflowStatus.label`)}
              options={workflowStatus.map((status) => ({
                label: status.name,
                value: "" + status.id,
              }))}
              action={{
                label: `${t("workflow.editWorkflow.fields.workflowStatus.actions.modify")}`,
                onClick: () => {
                  setModalName(WORFLOW_STATUS);
                },
              }}
              enableFiltering
            />

            <RHFSelect
              label={t(`workflow.editWorkflow.fields.assignedTo`)}
              name={"adminId"}
              stretch={false}
              filteringType="auto"
              options={members.map((member) => ({
                label: member.firstName + " " + member.lastName,
                value: "" + member.id,
              }))}
            />

            <RHFSelect
              label={"Facility"}
              name={"facilityId"}
              stretch={false}
              filteringType="auto"
              options={facilities.map((facility) => ({
                label: facility.name,
                value: "" + facility.id,
              }))}
            />
          </FormSection>
          <FormField label="Notes" stretch>
            <RHFTextArea name="notes" />
          </FormField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(StepOne);
