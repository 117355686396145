import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Grid,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CredentialPackageRequest } from "../../../redux/api/credentialpackages/types";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useCreateCredentialPackageMutation,
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
} from "../../../redux/api/credentialpackages/credentialpackages";
import InnerAppLayout from "../../../components/InnerAppLayout";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import {
  PACKAGE_STATUS,
  PACKAGE_TYPE,
  alertReminderOptions,
} from "../../../helpers/constants";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../../../redux/api/provider/provider";
import FormSection from "../../../components/FormSection";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddCredentialPackage({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<CredentialPackageRequest>();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addCredentialPackage] = useCreateCredentialPackageMutation();
  const { data: packageType = [] } = useGetAllPackageTypesQuery();
  const { data: template = [] } = useGetAllCredentialTemplatesQuery();
  const { data: packageStatus = [] } = useGetAllPackageStatusQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const onSubmit = async (data: CredentialPackageRequest) => {
    await notifyWrapper({
      promise: addCredentialPackage(data),
      resourceName: "Credential Package",
      actionName: "create",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("credentialPackages.addPackage.header.breadcrumb.text")}`,
                href: `/credentialpackages`,
              },
              {
                text: `${t("credentialPackages.addPackage.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      {t("credentialPackages.addPackage.header.actions.cancel")}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t("credentialPackages.addPackage.header.actions.submit")}
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <SpaceBetween direction="vertical" size="xl">
                <Container
                  header={
                    <Header>
                      {t("credentialPackages.addPackage.header.label")}
                    </Header>
                  }
                >
                  <SpaceBetween direction="vertical" size="m">
                    <Grid gridDefinition={[{ colspan: 9 }]}>
                      <FormSection columns={2}>
                        <RHFSelect
                          label={t(
                            "credentialPackages.addPackage.header.fields.provider",
                          )}
                          name={"providerId"}
                          stretch={false}
                          filteringType="auto"
                          options={provider.map((temp) => ({
                            label: temp.firstName + " " + temp.lastName,
                            value: "" + temp.id,
                          }))}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFSelect
                          name="templateId"
                          label={t(
                            "credentialPackages.addPackage.header.fields.credentialTemplate",
                          )}
                          options={template.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          stretch={false}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFFloatingSelect
                          name="packageTypeId"
                          label={"Credential Package Type"}
                          options={packageType.map((type) => ({
                            label: type.name,
                            value: "" + type.id,
                          }))}
                          stretch={false}
                          action={{
                            label: `${t("credentialPackages.addPackage.header.fields.packageType.actions.modify")}`,
                            onClick: () => {
                              setModalName(PACKAGE_TYPE);
                            },
                          }}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFTextField
                          label={t(
                            "credentialPackages.addPackage.header.fields.name",
                          )}
                          name="name"
                          stretch={false}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFFloatingSelect
                          name="packageStatusId"
                          label={t(
                            "credentialPackages.addPackage.header.fields.packageStatus.label",
                          )}
                          options={packageStatus.map((status) => ({
                            label: status.name,
                            value: "" + status.id,
                          }))}
                          stretch={false}
                          action={{
                            label: `${t("credentialPackages.addPackage.header.fields.packageStatus.actions.modify")}`,
                            onClick: () => {
                              setModalName(PACKAGE_STATUS);
                            },
                          }}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFDatePicker
                          label={t(
                            "credentialPackages.addPackage.header.fields.submittedDate",
                          )}
                          name={"submittedDate"}
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "credentialPackages.addPackage.header.fields.recredentialingDate",
                          )}
                          name={"recredentialingDate"}
                          stretch={false}
                        />
                        <RHFSelect
                          label={t(
                            "credentialPackages.addPackage.header.fields.followupDate",
                          )}
                          name={"followupDate"}
                          stretch={false}
                          options={alertReminderOptions}
                        />
                      </FormSection>
                    </Grid>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddCredentialPackage);
