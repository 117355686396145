import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Grid,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

import InnerAppLayout from "../../components/InnerAppLayout";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { TASK_STATUS, TASK_TYPE } from "../../helpers/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import FormSection from "../../components/FormSection";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { Divider } from "antd";
import {
  useAddTaskMutation,
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { TaskRequest } from "../../redux/api/task/types";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useEffect } from "react";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import { alertReminderOptions } from "../../helpers/constants";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddTask({ setModalName }: PropsFromDispatch) {
  const methods = useForm<TaskRequest>();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [addTask] = useAddTaskMutation();

  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const { data: workflows = [] } = useGetAllWorkflowQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const onSubmit = async (data: TaskRequest) => {
    await notifyWrapper({
      promise: addTask(data),
      resourceName: "task",
      actionName: "create",
    });
    navigate(-1);
  };

  const [providerId, taskTypeId] = methods.watch(["providerId", "taskTypeId"]);

  useEffect(() => {
    const assignedProvider = providers?.find(
      (provider) => provider?.id + "" === providerId + "",
    );
    const taskType = taskTypes?.find(
      (taskType) => taskType?.id + "" === taskTypeId + "",
    );
    const taskName =
      assignedProvider?.firstName +
      " " +
      assignedProvider?.lastName +
      "_" +
      taskType?.name;
    if (!!assignedProvider && !!taskType) methods.setValue("name", taskName);
  }, [providerId, taskTypeId]);

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `Tasks`,
                href: `/tasks`,
              },
              {
                text: `Add`,
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Save
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <Container
                header={
                  <div style={{ paddingBottom: "12px" }}>
                    <Header>Add Task</Header>
                  </div>
                }
              >
                <SpaceBetween size="l">
                  <Grid gridDefinition={[{ colspan: 9 }]}>
                    <FormSection columns={3}>
                      <RHFFloatingSelect
                        name="taskTypeId"
                        label="Task Type"
                        options={taskTypes.map((type) => ({
                          label: type.name,
                          value: "" + type.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: "Modify",
                          onClick: () => {
                            setModalName(TASK_TYPE);
                          },
                        }}
                      />
                      <RHFSelect
                        label={"Provider"}
                        name={"providerId"}
                        stretch={false}
                        filteringType="auto"
                        options={providers.map((provider) => ({
                          label: provider.firstName + " " + provider.lastName,
                          value: "" + provider.id,
                        }))}
                      />
                      <RHFTextField
                        label={"Task Name"}
                        name="name"
                        stretch={false}
                      />
                    </FormSection>
                  </Grid>
                  <Grid gridDefinition={[{ colspan: 9 }]}>
                    <FormSection columns={4}>
                      <RHFFloatingSelect
                        name="taskStatusId"
                        label="Task Status"
                        options={taskstatuses.map((status) => ({
                          label: status.name,
                          value: "" + status.id,
                        }))}
                        stretch={false}
                        action={{
                          label: "Modify",
                          onClick: () => {
                            setModalName(TASK_STATUS);
                          },
                        }}
                      />
                      <RHFSelect
                        label={"Assign to"}
                        name={"adminId"}
                        stretch={false}
                        filteringType="auto"
                        options={members.map((member) => ({
                          label: member.firstName + " " + member.lastName,
                          value: "" + member.id,
                        }))}
                        rules={{ required: "This field is required" }}
                      />

                      <RHFSelect
                        label={"Facility"}
                        name={"facilityId"}
                        stretch={false}
                        filteringType="auto"
                        options={facilities.map((facility) => ({
                          label: facility.name,
                          value: "" + facility.id,
                        }))}
                      />
                      {!!organization?.organizationServiceLimits?.workflows && (
                        <RHFSelect
                          name="workflowId"
                          label="Associated Workflow"
                          options={workflows.map((workflow) => ({
                            label: workflow.name,
                            value: "" + workflow.id,
                          }))}
                          stretch={false}
                        />
                      )}
                    </FormSection>
                  </Grid>
                  <Grid gridDefinition={[{ colspan: 9 }]}>
                    <FormSection columns={2}>
                      <RHFDatePicker
                        label={"Start date"}
                        name={"startDate"}
                        defaultValue={new Date().toUTCString()}
                        stretch={true}
                      />
                      <RHFDatePicker
                        label={"Completed date"}
                        name={"completedDate"}
                        stretch={true}
                      />
                    </FormSection>
                  </Grid>
                  <Grid gridDefinition={[{ colspan: 9 }]}>
                    <FormSection columns={2}>
                      <RHFDatePicker
                        label={"Expiration Date"}
                        name={"dueDate"}
                      />
                      <RHFSelect
                        label={"Alert Days"}
                        name={"alertDays"}
                        options={alertReminderOptions}
                      />
                    </FormSection>
                  </Grid>
                </SpaceBetween>
                <Divider />
                <Grid gridDefinition={[{ colspan: 9 }]}>
                  <ExpandableSection headerText="Notes">
                    <FormSection>
                      <RHFTextArea
                        label={"Notes"}
                        name="description"
                        stretch={true}
                      />
                    </FormSection>
                  </ExpandableSection>
                </Grid>
              </Container>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddTask);
