import { FormProvider, useForm } from "react-hook-form";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetPayerEnrollmentQuery,
  useUpdatePayerEnrollmentMutation,
} from "../../redux/api/payerenrollment/payerenrollment";

import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  PAYER_ENROLLMENT_STATUS,
  alertReminderOptions,
} from "../../helpers/constants";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import LoadingScreen from "../../components/LoadingScreen";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllPayerGroupQuery } from "../../redux/api/payergroup/payergroup";
import WorkflowSection from "../../payers/modify/work-flow-section";
import CommentActivies from "../../providersafe/document/versions/comments-activites";
import Comment from "../../redux/api/providerComments/types";
import {
  useAddPayerEnrollmentCommentMutation,
  useDeletePayerEnrollmentCommentMutation,
  useGetPayerEnrolmentCommentsQuery,
  useUpdatePayerEnrollmentCommentMutation,
} from "../../redux/api/providerComments/comment";
import RHFTextField from "../../components/RHF/RHFTextField";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditPayerEnrollment({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PayerEnrollmentRequest>();
  const { t } = useTranslation();
  const { id: payerId, enrollmentId } = useParams();
  const {
    data: payerEnrollment,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPayerEnrollmentQuery(
    !!enrollmentId
      ? {
          payerEnrollmentId: enrollmentId,
        }
      : skipToken,
  );

  const [addPayerEnrollmentCommentMutation] =
    useAddPayerEnrollmentCommentMutation();
  const [deletePayerEnrollmentCommentMutation] =
    useDeletePayerEnrollmentCommentMutation();
  const [updatePayerEnrollmentCommentMutation] =
    useUpdatePayerEnrollmentCommentMutation();

  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: payergroup = [] } = useGetAllPayerGroupQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { data: facility = [] } = useGetFacilitiesQuery();
  const providerId = methods.watch("providerId");
  const facilityId = methods.watch("facilityId");

  const [commentsList, setCommentsList] = useState<Comment[]>([]);

  const [selectedWorkflow, setselectedWorkflow] = useState<OptionDefinition>({
    label: "None - no workflow selected ",
    value: "-1",
  });

  const { data: comments } = useGetPayerEnrolmentCommentsQuery(
    !!enrollmentId ? { enrollmentId } : skipToken,
  );
  useEffect(() => {
    if (comments) {
      setCommentsList(comments);
    }
  }, [comments]);

  const addCommentForPayerEnrolment = async (message: string) => {
    if (!!enrollmentId) {
      await addPayerEnrollmentCommentMutation({
        enrollmentId: enrollmentId,
        message: message,
      });
    }
  };
  const deleteCommentForPayerEnrollment = async (commentId: string) => {
    if (!!enrollmentId) {
      await deletePayerEnrollmentCommentMutation({
        enrollmentId: enrollmentId,
        commentId: commentId,
      });
    }
  };
  const updateCommentForPayerEnrollment = async (
    commentId: string,
    message: string,
  ) => {
    if (!!enrollmentId) {
      await updatePayerEnrollmentCommentMutation({
        enrollmentId: enrollmentId,
        commentId: commentId,
        message: message,
      });
    }
  };
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const onSubmit = async (data: PayerEnrollmentRequest) => {
    if (!!enrollmentId) {
      await notifyWrapper({
        promise: updatePayerEnrollment({
          id: enrollmentId,
          ...data,
          workflowId:
            selectedWorkflow.value !== "-1" ? selectedWorkflow.value : "",
        }),
        resourceName: "payerenrollment",
        actionName: "update",
      });
      navigate(-1);
    }
  };

  const [updatePayerEnrollment] = useUpdatePayerEnrollmentMutation();

  const selectedPayerId = methods.watch("payerId");

  useEffect(() => {
    if (payerEnrollment) {
      methods.setValue("description", payerEnrollment?.description);
      !!payerEnrollment?.status?.id &&
        methods.setValue("enrollmentStatusId", payerEnrollment?.status?.id);
      !!payerEnrollment?.provider?.id &&
        methods.setValue("providerId", payerEnrollment?.provider?.id);
      !!payerEnrollment?.facility?.id &&
        methods.setValue("facilityId", payerEnrollment?.facility?.id);
      !!payerEnrollment?.payer?.id &&
        methods.setValue("payerId", payerEnrollment?.payer?.id);
      !!payerEnrollment?.payerGroup?.id &&
        methods.setValue("payerGroupId", payerEnrollment?.payerGroup?.id);
      methods.setValue("submittedDate", payerEnrollment?.submittedDate);
      methods.setValue("approvedDate", payerEnrollment?.approvedDate);
      methods.setValue("payerProviderId", payerEnrollment.payerProviderId);
      payerEnrollment?.workflow &&
        setselectedWorkflow({
          label: payerEnrollment?.workflow?.name,
          value: payerEnrollment?.workflow?.id + "",
        });
      methods.setValue("effectiveDate", payerEnrollment?.effectiveDate);
      methods.setValue(
        "recredentialingDate",
        payerEnrollment?.recredentialingDate,
      );
      methods.setValue("alertDays", payerEnrollment?.alertDays);
    }
  }, [fulfilledTimeStamp, payerEnrollment, enrollmentId]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <SpaceBetween size="xxl">
        <SpaceBetween size={"l"}>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("payerenrollments.editPayerEnrollment.header.breadcrumb.text")}`,
                href: `/payerenrollment`,
              },

              {
                text: `${t("payerenrollments.editPayerEnrollment.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
            ariaLabel="Breadcrumbs"
          />
          <Container
            header={
              <SpaceBetween size="xxs">
                <Header>
                  {t("payerenrollments.editPayerEnrollment.header.label")}
                </Header>
                <Divider></Divider>
              </SpaceBetween>
            }
          >
            <div style={{ paddingTop: "12px" }}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate(-1);
                          }}
                          formAction="none"
                        >
                          {t(
                            "payerenrollments.editPayerEnrollment.header.actions.cancel",
                          )}
                        </Button>
                        <Button
                          formAction="submit"
                          variant="primary"
                          disabled={!hasPermission}
                        >
                          {t(
                            "payerenrollments.editPayerEnrollment.header.actions.submit",
                          )}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <SpaceBetween size="m">
                      <FormSection columns={4}>
                        <RHFSelect
                          name="payerId"
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.payer",
                          )}
                          options={payer.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          stretch={false}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFSelect
                          name="payerGroupId"
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.payergroup",
                          )}
                          options={payer
                            ?.find(
                              (payer) =>
                                "" + payer?.id === selectedPayerId + "",
                            )
                            ?.payerGroup?.map((temp) => ({
                              label: temp.name,
                              value: "" + temp.id,
                            }))}
                          stretch={false}
                        />
                        {providerId && (
                          <RHFSelect
                            disabled
                            label={t(
                              "payerenrollments.editPayerEnrollment.header.fields.provider",
                            )}
                            name={"providerId"}
                            stretch={false}
                            options={provider.map((temp) => ({
                              label: temp.firstName + " " + temp.lastName,
                              value: "" + temp.id,
                              tags: temp?.providerLicenses?.map(
                                (type) => type.licenseType,
                              ),
                            }))}
                            rules={{ required: "This field is required" }}
                          />
                        )}
                        {/* {facilityId && ( */}
                        <RHFSelect
                          disabled={!payerEnrollment?.provider}
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.facility",
                          )}
                          name={"facilityId"}
                          stretch={false}
                          options={facility.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                        />
                        {/* )} */}
                        <RHFFloatingSelect
                          name="enrollmentStatusId"
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.status.label",
                          )}
                          options={status.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          rules={{ required: "This field is required" }}
                          stretch={false}
                          action={{
                            label: `${t("payerenrollments.addPayerEnrollment.header.fields.status.actions.modify")}`,
                            onClick: () => {
                              setModalName(PAYER_ENROLLMENT_STATUS);
                            },
                          }}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.submittedDate",
                          )}
                          name={"submittedDate"}
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.approvedDate",
                          )}
                          name="approvedDate"
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.effectiveDate",
                          )}
                          name="effectiveDate"
                          stretch={false}
                        />
                        <RHFDatePicker
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.recredentialingDate",
                          )}
                          name={"recredentialingDate"}
                          stretch={false}
                        />
                        <RHFSelect
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.followupDate",
                          )}
                          name={"alertDays"}
                          stretch={false}
                          options={alertReminderOptions}
                        />
                        <RHFTextField
                          label={t(
                            "payerenrollments.editPayerEnrollment.header.fields.payerProviderId",
                          )}
                          name="payerProviderId"
                          stretch
                        />
                      </FormSection>
                      <WorkflowSection
                        workflowId={payerEnrollment?.workflow?.id + ""}
                        selectedWorkflow={selectedWorkflow}
                        setselectedWorkflow={setselectedWorkflow}
                      />
                      <ExpandableSection
                        headerText={t(
                          "payerenrollments.editPayerEnrollment.header.headerText",
                        )}
                      >
                        <FormSection>
                          <RHFTextArea
                            label={t(
                              "payerenrollments.editPayerEnrollment.header.fields.description",
                            )}
                            name="description"
                            stretch={false}
                          />
                        </FormSection>
                      </ExpandableSection>
                    </SpaceBetween>
                  </Form>
                </form>
              </FormProvider>
            </div>
          </Container>
        </SpaceBetween>
        <SpaceBetween size="xxl" direction="vertical">
          <CommentActivies
            commentsList={commentsList}
            postComment={addCommentForPayerEnrolment}
            editComment={updateCommentForPayerEnrollment}
            deleteComment={deleteCommentForPayerEnrollment}
          />
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditPayerEnrollment);
