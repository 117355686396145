import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/usePrivilegeFilter";
import { useGetAllPrivilegeTypesQuery } from "../../redux/api/privileges/privileges";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";

interface PrivilegeFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function PrivilegeFilters({
  filter,
  setFilter,
  countText,
}: PrivilegeFiltersProps) {
  const { data: privilegeType = [] } = useGetAllPrivilegeTypesQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Name",
    value: "name",
  });

  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.privilegeTypeFilterOptions &&
      filter.privilegeTypeFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Privilege Type = ${join(
            filter.privilegeTypeFilterOptions.map(
              (privilegeTypeFilterOptions) => privilegeTypeFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    if (
      !!filter.providerFilterOptions &&
      filter.providerFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Provider = ${join(
            filter.providerFilterOptions.map(
              (providerFilterOptions) => providerFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];

    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterPrivilegeName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Privilege Name", value: "name" },
            { label: "Privilege Type", value: "privilegeType" },
            { label: "Provider", value: "provider" },
          ]}
        />
        <div>
          {selectedProperty.value === "name" && (
            <TextFilter
              filteringPlaceholder="Find Name"
              filteringText={filter.filterPrivilegeName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterPrivilegeName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "privilegeType" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter.privilegeTypeFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  privilegeTypeFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={privilegeType.map((type) => ({
                label: type.name,
                value: type.id + "",
              }))}
              placeholder="Choose Privilege Type"
            />
          )}
          {selectedProperty.value === "provider" && (
            <Multiselect
              expandToViewport
              filteringType="auto"
              hideTokens
              selectedOptions={filter.providerFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  providerFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={providers.map((type) => ({
                label: type.firstName + " " + type.lastName,
                value: type.id + "",
              }))}
              placeholder="Choose Provider"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Privilege Type")
          ) {
            setFilter({
              ...filter,
              privilegeTypeFilterOptions: [],
            });
          }
          if (!!tokens && tokens?.[itemIndex]?.label?.startsWith("Provider")) {
            setFilter({
              ...filter,
              providerFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
