import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../redux/UI/actions";
import {
  useGetAllDigitalFormTypesQuery,
  useSaveAllDigitalFormTypesMutation,
} from "../../redux/api/digitalform/digitalforms";
import { DigitalFormType } from "../../redux/api/digitalform/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function DigitalFormTypeModal({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { data = [], fulfilledTimeStamp } = useGetAllDigitalFormTypesQuery();
  const [digitalFormType, setDigitalFormType] = useState<DigitalFormType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllDigitalFormTypes] = useSaveAllDigitalFormTypesMutation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("readyforms.edit") ?? false),
  );

  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...digitalFormType].filter((item) => !!item.type);
    await notifyWrapper({
      promise: saveAllDigitalFormTypes(tmp),
      resourceName: "Ready Form Types",
      actionName: "save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setDigitalFormType(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>{t("readyForms.readyFormType.header.label")}</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t("readyForms.readyFormType.header.actions.cancel")}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
              disabled={!hasPermission}
            >
              {t("readyForms.readyFormType.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>

          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setDigitalFormType([
                      ...digitalFormType,
                      {
                        id: "",
                        type: "",
                        default: false,
                        deleted: false,
                        orgId: "",
                      },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...digitalFormType];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      type: item.type,
                      default: true,
                      deleted: true,
                      orgId: item.orgId,
                    };
                    setDigitalFormType(tmp);
                  }}
                  items={digitalFormType.filter(
                    (item) => item.deleted !== true,
                  )}
                  addButtonText={t(
                    "readyForms.readyFormType.header.actions.addReadyFormType",
                  )}
                  definition={[
                    {
                      label: `${t("readyForms.readyFormType.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.type}
                          onChange={(changeDetails) => {
                            const tmp = [...digitalFormType];
                            tmp[itemIndex] = {
                              id: item.id,
                              type: changeDetails.detail.value,
                              default: item.default,
                              deleted: item.deleted,
                              orgId: item.orgId,
                            };
                            setDigitalFormType(tmp);
                          }}
                          placeholder={t(
                            "readyForms.readyFormType.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DigitalFormTypeModal);
