import {
  Badge,
  Checkbox,
  Header,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import {
  Permission,
  RolePermissions,
  isPermissionCheckBoxDisabled,
} from "../../redux/api/role/types";
import { useMemo } from "react";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

interface PermissionsTabContentProps {
  group: string;
  rolePermissions: RolePermissions[];
  permissions: Permission[];
  onRolePermissionChange: ({
    checked,
    role,
    key,
  }: {
    checked: boolean;
    role: RolePermissions;
    key: string;
  }) => void;
}
export default function PermissionsTabContent({
  rolePermissions,
  group,
  permissions,
  onRolePermissionChange,
}: PermissionsTabContentProps) {
  const { t } = useTranslation();
  const tableProps: TableProps<RolePermissions> = useMemo(() => {
    return {
      sortingDisabled: true,
      resizableColumns: true,
      columnDefinitions: [
        {
          id: "role_name",
          header: (
            <div>
              {t("rolesandpermissions.permissions.table.fields.roleName")}
            </div>
          ),
          cell: (item) => (
            <SpaceBetween size="s" direction="horizontal">
              {item.name || "-"}
              {item.default && <Badge>default</Badge>}
            </SpaceBetween>
          ),
        },
        ...permissions.map(
          (permission, index): TableProps.ColumnDefinition<RolePermissions> => {
            return {
              id: permission.key,
              header: capitalize(permission.key.split(".").at(1)),
              cell: (role) => {
                return (
                  <Checkbox
                    disabled={
                      isPermissionCheckBoxDisabled({
                        role_permissions: role.permissions,
                        permissions: permissions,
                        permission_key: permission.key,
                      }) || role.default
                    }
                    checked={
                      !!role.permissions.find(
                        (role_permission) => role_permission === permission.key,
                      )
                    }
                    onChange={(changeDetail) => {
                      onRolePermissionChange({
                        role: role,
                        key: permission.key,
                        checked: changeDetail.detail.checked,
                      });
                    }}
                  />
                );
              },
            };
          },
        ),
      ],
      items: rolePermissions ?? [],
      loadingText: "Loading resources",
      header: <Header> {group?.replace("_", " ")} </Header>,
    };
  }, [group, rolePermissions, permissions]);

  return <Table {...tableProps} />;
}
