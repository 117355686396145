import { FormProvider, useForm } from "react-hook-form";
import { PersonalInfoRequestType } from "../../../../redux/api/provider/section/types";
import {
  Alert,
  BreadcrumbGroup,
  Button,
  Checkbox,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddProviderPersonalInfoMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
  useUpdateProviderMutation,
} from "../../../../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import { Countries, USStatesList } from "../../../../helpers/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import { Switch } from "antd";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";

type FormInputType = PersonalInfoRequestType & {
  firstName: string;
  lastName: string;
};

export default function EditPersonalInfo() {
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addProviderPersonalinfo] = useAddProviderPersonalInfoMutation();
  const [hasOtherName, setHasOtherName] = useState<boolean>(false);
  const { providerId } = useParams();
  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: degrees = [] } = useGetAllDegreeQuery();
  useEffect(() => {
    if (hasOtherName === false) methods.resetField("otherName");
  }, [hasOtherName]);
  const onSubmit = async (data: FormInputType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderPersonalinfo({
          providerId: providerId,
          ...data,
          hasOtherName: hasOtherName,
        }),
        resourceName: "personal info",
        actionName: "upload",
      });

      navigate(-1);
    }
  };

  useEffect(() => {
    if (!!provider) {
      const providerPersonalInfo = provider.personalInfo;
      methods.setValue("firstName", provider?.firstName);
      methods.setValue("lastName", provider?.lastName);
      methods.setValue("npiNumber", provider?.npiNumber);
      if (providerPersonalInfo) {
        setHasOtherName(providerPersonalInfo?.hasOtherName);
        methods.setValue("id", providerPersonalInfo?.id);
        methods.setValue("middleName", providerPersonalInfo?.middleName);
        providerPersonalInfo?.degree &&
          methods.setValue("degreeId", providerPersonalInfo?.degree.id);
        methods.setValue("hasOtherName", providerPersonalInfo?.hasOtherName);
        methods.setValue("otherName", providerPersonalInfo?.otherName);
        methods.setValue("gender", providerPersonalInfo?.gender);
        methods.setValue("dob", providerPersonalInfo?.dob);
        methods.setValue("placeOfBirth", providerPersonalInfo?.placeOfBirth);
        methods.setValue("ssn", providerPersonalInfo?.ssn);
        methods.setValue("citizenship", providerPersonalInfo?.citizenship);
        methods.setValue("passportNo", providerPersonalInfo?.passportNo);
        methods.setValue("email", providerPersonalInfo?.email);
        methods.setValue("mobile", providerPersonalInfo?.mobile);
        methods.setValue("homePhone", providerPersonalInfo?.homePhone);
        methods.setValue("fax", providerPersonalInfo?.fax);
        methods.setValue(
          "hasWorkedInMilitary",
          providerPersonalInfo?.hasWorkedInMilitary,
        );
        methods.setValue(
          "branchOfMilitary",
          providerPersonalInfo?.branchOfMilitary,
        );
        methods.setValue(
          "militaryDateOfService",
          providerPersonalInfo?.militaryDateOfService,
        );
        methods.setValue("addressLane1", providerPersonalInfo?.addressLane1);
        methods.setValue("addressLane2", providerPersonalInfo?.addressLane2);
        methods.setValue("city", providerPersonalInfo?.city);
        methods.setValue("state", providerPersonalInfo?.state);
        methods.setValue("zipcode", providerPersonalInfo?.zipcode);
        methods.setValue("county", providerPersonalInfo?.county);
        methods.setValue("country", providerPersonalInfo?.country);
      }
    }
  }, [fulfilledTimeStamp, provider]);

  const [showSSN, setShowSSN] = React.useState(false);
  const [alertShow, setAlertShow] = React.useState(false);

  const hasPermissionForView = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("ssn.view") ?? false),
  );
  const hasPermissionForEdit = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("ssn.edit") ?? false),
  );

  const showSecrets = () => {
    if (hasPermissionForView || hasPermissionForEdit) {
      setShowSSN(!showSSN);
    } else {
      setAlertShow(!alertShow);
    }
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const handleMobileNumberChange = (newValue: string) => {
    setPhoneNumber(newValue);
  };
  const handleHomePhoneChange = (newValue: string) => {
    setHomePhoneNumber(newValue);
  };

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.personalInfo.editPersonalInfo.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=personal_info`,
            },
            {
              text: `${t("providerProfiles.personalInfo.editPersonalInfo.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.personalInfo.editPersonalInfo.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.personalInfo.editPersonalInfo.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.personalInfo.editPersonalInfo.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.middleName",
                      )}
                      name="middleName"
                      stretch
                    />
                    <div style={{ paddingTop: "12px" }}>
                      <Checkbox
                        onChange={({ detail }) =>
                          setHasOtherName(detail.checked)
                        }
                        checked={hasOtherName}
                      >
                        <b>
                          {t(
                            "providerProfiles.personalInfo.editPersonalInfo.header.fields.hasOtherName",
                          )}
                        </b>
                      </Checkbox>
                    </div>
                    {hasOtherName === true ? (
                      <RHFTextField
                        label={t(
                          "providerProfiles.personalInfo.editPersonalInfo.header.fields.otherName",
                        )}
                        name="otherName"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                    ) : (
                      <></>
                    )}

                    <RHFSelect
                      name="degreeId"
                      filteringType="auto"
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.degree",
                      )}
                      options={degrees.map((degree) => ({
                        value: degree.id + "",
                        label: degree.name,
                      }))}
                    />

                    <RHFSelect
                      name="gender"
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.gender",
                      )}
                      options={["Male", "Female"].map((gender) => ({
                        value: gender,
                      }))}
                    />
                    <RHFDatePicker
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.dateOfBirth",
                      )}
                      name="dob"
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.placeOfBirth",
                      )}
                      name="placeOfBirth"
                      stretch
                    />
                    <SpaceBetween size="xs">
                      <RHFTextField
                        type={showSSN ? "text" : "password"}
                        readOnly={
                          showSSN && hasPermissionForEdit ? false : true
                        }
                        label={t(
                          "providerProfiles.personalInfo.editPersonalInfo.header.fields.ssn",
                        )}
                        name="ssn"
                        stretch
                      />
                      {alertShow ? (
                        <Alert
                          dismissible
                          onDismiss={showSecrets}
                          type="warning"
                        >
                          You Don't Have A Permission
                        </Alert>
                      ) : (
                        <SpaceBetween size="xs" direction="horizontal">
                          {" "}
                          <Switch
                            value={showSSN}
                            size="small"
                            onClick={showSecrets}
                          />{" "}
                          Show SSN
                        </SpaceBetween>
                      )}
                    </SpaceBetween>
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.passportNo",
                      )}
                      name="passportNo"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.citizenship",
                      )}
                      name="citizenship"
                    />
                    <div style={{ paddingTop: "12px" }}>
                      <RHFCheckBox
                        label={t(
                          "providerProfiles.personalInfo.editPersonalInfo.header.fields.haveYouEverServedInTheUSMilitary",
                        )}
                        name="hasWorkedInMilitary"
                        stretch
                      />
                    </div>
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.branchOfMilitary",
                      )}
                      name="branchOfMilitary"
                      stretch
                    />
                    <RHFDatePicker
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.militaryDateOfService",
                      )}
                      name="militaryDateOfService"
                    />
                    <RHFTextField
                      label={"Personal Email"}
                      name="email"
                      rules={{
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email",
                        },
                      }}
                      stretch
                    />
                    <RHFPhoneNumber label={"Personal Phone"} name="mobile" />
                    <RHFPhoneNumber
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.homePhone",
                      )}
                      name="homePhone"
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.fax",
                      )}
                      name="fax"
                      stretch
                    />

                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.addressLane1",
                      )}
                      name="addressLane1"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.addressLane2",
                      )}
                      name="addressLane2"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.city",
                      )}
                      name="city"
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.county",
                      )}
                      name="county"
                      stretch
                    />
                    <RHFSelect
                      name="state"
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.state",
                      )}
                      options={USStatesList.map((state) => ({
                        value: state.value,
                      }))}
                      stretch
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.zipcode",
                      )}
                      name="zipcode"
                      stretch
                    />
                    <RHFSelect
                      name="country"
                      label={t(
                        "providerProfiles.personalInfo.editPersonalInfo.header.fields.country",
                      )}
                      options={Countries.map((country) => ({
                        value: country.value,
                      }))}
                      stretch
                    />
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
