import axios from "axios";
import { HOST_API } from "../config";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API, //`https://${window.location.hostname}`,
  withCredentials: true,
});

export const fileAxios = axios.create({
  withCredentials: true,
});

export default axiosInstance;
