import {
  Box,
  Button,
  Link,
  Modal,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetAllDocumentQuery } from "../../../redux/api/document/document";
import {
  AllDocumentResponse,
  Document,
} from "../../../redux/api/document/types";
import {
  useGetAllCredentialPackageDocumentsQuery,
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageDocumentsMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { useTranslation } from "react-i18next";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useGetAllProviderSafeDocumentsQuery } from "../../../redux/api/provider/provider";

type CredentailTemplateAddFormModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};
export default function CredentialPackageAddDocumentsModal({
  visible,
  closeModal,
}: CredentailTemplateAddFormModalProps) {
  const { t } = useTranslation();

  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const { id: credentialPackageId } = useParams();
  const { data: credentialPackage } = useGetCredentialPackageQuery(
    credentialPackageId ?? skipToken,
  );
  const { data: packageDocuments = [] } =
    useGetAllCredentialPackageDocumentsQuery(
      credentialPackageId ? { credentialPackageId } : skipToken,
    );

  const { data: documents = [] } = useGetAllProviderSafeDocumentsQuery(
    !!credentialPackage?.provider?.id
      ? {
          providerId: credentialPackage?.provider.id,
        }
      : skipToken,
  );

  const { items, collectionProps } = useCollection(documents, {
    propertyFiltering: {
      filteringProperties: [],
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });

  const [updateCredentialPackageDocuments] =
    useUpdateCredentialPackageDocumentsMutation();

  useEffect(() => {
    setSelectedDocuments([]);
  }, [closeModal]);

  const packageDocumentIdList = useMemo(() => {
    return packageDocuments.map((doc) => doc.id + "");
  }, [packageDocuments]);

  return (
    <Modal
      onDismiss={closeModal}
      size="large"
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={closeModal}>
              {t(
                "credentialPackages.packageDetails.header.documentList.addDocument.header.actions.cancel",
              )}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (
                  !selectedDocuments ||
                  !credentialPackageId ||
                  selectedDocuments?.length === 0
                ) {
                  closeModal();
                  return;
                }
                const documentIds = selectedDocuments.map((doc) => doc.id + "");
                updateCredentialPackageDocuments({
                  id: credentialPackageId,
                  documents: documentIds,
                });
                closeModal();
              }}
            >
              {t(
                "credentialPackages.packageDetails.header.documentList.addDocument.header.actions.save",
              )}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t(
        "credentialPackages.packageDetails.header.documentList.table.header.label",
      )}
    >
      <Table
        variant="embedded"
        {...collectionProps}
        columnDefinitions={[
          {
            id: "name",
            header: (
              <div>
                {t(
                  "credentialPackages.packageDetails.header.documentList.table.header.fields.name",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "category",
            header: <div>Category</div>,
            cell: (item) => <div>{item?.category?.name || "-"}</div>,
            sortingField: "category.name",
            sortingComparator: (a, b) =>
              (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
          },
        ]}
        isItemDisabled={(item) =>
          packageDocumentIdList?.includes(item?.id + "")
        }
        items={items}
        selectionType="multi"
        selectedItems={selectedDocuments ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems)
            setSelectedDocuments(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedDocuments;
          if (
            !detail.item ||
            packageDocumentIdList?.includes(detail.item.id + "")
          )
            return;
          if (temp?.includes(detail.item))
            setSelectedDocuments(temp.filter((item) => item !== detail.item));
          else setSelectedDocuments([detail.item, ...temp]);
        }}
        stickyHeader
        loadingText="Loading resources"
      />
    </Modal>
  );
}
