import { SpaceBetween, TextFilter } from "@cloudscape-design/components";
import { FilterType } from "../../hooks/useFacilityGroupFilter";
interface FacilityGroupFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function FacilityGroupFilters({
  filter,
  setFilter,
  countText,
}: FacilityGroupFiltersProps) {
  return (
    <SpaceBetween size={"s"}>
      <TextFilter
        filteringPlaceholder="Find by Facility group name"
        filteringText={filter.filterName}
        onChange={({ detail }) =>
          setFilter({ ...filter, filterName: detail.filteringText })
        }
        countText={countText && `${countText} matches`}
      />
    </SpaceBetween>
  );
}
