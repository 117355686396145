import { BreadcrumbGroup, Header } from "@cloudscape-design/components";
import { useParams } from "react-router-dom";
import InnerAppLayout from "../components/InnerAppLayout";
import PayerNavigation from "./payer-nav";
import { Payers } from "../redux/api/payers/types";
import { useState } from "react";
import PayerEnrollments from "./payer-enrollment";
import { useTranslation } from "react-i18next";

export const NoPayerSelected = () => (
  <div>
    <Header variant="h2">Select a Payer</Header>
  </div>
);

export default function PayerTable() {
  const [selectedItem, setSelectedItem] = useState<Payers>();
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={
            selectedItem
              ? [
                  {
                    text: `${t("payerenrollments.breadcrumb.text")}`,
                    href: "#",
                  },
                  {
                    text: selectedItem.name,
                    href: "#",
                  },
                ]
              : [
                  {
                    text: `${t("payerenrollments.breadcrumb.text")}`,
                    href: "#",
                  },
                ]
          }
        />
      }
      leftPanel={
        <PayerNavigation
          onChange={(selectedPayer) => {
            setSelectedItem(selectedPayer);
          }}
          navigateTo={(id: string) => `/payerenrollment/${id}`}
        />
      }
      content={!!id ? <PayerEnrollments /> : <NoPayerSelected />}
    />
  );
}
