import { useEffect } from "react";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  ADD_PRIVILEGE_TYPE,
  alertReminderOptions,
} from "../../helpers/constants";

import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { PrivilegeCreateRequest } from "../../redux/api/privileges/types";
import {
  useGetAllProvidersQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import {
  useGetAllPrivilegeTypesQuery,
  useGetPrivilegeQuery,
  useUpdatePrivilegeMutation,
} from "../../redux/api/privileges/privileges";

import { setModalName } from "../../redux/UI/actions";
import { ApplicationState } from "../../redux/store";

import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";

import { useTranslation } from "react-i18next";
import useIsLoading from "../../hooks/useIsLoading";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditPrivilege({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PrivilegeCreateRequest>();
  const { t } = useTranslation();
  const { id } = useParams();
  const providerId = methods.watch("providerId");

  const {
    data: privilege,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPrivilegeQuery({
    privilegeId: id ?? "",
  });
  const { data: privilegesType = [], isLoading: privilegesTypeLLoading } =
    useGetAllPrivilegeTypesQuery();
  const { data: providersList = [], isLoading: providerListLoading } =
    useGetAllProvidersQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [updatePrivilege] = useUpdatePrivilegeMutation();
  const { data: provider, isLoading: providerLoading } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const navigate = useNavigate();

  const onSubmit = async (data: PrivilegeCreateRequest) => {
    if (!!id)
      await notifyWrapper({
        promise: updatePrivilege({
          id: id,
          name: data?.name,
          description: data?.description,
          privilegeTypeId: data.privilegeTypeId,
          licenseTypeId: data.licenseTypeId,
          providerId: data.providerId,
          providerAffiliationId: data.providerAffiliationId,
          expirationDate: data.expirationDate,
          alertDays: data.alertDays,
        }),
        resourceName: "privilege",
        actionName: "update",
      });
    navigate(-1);
  };
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("privileges.edit") ?? false),
  );

  useEffect(() => {
    if (!!privilege && !!provider) {
      const affiliationIndex = provider?.providerAffiliations?.findIndex(
        (providerAffiliation) =>
          providerAffiliation?.id + "" ===
          privilege?.providerAffiliation?.id?.toString(),
      );
      if (affiliationIndex === -1) methods.resetField("providerAffiliationId");
      else
        methods.setValue(
          "providerAffiliationId",
          privilege?.providerAffiliation?.id?.toString(),
        );
    } else methods.resetField("providerAffiliationId");
  }, [provider]);

  useEffect(() => {
    if (!!privilege) {
      methods.setValue("name", privilege?.name);
      methods.setValue("description", privilege?.description);
      !!privilege?.privilegeType?.id &&
        methods.setValue("privilegeTypeId", privilege?.privilegeType?.id);
      methods.setValue("licenseTypeId", privilege?.licenseType?.id);
      methods.setValue("providerId", privilege?.provider?.id);
      methods.setValue("expirationDate", privilege?.expirationDate);
      methods.setValue("alertDays", privilege?.alertDays);
    }
  }, [fulfilledTimeStamp, privilege]);

  useIsLoading(
    isLoading ||
      privilegesTypeLLoading ||
      providerLoading ||
      providerListLoading,
  );

  return (
    <>
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("privileges.editPrivilege.header.breadcrumb.text")}`,
                href: `/privileges`,
              },

              {
                text: `${t("privileges.editPrivilege.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <Container
            header={
              <Header>{t("privileges.editPrivilege.header.label")}</Header>
            }
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate("/privileges");
                        }}
                        formAction="none"
                      >
                        {t("privileges.editPrivilege.header.actions.cancel")}
                      </Button>
                      <Button
                        formAction="submit"
                        variant="primary"
                        disabled={!hasPermission}
                      >
                        {t("privileges.editPrivilege.header.actions.submit")}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={1}>
                    <FormSection
                      gridDefinition={[
                        { colspan: { default: 12, xs: 3 } },
                        { colspan: { default: 12, xs: 5 } },
                      ]}
                    >
                      <RHFFloatingSelect
                        name="privilegeTypeId"
                        label={t(
                          "privileges.editPrivilege.header.fields.type.label",
                        )}
                        options={privilegesType.map((type) => ({
                          label: type.name,
                          value: "" + type.id,
                        }))}
                        action={{
                          label: `${t("privileges.editPrivilege.header.fields.type.actions.modify")}`,
                          onClick: () => {
                            setModalName(ADD_PRIVILEGE_TYPE);
                          },
                        }}
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("privileges.editPrivilege.header.fields.name")}
                        name="name"
                        stretch={false}
                      />
                    </FormSection>
                    <RHFSelect
                      label={t(
                        "privileges.editPrivilege.header.fields.provider",
                      )}
                      name={"providerId"}
                      stretch={false}
                      filteringType="auto"
                      options={providersList.map((provider) => ({
                        label: provider.firstName + " " + provider.lastName,
                        value: "" + provider.id,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFSelect
                      label={t(
                        "privileges.editPrivilege.header.fields.providerAffiliation",
                      )}
                      name={"providerAffiliationId"}
                      stretch={false}
                      options={provider?.providerAffiliations?.map(
                        (providerAffiliation) => ({
                          label:
                            providerAffiliation?.facility?.name ??
                            providerAffiliation.name,
                          value: "" + providerAffiliation.id,
                        }),
                      )}
                    />
                    <RHFDatePicker
                      label={t(
                        "privileges.editPrivilege.header.fields.expirationDate",
                      )}
                      name={"expirationDate"}
                      stretch={false}
                    />
                    <RHFSelect
                      label={t(
                        "privileges.editPrivilege.header.fields.followUpDate",
                      )}
                      name={"alertDays"}
                      stretch={false}
                      options={alertReminderOptions}
                    />
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </Container>
        }
      />
    </>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditPrivilege);
