// awsui
import {
  Alert,
  AppLayout,
  Box,
  ContentLayout,
  Flashbar,
  FlashbarProps,
} from "@cloudscape-design/components";
import { applyTheme } from "@cloudscape-design/components/theming";
// react
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
// constants
import {
  ADD_MANAGEPROVIDER_MODAL,
  CREATE_FACILITY,
  ADD_ROLE,
  EDIT_ROLE,
  CREATE_SECTION_MODAL,
  EDIT_FACILITY,
  EDIT_MEMBER,
  MANAGEPROVIDER_EDIT_MODAL,
  ADD_ASSOCIATED_FACILITY,
  ADD_ASSOCIATED_PROVIDER,
  ADD_CONTRACT_TYPE,
  EDIT_CONTRACT_TYPE,
  ADD_PRIVILEGE_TYPE,
  PACKAGE_TYPE,
  PACKAGE_STATUS,
  WORFLOW_STATUS,
  FACILITY_TYPE,
  EDIT_PSV,
  ADD_AUTO_PSV,
  CONTRACT_TYPE,
  PAYER_ENROLLMENT_STATUS,
  DIGITALFORMTYPE_MODAL,
  EDIT_PACKAGE,
  EDIT_TEMPLATE,
  AFFILIATION_STATUS,
  AFFILIATION_TYPE,
  BOARD_NAME,
  TASK_STATUS,
  TASK_TYPE,
  ADD_MY_TASK,
  SPECIALTY,
} from "../helpers/constants";
// redux
import { ApplicationState } from "../redux/store";
// modals
import createSectionModal from "../manageprofile/create-section-modal";
// css
import "./dashboard-layout.css";
import TopHeaderBar from "../components/TopHeaderBar";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { dismissFlashBarItem } from "../redux/UI/actions";
import addManageproviderModal from "../manageprovider/modals/add-manageprovider";
import manageproviderEditModal from "../manageprovider/modals/manageprovider-edit";
import SideBar from "./side-bar";
import editMemberModal from "../manageadmin/manageadmin-details/modals/edit-member-modal";
import addRoleModal from "../roles/modals/add-role-modal";
import editRoleModal from "../roles/modals/edit-role-modal";
import addAssociatedFacilitiesModal from "../roles/modals/add-associated-facilities-modal";
import addAssociatedProvidersModal from "../roles/modals/add-associated-providers-modal";
import addContracttypeModal from "../contracts/contracttype/modals/add-contracttype";
import editContracttypeModal from "../contracts/contracttype/modals/edit-contracttype";
import addPrivilegeType from "../privileges/privilegesType/modals/addPrivilegeType";
import packagestypemodal from "../credentialpackages/packages/modals/packagestypemodal";
import packagestatusmodal from "../credentialpackages/packages/modals/packagestatusmodal";
import facilityTypeModal from "../managefacility/modal/facility-type-modal";
import editPsvModal from "../providersafe/psv/modals/edit-psv-modal";
import AddAutoPSVModal from "../providersafe/psv/modals/add-auto-psv-modal";
import contracttypemodal from "../contracts/modals/contracttypemodal";
import payerenrollmentstatusmodal from "../payerenrollment/modals/payerenrollmentstatusmodal";
import digitalformtypeModal from "../digitalform/modals/digitalformtype-modal";
import editpackagemodal from "../credentialpackages/packages/modals/editpackagemodal";
import editcredentialtemplatemodal from "../credentialpackages/templates/modals/editcredentialtemplatemodal";
import addManageprovider from "../manageprovider/modals/add-manageprovider";
import provideraffiliationstatusmodal from "../provider/provider-profile/sections/modals/provideraffiliationstatusmodal";
import provideraffiliationtypemodal from "../provider/provider-profile/sections/modals/provideraffiliationtypemodal";
import workflowstatusmodal from "../workflow/modals/workflowstatusmodal";
import taskstatusmodal from "../workflow/tasks/modals/taskstatusmodal";
import tasktypemodal from "../workflow/tasks/modals/tasktypemodal";
import { Drawer } from "antd";
import MyTasks from "../workflow/tasks/my-task";
import addmytaskmodal from "../workflow/tasks/modals/addmytaskmodal";
import boardnamemodal from "../provider/provider-profile/sections/modals/providerboardnamemodal";
import specialtymodal from "../provider/provider-profile/sections/modals/providerspecialtymodal";

const modals = new Map<string, any>();
modals.set(DIGITALFORMTYPE_MODAL, digitalformtypeModal);
modals.set(ADD_MANAGEPROVIDER_MODAL, addManageproviderModal);
modals.set(MANAGEPROVIDER_EDIT_MODAL, manageproviderEditModal);
modals.set(CREATE_SECTION_MODAL, createSectionModal);
modals.set(ADD_ROLE, addRoleModal);
modals.set(ADD_ASSOCIATED_FACILITY, addAssociatedFacilitiesModal);
modals.set(ADD_ASSOCIATED_PROVIDER, addAssociatedProvidersModal);
modals.set(EDIT_ROLE, editRoleModal);
modals.set(EDIT_MEMBER, editMemberModal);
modals.set(ADD_CONTRACT_TYPE, addContracttypeModal);
modals.set(EDIT_CONTRACT_TYPE, editContracttypeModal);
modals.set(ADD_PRIVILEGE_TYPE, addPrivilegeType);
modals.set(PACKAGE_TYPE, packagestypemodal);
modals.set(PACKAGE_STATUS, packagestatusmodal);
modals.set(WORFLOW_STATUS, workflowstatusmodal);
modals.set(TASK_STATUS, taskstatusmodal);
modals.set(TASK_TYPE, tasktypemodal);
modals.set(ADD_MY_TASK, addmytaskmodal);

modals.set(FACILITY_TYPE, facilityTypeModal);
modals.set(EDIT_PSV, editPsvModal);
modals.set(ADD_AUTO_PSV, AddAutoPSVModal);
modals.set(CONTRACT_TYPE, contracttypemodal);
modals.set(PAYER_ENROLLMENT_STATUS, payerenrollmentstatusmodal);
modals.set(EDIT_PACKAGE, editpackagemodal);
modals.set(EDIT_TEMPLATE, editcredentialtemplatemodal);
modals.set(ADD_MANAGEPROVIDER_MODAL, addManageprovider);
modals.set(AFFILIATION_STATUS, provideraffiliationstatusmodal);
modals.set(AFFILIATION_TYPE, provideraffiliationtypemodal);
modals.set(BOARD_NAME, boardnamemodal);
modals.set(SPECIALTY, specialtymodal);

interface PropsFromState {
  modalName: string;
  flashBarItems: FlashbarProps.MessageDefinition[];
}
interface PropsFromDispatch {
  dismissFlashBarItem: typeof dismissFlashBarItem;
}

const theme = {
  tokens: {
    colorBackgroundLayoutMain: {
      light: "#f4f4f4",
      dark: "#0f0f0f",
    },
    colorBackgroundContainerContent: {
      light: "#fff",
      dark: "#0f0f0f",
    },
    colorBackgroundContainerHeader: {
      light: "#fff",
      dark: "#0f0f0f",
    },
  },
  contexts: {
    "top-navigation": {
      tokens: {
        colorBackgroundContainerContent: {
          light: "#0f0f0f",
          dark: "#0f0f0f",
        },
      },
    },
  },
};
applyTheme({ theme });

const appStyle = {
  backgroundColor: "#000",
};

function DashboardLayout({
  modalName,
  flashBarItems,
  dismissFlashBarItem,
}: PropsFromState & PropsFromDispatch) {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const [activeHref, setActiveHref] = useState<string>("");
  useEffect(() => {
    setActiveHref("/".concat(pathname.split("/").at(1) ?? "#"));
  }, [pathname, navOpen]);

  const disableContentPaddings = useMemo(() => {
    return pathname.includes("designer") || pathname.includes("list");
  }, [pathname]);

  const Modal = modals.get(modalName);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {Modal && <Modal />}
      <TopHeaderBar showDrawer={showDrawer} />
      <Alert dismissible statusIconAriaLabel="Info" header="Exciting News!">
        Ready Doc is undergoing a rebrand! Over the next few weeks, you’ll
        notice updates to our platform that reflect our new identity,
        Incredable. These changes will include a refreshed logo, updated colors,
        and enhanced features — all designed to improve your experience. -The
        Incredable Team
      </Alert>
      <ContentLayout disableOverlap>
        <AppLayout
          contentType="default"
          headerSelector="#topNavigation"
          className="appLayout"
          disableContentPaddings={disableContentPaddings}
          notifications={
            flashBarItems.length && (
              <Box padding={{ top: "s" }}>
                <Flashbar
                  stackItems={true}
                  items={[...flashBarItems].map((item) => {
                    if (item.dismissible) {
                      return {
                        ...item,
                        onDismiss: () => {
                          item.id && dismissFlashBarItem(item.id);
                        },
                      };
                    } else return item;
                  })}
                />
              </Box>
            )
          }
          navigation={<SideBar activeHref={activeHref} />}
          navigationOpen={navOpen}
          onNavigationChange={(changeDetails) => {
            setNavOpen(changeDetails.detail.open);
            setActiveHref(pathname);
          }}
          navigationWidth={220}
          maxContentWidth={
            searchParams.has("preview") &&
            searchParams.get("preview") === "true"
              ? Number.MAX_VALUE
              : undefined
          }
          content={<Outlet />}
          toolsHide={true}
        />
        <Drawer title="My Tasks" size="large" onClose={onClose} open={open}>
          <MyTasks />
        </Drawer>
      </ContentLayout>
    </div>
  );
}

const mapDispatchToProps = {
  dismissFlashBarItem: dismissFlashBarItem,
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    modalName: state.ui.modalName,
    flashBarItems: state.ui.flashBarItems,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
