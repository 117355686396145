import { Header, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { connect } from "react-redux";
import { setModalName } from "../redux/UI/actions";
import { useEffect, useState } from "react";
import RolesTable from "./manage-roles/roles-table";
import { useSearchParams } from "react-router-dom";
import PermissionsTable from "./manage-permissions";
import { useGetAllRoleQuery } from "../redux/api/role/role";
import { RolePermissions } from "../redux/api/role/types";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function RolesAndPermissions({ openModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetAllRoleQuery();

  const [roles, setRoles] = useState<RolePermissions[]>([]);

  useEffect(() => {
    if (data)
      setRoles(
        data.map((role) => {
          return {
            id: role.id,
            name: role.name,
            default: role.default,
            permissions: role.permissions.map((permission) => permission.key),
          };
        }),
      );
  }, [data]);

  useEffect(() => {
    if (searchParams.has("activeTab") && searchParams.get("activeTab"))
      setActiveTabId(searchParams.get("activeTab") ?? "groups");
  }, [searchParams]);

  return (
    <div
      style={{
        padding: "24px 10px 0px 10px",
      }}
    >
      <LoadingScreen isOpen={isLoading} />
      <SpaceBetween size={"xxs"} direction="vertical">
        <Header>{t("rolesandpermissions.header.label")}</Header>
        <Tabs
          activeTabId={activeTabId}
          onChange={(changeDetail) => {
            setActiveTabId(changeDetail.detail.activeTabId);
            setSearchParams({ activeTab: changeDetail.detail.activeTabId });
          }}
          tabs={[
            {
              label: `${t("rolesandpermissions.header.tabs.roles")}`,
              id: "roles",
              content: <RolesTable />,
            },
            {
              label: `${t("rolesandpermissions.header.tabs.permissions")}`,
              id: "permissions",
              content: <PermissionsTable roles={roles} setRoles={setRoles} />,
            },
          ]}
        />
      </SpaceBetween>
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(RolesAndPermissions);
