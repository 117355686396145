import { v4 as uuidv4 } from "uuid";
import useNotify from "./useNotify";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { captureException } from "@sentry/react";

export default function useAsyncNotifyWrapper() {
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();

  const notifyWrapper = async ({
    promise,
    actionName,
    resourceName,
    callback,
  }: {
    promise: MutationActionCreatorResult<any>;
    actionName: string;
    resourceName: string;
    callback?: (id: string) => void;
  }) => {
    const notificationId = uuidv4();
    try {
      notifyInProgress({
        name: resourceName,
        action:
          actionName.length >= 4
            ? actionName.charAt(3).endsWith("a") ||
              actionName.charAt(3).endsWith("e") ||
              actionName.charAt(3).endsWith("i") ||
              actionName.charAt(3).endsWith("o") ||
              actionName.charAt(3).endsWith("u")
              ? actionName.substring(0, actionName.length >= 6 ? 6 : 5) + "ing"
              : actionName.substring(0, 4) + "ing"
            : actionName + "ing",
        id: notificationId,
      });
      await promise
        .unwrap()
        .then((result: any) => {
          notifySucess({
            name: resourceName,
            // if action name is ending with e then just append d
            action: !!actionName.endsWith("e")
              ? actionName + "d"
              : actionName + "ed",
            id: notificationId,
          });
          if (!!result?.id) {
            callback && callback(result?.id);
          }
        })
        .catch((error) => {
          captureException(error);
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: resourceName,
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: resourceName,
              action: actionName,
              id: notificationId,
            });
        });
    } catch (error) {
      captureException(error);
      notifyFailed({
        name: resourceName,
        action: actionName,
        id: notificationId,
      });
    }
  };
  return { notifyWrapper };
}
