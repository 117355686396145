import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useEffect } from "react";
import { setModalName } from "../../../redux/UI/actions";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";
import {
  useGetMemberQuery,
  useUpdateMemberRoleMutation,
} from "../../../redux/api/manageadmin/manageadmin";
import { useParams } from "react-router";
import FormSection from "../../../components/FormSection";
import { useGetAllRoleQuery } from "../../../redux/api/role/role";
import useIsLoading from "../../../hooks/useIsLoading";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

function EditMember({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { manageadminId } = useParams();
  const { data: member } = useGetMemberQuery(
    !!manageadminId
      ? {
          activeId: manageadminId,
        }
      : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false),
  );
  const methods = useForm<FormInputType>();
  const [updateMemberRole, { isLoading: isCreating }] =
    useUpdateMemberRoleMutation();
  const { data: roles, isLoading: isRoleLoading } = useGetAllRoleQuery();

  useIsLoading(isCreating || isRoleLoading);

  useEffect(() => {
    if (!!member) {
      methods.setValue("firstName", member.firstName);
      methods.setValue("lastName", member?.lastName);
      methods.setValue("email", member?.email);
      methods.setValue("role", member?.role.id);
    }
  }, [member]);

  const onSubmit = async (data: FormInputType) => {
    if (data.role && manageadminId != null) {
      await notifyWrapper({
        promise: updateMemberRole({
          id: "" + manageadminId,
          role_ids: data.role,
        }),
        resourceName: "member",
        actionName: "update",
      });
    }
    setModalName("");
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={
        <Header variant="h2">
          {t("members.header.active.editMember.header.label")}
        </Header>
      }
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <FormSection columns={2}>
              <RHFTextField
                label={t(
                  "members.header.active.editMember.header.fields.firstName",
                )}
                name="firstName"
                stretch
                readOnly
                rules={{ required: "This field is required" }}
              />
              <RHFTextField
                label={t(
                  "members.header.active.editMember.header.fields.lastName",
                )}
                name="lastName"
                stretch
                readOnly
                rules={{ required: "This field is required" }}
              />
            </FormSection>
            <RHFTextField
              label={t("members.header.active.editMember.header.fields.email")}
              name="email"
              stretch
              readOnly
              rules={{
                required: "This field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email",
                },
              }}
            />
            <RHFSelect
              label={t("members.header.active.editMember.header.fields.role")}
              name={"role"}
              disabled={member?.role.default || !hasPermission}
              options={roles?.map((role) => {
                return {
                  label: role.name,
                  value: role.id,
                  disabled: role.default,
                };
              })}
            />
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  {t("members.header.active.editMember.header.actions.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!hasPermission}
                  formAction="submit"
                  variant="primary"
                >
                  {t("members.header.active.editMember.header.actions.save")}
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditMember);
