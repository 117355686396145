import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import { Category } from "../redux/api/category/types";

export type FilterType = {
  filterCategoryName: string;
  stateRequiredFilterOptions: OptionDefinition[];
  expirationRequiredFilterOptions: OptionDefinition[];
};

export default function useCategoryFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllCategoryQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterCategoryName: "",
    stateRequiredFilterOptions: [],
    expirationRequiredFilterOptions: [],
  });

  const [filteredCategory, setFilteredCategory] = useState<Category[]>(data);

  useEffect(() => {
    setFilteredCategory(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_category = [...data];
    if (filter.filterCategoryName) {
      temp_filtered_category = temp_filtered_category.filter((category) =>
        category.name
          .toLowerCase()
          .includes(filter.filterCategoryName.toLowerCase()),
      );
    }

    const selectedStateRequired = filter.stateRequiredFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedStateRequired && selectedStateRequired.length > 0) {
      temp_filtered_category = temp_filtered_category.filter(
        (category) =>
          selectedStateRequired.indexOf(category.stateRequired + "") >= 0,
      );
    }

    const selectedExpirationRequired = filter.expirationRequiredFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedExpirationRequired && selectedExpirationRequired.length > 0) {
      temp_filtered_category = temp_filtered_category.filter(
        (category) =>
          selectedExpirationRequired.indexOf(
            category?.expirationRequired + "",
          ) >= 0,
      );
    }
    setFilteredCategory(temp_filtered_category);
  }, [filter]);

  return {
    filteredCategory,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
