import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Link,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import StyledRouterLink from "../components/styled-route-link";
import {
  PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS,
  PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import { useDeletePayerEnrollmentMutation } from "../redux/api/payerenrollment/payerenrollment";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import ChangeTimeZone from "../components/Timezone";
import KeyValue from "../components/KeyValue";
import { useGetPayerQuery } from "../redux/api/payers/payers";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import PayerEnrollmentFilters from "../components/filter/payerenrollment-filters";
import usePayerEnrollmentFilter from "../hooks/usePayerEnrollmentFilter";
import { usePageIndex } from "../hooks/usePageIndex";
import { PATH_DASHBOARD } from "../routes/paths";

export default function PayerEnrollments() {
  const navigate = useNavigate();
  const { id: payerId } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { data: payer } = useGetPayerQuery(payerId ? { payerId } : skipToken);
  const [selectedItem, setSelectedItem] = useState<PayerEnrollment>();
  const [deletePayerEnrollment] = useDeletePayerEnrollmentMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-PayerEnrollment-Table-Preferences",
    PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES,
  );
  const { filteredPayers, filter, isLoading, isFetching, setFilter, refetch } =
    usePayerEnrollmentFilter();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayers,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName="Payer Enrollment"
            action={() => {
              navigate(`addenrollment`);
            }}
          />
        ),
      },
      selection: { keepSelection: true, trackBy: (item) => item.id },
      pagination: {
        pageSize: tablePreferences.pageSize,
        defaultPage:
          Number(searchParams.get("enrollmentPageIndex")) ?? undefined,
      },
      sorting: {
        defaultState: {
          isDescending: true,
          sortingColumn: { sortingField: "id" },
        },
      },
    },
  );

  usePageIndex(String(paginationProps.currentPageIndex), {
    queryParamName: "enrollmentPageIndex",
  });

  const tableProps: TableProps<PayerEnrollment> = useMemo(() => {
    return {
      trackBy: (item) => item.id,
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>{t("payerenrollments.contentTable.header.fields.id")}</div>
          ),
          cell: (item) => item.id,
          sortingField: "id",
        },
        {
          id: "network",
          header: (
            <div>
              {t(
                "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.payergroup",
              )}
            </div>
          ),
          cell: (item) => item?.payerGroup?.name,
          sortingField: "payerGroup.name",
          sortingComparator: (a, b) =>
            (a?.payerGroup?.name ?? "").localeCompare(
              b?.payerGroup?.name ?? "",
            ),
        },
        {
          id: "payer",
          header: (
            <div>{t("payerenrollments.contentTable.header.fields.payer")}</div>
          ),
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editenrollment/${item.id}` : `#`}
              label={item?.payer?.name}
            />
          ),
          sortingField: "payer.name",
          sortingComparator: (a, b) =>
            (a?.payer?.name ?? "").localeCompare(b?.payer?.name ?? ""),
        },
        {
          id: "status",
          header: (
            <div>{t("payerenrollments.contentTable.header.fields.status")}</div>
          ),
          cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
          sortingField: "status.name",
          sortingComparator: (a, b) =>
            (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
        },
        {
          id: "payerProviderId",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.payerProviderId")}
            </div>
          ),
          cell: (item) => <div>{item?.payerProviderId}</div>,
          sortingField: "payerProviderId",
          sortingComparator: (a, b) =>
            (a?.payerProviderId ?? "").localeCompare(b?.payerProviderId ?? ""),
        },
        {
          id: "provider",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.provider")}
            </div>
          ),
          cell: (item) => (
            <span>
              {(item?.provider &&
                item?.provider?.firstName + " " + item?.provider?.lastName) ??
                "-"}
            </span>
          ),
          sortingField: "provider.firstName",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.firstName ?? "",
            ),
        },
        {
          id: "facility",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.facility")}
            </div>
          ),
          cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
          sortingField: "facility.name",
          sortingComparator: (a, b) =>
            (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
        },
        {
          id: "submittedDate",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.submittedDate")}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.submittedDate) ?? "-"}</span>
          ),
        },
        {
          id: "approvedDate",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.approvedDate")}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.approvedDate) ?? "-"}</span>
          ),
        },
        {
          id: "effectiveDate",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.effectiveDate")}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.effectiveDate) ?? "-"}</span>
          ),
        },

        {
          id: "recredentialingDate",
          header: (
            <div>
              {t(
                "payerenrollments.contentTable.header.fields.recredentialingDate",
              )}
            </div>
          ),
          cell: (item) => (
            <span>{ChangeTimeZone(item?.recredentialingDate) ?? "-"}</span>
          ),
        },
        {
          id: "followupDate",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.followupDate")}
            </div>
          ),
          cell: (item) => (
            <span>{item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}</span>
          ),
        },
        {
          id: "description",
          header: (
            <div>
              {t("payerenrollments.contentTable.header.fields.description")}
            </div>
          ),
          cell: (item) => <span>{item?.description ?? "-"}</span>,
        },
        {
          id: "workflow",
          header: (
            <div>
              {t("payerenrollments.addPayerEnrollment.header.fields.workflow")}
            </div>
          ),
          cell: (item) =>
            item?.workflow?.name ? (
              <Link
                external
                href={
                  hasPermission && item?.workflow?.id
                    ? PATH_DASHBOARD.manage.workflows.edit(item.workflow.id)
                    : `#`
                }
              >
                {item?.workflow?.name}
              </Link>
            ) : (
              <>-</>
            ),
        },
      ],
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Payer Enrollment Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      filter: <PayerEnrollmentFilters filter={filter} setFilter={setFilter} />,
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loading: isLoading || isFetching,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      header: (
        <Header
          counter={`(${filteredPayers.length})`}
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  {
                    text: hasPermission
                      ? `${t("payerenrollments.contentTable.header.actions.payerEnrollmentEdit")}`
                      : `${t("payerenrollments.contentTable.header.actions.payerEnrollmentView")}`,
                    id: "edit",
                  },
                  {
                    text: `${t("payerenrollments.contentTable.header.actions.payerEnrollmentDelete")}`,
                    id: "delete",
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editenrollment/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && payerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                {t(
                  "payerenrollments.contentTable.header.actions.payerEnrollmentActions",
                )}
              </ButtonDropdown>
              <Button
                disabled={!hasPermission}
                variant="primary"
                onClick={() => {
                  navigate(`addenrollment`);
                }}
              >
                {t(
                  "payerenrollments.contentTable.header.actions.addPayerEnrollment",
                )}
              </Button>
            </SpaceBetween>
          }
        >
          {payer?.name}
        </Header>
      ),
    };
  }, [selectedItem, tablePreferences, items]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!selectedItem?.id && !!payerId) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePayerEnrollment({
                payerEnrollmentId: selectedItem?.id,
              }),
              resourceName: "payer enrollment",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("payerenrollments.delete.header.label")}
        content={
          <SpaceBetween size="s" direction="vertical">
            <KeyValue
              label={t("payerenrollments.delete.header.content.label")}
              value={selectedItem?.payer.name}
            />
            <KeyValue
              label={t("payerenrollments.delete.header.content.providerLabel")}
              value={
                selectedItem?.provider?.firstName +
                " " +
                selectedItem?.provider?.lastName
              }
            />
          </SpaceBetween>
        }
        description={t("payerenrollments.delete.header.content.description")}
      />
    </div>
  );
}
