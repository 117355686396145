import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { PrivilegeCreateRequest } from "../../redux/api/privileges/types";
import {
  useGetAllLicenseTypesQuery,
  useGetAllProvidersQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCreatePrivilegeMutation,
  useGetAllPrivilegeTypesQuery,
} from "../../redux/api/privileges/privileges";
import InnerAppLayout from "../../components/InnerAppLayout";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import {
  ADD_PRIVILEGE_TYPE,
  alertReminderOptions,
} from "../../helpers/constants";
import Select from "@cloudscape-design/components/select";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useTranslation } from "react-i18next";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { skipToken } from "@reduxjs/toolkit/query";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddPrivileges({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PrivilegeCreateRequest>();
  const { t } = useTranslation();
  const { data: privilegesType = [] } = useGetAllPrivilegeTypesQuery();
  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const [addPrivilege] = useCreatePrivilegeMutation();
  const providerId = methods.watch("providerId");
  useEffect(() => {
    methods.resetField("providerAffiliationId");
  }, [providerId]);
  const { data: getProvider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const onSubmit = async (data: PrivilegeCreateRequest) => {
    await notifyWrapper({
      promise: addPrivilege({
        name: data?.name,
        description: data?.description,
        privilegeTypeId: data.privilegeTypeId,
        categoryId: data.categoryId,
        licenseTypeId: data.licenseTypeId,
        providerId: data?.providerId,
        providerAffiliationId: data.providerAffiliationId,
        expirationDate: data.expirationDate,
        alertDays: data.alertDays,
      }),
      resourceName: "privilege",
      actionName: "create",
    });
    navigate(-1);
  };

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("privileges.addPrivilege.header.breadcrumb.text")}`,
              href: `/privileges`,
            },

            {
              text: `${t("privileges.addPrivilege.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("privileges.addPrivilege.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate("/privileges");
                      }}
                      formAction="none"
                    >
                      {t("privileges.addPrivilege.header.actions.cancel")}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t("privileges.addPrivilege.header.actions.submit")}
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <FormSection
                    gridDefinition={[
                      { colspan: { default: 12, xs: 3 } },
                      { colspan: { default: 12, xs: 5 } },
                    ]}
                  >
                    <RHFFloatingSelect
                      name="privilegeTypeId"
                      label={t(
                        "privileges.addPrivilege.header.fields.type.label",
                      )}
                      rules={{ required: "This field is required" }}
                      options={privilegesType.map((type) => ({
                        label: type.name,
                        value: "" + type.id,
                      }))}
                      action={{
                        label: `${t("privileges.addPrivilege.header.fields.type.actions.modify")}`,
                        onClick: () => {
                          setModalName(ADD_PRIVILEGE_TYPE);
                        },
                      }}
                      stretch={false}
                    />
                    <RHFTextField
                      label={t("privileges.addPrivilege.header.fields.name")}
                      name="name"
                      stretch={false}
                    />
                  </FormSection>
                  <RHFSelect
                    label={t("privileges.addPrivilege.header.fields.provider")}
                    name={"providerId"}
                    stretch={false}
                    filteringType="auto"
                    options={provider.map((temp) => ({
                      label: temp.firstName + " " + temp.lastName,
                      value: "" + temp.id,
                    }))}
                    rules={{ required: "This field is required" }}
                  />
                  <RHFSelect
                    label={t(
                      "privileges.addPrivilege.header.fields.providerAffiliation",
                    )}
                    name={"providerAffiliationId"}
                    stretch={false}
                    options={getProvider?.providerAffiliations?.map((temp) => ({
                      label: temp?.facility?.name ?? temp.name,
                      value: "" + temp.id,
                    }))}
                    empty={t(
                      "privileges.addPrivilege.header.emptyAffiliationsLabel",
                    )}
                  />
                  <RHFDatePicker
                    label={t(
                      "privileges.addPrivilege.header.fields.expirationDate",
                    )}
                    name={"expirationDate"}
                    stretch={false}
                  />
                  <RHFSelect
                    label={t(
                      "privileges.addPrivilege.header.fields.followUpDate",
                    )}
                    name={"alertDays"}
                    stretch={false}
                    options={alertReminderOptions}
                  />
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddPrivileges);
