import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { Contract } from "../redux/api/contracts/types";
import { useGetAllContractsQuery } from "../redux/api/contracts/contract";

export type FilterType = {
  filterCompanyName: string;
  contractTypeFilterOptions: OptionDefinition[];
};

export default function useContractFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllContractsQuery();

  const [filter, setFilter] = useState<FilterType>({
    filterCompanyName: "",
    contractTypeFilterOptions: [],
  });

  const [filteredContract, setFilteredContract] = useState<Contract[]>(data);

  useEffect(() => {
    setFilteredContract(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_contract = [...data];
    if (filter.filterCompanyName) {
      temp_filtered_contract = temp_filtered_contract.filter((contract) =>
        contract.title
          .toLowerCase()
          .includes(filter.filterCompanyName.toLowerCase()),
      );
    }

    const selectedContractTypeId = filter.contractTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedContractTypeId && selectedContractTypeId.length > 0) {
      temp_filtered_contract = temp_filtered_contract.filter(
        (contract) =>
          selectedContractTypeId.indexOf(contract.contractType?.id + "") >= 0,
      );
    }

    setFilteredContract(temp_filtered_contract);
  }, [filter]);

  return {
    filteredContract,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
