import { FormProvider, useForm } from "react-hook-form";
import { ProviderDeaType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderDeaMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useTranslation } from "react-i18next";
import { USStatesList } from "../../../../helpers/constants";

export default function EditDea() {
  const { t } = useTranslation();
  const methods = useForm<ProviderDeaType>();
  const { providerId, deaId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const onSubmit = async (data: ProviderDeaType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderDea({
          providerId: providerId,
          ...data,
          documentId:
            selectedDocument.value !== "-1" ? selectedDocument.value : "",
        }),
        resourceName: "dea",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderDea] = useAddProviderDeaMutation();

  const [type] = methods.watch(["type"]);

  useEffect(() => {
    methods.resetField("state");
  }, [type]);

  useEffect(() => {
    if (provider && provider.providerDeas) {
      const dea = provider.providerDeas.find(
        (dea) => dea?.id + "" === deaId + "",
      );

      if (dea) {
        methods.setValue("id", dea.id);
        methods.setValue("type", dea?.type);
        methods.setValue("deaNumber", dea?.deaNumber);
        methods.setValue("startDate", dea?.startDate);
        methods.setValue("endDate", dea?.endDate);
        methods.setValue("status", dea?.status);
        methods.setValue("state", dea?.state);
        methods.setValue("document", dea?.document);
        dea?.document &&
          setSelectedDocument({
            label: dea?.document?.name,
            value: dea?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, deaId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.dea.editDEA.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=dea`,
            },
            {
              text: `${t("providerProfiles.dea.editDEA.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("providerProfiles.dea.editDEA.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.dea.editDEA.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.dea.editDEA.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFSelect
                        name="type"
                        label={t(
                          "providerProfiles.dea.editDEA.header.fields.type",
                        )}
                        options={["Federal", "State"].map((type) => ({
                          value: type,
                        }))}
                      />
                      <RHFTextField
                        label={t(
                          "providerProfiles.dea.editDEA.header.fields.deaNumber",
                        )}
                        name="deaNumber"
                        rules={{
                          required: "This field is required",
                        }}
                        stretch
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.dea.editDEA.header.fields.startDate",
                        )}
                        name="startDate"
                      />
                      <RHFDatePicker
                        label={t(
                          "providerProfiles.dea.editDEA.header.fields.endDate",
                        )}
                        name="endDate"
                      />
                      {type === "State" && (
                        <RHFSelect
                          label={t(
                            "providerProfiles.dea.editDEA.header.fields.state",
                          )}
                          name="state"
                          options={USStatesList.map((state) => ({
                            value: state.value,
                          }))}
                        />
                      )}
                      <RHFSelect
                        name="status"
                        label={t(
                          "providerProfiles.dea.editDEA.header.fields.status",
                        )}
                        options={["Active", "Inactive"].map((status) => ({
                          value: status,
                        }))}
                      />
                    </FormSection>
                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
