import {
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Table,
  Tabs,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCredentialTemplateCategoriesMutation,
  useDeleteCredentialTemplateMutation,
  useGetAllCredentialTemplateCategoriesQuery,
  useGetCredentialTemplateQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import FormsList from "./forms-list";
import KeyValue from "../../components/KeyValue";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useState } from "react";
import CredentialTemplateAddCategoriesModal from "./credentialtemplate-add-catgories-modal";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_TEMPLATE } from "../../helpers/constants";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { DigitalForm } from "../../redux/api/digitalform/types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { Category } from "../../redux/api/category/types";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function CredentialTemplateDetails({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  const { id: credentialTemplateId } = useParams();
  const { t } = useTranslation();
  const { data: credentialTemplate } = useGetCredentialTemplateQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedForms, setSelectedForms] = useState<DigitalForm[]>([]);
  const [deleteCredentialTemplate] = useDeleteCredentialTemplateMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false),
  );

  return (
    <SpaceBetween size="s">
      <BreadcrumbGroup
        items={[
          {
            text: `${t("credentialTemplates.templateDetails.breadcrumb.text")}`,
            href: PATH_DASHBOARD.credential.templates.list,
          },
          {
            text:
              credentialTemplate?.name ??
              `${t("credentialTemplates.templateDetails.breadcrumb.currentPage")}`,
            href: "#",
          },
        ]}
      />

      <SpaceBetween size="xxl">
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    disabled={!hasDeletePermission}
                    onClick={() => {
                      if (credentialTemplateId) OpenDeleteModal(true);
                    }}
                  >
                    {t(
                      "credentialTemplates.templateDetails.details.header.actions.delete",
                    )}
                  </Button>
                  <Button
                    disabled={!hasPermission}
                    onClick={() => {
                      if (credentialTemplateId) openModalName(EDIT_TEMPLATE);
                    }}
                  >
                    {t(
                      "credentialTemplates.templateDetails.details.header.actions.edit",
                    )}
                  </Button>
                </SpaceBetween>
              }
            >
              {t("credentialTemplates.templateDetails.details.header.label")}
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <KeyValue
              label={t(
                "credentialTemplates.templateDetails.details.header.fields.name",
              )}
              value={credentialTemplate?.name ?? "-"}
            />
            <KeyValue
              label={t(
                "credentialTemplates.templateDetails.details.header.fields.categoriesCount",
              )}
              value={credentialTemplate?.categories?.length ?? "-"}
            />
            <KeyValue
              label={t(
                "credentialTemplates.templateDetails.details.header.fields.licenseType",
              )}
              value={credentialTemplate?.licenseType?.name ?? "-"}
            />
          </ColumnLayout>
        </Container>
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (credentialTemplateId) {
              OpenDeleteModal(false);
              await notifyWrapper({
                promise: deleteCredentialTemplate({
                  credentialTemplateId: credentialTemplateId,
                }),
                resourceName: "template",
                actionName: "delete",
              });
              navigate(-1);
            }
          }}
          closeModal={() => OpenDeleteModal(false)}
          header={t("credentialTemplates.delete.header.label")}
          content={
            <>
              <Box>{t("credentialTemplates.delete.header.content.label")}</Box>
              <Box variant="awsui-key-label">{credentialTemplate?.name}</Box>
            </>
          }
          description={t(
            "credentialTemplates.delete.header.content.description",
          )}
        />
        <Tabs
          tabs={[
            {
              label: "Forms",
              id: "forms",
              content: (
                <FormsList
                  forms={credentialTemplate?.digitalForm ?? []}
                  selectedForms={selectedForms}
                  setSelectForms={setSelectedForms}
                />
              ),
            },
            {
              label: "Categories",
              id: "documents",
              content: (
                <CategoryList
                  categories={credentialTemplate?.categories ?? []}
                  selectedCategories={selectedCategories}
                  setSelectCategories={setSelectedCategories}
                />
              ),
            },
          ]}
        />
      </SpaceBetween>
    </SpaceBetween>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CredentialTemplateDetails);

function CategoryList({
  selectedCategories,
  setSelectCategories,
}: {
  categories: Category[];
  selectedCategories: Category[];
  setSelectCategories: (selectedCategories: Category[]) => void;
}) {
  const { id: credentialTemplateId } = useParams();
  const { t } = useTranslation();
  const {
    data: categories = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllCredentialTemplateCategoriesQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState<boolean>(false);

  const [deleteCredentialTemplateCategories] =
    useDeleteCredentialTemplateCategoriesMutation();

  return (
    <>
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: (
              <div>
                {t(
                  "credentialTemplates.templateDetails.categoriesList.table.header.fields.name",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
        ]}
        items={categories}
        selectedItems={selectedCategories ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectCategories(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedCategories;
          if (!detail.item) return;
          if (temp?.includes(detail.item))
            setSelectCategories(temp.filter((item) => item !== detail.item));
          else setSelectCategories([detail.item, ...temp]);
        }}
        selectionType="multi"
        loadingText="Loading..."
        loading={isLoading || isFetching}
        stickyHeader
        sortingDisabled
        header={
          <Header
            variant="h3"
            counter={`(${categories.length})`}
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
                <Button
                  onClick={() => {
                    if (!selectedCategories || !credentialTemplateId) return;
                    const categoryIds = selectedCategories.map(
                      (category) => category.id + "",
                    );
                    deleteCredentialTemplateCategories({
                      credentialTemplateId: credentialTemplateId,
                      categories: categoryIds,
                    });
                  }}
                >
                  {t(
                    "credentialTemplates.templateDetails.categoriesList.table.header.actions.remove",
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setIsAddCategoryModalVisible(true);
                  }}
                >
                  {t(
                    "credentialTemplates.templateDetails.categoriesList.table.header.actions.addCategory",
                  )}
                </Button>
              </SpaceBetween>
            }
          >
            {t(
              "credentialTemplates.templateDetails.categoriesList.table.header.label",
            )}
          </Header>
        }
      />
      <CredentialTemplateAddCategoriesModal
        closeModal={() => {
          setIsAddCategoryModalVisible(false);
        }}
        visible={isAddCategoryModalVisible}
      />
    </>
  );
}
