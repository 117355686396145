import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

type DeleteModalProps = {
  visible: boolean;
  action: VoidFunction;
  closeModal: VoidFunction;
  header: React.ReactNode | string;
  content: React.ReactNode | string;
  description: React.ReactNode | string;
};

export default function DeleteAlertModal({
  visible,
  action,
  header,
  closeModal,
  content,
  description,
}: DeleteModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      header={header}
      onDismiss={closeModal}
      footer={
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={closeModal}>
              {t("deleteAlertModal.header.actions.cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              onClick={() => {
                action();
              }}
            >
              {t("deleteAlertModal.header.actions.delete")}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpaceBetween size={"xs"}>
        {content}
        <div>{description}</div>
      </SpaceBetween>
    </Modal>
  );
}
