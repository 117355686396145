import {
  Alert,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Switch } from "antd";

import KeyValue from "../../../../components/KeyValue";
import FormSection from "../../../../components/FormSection";
import { Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProviderQuery } from "../../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import ChangeTimeZone from "../../../../components/Timezone";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import React from "react";
import { AsYouType } from "libphonenumber-js";

export default function PersonalInfo() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [showSSN, setShowSSN] = React.useState(false);
  const [alertShow, setAlertShow] = React.useState(false);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("ssn.view") ?? false),
  );

  const showSecrets = () => {
    if (hasPermission) {
      setShowSSN(!showSSN);
    } else {
      setAlertShow(!alertShow);
    }
  };

  return (
    <Container
      header={
        <SpaceBetween size="xxs">
          <Header
            actions={
              <Button
                onClick={() => {
                  navigate(`editpersonalinfo`);
                }}
              >
                {t("providerProfiles.personalInfo.header.actions.edit")}
              </Button>
            }
          >
            {t("providerProfiles.personalInfo.header.label")}
          </Header>
          <Divider></Divider>
        </SpaceBetween>
      }
    >
      <div style={{ paddingTop: "12px" }}>
        <FormSection columns={4}>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.middleName")}
            value={provider?.personalInfo?.middleName ?? "-"}
          ></KeyValue>

          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.hasOtherName",
            )}
            value={
              provider?.personalInfo?.hasOtherName === true
                ? "Yes"
                : "No" ?? "No"
            }
          ></KeyValue>
          {provider?.personalInfo?.hasOtherName === true ? (
            <KeyValue
              label={t("providerProfiles.personalInfo.header.fields.otherName")}
              value={provider?.personalInfo?.otherName ?? "-"}
            ></KeyValue>
          ) : null}

          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.degree")}
            value={provider?.personalInfo?.degree?.name ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.gender")}
            value={provider?.personalInfo?.gender ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.dateOfBirth")}
            value={ChangeTimeZone(provider?.personalInfo?.dob) ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.placeOfBirth",
            )}
            value={provider?.personalInfo?.placeOfBirth ?? "-"}
          ></KeyValue>
          <SpaceBetween size="xs">
            <KeyValue
              label={t("providerProfiles.personalInfo.header.fields.ssn")}
              value={provider?.personalInfo?.ssn ?? "-"}
              maskContent={showSSN ? false : true}
            ></KeyValue>
            {alertShow ? (
              <Alert dismissible onDismiss={showSecrets} type="warning">
                You Don't Have A Permission
              </Alert>
            ) : (
              <SpaceBetween direction="horizontal" size="xs">
                <Switch size="small" value={showSSN} onClick={showSecrets} />
                Show SSN
              </SpaceBetween>
            )}
          </SpaceBetween>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.passportNo")}
            value={provider?.personalInfo?.passportNo ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.citizenship")}
            value={provider?.personalInfo?.citizenship ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.mobile")}
            value={
              new AsYouType("US").input(provider?.personalInfo?.mobile ?? "") ??
              "-"
            }
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.personalEmail",
            )}
            value={provider?.personalInfo?.email ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.homePhone")}
            value={
              new AsYouType("US").input(
                provider?.personalInfo?.homePhone ?? "",
              ) ?? "-"
            }
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.fax")}
            value={provider?.personalInfo?.fax ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.haveYouEverServedInTheUSMilitary",
            )}
            value={provider?.personalInfo?.hasWorkedInMilitary ?? "No"}
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.branchOfMilitary",
            )}
            value={provider?.personalInfo?.branchOfMilitary ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.militaryDateOfService",
            )}
            value={
              ChangeTimeZone(provider?.personalInfo?.militaryDateOfService) ??
              "-"
            }
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.addressLane1",
            )}
            value={provider?.personalInfo?.addressLane1 ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t(
              "providerProfiles.personalInfo.header.fields.addressLane2",
            )}
            value={provider?.personalInfo?.addressLane2 ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.city")}
            value={provider?.personalInfo?.city ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.state")}
            value={provider?.personalInfo?.state ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.zipcode")}
            value={provider?.personalInfo?.zipcode ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.county")}
            value={provider?.personalInfo?.county ?? "-"}
          ></KeyValue>
          <KeyValue
            label={t("providerProfiles.personalInfo.header.fields.country")}
            value={provider?.personalInfo?.country ?? "-"}
          ></KeyValue>
        </FormSection>
      </div>
    </Container>
  );
}
