import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import StyledRouterLink from "../components/styled-route-link";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useDeletePayerMutation } from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";
import {
  PAYER_CONTENT_DISPLAY_OPTIONS,
  PAYER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import usePayerFilter from "../hooks/usePayerFilter";
import PayerFilters from "../components/filter/payer-filters";
import { AsYouType } from "libphonenumber-js";

export default function PayersTable() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePayer] = useDeletePayerMutation();
  const [selectedItem, setSelectedItem] = useState<Payers>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Payers-Table-Preferences",
    PAYER_TABLE_DEFAULT_PREFERENCES,
  );
  const { filteredPayers, filter, isLoading, isFetching, setFilter, refetch } =
    usePayerFilter();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayers,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t("payers.table.header.label")}
            action={() => {
              navigate(`addnew`);
            }}
          />
        ),
      },
    },
  );
  useEffect(() => {
    const temp = filteredPayers.find((payers) => "" + payers.id === "" + id);
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false),
  );

  const tableProps: TableProps<Payers> = {
    header: (
      <Header
        variant="h2"
        counter={`(${filteredPayers.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
            </span>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectedItem}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission
                      ? `${t("payers.table.header.actions.payerEdit")}`
                      : `${t("payers.table.header.actions.payerView")}`,
                  },
                  {
                    id: "delete_form",
                    text: `${t("payers.table.header.actions.payerDelete")}`,
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") {
                    if (selectedItem) OpenDeleteModal(true);
                  } else if (id === "edit_form") {
                    navigate(`/payers/${selectedItem?.id}`);
                  }
                }}
              >
                {t("payers.table.header.actions.payerActions")}
              </ButtonDropdown>
            </span>

            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  navigate("addnew");
                }}
                variant="primary"
              >
                {t("payers.table.header.actions.addPayer")}
              </Button>
            </span>
          </div>
        }
      >
        {t("payers.table.header.label")}
      </Header>
    ),
    items: items,
    filter: <PayerFilters filter={filter} setFilter={setFilter} />,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        title="Payer Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={PAYER_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    loadingText: "Loading...",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    selectedItems: selectedItem ? [selectedItem] : [],
    onSelectionChange: ({ detail }) => {
      if (detail.selectedItems && detail.selectedItems.at(0))
        setSelectedItem(detail.selectedItems.at(0));
    },
    onRowClick: ({ detail }) => {
      if (detail.item) setSelectedItem(detail.item);
    },
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>{t("payers.table.header.fields.id")}</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: <div>{t("payers.table.header.fields.name")}</div>,
        cell: (item) => (
          <StyledRouterLink
            className={hasPermission ? "edit_link" : "normal"}
            to={hasPermission ? `/payers/${item.id}` : `#`}
            label={item?.name}
          />
        ),
      },
      {
        id: "payerId",
        header: <div>{t("payers.table.header.fields.payerId")}</div>,
        cell: (item) => <div>{item?.payerId ?? "-"}</div>,
      },
      {
        id: "website",
        header: "Website",
        cell: (item) => <Link href="#">{item.website || "-"}</Link>,
        sortingField: "website",
        isRowHeader: true,
      },
      {
        id: "addressLane1",
        header: <div>{t("payers.table.header.fields.addressLane1")}</div>,
        cell: (item) => <div> {item?.payerAddress?.addressLane1 ?? "-"} </div>,
      },
      {
        id: "addressLane2",
        header: <div>{t("payers.table.header.fields.addressLane2")}</div>,
        cell: (item) => <div> {item?.payerAddress?.addressLane2 ?? "-"} </div>,
      },
      {
        id: "city",
        header: <div>{t("payers.table.header.fields.city")}</div>,
        cell: (item) => <div> {item?.payerAddress?.city ?? "-"} </div>,
      },
      {
        id: "state",
        header: <div>{t("payers.table.header.fields.state")}</div>,
        cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
      },
      {
        id: "zipcode",
        header: <div>{t("payers.table.header.fields.zipcode")}</div>,
        cell: (item) => <div> {item?.payerAddress?.zipcode ?? "-"} </div>,
      },
      {
        id: "contactName",
        header: <div>{t("payers.table.header.fields.contactName")}</div>,
        cell: (item) => <div> {item?.payerAddress?.contactName ?? "-"} </div>,
      },
      {
        id: "contactEmail",
        header: <div>{t("payers.table.header.fields.contactEmail")}</div>,
        cell: (item) => <div> {item?.payerAddress?.contactEmail ?? "-"} </div>,
      },
      {
        id: "contactFax",
        header: <div>{t("payers.table.header.fields.contactFax")}</div>,
        cell: (item) => <div> {item?.payerAddress?.contactFax ?? "-"} </div>,
      },
      {
        id: "contactPhone",
        header: <div>{t("payers.table.header.fields.contactPhone")}</div>,
        cell: (item) => (
          <div>
            {" "}
            {new AsYouType("US").input(
              item?.payerAddress?.contactPhone ?? "",
            ) ?? "-"}{" "}
          </div>
        ),
      },
    ],
  };
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePayer({
                payerId: selectedItem?.id,
              }),
              resourceName: "payer",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("payers.delete.header.label")}
        content={
          <>
            <Box>{t("payers.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t("payers.delete.header.content.description")}
      />
    </div>
  );
}
