import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InnerAppLayout from "../../components/InnerAppLayout";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useCreatePayerMutation } from "../../redux/api/payers/payers";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";
import { USStatesList } from "../../helpers/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useState } from "react";
import RHFPhoneNumber from "../../components/RHF/RHFPhoneNumber";

type FormInputProps = {
  name: string;
  payerId: string;
  website: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
  contactEmail: string;
  contactFax: string;
  contactName: string;
  contactPhone: string;
};

export default function AddPayer() {
  const methods = useForm<FormInputProps>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addPayer] = useCreatePayerMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputProps) => {
    await notifyWrapper({
      promise: addPayer({
        name: data?.name,
        payerId: data?.payerId,
        website: data?.website,
        payerAddress: {
          addressLane1: data?.addressLane1,
          addressLane2: data?.addressLane2,
          city: data?.city,
          state: data?.state,
          zipcode: data?.zipcode,
          contactEmail: data?.contactEmail,
          contactFax: data?.contactFax,
          contactName: data?.contactName,
          contactPhone: data?.contactPhone,
        },
      }),
      resourceName: "payer",
      actionName: "add",
      callback: (id) => {
        if (!!id) navigate(`/payers/${id}`);
      },
    });
  };

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("payers.addPayer.header.breadcrumb.text")}`,
              href: `/payers`,
            },

            {
              text: `${t("payers.addPayer.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("payers.addPayer.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate("/payers");
                      }}
                      formAction="none"
                    >
                      {t("payers.addPayer.header.actions.cancel")}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t("payers.addPayer.header.actions.submit")}
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <RHFTextField
                    label={t("payers.addPayer.header.fields.name")}
                    name="name"
                    stretch={false}
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField
                    label={t("payers.addPayer.header.fields.payerId")}
                    name="payerId"
                    stretch={false}
                  />
                  <RHFTextField
                    label={t("payers.addPayer.header.fields.website")}
                    name="website"
                    stretch={false}
                  />
                  <ExpandableSection
                    headerText={t("payers.addPayer.header.headerText")}
                  >
                    <FormSection header="" columns={2}>
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.addressLane1")}
                        name="addressLane1"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.addressLane2")}
                        name="addressLane2"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.city")}
                        name="city"
                        stretch={false}
                      />
                      <RHFSelect
                        name="state"
                        label={t("payers.addPayer.header.fields.state")}
                        options={USStatesList.map((state) => ({
                          value: state.value,
                        }))}
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.zipcode")}
                        name="zipcode"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.contactName")}
                        name="contactName"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.contactEmail")}
                        name="contactEmail"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("payers.addPayer.header.fields.contactFax")}
                        name="contactFax"
                        stretch={false}
                      />
                      <RHFPhoneNumber
                        label={t("payers.addPayer.header.fields.contactPhone")}
                        name="contactPhone"
                      />
                    </FormSection>
                  </ExpandableSection>
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
