import { api } from "..";
import { SignatureRequest, eSignatureResponse } from "./types";

const SignatureRequestAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSignatureRequests: build.query<eSignatureResponse[], void>({
      query: () => "/signature/all",
      providesTags: ["SignatureRequest"],
    }),
    getSignatureRequest: build.query<eSignatureResponse, { id: string }>({
      query: ({ id }) => `/signature/${id}`,
      providesTags: ["SignatureRequest"],
    }),
    voidSignature: build.mutation<void, { signatureId: string }>({
      query({ signatureId }) {
        return {
          url: `signature/${signatureId}/void`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "SignatureRequest" }],
    }),
    resendSignature: build.mutation<void, { signatureId: string }>({
      query({ signatureId }) {
        return {
          url: `signature/${signatureId}/request`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "SignatureRequest" }],
    }),
    addDigitalformSignatureRequest: build.mutation<
      eSignatureResponse,
      { formId: string } & SignatureRequest
    >({
      query: ({ formId, ...body }) => {
        return {
          url: `digitalform/${formId}/requestsignature`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SignatureRequest"],
    }),
    addCredentialPackageSignatureRequest: build.mutation<
      eSignatureResponse,
      { packageId: string } & SignatureRequest
    >({
      query: ({ packageId, ...body }) => {
        return {
          url: `credentialing/packages/${packageId}/requestsignature`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SignatureRequest"],
    }),
  }),
});

export const {
  useGetAllSignatureRequestsQuery,
  useResendSignatureMutation,
  useVoidSignatureMutation,
  useGetSignatureRequestQuery,
  useAddDigitalformSignatureRequestMutation,
  useAddCredentialPackageSignatureRequestMutation,
} = SignatureRequestAPI;
