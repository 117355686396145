import { Form, Input, Button, Checkbox } from "antd";
import "./login.css";

export default function IncredableLogin() {
  return (
    <div className="login-container">
      <div className="login-left"></div>
      <div className="login-right">
        <h2>Login</h2>
        <p>
          Welcome back! Your gateway to seamless document management awaits.
        </p>
        <Form
          name="login"
          initialValues={{ remember: true }}
          layout="vertical"
          className="login-form"
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input type="password" placeholder="Enter your password" />
          </Form.Item>
          <Form.Item>
            <Checkbox>Keep me logged in</Checkbox>
            <Button type="link" to="#" className="login-form-forgot">
              Forgot Password?
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Login
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="default" className="login-google-button">
              <i className="google-icon"></i> Login with Google
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
