import {
  Badge,
  Button,
  Header,
  SpaceBetween,
  Table,
  TableProps,
  ButtonDropdown,
  Pagination,
  Box,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState } from "react";
import { setModalName } from "../../redux/UI/actions";
import {
  useDeleteRoleMutation,
  useGetAllRoleQuery,
} from "../../redux/api/role/role";
import { RoleMetaData } from "../../redux/api/role/types";
import StyledRouterLink from "../../components/styled-route-link";
import { ADD_ROLE } from "../../helpers/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useNavigate } from "react-router";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../common/localStorage";
import {
  ROLES_CONTENT_DISPLAY_OPTIONS,
  ROLES_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function RolesTable({ openModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { data = [] } = useGetAllRoleQuery();
  const [selectedRole, setSelectedRole] = useState<RoleMetaData>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteRole] = useDeleteRoleMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false),
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Role-Table-Preferences",
    ROLES_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <TableEmptyState
          resourceName={t("rolesandpermissions.roles.table.header.label")}
          action={() => {
            openModalName(ADD_ROLE);
          }}
        />
      ),
    },
    pagination: { pageSize: tablePreferences.pageSize },
  });

  const tableProps: TableProps<RoleMetaData> = {
    header: (
      <Header
        counter={`(${data.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectedRole}
                items={[
                  {
                    id: "details_form",
                    text: `${t("rolesandpermissions.roles.table.header.actions.details")}`,
                  },
                  {
                    id: "delete_form",
                    text: `${t("rolesandpermissions.roles.table.header.actions.delete")}`,
                    disabled: !hasDeletePermission || selectedRole?.default,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") {
                    if (selectedRole) {
                      OpenDeleteModal(true);
                    }
                  } else if (id === "details_form") {
                    navigate(`/settings/roles/${selectedRole?.id}`);
                  }
                }}
              >
                {t(
                  "rolesandpermissions.roles.table.header.actions.roleActions",
                )}
              </ButtonDropdown>
            </span>
            <span>
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  openModalName(ADD_ROLE);
                }}
                variant="primary"
              >
                {t("rolesandpermissions.roles.table.header.actions.addRole")}
              </Button>
            </span>
          </div>
        }
      >
        {t("rolesandpermissions.roles.table.header.label")}
      </Header>
    ),
    items,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        title="Roles Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={ROLES_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    resizableColumns: true,
    stickyHeader: true,
    onSelectionChange: (selectionChangeDetail) => {
      if (selectionChangeDetail.detail.selectedItems.at(0))
        setSelectedRole(selectionChangeDetail.detail.selectedItems.at(0));
    },
    onRowClick: (onRowClickDetail) => {
      setSelectedRole(onRowClickDetail.detail.item);
    },
    selectedItems: selectedRole ? [selectedRole] : [],
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "id",
        header: (
          <div>{t("rolesandpermissions.roles.table.header.fields.id")}</div>
        ),
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: (
          <div>{t("rolesandpermissions.roles.table.header.fields.name")}</div>
        ),
        cell: (item) => (
          <SpaceBetween size="s" direction="horizontal">
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `${item.id}` : `#`}
              label={item?.name}
            />
            {item.default && <Badge>default</Badge>}
          </SpaceBetween>
        ),
      },
      {
        id: "description",
        header: (
          <div>
            {t("rolesandpermissions.roles.table.header.fields.description")}
          </div>
        ),
        cell: (item) => <span>{item?.description}</span>,
      },
    ],
  };

  return (
    <div style={{ paddingTop: "24px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRole?.id)
            await notifyWrapper({
              promise: deleteRole({ roleId: selectedRole?.id }),
              resourceName: "role",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("rolesandpermissions.roles.delete.header.label")}
        content={
          <>
            <Box>
              {t("rolesandpermissions.roles.delete.header.content.label")}
            </Box>
            <Box variant="awsui-key-label">{selectedRole?.name}</Box>
          </>
        }
        description={t(
          "rolesandpermissions.roles.delete.header.content.description",
        )}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(RolesTable);
