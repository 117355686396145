import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
} from "@cloudscape-design/components";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSaveDocumentMutation } from "../../redux/api/document/document";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import InnerAppLayout from "../../components/InnerAppLayout";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import DocumentMetaData from "../../components/DocumentMetaData";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import { fileAxios } from "../../context/axios";
import { captureException } from "@sentry/react";
import { getFileUrl } from "../../config";

type FormInputType = {
  name: string;
  expirationDate: string;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
};

export default function AddDocument() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // get providerId from url and fetch provider data
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const [uploadFile, setUploadFile] = useState<File>();
  const [saveDocument] = useSaveDocumentMutation();
  const methods = useForm<FormInputType>();
  const documentName = methods.watch("name");

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );

  const providerName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();
  const handleSave = async (data: FormInputType) => {
    if (!hasPermission || !providerId) return;
    const notificationId = uuidv4();
    try {
      notifyInProgress({
        name: "Document",
        action: "adding",
        id: notificationId,
      });
      await saveDocument({
        providerId,
        document: {
          name: data?.name,
          alertDays: data?.alertDays,
          expirationDate: data?.expirationDate,
          categoryId: data.categoryId,
          file: uploadFile,
          notes: data.notes,
        },
      })
        .unwrap()
        .then(async (result) => {
          if (!!uploadFile && result?.attachment?.key) {
            try {
              await fileAxios.put(
                getFileUrl(result?.attachment?.key),
                uploadFile,
              );
              notifySucess({
                name: "document",
                action: "created",
                id: notificationId,
              });
            } catch (error) {
              captureException(error);
              notifyFailed({
                name: "Document",
                action: "add",
                id: notificationId,
              });
            }
          } else
            notifySucess({
              name: "document",
              action: "created",
              id: notificationId,
            });
        })
        .catch((error) => {
          captureException(error);
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: "Document",
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: "Document",
              action: "add",
              id: notificationId,
            });
        });
    } catch (error) {
      captureException(error);
      notifyFailed({
        name: "Document",
        action: "add",
        id: notificationId,
      });
    }
    navigate(-1);
  };

  useEffect(() => {
    if (!!uploadFile?.name && !documentName)
      methods.setValue("name", uploadFile?.name);
  }, [uploadFile, uploadFile?.size]);

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerSafe.content.addNewDocument.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: providerName,
              href: `/manageprovider/${providerId}/profile`,
            },
            {
              text: `${t("providerSafe.content.addNewDocument.header.breadcrumb.nextPage")}`,
              href: `/safe?providerId=${providerId}`,
            },
            {
              text: `${t("providerSafe.content.addNewDocument.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      rightPanel={
        <AttachmentViewer
          attachment={undefined}
          file={uploadFile}
          onFileChange={(uploadFile?: File) => {
            setUploadFile(uploadFile);
          }}
        />
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSave)}>
            <Form>
              <Container
                header={
                  <Header>
                    {documentName ??
                      `${t("providerSafe.content.addNewDocument.header.fields.documentName.label")}`}
                  </Header>
                }
                footer={
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerSafe.content.addNewDocument.header.actions.cancel",
                        )}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="primary"
                        disabled={!hasPermission}
                        formAction="submit"
                      >
                        {t(
                          "providerSafe.content.addNewDocument.header.actions.save",
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                }
              >
                <DocumentMetaData />
              </Container>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
