export type DIGITALFORM_FIELDS_Type = {
  label: string;
  value: string;
  type: string;
  properties: {
    label: string;
    value: string;
    key?: string;
    svg?: React.ReactNode;
    type: "string" | "date" | "bool" | "signature";
  }[];
};

export const STANDARD_DIGITALFORM_FIELDS: DIGITALFORM_FIELDS_Type[] = [
  {
    label: "Standard",
    value: "standard_fields",
    type: "list",
    properties: [
      {
        label: "Text Field",
        value: "text_field",
        type: "string",
        svg: (
          <svg
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
          >
            <path d="M7 2h2v12H7z"></path>
            <path d="M2 2h12v2H2zm3 10h6v2H5z"></path>
            <path d="M2 2h2v4H2zm10 0h2v4h-2z"></path>
          </svg>
        ),
      },
      {
        label: "Check Box",
        value: "check_box",
        type: "bool",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            viewBox="0 0 16 16"
            focusable="false"
            style={{
              color: "rgb(102, 102, 102)",
              fontSize: "12px",
            }}
          >
            <path d="M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z"></path>
            <path d="m13 5.5-1.4-1.4L6.7 9 4.4 6.8 3 8.2l3.7 3.7z"></path>
          </svg>
        ),
      },
      {
        label: "Signature",
        value: "signature",
        type: "signature",
        svg: (
          <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            viewBox="0 0 16 16"
            focusable="false"
          >
            <path d="M16 16H0v-1h16zM13.8.3c.4.4.4 1 0 1.4L11 4.5c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L12.4.3c.3-.4 1-.4 1.4 0zM1.4 9.9 0 14.1l4.2-1.4z"></path>
            <path d="M4.9 12 11 5.9l.5-.5.9-.9c.4-.4.4-1 0-1.4L11 1.7c-.4-.4-1-.4-1.4 0l-.9.9-6.6 6.6L4.9 12z"></path>
          </svg>
        ),
      },
    ],
  },
];

export const PROVIDER_DIGITALFORM_FIELDS: DIGITALFORM_FIELDS_Type[] = [
  {
    label: "Personal Info",
    value: "personalInfo",
    type: "singular",
    properties: [
      {
        label: "First Name",
        value: "firstName",
        key: "firstName",
        type: "string",
      },
      {
        label: "Last Name",
        value: "lastName",
        key: "lastName",
        type: "string",
      },
      { label: "Email address", value: "email", key: "email", type: "string" },
      {
        label: "NPI number",
        value: "npiNumber",
        key: "npiNumber",
        type: "string",
      },
      { label: "Middle Name", value: "middleName", type: "string" },
      { label: "Degree", value: "degree.name", type: "string" },
      { label: "Has Other Name", value: "hasOtherName", type: "bool" },
      { label: "Npi Number", value: "npiNumber", type: "string" },
      { label: "SSN", value: "ssn", type: "string" },
      { label: "Gender", value: "gender", type: "string" },
      { label: "Date Of Birth", value: "dob", type: "date" },
      { label: "Place Of Birth", value: "placeOfBirth", type: "string" },
      { label: "Citizenship", value: "citizenship", type: "string" },
      { label: "County", value: "county", type: "string" },
      {
        label: "Worked in Military?",
        value: "hasWorkedInMilitary",
        type: "bool",
      },
      {
        label: "Branch of Military",
        value: "branchOfMilitary",
        type: "string",
      },
      {
        label: "Military Date of Service",
        value: "militaryDateOfService",
        type: "string",
      },
      { label: "Passport No", value: "passportNo", type: "string" },
      { label: "Email", value: "email", type: "string" },
      { label: "Mobile", value: "mobile", type: "string" },
      { label: "Home Phone", value: "homePhone", type: "string" },
      { label: "Fax", value: "fax", type: "string" },
      { label: "Street 1", value: "addressLane1", type: "string" },
      { label: "Street 2", value: "addressLane2", type: "string" },
      { label: "City", value: "city", type: "string" },
      { label: "State", value: "state", type: "string" },
      { label: "Zip Code", value: "zipcode", type: "string" },
      { label: "Country", value: "country", type: "string" },
    ],
  },
  {
    label: "Professional Info",
    value: "professionalInfo",
    type: "singular",
    properties: [
      { label: "CAQH", value: "caqh", type: "string" },
      { label: "License Type", value: "licenseType.name", type: "string" },
      {
        label: "Primary Specialty",
        value: "primarySpecialty.name",
        type: "string",
      },
      { label: "Title", value: "title.name", type: "string" },
      { label: "Work Phone", value: "workPhone", type: "string" },
      { label: "Work Phone Alt", value: "workPhoneAlt", type: "string" },
      { label: "Practice name", value: "practiceInfo.name", type: "string" },
      {
        label: "Years Of Practice",
        value: "practiceInfo.yearsOfPractice",
        type: "string",
      },
      {
        label: "Practice Start Date",
        value: "practiceInfo.startDate",
        type: "date",
      },
      {
        label: "Practice End Date",
        value: "practiceInfo.endDate",
        type: "date",
      },
      {
        label: "Practice Address Lane1",
        value: "practiceInfo.addressLane1",
        type: "string",
      },
      {
        label: "Practice Address Lane2",
        value: "practiceInfo.addressLane2",
        type: "string",
      },
      { label: "Practice City", value: "practiceInfo.city", type: "string" },
      { label: "Practice State", value: "practiceInfo.state", type: "string" },
      {
        label: "Practice Zip Code",
        value: "practiceInfo.zipcode",
        type: "string",
      },
      {
        label: "Practice Country",
        value: "practiceInfo.country",
        type: "string",
      },
      {
        label: "Practice Contact Name",
        value: "practiceInfo.contactName",
        type: "string",
      },
      {
        label: "Practice Contact Title",
        value: "practiceInfo.contactTitle",
        type: "string",
      },
      {
        label: "Practice Contact Phone",
        value: "practiceInfo.contactPhone",
        type: "string",
      },
      {
        label: "Practice Contact Email",
        value: "practiceInfo.contactEmail",
        type: "string",
      },
      {
        label: "Practice Contact Fax",
        value: "practiceInfo.contactFax",
        type: "string",
      },
    ],
  },
  {
    label: "Facility",
    value: "facility",
    type: "singular",
    properties: [
      {
        label: "Facility Name",
        value: "name",
        type: "string",
      },
      {
        label: "NPI Number",
        value: "npi_number",
        type: "string",
      },
      {
        label: "Clia Id",
        value: "cliaId",
        type: "string",
      },
      { label: "Tax Id", value: "taxId", type: "string" },
      {
        label: "Taxonomy Code",
        value: "taxonomyCode",
        type: "string",
      },
      {
        label: "Immunization Id",
        value: "immunizationId",
        type: "string",
      },
      {
        label: "Facility Type",
        key: "facility.facilityType.name",
        value: "type",
        type: "string",
      },
      {
        label: "Address Lane1",
        key: "facility.facilityAddress.addressLane1",
        value: "addressLane1",
        type: "string",
      },
      {
        label: "Address Lane2",
        key: "facility.facilityAddress.addressLane2",
        value: "addressLane2",
        type: "string",
      },
      {
        label: "City",
        key: "facility.facilityAddress.city",
        value: "city",
        type: "string",
      },
      {
        label: "State",
        key: "facility.facilityAddress.state",
        value: "state",
        type: "string",
      },
      {
        label: "Zipcode",
        key: "facility.facilityAddress.zipcode",
        value: "zipcode",
        type: "string",
      },
      {
        label: "Country",
        key: "facility.facilityAddress.country",
        value: "country",
        type: "string",
      },
    ],
  },
  {
    label: "Education",
    value: "providerEducations",
    type: "list",
    properties: [
      { label: "Type", value: "type", type: "string" },
      { label: "Name", value: "name", type: "string" },
      { label: "Start Date", value: "startDate", type: "date" },
      { label: "End Date", value: "endDate", type: "date" },
      { label: "Degree", value: "degree.name", type: "string" },
      { label: "Email", value: "email", type: "string" },
      { label: "Phone", value: "phone", type: "string" },
      { label: "Fax", value: "fax", type: "string" },
      { label: "Address Lane1", value: "addressLane1", type: "string" },
      { label: "Address Lane2", value: "addressLane2", type: "string" },
      { label: "City", value: "city", type: "string" },
      { label: "State", value: "state", type: "string" },
      { label: "Zip code", value: "zip", type: "string" },
      { label: "Country", value: "country", type: "string" },
      {
        label: "Residency Director",
        value: "residencyDirector",
        type: "string",
      },
      {
        label: "Fellowship Director",
        value: "fellowshipDirector",
        type: "string",
      },
    ],
  },
  {
    label: "Affiliations",
    value: "providerAffiliations",
    type: "list",
    properties: [
      { label: "Name", value: "name", type: "string" },
      { label: "Is Current?", value: "isCurrent", type: "bool" },
      { label: "Start Date", value: "startDate", type: "date" },
      { label: "End Date", value: "endDate", type: "date" },
      { label: "Email", value: "email", type: "string" },
      { label: "Phone", value: "phone", type: "string" },
      { label: "Fax", value: "fax", type: "string" },
    ],
  },
  {
    label: "DEA",
    value: "providerDeas",
    type: "list",
    properties: [
      { label: "Type", value: "type", type: "string" },
      { label: "DEA Number", value: "deaNumber", type: "string" },
      { label: "Start Date", value: "startDate", type: "date" },
      { label: "End Date", value: "endDate", type: "date" },
      { label: "Status", value: "status", type: "string" },
      { label: "State", value: "state", type: "string" },
    ],
  },
  {
    label: "Liability",
    value: "providerLiabilityInsurances",
    type: "list",
    properties: [
      { label: "Is Current?", value: "isCurrent", type: "bool" },
      { label: "Name", value: "name", type: "string" },
      { label: "Policy Number", value: "policyNumber", type: "string" },
      { label: "Self Insured?", value: "selfInsured", type: "bool" },
      { label: "Effective Date", value: "effectiveDate", type: "date" },
      { label: "Expiration Date", value: "expirationDate", type: "date" },
      { label: "Type of Coverage", value: "typeOfCoverage", type: "string" },
      {
        label: "Time With Current Carrier Value",
        value: "timeWithCurrentCarrierValue",
        type: "string",
      },
      {
        label: "Time With Current Carrier",
        value: "timeWithCurrentCarrierUnit",
        type: "string",
      },
      {
        label: "Coverage Per Occurrence",
        value: "coveragePerOccurrence",
        type: "string",
      },
      {
        label: "Aggregate Coverage",
        value: "aggregateCoverage",
        type: "string",
      },
      { label: "Email", value: "email", type: "string" },
      { label: "Phone", value: "phone", type: "string" },
      { label: "Fax", value: "fax", type: "string" },
      { label: "Street 1", value: "addressLane1", type: "string" },
      { label: "Street 2", value: "addressLane2", type: "string" },
      { label: "City", value: "city", type: "string" },
      { label: "State", value: "state", type: "string" },
      { label: "Zip", value: "zip", type: "string" },
      { label: "Country", value: "country", type: "string" },
    ],
  },
  {
    label: "Licenses",
    value: "providerLicenses",
    type: "list",
    properties: [
      { label: "License Type", value: "licenseType", type: "string" },
      { label: "License Number", value: "licenseNumber", type: "string" },
      { label: "State", value: "state", type: "string" },
      { label: "Is Current?", value: "isCurrent", type: "bool" },
      { label: "Start Date", value: "startDate", type: "date" },
      { label: "End Date", value: "endDate", type: "date" },
    ],
  },
  {
    label: "Peer References",
    value: "providerPeerReferences",
    type: "list",
    properties: [
      { label: "Name", value: "name", type: "string" },
      { label: "Degrre", value: "degreeId", type: "string" },
      { label: "Specialty", value: "specialtyId", type: "string" },
      {
        label: "Time With Reference",
        value: "timeWithReferenceValue",
        type: "string",
      },
      {
        label: "Time With Reference (Unit)",
        value: "timeWithReferenceUnit",
        type: "string",
      },
      { label: "Email", value: "email", type: "string" },
      { label: "Phone", value: "phone", type: "string" },
      { label: "Fax", value: "fax", type: "string" },
      { label: "Street 1", value: "addressLand1", type: "string" },
      { label: "Street 2", value: "addressLane2", type: "string" },
      { label: "City", value: "city", type: "string" },
      { label: "State", value: "state", type: "string" },
      { label: "Zip", value: "zip", type: "string" },
      { label: "Country", value: "country", type: "string" },
    ],
  },
  {
    label: "Specialties",
    value: "providerSpecialties",
    type: "list",
    properties: [
      { label: "Board Certified?", value: "isBoardCertified", type: "bool" },
      { label: "Board Name", value: "boardName", type: "string" },
      {
        label: "Initial Certification Date",
        value: "initialCertificationDate",
        type: "date",
      },
      { label: "Expiration Date", value: "expirationDate", type: "date" },
      {
        label: "Recertification Date",
        value: "reCertificationDate",
        type: "date",
      },
      { label: "Specialty", value: "specialty.name", type: "string" },
    ],
  },
  {
    label: "Work History",
    value: "providerWorkHistories",
    type: "list",
    properties: [
      { label: "Type", value: "type", type: "string" },
      { label: "Name", value: "name", type: "string" },
      { label: "Start Date", value: "startDate", type: "date" },
      { label: "End Date", value: "endDate", type: "date" },
      { label: "Position Held", value: "positionHeld", type: "string" },
      { label: "Gap Explanation", value: "gapExplanation", type: "string" },
      { label: "Reference Name", value: "referenceName", type: "string" },
      { label: "Reference Email", value: "referenceEmail", type: "string" },
      { label: "Reference Phone", value: "referencePhone", type: "string" },
      { label: "Reference Fax", value: "referenceFax", type: "string" },
      { label: "Street 1", value: "addressLane1", type: "string" },
      { label: "Street 2", value: "addressLane2", type: "string" },
      { label: "City", value: "city", type: "string" },
      { label: "State", value: "state", type: "string" },
      { label: "Zip", value: "zip", type: "string" },
      { label: "Country", value: "country", type: "string" },
    ],
  },
];
