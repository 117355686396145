import { Box } from "@cloudscape-design/components";

type LabelProp = {
  label: String;
  value: React.ReactNode | String;
  maskContent?: boolean;
  required?: boolean;
};

export default function KeyValue({
  label,
  value,
  required = false,
  maskContent = false,
}: LabelProp) {
  if (maskContent && !!value && typeof value === "string")
    return (
      <div>
        <Box variant="awsui-key-label">{label}</Box>
        {maskContent && !!value && typeof value === "string" ? (
          <div>{"*".repeat(value.length)}</div>
        ) : (
          <div>{value}</div>
        )}
      </div>
    );
  else if (!!required)
    return (
      <span>
        {label} {<span style={{ color: "#fc0a37" }}>*</span>}
      </span>
    );
  else
    return (
      <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{value}</div>
      </div>
    );
}
