import {
  Tabs,
  SpaceBetween,
  Header,
  Box,
  BreadcrumbGroup,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { setActiveTabId } from "../../redux/provider/action";
import Overview from "./sections/overview";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { NoProviderSelected } from "../../components/NoProviderSelected";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useIsLoading from "../../hooks/useIsLoading";
import PersonalInfo from "./sections/sections/personal-info";
import ProfessionalInfo from "./sections/sections/professional-info";
import Affiliation from "./sections/sections/affiliations";
import Dea from "./sections/sections/dea";
import Education from "./sections/sections/education";
import LiabilityInsurance from "./sections/sections/liability-insurance";
import License from "./sections/sections/license";
import PeerReference from "./sections/sections/peer-reference";
import SpecialtyInfo from "./sections/sections/specialty-info";
import ThirdPartyLogin from "./sections/sections/third-party-login";
import WorkHistory from "./sections/sections/work-history";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import EnrolledPayers from "./sections/sections/enrolled-payers";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";

interface PropsFromState {
  activeTabId: string;
}

interface PropsFromActions {
  changeActiveTabId: typeof setActiveTabId;
}

function ProviderDetail({
  activeTabId,
  changeActiveTabId,
}: PropsFromState & PropsFromActions) {
  const { providerId } = useParams();
  // const search = useLocation().search;
  // const providerId = new URLSearchParams(search).get("providerId");
  const { t } = useTranslation();
  const { data: provider, isLoading } = useGetProviderQuery(
    !!providerId
      ? {
          providerId,
        }
      : skipToken,
  );
  const { data: organization } = useGetOrganizationDetailsQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchParam_activeTabId = searchParams.get("activeTabId");
    if (searchParam_activeTabId) changeActiveTabId(searchParam_activeTabId);
  }, []);

  useIsLoading(isLoading);

  if (!provider) return <NoProviderSelected />;
  else
    return (
      <SpaceBetween size={"s"}>
        <LoadingScreen isOpen={isLoading} />
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.providerDetails.breadcrumb.text")}`,
              href: "/manageprovider",
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: "#",
            },
          ]}
        />
        <Box padding={{ right: "m", left: "m" }}>
          <Header>{provider?.firstName + " " + provider?.lastName}</Header>
          <Tabs
            variant="default"
            activeTabId={activeTabId}
            onChange={(changeDetail) => {
              setSearchParams({ activeTabId: changeDetail.detail.activeTabId });
              changeActiveTabId(changeDetail.detail.activeTabId);
            }}
            tabs={[
              {
                label: `${t("providerProfiles.providerDetails.tabs.overview")}`,
                id: "overview",
                content: <Overview />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.personalInfo")}`,
                id: "personal_info",
                content: <PersonalInfo />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.professionalInfo")}`,
                id: "professional_info",
                content: <ProfessionalInfo />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.affiliations")}`,
                id: "affiliation",
                content: <Affiliation />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.dea")}`,
                id: "dea",
                content: <Dea />,
              },
              // {
              //   label: `${t("providerProfiles.providerDetails.tabs.disclosure")}`,
              //   id: "disclosure",
              //   content: <Disclosure />,
              // },
              {
                label: `${t("providerProfiles.providerDetails.tabs.education")}`,
                id: "education",
                content: <Education />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.liabilityInsurance")}`,
                id: "LiabilityInsurance",
                content: <LiabilityInsurance />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.license")}`,
                id: "license",
                content: <License />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.peerReference")}`,
                id: "peerreference",
                content: <PeerReference />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.specialityInfo")}`,
                id: "specialtyinfo",
                content: <SpecialtyInfo />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.thirdPartyLogin")}`,
                id: "thirdpartylogin",
                content: <ThirdPartyLogin />,
              },
              {
                label: `${t("providerProfiles.providerDetails.tabs.workHistory")}`,
                id: "workhistory",
                content: <WorkHistory />,
              },
              ...(!!organization?.organizationServiceLimits?.payerEnrollments
                ? [
                    {
                      label: `${t("providerProfiles.providerDetails.tabs.enrolledPayers")}`,
                      id: "enrolledpayers",
                      content: <EnrolledPayers />,
                    },
                  ]
                : []),
            ]}
          />
        </Box>
      </SpaceBetween>
    );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    activeTabId: state.provider.activeTabId,
  };
};

const mapDispatchToProps = {
  changeActiveTabId: setActiveTabId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetail);
