import { api } from "..";
import { BoardName } from "./types";

const boardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllBoardName: build.query<BoardName[], void>({
      query: () => `boardname/all`,
      providesTags: ["Board"],
    }),
    saveAllBoardName: build.mutation<void, BoardName[]>({
      query: (types) => {
        return {
          url: `boardname`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Board"],
    }),
    deleteBoardName: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `boardname/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Board"],
    }),
  }),
});

export const {
  useGetAllBoardNameQuery,
  useSaveAllBoardNameMutation,
  useDeleteBoardNameMutation,
} = boardApi;
