import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetAllProviderSafeDocumentsQuery } from "../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import { Document } from "../redux/api/document/types";

export type FilterType = {
  filterDocumentName: string;
  categoryFilterOptions: OptionDefinition[];
};

export default function useProviderSafeFilter() {
  const [searchParams] = useSearchParams();

  const providerId = searchParams.get("providerId") ?? "";

  const {
    data = [],
    fulfilledTimeStamp,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProviderSafeDocumentsQuery(
    providerId ? { providerId } : skipToken,
  );

  const [filter, setFilter] = useState<FilterType>({
    filterDocumentName: "",
    categoryFilterOptions: [],
  });

  const [filteredProviderSafe, setFilteredProviderSafe] =
    useState<Document[]>(data);

  useEffect(() => {
    setFilteredProviderSafe(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_safe = [...data];
    if (filter.filterDocumentName) {
      temp_filtered_safe = temp_filtered_safe.filter((document) =>
        document?.name
          .toLowerCase()
          .includes(filter.filterDocumentName.toLowerCase()),
      );
    }
    const selectedCategory = filter.categoryFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);
    if (selectedCategory && selectedCategory.length > 0) {
      temp_filtered_safe = temp_filtered_safe.filter(
        (document) =>
          selectedCategory.indexOf(document?.category?.id + "") >= 0,
      );
    }
    setFilteredProviderSafe(temp_filtered_safe);
  }, [filter]);

  return {
    filteredProviderSafe,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
    fulfilledTimeStamp,
  };
}
