import {
  Link,
  ColumnLayout,
  Container,
  Header,
  Input,
  FormField,
  Textarea,
  SpaceBetween,
  Select,
  Button,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const issueTypes = [
  {
    label: "General inquiry",
    value: "General inquiry",
  },
  {
    label: "Request a feature",
    value: "Request a feature",
  },
  {
    label: "Report a technical issue",
    value: "Report a technical issue",
  },
  {
    label: "Support with the platform",
    value: "Support with the platform",
  },
  {
    label: "Other issues",
    value: "Other issues",
  },
];

function Support() {
  const { t } = useTranslation();
  const [issueType, setIssueType] = useState<OptionDefinition>({});
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  return (
    <div style={{ paddingTop: "12px" }}>
      <Header>{t("support.content.header.label")}</Header>
      <Container fitHeight>
        <ColumnLayout columns={2}>
          <Container>
            <SpaceBetween direction="vertical" size="m">
              <div>
                <h3>Contact our support team for help with:</h3>
                <ul>
                  <SpaceBetween direction="vertical" size="xxs">
                    <li>General inquiry</li>
                    <li>Request a feature</li>
                    <li>Report a technical issue</li>
                    <li>Support with the platform</li>
                    <li>Other issues</li>
                  </SpaceBetween>
                </ul>
              </div>
              <h4>
                Checkout our platform user guide{"  "}
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href="https://readydoc.s3.us-east-2.amazonaws.com/FMG+Platform+Overview.pdf"
                >
                  Platform Overview.pdf
                </Link>
              </h4>
            </SpaceBetween>
          </Container>
          <SpaceBetween direction="vertical" size="l">
            <FormField
              label={t("support.content.header.fields.issueType.label")}
            >
              <Select
                selectedOption={issueType}
                onChange={({ detail }) => setIssueType(detail.selectedOption)}
                options={issueTypes}
              />
            </FormField>
            <FormField label={t("support.content.header.fields.subject.label")}>
              <Input
                onChange={({ detail }) => setSubject(detail.value)}
                value={subject}
                placeholder={t(
                  "support.content.header.fields.subject.placeHolder",
                )}
              />
            </FormField>
            <FormField
              label={t("support.content.header.fields.description.label")}
            >
              <Textarea
                onChange={({ detail }) => setDescription(detail.value)}
                value={description}
                placeholder={t(
                  "support.content.header.fields.description.placeHolder",
                )}
              />
            </FormField>
            <div style={{ float: "right" }}>
              <SpaceBetween size={"s"} direction="horizontal">
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("support.content.header.actions.cancel")}
                </Button>
                <Button variant="primary">
                  {t("support.content.header.actions.submit")}
                </Button>
              </SpaceBetween>
            </div>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}

export default Support;
