import { api } from "..";
import Comment from "./types";

const CommentRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
    addCommentToContract: build.mutation<
      void,
      { contractId: string; message: string }
    >({
      query: ({ contractId, message }) => ({
        url: `/contract/${contractId}/comment`,
        method: "POST",
        body: message,
      }),
      invalidatesTags: (result, error, { contractId }) => [
        { type: "Comments", id: contractId },
      ],
    }),
    deleteCommentFromContract: build.mutation<
      Comment,
      { contractId: string; commentId: string }
    >({
      query: ({ contractId, commentId }) => ({
        url: `/contract/${contractId}/comment/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { contractId }) => [
        { type: "Comments", id: contractId },
      ],
    }),
    updateCommentToContract: build.mutation<
      Comment,
      {
        contractId: string;
        commentId: string;
        message: string;
      }
    >({
      query: ({ contractId, commentId, message }) => ({
        url: `/contract/${contractId}/comment/${commentId}`,
        method: "PUT",
        body: message,
      }),
      invalidatesTags: (result, error, { contractId }) => [
        { type: "Comments", id: contractId },
      ],
    }),
    getAllCommentsByContractId: build.query<Comment[], { contractId: string }>({
      query: ({ contractId }) => `/contract/${contractId}/comments/all`,
      providesTags: (result, error, { contractId }) => [
        { type: "Comments", id: contractId },
      ],
    }),
  }),
});
export const {
  useAddCommentToContractMutation,
  useDeleteCommentFromContractMutation,
  useUpdateCommentToContractMutation,
  useGetAllCommentsByContractIdQuery,
} = CommentRequestApi;
