import {
  Badge,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { useEffect, useMemo, useState } from "react";
import { MemberType } from "../../redux/api/manageadmin/types";
import {
  useGetAllMembersQuery,
  useDeleteAdminMutation,
  useDeleteAdminFacilityMutation,
} from "../../redux/api/manageadmin/manageadmin";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_MEMBER } from "../../helpers/constants";
import { useNavigate, useParams } from "react-router";
import StyledRouterLink from "../../components/styled-route-link";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import KeyValue from "../../components/KeyValue";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../common/localStorage";
import {
  MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS,
  MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import useMemberActiveFilter from "../../hooks/useMemberActiveFilter";
import MemberActiveFilters from "../../components/filter/membersactive-filters";
import { PATH_DASHBOARD } from "../../routes/paths";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ActiveTable({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { manageadminId } = useParams();
  const [selectedActive, setSelectActive] = useState<MemberType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  const {
    filteredMemberActive,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
    fulfilledTimeStamp,
  } = useMemberActiveFilter();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false),
  );

  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.delete") ?? false),
  );
  const [deleteAdmin] = useDeleteAdminMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Active-Table-Preferences",
    MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(
    filteredMemberActive,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: tablePreferences.pageSize },
    },
  );

  useEffect(() => {
    const temp = filteredMemberActive.find(
      (admin) => "" + admin.id === "" + manageadminId,
    );
    if (temp) setSelectActive(temp);
    else {
      if (filteredMemberActive.at(0)?.id)
        navigate(`/manageadmin/${filteredMemberActive.at(0)?.id}`);
    }
  }, [filteredMemberActive, manageadminId, fulfilledTimeStamp]);

  useEffect(() => {
    if (selectedActive?.id) navigate(`/manageadmin/${selectedActive?.id}`);
  }, [selectedActive?.id]);

  const tableProps: TableProps<MemberType> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredMemberActive.length})`}
          actions={
            <SpaceBetween size={"s"} direction="horizontal">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
              <Button
                onClick={() => {
                  if (selectedActive) {
                    const adminId = selectedActive.id;
                    if (adminId !== undefined) OpenDeleteModal(true);
                  }
                }}
                disabled={
                  !(
                    hasDeletePermission &&
                    selectedActive &&
                    !selectedActive.role.default
                  )
                }
              >
                {t("members.header.active.table.header.actions.activeDelete")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(
                    PATH_DASHBOARD.manageadmin.editmember(
                      `${selectedActive?.id}`,
                    ),
                  );
                  //  openModalName(EDIT_MEMBER);
                }}
                disabled={!selectedActive || selectedActive.role.default}
              >
                {hasPermission
                  ? `${t("members.header.active.table.header.actions.activeEdit")}`
                  : `${t("members.header.active.table.header.actions.activeView")}`}
              </Button>
            </SpaceBetween>
          }
        >
          {t("members.header.active.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Active Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      filter: <MemberActiveFilters filter={filter} setFilter={setFilter} />,
      empty: (
        <p>
          <i>{t("members.header.active.table.header.empty")}</i>
        </p>
      ),
      onSelectionChange: (selectionChangeDetail) => {
        const temp_member = selectionChangeDetail.detail.selectedItems.at(0);
        if (temp_member) setSelectActive(temp_member);
      },
      onRowClick: (onRowClickDetail) => {
        const temp_member = onRowClickDetail.detail.item;
        if (temp_member) setSelectActive(temp_member);
      },
      selectedItems: selectedActive ? [selectedActive] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div>{t("members.header.active.table.header.fields.id")}</div>
          ),
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "firstName",
          header: (
            <div>
              {t("members.header.active.table.header.fields.firstName")}
            </div>
          ),
          cell: (item) => <span>{item.firstName}</span>,
        },
        {
          id: "lastName",
          header: (
            <div>{t("members.header.active.table.header.fields.lastName")}</div>
          ),
          cell: (item) => <span>{item.lastName}</span>,
        },
        {
          id: "email",
          header: (
            <div>{t("members.header.active.table.header.fields.email")}</div>
          ),
          cell: (item) => <span>{item.email}</span>,
          width: 280,
        },
        {
          id: "role",
          header: (
            <div>{t("members.header.active.table.header.fields.role")}</div>
          ),
          cell: (item) => (
            <SpaceBetween size="s" direction="horizontal">
              <StyledRouterLink
                className={hasPermission ? "edit_link" : "normal"}
                to={hasPermission ? `/settings/roles/${item.role.id}` : `#`}
                label={item.role.name}
              />
              {item.role.default && <Badge>default</Badge>}
            </SpaceBetween>
          ),
        },
        {
          id: "lastlogin",
          header: (
            <div>
              {t("members.header.active.table.header.fields.lastLogin")}
            </div>
          ),
          cell: (item) => (
            <span>
              {!!item.lastLogin
                ? new Date(item.lastLogin)?.toLocaleString()
                : "-"}
            </span>
          ),
        },
      ],
    };
  }, [selectedActive, tablePreferences, items, isLoading]);

  return (
    <div style={{ paddingTop: "5px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedActive?.id)
            await notifyWrapper({
              promise: deleteAdmin({ adminId: selectedActive?.id }),
              resourceName: "active",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("members.header.active.delete.header.label")}
        content={
          <KeyValue
            label={t("members.header.active.delete.header.content.label")}
            value={
              <Header variant="h3">
                {selectedActive?.firstName + " " + selectedActive?.lastName}
              </Header>
            }
          ></KeyValue>
        }
        description={t(
          "members.header.active.delete.header.content.description",
        )}
      />
    </div>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};
export default connect(null, mapDispatchToProps)(ActiveTable);
