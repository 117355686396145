import {
  Header,
  Table,
  TableProps,
  Button,
  Pagination,
  Input,
} from "@cloudscape-design/components";
import "./index.css";
import { useState, useEffect, useMemo } from "react";
import { ApplicationState } from "../../redux/store";
import { InvitationType } from "../../redux/api/manageadmin/types";
import { useSelector } from "react-redux";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import KeyValue from "../../components/KeyValue";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS,
  MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../common/table-config";
import { useLocalStorage } from "../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import {
  useDeleteInviteMutation,
  useGetAllInvitedQuery,
} from "../../redux/api/manageadmin/invitation";

export default function InvitedTable() {
  const { t } = useTranslation();
  const [selectedInvited, setSelectInvited] = useState<InvitationType>();
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
    fulfilledTimeStamp,
  } = useGetAllInvitedQuery();
  const [deleteInvitedMember] = useDeleteInviteMutation();

  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Invited-Table-Preferences",
    MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES,
  );
  const [email, setEmail] = useState("");
  const [filteredInvited, setFilteredInvited] =
    useState<InvitationType[]>(data);
  useEffect(() => {
    let temp_filtered_invited = [...data];
    if (email) {
      temp_filtered_invited = temp_filtered_invited.filter((invited) =>
        invited.email.toLowerCase().includes(email.toLowerCase()),
      );
      setFilteredInvited(temp_filtered_invited);
    } else {
      setFilteredInvited(data);
    }
  }, [data, email, filteredInvited]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredInvited,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: tablePreferences.pageSize },
    },
  );
  useEffect(() => {
    if (!selectedInvited) return;
    let filterSelected = data.filter(
      (invited) => invited.id === selectedInvited?.id,
    );
    if (filterSelected.length) setSelectInvited(filterSelected.at(0));
  }, [fulfilledTimeStamp]);
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.delete") ?? false),
  );

  const tableProps: TableProps<InvitationType> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredInvited.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    if (selectedInvited) {
                      const invitedId = selectedInvited.id;
                      if (invitedId !== undefined) {
                        OpenDeleteModal(true);
                      }
                    }
                  }}
                  disabled={!(hasDeletePermission && selectedInvited)}
                >
                  {t(
                    "members.header.invited.table.header.actions.invitedDelete",
                  )}
                </Button>
              </span>
            </div>
          }
        >
          {t("members.header.invited.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      empty: <p> {t("members.header.invited.table.header.empty")}</p>,
      filter: (
        <Input
          onChange={({ detail }) => setEmail(detail.value)}
          value={email}
          placeholder="Find by invited by email"
          type="search"
        />
      ),
      selectedItems: selectedInvited ? [selectedInvited] : [],
      onSelectionChange: (selectionChangeDetail) => {
        setSelectInvited(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectInvited(onRowClickDetail.detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: (
            <div> {t("members.header.invited.table.header.fields.id")}</div>
          ),
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "email",
          isRowHeader: true,
          header: (
            <div> {t("members.header.invited.table.header.fields.email")}</div>
          ),
          cell: (item) => <span>{item.email}</span>,
        },
        {
          id: "role",
          header: (
            <div> {t("members.header.invited.table.header.fields.role")}</div>
          ),
          cell: (item) => <span>{item.role?.name ?? "-"}</span>,
        },
        {
          id: "inviterName",
          header: (
            <div>
              {" "}
              {t("members.header.invited.table.header.fields.inviterName")}
            </div>
          ),
          cell: (item) => <span>{item.inviterName}</span>,
        },
        {
          id: "inviterEmail",
          header: (
            <div>
              {" "}
              {t("members.header.invited.table.header.fields.inviterEmail")}
            </div>
          ),
          cell: (item) => <span>{item.inviterEmail}</span>,
        },
        {
          id: "status",
          header: (
            <div> {t("members.header.invited.table.header.fields.status")}</div>
          ),
          cell: (item) => <span>{item.status}</span>,
        },
      ],
    };
  }, [tablePreferences, items, selectedInvited, isLoading]);

  return (
    <div style={{ paddingTop: "5px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedInvited?.id)
            await notifyWrapper({
              promise: deleteInvitedMember({ invitedId: selectedInvited?.id }),
              resourceName: "Invite",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("members.header.invited.delete.header.label")}
        content={
          <KeyValue
            label={t("members.header.invited.delete.header.content.label")}
            value={<Header variant="h3">{selectedInvited?.email}</Header>}
          ></KeyValue>
        }
        description={t(
          "members.header.invited.delete.header.content.description",
        )}
      />
    </div>
  );
}
