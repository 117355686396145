import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { useGetAllAffiliationQuery } from "../redux/api/affiliation/affiliation";
import { ProviderAffiliation } from "../redux/api/affiliation/types";

export type FilterType = {
  providerFilterOptions: OptionDefinition[];
  facilityFilterOptions: OptionDefinition[];
  affiliationTypeFilterOptions: OptionDefinition[];
  affiliationStatusFilterOptions: OptionDefinition[];
};

export default function useAffiliationFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllAffiliationQuery();

  const [filter, setFilter] = useState<FilterType>({
    providerFilterOptions: [],
    facilityFilterOptions: [],
    affiliationTypeFilterOptions: [],
    affiliationStatusFilterOptions: [],
  });

  const [filteredAffiliations, setFilteredAffiliations] = useState<
    ProviderAffiliation[]
  >([]);

  useEffect(() => {
    let temp_filtered_affiliations = [...data];

    const selectedProviderId = filter.providerFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedProviderId && selectedProviderId.length > 0) {
      temp_filtered_affiliations = temp_filtered_affiliations.filter(
        (affiliation) =>
          selectedProviderId.includes(affiliation.provider?.id + ""),
      );
    }

    const selectedFaciltiesId = filter.facilityFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesId && selectedFaciltiesId.length > 0) {
      temp_filtered_affiliations = temp_filtered_affiliations.filter(
        (affiliation) =>
          selectedFaciltiesId.includes(affiliation.facility?.id + ""),
      );
    }

    const selectedaffiliationTypeId = filter.affiliationTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedaffiliationTypeId && selectedaffiliationTypeId.length > 0) {
      temp_filtered_affiliations = temp_filtered_affiliations.filter(
        (affiliation) =>
          selectedaffiliationTypeId.includes(
            affiliation.affiliationType?.id + "",
          ),
      );
    }

    const selectedStatus = filter.affiliationStatusFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedStatus && selectedStatus.length > 0) {
      temp_filtered_affiliations = temp_filtered_affiliations.filter(
        (affiliation) =>
          selectedStatus.includes(affiliation.affiliationStatus?.id + ""),
      );
    }

    setFilteredAffiliations(temp_filtered_affiliations);
  }, [filter, data]);

  return {
    filteredAffiliations,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
