import LoadingScreen from "../../components/LoadingScreen";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { Facility } from "../../redux/api/facility/types";
import FacilityFilters from "../../components/filter/facility-filters";
import useFacilityFilter from "../../hooks/useFacilityFilter";
import AssociatedFacilityGroupModals from "./associated-facility-model";
import { useRemoveFacilitiesFromFacilityGroupMutation } from "../../redux/api/facilitygroup/facilityGroup";
import * as Sentry from "@sentry/react";

export default function AssoicatedFacilityTable({
  associatedFacilities,
  setAssociatedFacilities,
}: {
  associatedFacilities: Facility[];
  setAssociatedFacilities: Dispatch<SetStateAction<Facility[]>>;
}) {
  const [isAssociatedFacilityModalOpen, setIsAssociatedFacilityModalOpen] =
    useState<boolean>(false);
  const { id } = useParams();

  const { t } = useTranslation();
  const { filteredFacilities, filter, isLoading, isFetching, setFilter } =
    useFacilityFilter(associatedFacilities);
  const [removeFacilitiesFromFacilityGroup] =
    useRemoveFacilitiesFromFacilityGroupMutation();

  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={"Associated Facilities"}
            action={() => {
              setIsAssociatedFacilityModalOpen(true);
            }}
          />
        ),
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredFacilities?.length})`}
          actions={
            <SpaceBetween size="l" direction="horizontal">
              <Button
                onClick={async () => {
                  try {
                    if (!id) return;
                    const groupsFacilities =
                      selectedFacilities?.map(
                        (selectedFacility) => selectedFacility?.id + "",
                      ) || [];

                    await removeFacilitiesFromFacilityGroup({
                      facilityGroupId: id,
                      facilityId: groupsFacilities,
                    });

                    const temp = associatedFacilities.filter(
                      (facility) =>
                        !groupsFacilities.includes(facility?.id + ""),
                    );
                    setAssociatedFacilities(temp);
                    setSelectedFacilities([]);
                  } catch (error) {
                    console.error("Error deleting facility group:", error);
                    Sentry.captureException(error);
                    // Handle error (e.g., show a message to the user)
                  }
                }}
              >
                Remove
              </Button>

              <Button
                onClick={() => {
                  setIsAssociatedFacilityModalOpen(true);
                }}
                variant="primary"
                data-test="add_facility"
              >
                Associate Facilities
              </Button>
            </SpaceBetween>
          }
        >
          Associated Facilities
        </Header>
      ),
      items: items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <FacilityFilters filter={filter} setFilter={setFilter} />,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: true,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacilities(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacilities;
        if (!detail.item) return;
        if (temp?.includes(detail.item))
          setSelectedFacilities(temp.filter((item) => item !== detail.item));
        else setSelectedFacilities([detail.item, ...temp]);
      },
      selectedItems: selectedFacilities,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "id", visible: true },
        { id: "name", visible: true },
        { id: "description", visible: true },
        { id: "npiNumber", visible: true },
        { id: "facilityType", visible: true },
        { id: "taxId", visible: true },
        { id: "cliaId", visible: true },
        { id: "immunizationId", visible: true },
        { id: "taxonomyCode", visible: true },
        { id: "addressLane1", visible: true },
        { id: "addressLane2", visible: true },
        { id: "city", visible: true },
        { id: "state", visible: true },
        { id: "zipcode", visible: true },
        { id: "country", visible: true },
      ],
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("facilityGroup.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>{t("facilityGroup.table.header.fields.name")}</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "description",
          header: (
            <div>{t("facilityGroup.table.header.fields.description")}</div>
          ),
          cell: (item) => <div>{item?.description ?? "-"}</div>,
        },
        {
          id: "npiNumber",
          header: <div>{t("facilityGroup.table.header.fields.npiNumber")}</div>,
          cell: (item) => <div>{item?.npiNumber ?? "-"}</div>,
        },
        {
          id: "facilityType",
          header: (
            <div>{t("facilityGroup.table.header.fields.facilityType")}</div>
          ),
          cell: (item) => <div>{item?.facilityType?.name ?? "-"}</div>,
        },
        {
          id: "taxId",
          header: <div>{t("facilityGroup.table.header.fields.taxId")}</div>,
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "cliaId",
          header: <div>{t("facilityGroup.table.header.fields.CLIAID")}</div>,
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "immunizationId",
          header: (
            <div>{t("facilityGroup.table.header.fields.immunizationId")}</div>
          ),
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "taxonomyCode",
          header: (
            <div>{t("facilityGroup.table.header.fields.taxonomyCode")}</div>
          ),
          cell: (item) => <div>{item?.taxId ?? "-"}</div>,
        },
        {
          id: "addressLane1",
          header: (
            <div>{t("facilityGroup.table.header.fields.addressLane1")}</div>
          ),
          cell: (item) => (
            <div>{item?.facilityAddress?.addressLane1 ?? "-"}</div>
          ),
        },
        {
          id: "addressLane2",
          header: (
            <div>{t("facilityGroup.table.header.fields.addressLane2")}</div>
          ),
          cell: (item) => (
            <div>{item?.facilityAddress?.addressLane2 ?? "-"}</div>
          ),
        },
        {
          id: "city",
          header: <div>{t("facilityGroup.table.header.fields.city")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.city ?? "-"}</div>,
        },
        {
          id: "state",
          header: <div>{t("facilityGroup.table.header.fields.state")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.state ?? "-"}</div>,
        },
        {
          id: "zipcode",
          header: <div>{t("facilityGroup.table.header.fields.zipcode")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.zipcode ?? "-"}</div>,
        },
        {
          id: "country",
          header: <div>{t("facilityGroup.table.header.fields.country")}</div>,
          cell: (item) => <div>{item?.facilityAddress?.country ?? "-"}</div>,
        },
      ],
    };
  }, [items, selectedFacilities]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <AssociatedFacilityGroupModals
        associatedFacilities={associatedFacilities}
        setAssociatedFacilities={setAssociatedFacilities}
        isAssociatedFacilityModalOpen={isAssociatedFacilityModalOpen}
        setIsAssociatedFacilityModalOpen={setIsAssociatedFacilityModalOpen}
      />
    </div>
  );
}
