import React from "react";
import { Flex, Result } from "antd";
import { TopNavigation } from "@cloudscape-design/components";

const SigningFailed: React.FC = () => (
  <Flex gap={32} vertical>
    <TopNavigation
      identity={{
        href: "#",
        title: "Ready Doc",
        logo: {
          //pointing to logo image in public folder
          src: "/RD_Horizozntal_Color_Logo.svg",
          alt: "Readydoc logo",
        },
      }}
    />
    <Result
      status="error"
      title="The signature request link has either expired or has already been submitted!"
      subTitle="Please contact your facility administrator."
    />
  </Flex>
);

export default SigningFailed;
