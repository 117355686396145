import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/dist/query";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import RequestSignature from "../add-eSignature/request-signature";
import {
  useAddCredentialPackageSignatureRequestMutation,
  useAddDigitalformSignatureRequestMutation,
} from "../../redux/api/signaturerequest/signaturerequest";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { eSignatureResponse } from "../../redux/api/signaturerequest/types";
import { useGetCredentialPackageCombinedPdfQuery } from "../../redux/api/credentialpackages/credentialpackages";
import { dismissFlashBarItem } from "../../redux/UI/actions";
import { captureException } from "@sentry/react";

export default function CredentialPackageSignatureRequest() {
  const { id: packageId } = useParams();
  const {
    data: credentialPackage,
    isLoading,
    error,
    isError,
  } = useGetCredentialPackageCombinedPdfQuery(packageId ?? skipToken);
  const { notifyInProgress, notifyFailed } = useNotify();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    addCredentialPackageSignatureRequest,
    { isLoading: loadingSubmitSignatureRequest },
  ] = useAddCredentialPackageSignatureRequestMutation();

  const [signatureResponse, setSignatureResponse] =
    useState<eSignatureResponse>();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  useEffect(() => {
    if (!!error && isError) {
      const notificationId = uuidv4();
      if ("status" in error && "data" in error && error.status === 400) {
        const errorData: any = error.data;
        notifyFailed({
          name: "Credential Pacakge",
          action: "Failed to request signature.",
          content: errorData.errorMessage,
          id: notificationId,
        });
      } else
        notifyFailed({
          name: "Credential Pacakge",
          action: "request",
          id: notificationId,
        });
      navigate(-1);
    }
    captureException(error);
  }, [error]);

  if (!credentialPackage) return <LoadingScreen isOpen={true} />;
  else
    return (
      <>
        <RequestSignature
          fileKey={credentialPackage.template.key}
          name={credentialPackage.name}
          isLoading={isLoading}
          savetosafe={async (xfdfString, providerId) => {
            navigate(-1);
          }}
          addsignaturerequest={async (
            primaryRecipient,
            addlRecipients,
            signatureRequest,
          ) => {
            const notificationId = uuidv4();
            notifyInProgress({
              name: "e-Signature",
              action: "requesting",
              id: notificationId,
            });
            await addCredentialPackageSignatureRequest({
              packageId: credentialPackage.id,
              id: "",
              primaryRecipient,
              addlRecipients,
              ...signatureRequest,
            })
              .unwrap()
              .then((payload) => {
                dispatch(dismissFlashBarItem(notificationId));
                setSignatureResponse(payload);
                setShowSuccessModal(true);
              })
              .catch((error) => {
                if (error.status < 500 && error.status >= 400) {
                  notifyFailed({
                    name: "e-Signature",
                    action: error.data.errorMessage,
                    content: error.data.errorDescription,
                    id: notificationId,
                  });
                } else
                  notifyFailed({
                    name: "e-Signature",
                    action: "request",
                    id: notificationId,
                  });
                navigate(-1);
              });
          }}
          loadingSubmitSignatureRequest={loadingSubmitSignatureRequest}
        />
        <Modal
          onDismiss={() => {
            setShowSuccessModal(false);
            navigate(`/esignatures/${signatureResponse?.id}`);
          }}
          visible={!!showSuccessModal}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link">Cancel</Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowSuccessModal(false);
                    navigate(`/esignatures/${signatureResponse?.id}`);
                  }}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Signature Request Completed!"
        >
          Your signature request has been successfully sent
        </Modal>
      </>
    );
}
