import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllPackageTypesQuery,
  useSaveAllPackageTypesMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { CredentialPackageType } from "../../../redux/api/credentialpackages/types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function PackageTypeModal({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { data = [], fulfilledTimeStamp } = useGetAllPackageTypesQuery();
  const [packageType, setPackageType] = useState<CredentialPackageType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllPackageTypes] = useSaveAllPackageTypesMutation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );
  const handleSubmit = async () => {
    setModalName("");

    const tmp = [...packageType].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllPackageTypes(tmp),
      resourceName: "Package Types",
      actionName: "save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setPackageType(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={
        <Header>{t("credentialPackages.packageType.header.label")}</Header>
      }
      size="large"
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t("credentialPackages.packageType.header.actions.cancel")}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
              disabled={!hasPermission}
            >
              {t("credentialPackages.packageType.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>

          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setPackageType([
                      ...packageType,
                      { id: "", name: "", description: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...packageType];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      description: item.description,
                      deleted: true,
                    };
                    setPackageType(tmp);
                  }}
                  items={packageType.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "credentialPackages.packageType.header.actions.addPackageType",
                  )}
                  definition={[
                    {
                      label: `${t("credentialPackages.packageType.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...packageType];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              description: item.description,
                              deleted: item.deleted,
                            };
                            setPackageType(tmp);
                          }}
                          placeholder={t(
                            "credentialPackages.packageType.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },

                    /*   {
                        label: "Description",
                        control: (item, itemIndex: number) => (
                          <Input
                            value={item.description}
                            onChange={(changeDetails) => {
                              const tmp = [...privilegeType];
                              tmp[itemIndex] = {
                                id: item.id,
                                name: item.name,
                                description: changeDetails.detail.value,
                              };
                              setPrivilegeType(tmp);
                            }}
                            placeholder="Enter Description"
                          />
                        ),
                      }, */
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(PackageTypeModal);
