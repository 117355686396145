import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import { useFormContext, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
dayjs.extend(dayjsPluginUTC);

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  defaultValue?: string;
  rules?: { required: string | boolean };
  disabled?: boolean;
  testid?: string;
};

type RFimProps = {
  accept?: RegExp;
  dateFormat?: string;
  replace?: (str: string) => string;
};

type Props = IProps & FormFieldProps & RFimProps;

export default function RHFDatePicker({
  name,
  rules,
  label,
  errorText,
  description,
  defaultValue,
  disabled,
  stretch,
  testid,
  replace,
}: Props & { label: string }) {
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? null}
        render={({ field, fieldState }) => {
          return (
            <FormField
              label={
                <span>
                  {label}{" "}
                  {rules?.required && (
                    <span style={{ color: "#fc0a37" }}>*</span>
                  )}
                </span>
              }
              info={
                description && (
                  <span>
                    <i>{description}</i>
                  </span>
                )
              }
              errorText={errorText ?? fieldState.error?.message}
              stretch={stretch ?? true}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={label}
                disabled={disabled}
                defaultValue={
                  dayjs(defaultValue).isValid() ? dayjs.utc(defaultValue) : null
                }
                onChange={(date, dateString) => {
                  if (!date) {
                    field.onChange();
                  } else
                    field.onChange(date.format("YYYY-MM-DD") + "T00:00:00");
                }}
                value={
                  dayjs(field.value).isValid() ? dayjs.utc(field.value) : null
                }
                showNow={false}
                status={!!fieldState.error?.message ? "error" : ""}
                allowClear={true}
                format={"MM-DD-YYYY"}
              />
            </FormField>
          );
        }}
        rules={rules}
      />
    </LocalizationProvider>
  );
}
