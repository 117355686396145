import { FormProvider, useForm } from "react-hook-form";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalName } from "../../../../redux/UI/actions";
import { PayerEnrollmentRequest } from "../../../../redux/api/payerenrollment/types";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import {
  useCreatePayerEnrollmentMutation,
  useGetAllPayerEnrollmentStatusQuery,
} from "../../../../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerQuery } from "../../../../redux/api/payers/payers";
import {
  useGetAllProvidersQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useGetFacilitiesQuery } from "../../../../redux/api/facility/facility";
import { Divider } from "semantic-ui-react";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import {
  PAYER_ENROLLMENT_STATUS,
  alertReminderOptions,
} from "../../../../helpers/constants";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAllPayerGroupQuery } from "../../../../redux/api/payergroup/payergroup";
import { PayerGroup } from "../../../../redux/api/payergroup/types";
import RHFTextField from "../../../../components/RHF/RHFTextField";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddEnrolledPayers({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PayerEnrollmentRequest>();
  const { t } = useTranslation();
  const { providerId } = useParams();
  const { data: providerName } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const { data: facility = [] } = useGetFacilitiesQuery();
  const onSubmit = async (data: PayerEnrollmentRequest) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addPayerEnrollment({
          ...data,
          payerId: data.payerId,
        }),
        resourceName: "Enrolled Payer",
        actionName: "add",
      });
      navigate(-1);
    }
  };

  const selectedPayerId = methods.watch("payerId");

  useEffect(() => {
    if (providerId) {
      methods.setValue("providerId", providerId);
    }
  }, [providerId]);

  const [addPayerEnrollment] = useCreatePayerEnrollmentMutation();

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.enrolledPayers.addEnrolledPayer.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: providerName?.firstName + " " + providerName?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=enrolledpayers`,
            },
            {
              text: `${t("providerProfiles.enrolledPayers.addEnrolledPayer.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.enrolledPayers.addEnrolledPayer.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.enrolledPayers.addEnrolledPayer.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.enrolledPayers.addEnrolledPayer.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    <RHFSelect
                      name="payerId"
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.payer",
                      )}
                      options={payer.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />

                    <RHFSelect
                      name="payerGroupId"
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.payergroup",
                      )}
                      options={payer
                        ?.find(
                          (payer) => "" + payer?.id === selectedPayerId + "",
                        )
                        ?.payerGroup?.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                      stretch={false}
                    />
                    <RHFSelect
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.provider",
                      )}
                      name={"providerId"}
                      stretch={false}
                      filteringType="auto"
                      options={provider.map((temp) => ({
                        label: temp.firstName + " " + temp.lastName,
                        value: "" + temp.id,
                        tags: temp?.providerLicenses?.map(
                          (type) => type.licenseType,
                        ),
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFSelect
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.facility",
                      )}
                      name={"facilityId"}
                      stretch={false}
                      filteringType="auto"
                      options={facility.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                    />
                    <RHFFloatingSelect
                      name="enrollmentStatusId"
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.status.label",
                      )}
                      options={status.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      stretch={false}
                      action={{
                        label: `${t("providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.status.actions.modify")}`,
                        onClick: () => {
                          setModalName(PAYER_ENROLLMENT_STATUS);
                        },
                      }}
                    />
                    <RHFDatePicker
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.submittedDate",
                      )}
                      name={"submittedDate"}
                      stretch={false}
                    />
                    <RHFDatePicker
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.approvedDate",
                      )}
                      name="approvedDate"
                      stretch={false}
                    />
                    <RHFDatePicker
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.effectiveDate",
                      )}
                      name="effectiveDate"
                      stretch={false}
                    />
                    <RHFDatePicker
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.recredentialingDate",
                      )}
                      name={"recredentialingDate"}
                      stretch={false}
                    />

                    <RHFSelect
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.followupDate",
                      )}
                      name={"alertDays"}
                      stretch={false}
                      options={alertReminderOptions}
                    />
                    <RHFTextField
                      label={t(
                        "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.payerProviderId",
                      )}
                      name="payerProviderId"
                      stretch
                    />
                  </FormSection>
                  <ExpandableSection
                    headerText={t(
                      "providerProfiles.enrolledPayers.addEnrolledPayer.header.headerText",
                    )}
                  >
                    <FormSection>
                      <RHFTextArea
                        label={t(
                          "providerProfiles.enrolledPayers.addEnrolledPayer.header.fields.description",
                        )}
                        name="description"
                        stretch={false}
                      />
                    </FormSection>
                  </ExpandableSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddEnrolledPayers);
