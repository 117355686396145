// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import { capitalize } from "lodash";

export const TableNoMatchState = ({
  onClearFilter,
}: {
  onClearFilter: () => void;
}) => (
  <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const TableEmptyState = ({
  resourceName,
  header,
  description,
  actionLabel,
  action,
}: {
  resourceName: string;
  header?: string;
  description?: string;
  actionLabel?: string;
  action: VoidFunction;
}) => (
  <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>{header ?? `${resourceName} Not Found`}</b>
        <Box variant="p" color="inherit">
          {description ??
            `No ${resourceName.toLowerCase()} associated with this
          resource.`}
        </Box>
      </div>
      <Button
        onClick={() => {
          action();
        }}
      >
        {actionLabel ?? `Create ${resourceName}`}
      </Button>
    </SpaceBetween>
  </Box>
);
