import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../components/LoadingScreen";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useUpdateAssociatedPayerMutation } from "../../redux/api/payergroup/payergroup";
import PayerFilters from "../../components/filter/payer-filters";
import usePayerFilter from "../../hooks/usePayerFilter";
import { Payers } from "../../redux/api/payers/types";

export default function AssociatedPayersModals({
  isAssociatedPayersModalOpen,
  setAssociatedPayersModalOpen,
  associatedPayers,
  setAssociatedPayers,
}: {
  isAssociatedPayersModalOpen: boolean;
  setAssociatedPayersModalOpen: Dispatch<SetStateAction<boolean>>;
  associatedPayers: Payers[];
  setAssociatedPayers: Dispatch<SetStateAction<Payers[]>>;
}) {
  const { id } = useParams();
  const [updateAssociatedPayer] = useUpdateAssociatedPayerMutation();
  const { t } = useTranslation();
  const { filteredPayers, filter, isLoading, isFetching, setFilter } =
    usePayerFilter();
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayers,
    {
      propertyFiltering: {
        filteringProperties: [],
      },
      pagination: { pageSize: preferences?.pageSize ?? 10 },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedPayers, setSelectedPayers] = useState<Payers[]>([]);

  const tableProps: TableProps<Payers> = useMemo(() => {
    return {
      items,
      ...collectionProps,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: <PayerFilters filter={filter} setFilter={setFilter} />,
      variant: "borderless",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedPayers(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedPayers;
        if (
          !detail.item ||
          !!associatedPayers
            ?.map((associatedPayer) => associatedPayer?.id + "")
            ?.includes(detail.item.id + "")
        )
          return;
        if (temp?.includes(detail.item))
          setSelectedPayers(temp.filter((item) => item !== detail.item));
        else setSelectedPayers([detail.item, ...temp]);
      },
      selectedItems: selectedPayers,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "payerId", visible: true },
        { id: "website", visible: true },
        { id: "state", visible: true },
      ],
      isItemDisabled: (item) => {
        return associatedPayers
          ?.map((associatedPayer) => associatedPayer?.id + "")
          ?.includes(item?.id + "");
      },
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t("payers.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>{t("payers.table.header.fields.name")}</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "payerId",
          header: <div>{t("payers.table.header.fields.payerId")}</div>,
          cell: (item) => <div>{item?.payerId ?? "-"}</div>,
        },
        {
          id: "website",
          header: "Website",
          cell: (item) => <Link href="#">{item.website || "-"}</Link>,
          sortingField: "website",
          isRowHeader: true,
        },
        {
          id: "state",
          header: <div>{t("payers.table.header.fields.state")}</div>,
          cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
        },
      ],
    };
  }, [items, selectedPayers]);
  return (
    <Modal
      visible={isAssociatedPayersModalOpen}
      size="max"
      header={<Header counter={`(${filteredPayers.length})`}>Payers</Header>}
      onDismiss={() => {
        setAssociatedPayersModalOpen(false);
      }}
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={() => {
                setSelectedPayers([]);
                setAssociatedPayersModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                try {
                  if (!!selectedPayers && !!id) {
                    const payersGroupsToUpdate =
                      selectedPayers?.map(
                        (selectedPayersItem) => selectedPayersItem?.id + "",
                      ) || [];

                    await updateAssociatedPayer({
                      payerGroupID: id,
                      payersToUpdate: payersGroupsToUpdate,
                    });

                    setAssociatedPayers([
                      ...associatedPayers,
                      ...selectedPayers,
                    ]);
                    setSelectedPayers([]);
                    setAssociatedPayersModalOpen(false);
                  }
                } catch (error) {
                  console.error("Error updating admin payerGroups:", error);
                  // Handle error (e.g., show a message to the user)
                }
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div>
        <LoadingScreen isOpen={isLoading} />
        <Table {...tableProps} />
      </div>
    </Modal>
  );
}
