import { FormProvider, useForm } from "react-hook-form";
import { ProviderAffiliationTypeRequest } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Grid,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderAffiliationMutation,
  useGetAllAffiliationStatusQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTranslation } from "react-i18next";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../../../redux/UI/actions";
import { connect } from "react-redux";
import {
  AFFILIATION_STATUS,
  AFFILIATION_TYPE,
  alertReminderOptions,
} from "../../../../helpers/constants";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../../../redux/api/facility/facility";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import DocumentSection from "../../document-section";
import RHFPhoneNumber from "../../../../components/RHF/RHFPhoneNumber";
import { useGetAllAffiliationTypeQuery } from "../../../../redux/api/affiliationType/affiliation";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditAffiliation({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<ProviderAffiliationTypeRequest>();
  const { providerId, affiliationId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllAffiliationStatusQuery();
  const { data: type = [] } = useGetAllAffiliationTypeQuery();
  const onSubmit = async (data: ProviderAffiliationTypeRequest) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderAffiliation({
          ...data,
          providerId: providerId,
          documentId:
            selectedDocument.value !== "-1" ? selectedDocument.value : "",
        }),
        resourceName: "affiliation",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const { data: facility = [] } = useGetFacilitiesQuery();
  const [addProviderAffiliation] = useAddProviderAffiliationMutation();
  const [isCurrent] = methods.watch(["isCurrent"]);
  useEffect(() => {
    methods.clearErrors("endDate");
  }, [isCurrent]);

  useEffect(() => {
    if (provider && provider.providerAffiliations) {
      const affiliation = provider.providerAffiliations.find(
        (affiliation) => affiliation?.id + "" === affiliationId + "",
      );

      if (affiliation) {
        methods.setValue("id", affiliation.id);
        methods.setValue("name", affiliation?.name);
        methods.setValue("startDate", affiliation?.startDate);
        methods.setValue("endDate", affiliation?.endDate);
        methods.setValue("facilityId", affiliation?.facility?.id);
        methods.setValue("isCurrent", affiliation?.isCurrent);
        methods.setValue(
          "affiliationStatusId",
          affiliation?.affiliationStatus?.id,
        );
        methods.setValue("affiliationTypeId", affiliation?.affiliationType?.id);
        methods.setValue("email", affiliation?.email);
        methods.setValue("phone", affiliation?.phone);
        methods.setValue("fax", affiliation?.fax);
        methods.setValue("alertDays", affiliation?.alertDays);
        methods.setValue("documentId", affiliation?.document?.id);
        affiliation?.document &&
          setSelectedDocument({
            label: affiliation?.document?.name,
            value: affiliation?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, affiliationId]);

  return (
    <div>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            {
              text: `${t("providerProfiles.affiliations.editAffiliation.header.breadcrumb.text")}`,
              href: `/manageprovider/${providerId}`,
            },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: `/manageprovider/${providerId}/profile?activeTabId=affiliation`,
            },
            {
              text: `${t("providerProfiles.affiliations.editAffiliation.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>
                {t(
                  "providerProfiles.affiliations.editAffiliation.header.label",
                )}
              </Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t(
                          "providerProfiles.affiliations.editAffiliation.header.actions.cancel",
                        )}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t(
                          "providerProfiles.affiliations.editAffiliation.header.actions.submit",
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <Grid gridDefinition={[{ colspan: 9 }]}>
                    <SpaceBetween size="l">
                      <FormSection columns={2}>
                        <RHFFloatingSelect
                          name="affiliationTypeId"
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.affiliationType.label",
                          )}
                          options={type.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          stretch={false}
                          action={{
                            label: `${t("providerProfiles.affiliations.editAffiliation.header.fields.affiliationType.actions.modify")}`,
                            onClick: () => {
                              setModalName(AFFILIATION_TYPE);
                            },
                          }}
                        />
                        <RHFFloatingSelect
                          name="affiliationStatusId"
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.affiliationStatus.label",
                          )}
                          options={status.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          stretch={false}
                          action={{
                            label: `${t("providerProfiles.affiliations.editAffiliation.header.fields.affiliationStatus.actions.modify")}`,
                            onClick: () => {
                              setModalName(AFFILIATION_STATUS);
                            },
                          }}
                        />
                      </FormSection>
                      <FormSection columns={2}>
                        <RHFSelect
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.facility",
                          )}
                          name={"facilityId"}
                          stretch={false}
                          filteringType="auto"
                          options={facility.map((temp) => ({
                            label: temp.name,
                            value: "" + temp.id,
                          }))}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFDatePicker
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.startDate",
                          )}
                          name="startDate"
                        />
                      </FormSection>
                      <FormSection columns={2}>
                        <RHFDatePicker
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.endDate",
                          )}
                          name="endDate"
                        />

                        <RHFSelect
                          label={"Expiration Reminder"}
                          name={"alertDays"}
                          stretch={false}
                          options={alertReminderOptions}
                        />
                      </FormSection>
                      <FormSection
                        header="Contact Info"
                        columns={2}
                        headerDividerBottom
                      >
                        <RHFTextField
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.name",
                          )}
                          name="name"
                          stretch
                        />
                        <RHFTextField
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.email",
                          )}
                          name="email"
                          rules={{
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email",
                            },
                          }}
                        />
                        <RHFPhoneNumber
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.phone",
                          )}
                          name="phone"
                        />

                        <RHFTextField
                          label={t(
                            "providerProfiles.affiliations.editAffiliation.header.fields.fax",
                          )}
                          name="fax"
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Please enter a number",
                            },
                          }}
                          stretch
                        />
                      </FormSection>
                      <DocumentSection
                        providerId={providerId}
                        selectedDocument={selectedDocument}
                        setSelectedDocument={setSelectedDocument}
                      />
                    </SpaceBetween>
                  </Grid>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditAffiliation);
