import { api } from "..";
import {
  Task,
  TaskRequest,
  TaskStatus,
  TaskStatusRequest,
  TaskType,
  TaskTypeRequest,
} from "./types";

const TaskAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTasks: build.query<Task[], void>({
      query: () => "/task/all",
      providesTags: ["Task"],
    }),
    getMyTasks: build.query<Task[], void>({
      query: () => "/task/mytasks",
      providesTags: ["Task"],
    }),
    getTask: build.query<Task, { id: string }>({
      query: ({ id }) => `/task/${id}`,
      providesTags: ["Task"],
    }),
    addTask: build.mutation<void, TaskRequest>({
      query: ({ ...body }) => {
        return {
          url: "task",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Task"],
    }),
    updateTask: build.mutation<void, { id: string } & Partial<TaskRequest>>({
      query: ({ id, ...patch }) => {
        return {
          url: `task/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Task", id: arg.id },
        { type: "Task" },
      ],
    }),
    addMyTask: build.mutation<void, TaskRequest>({
      query: ({ ...body }) => {
        return {
          url: "task/mytask",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Task"],
    }),
    updateMyTask: build.mutation<void, { id: string } & Partial<TaskRequest>>({
      query: ({ id, ...patch }) => {
        return {
          url: `task/${id}/mytask`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Task", id: arg.id },
        { type: "Task" },
      ],
    }),
    patchTask: build.mutation<
      void,
      { id: string } & { request: Record<string, string> }
    >({
      query: ({ id, request }) => {
        return {
          url: `task/${id}/patch`,
          method: "PUT",
          body: { ...request },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Task", id: arg.id },
        { type: "Task" },
      ],
    }),
    deleteTask: build.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `task/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Task"],
    }),
    getAllTaskStatus: build.query<TaskStatus[], void>({
      query: () => `task/status/all`,
      providesTags: ["Task"],
    }),
    saveAllTaskStatus: build.mutation<void, TaskStatusRequest[]>({
      query: (statusList) => {
        return {
          url: "task/status/all",
          method: "POST",
          body: statusList,
        };
      },
      invalidatesTags: ["Task"],
    }),
    getAllTaskType: build.query<TaskType[], void>({
      query: () => `task/type/all`,
      providesTags: ["Task"],
    }),
    saveAllTaskType: build.mutation<void, TaskTypeRequest[]>({
      query: (types) => {
        return {
          url: "task/type/all",
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Task"],
    }),
  }),
});

export const {
  useGetAllTasksQuery,
  useGetMyTasksQuery,
  useAddMyTaskMutation,
  useUpdateMyTaskMutation,
  useGetTaskQuery,
  useAddTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  usePatchTaskMutation,
  useGetAllTaskStatusQuery,
  useSaveAllTaskStatusMutation,
  useGetAllTaskTypeQuery,
  useSaveAllTaskTypeMutation,
} = TaskAPI;
