import { Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { WorkflowRequest } from "../../redux/api/workflow/types";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export default function StepThree() {
  const { t } = useTranslation();
  const methods = useFormContext<WorkflowRequest>();
  useEffect(() => {
    if (methods.getValues("startDate") === undefined) {
      methods.setValue("startDate", "");
    }
    if (methods.getValues("dueDate") === undefined) {
      methods.setValue("dueDate", "");
    }
  }, [methods]);
  return (
    <SpaceBetween size="xs">
      <Header>Start and Due Dates</Header>
      <Container>
        <SpaceBetween direction="vertical" size="l">
          <RHFDatePicker
            label={t("workflow.editWorkflow.fields.startDate")}
            name={"startDate"}
            stretch={false}
          />
          <RHFDatePicker
            label={t("workflow.editWorkflow.fields.dueDate")}
            name={"dueDate"}
            stretch={false}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
