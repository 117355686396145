import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import ProviderSafeDetails from "../../provider-safe-details";

export default function DocumentVerionsTable() {
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );

  return (
    <>
      <ProviderSafeDetails />
    </>
  );
}
