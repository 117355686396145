import TopNavigation from "@cloudscape-design/components/top-navigation";
import useAuth from "../hooks/useAuth";
import { useGetProfileDetailsQuery } from "../redux/api/profile/profile";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { PATH_DASHBOARD } from "../routes/paths";
import { useLazyGetSignedSamlResponseQuery } from "../redux/api/analytics/analytics";
import useIsLoading from "../hooks/useIsLoading";
import { useState } from "react";

export default function TopHeaderBar({
  showDrawer,
}: {
  showDrawer: () => void;
}) {
  const { logout } = useAuth();

  const { data: organization } = useGetOrganizationDetailsQuery();
  const [isLoading, setIsLoading] = useState(false);
  const { data: user } = useGetProfileDetailsQuery();

  const { t } = useTranslation();

  const [trigger] = useLazyGetSignedSamlResponseQuery();

  const handleSubmit = async () => {
    try {
      const payload = await trigger().unwrap();
      if (
        !payload ||
        !payload?.SeviceProviderAcsPostUrl ||
        !payload?.SAMLAssertion ||
        !payload?.RelayState
      )
        return;

      // Create a hidden form
      const form = document.createElement("form");
      form.method = "POST";
      form.action = payload.SeviceProviderAcsPostUrl;
      form.target = "_blank"; // Open the form submission in a new tab

      // Add the access token as a hidden input field
      const samlResponseInput = document.createElement("input");
      samlResponseInput.type = "hidden";
      samlResponseInput.name = "SAMLResponse"; // Replace with the actual name of the token header
      samlResponseInput.value = payload.SAMLAssertion;
      form.appendChild(samlResponseInput);

      // Add the access token as a hidden input field
      const relayStateInput = document.createElement("input");
      relayStateInput.type = "hidden";
      relayStateInput.name = "RelayState"; // Replace with the actual name of the token header
      relayStateInput.value = payload.RelayState;
      form.appendChild(relayStateInput);

      // Append the form to the document and submit it
      document.body.appendChild(form);
      form.submit();

      // Remove the form from the document after submission (optional)
      document.body.removeChild(form);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  useIsLoading(isLoading);
  return (
    <div style={{ position: "sticky", top: 0, zIndex: 1002 }}>
      <TopNavigation
        id="topNavigation"
        identity={{
          href: "/dashboard",
          //     title: `${t("layout.topHeaderBar.header.title")} incredable_logo.png`,
          logo: {
            src: "/logo.svg",
            alt: "incredable",
          },
        }}
        utilities={[
          ...(!!organization?.organizationServiceLimits?.tasks
            ? [
                {
                  type: "button" as "button",
                  text: "My Tasks",
                  onClick: showDrawer,
                },
              ]
            : []),
          {
            type: "menu-dropdown",
            text: organization?.name,
            description: user?.email ?? "",
            iconName: "user-profile",
            items: [
              {
                id: "org_setting",
                text: `${t("layout.topHeaderBar.header.utilities.organization.label")}`,
                items: [
                  {
                    id: "general",
                    text: `${t("layout.topHeaderBar.header.utilities.organization.items.general")}`,
                    href: "/org",
                  },
                  {
                    id: "members",
                    text: `${t("layout.topHeaderBar.header.utilities.organization.items.members")}`,
                    href: "/manageadmin",
                  },
                  {
                    id: "rolesandpermissions",
                    text: `${t("layout.topHeaderBar.header.utilities.organization.items.rolesandpermissions")}`,
                    href: PATH_DASHBOARD.roles.list,
                  },
                ],
              },
              {
                id: "account",
                text: `${t("layout.topHeaderBar.header.utilities.account.label")}`,
                items: [
                  {
                    id: "profile",
                    text: `${t("layout.topHeaderBar.header.utilities.account.items.profile")}`,
                    href: "/settings/profile",
                  },
                  {
                    id: "support",
                    text: `${t("layout.topHeaderBar.header.utilities.account.items.support")}`,
                    href: "/support",
                  },
                  ...(!!organization?.organizationServiceLimits?.zohoAnalytics
                    ? [
                        {
                          id: "zoho",
                          text: "Open Zoho",
                        },
                      ]
                    : []),

                  {
                    id: "signout",
                    text: `${t("layout.topHeaderBar.header.utilities.account.items.signOut")}`,
                  },
                ],
              },
            ],
            onItemClick: async (itemClickDetails) => {
              if (itemClickDetails.detail.id === "signout") {
                logout();
              }
              if (itemClickDetails.detail.id === "zoho") {
                setIsLoading(true);
                await handleSubmit();
                setIsLoading(false);
              }
            },
          },
        ]}
      ></TopNavigation>
    </div>
  );
}
