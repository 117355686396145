import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
  TokenGroup,
  TokenGroupProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { join } from "lodash";
import { FilterType } from "../../hooks/useCategoryFilter";

interface CategoryFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function CategoryFilters({
  filter,
  setFilter,
  countText,
}: CategoryFiltersProps) {
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Name",
    value: "name",
  });
  const stateRequiredArray = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];
  const expirationRequiredArray = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];
  const [tokens, setTokens] = useState<TokenGroupProps.Item[]>([]);
  useEffect(() => {
    let temp: TokenGroupProps.Item[] = [];
    if (
      !!filter.stateRequiredFilterOptions &&
      filter.stateRequiredFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `State Required = ${join(
            filter.stateRequiredFilterOptions.map(
              (stateRequiredFilterOptions) => stateRequiredFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];
    if (
      !!filter.expirationRequiredFilterOptions &&
      filter.expirationRequiredFilterOptions.length > 0
    )
      temp = [
        ...temp,
        {
          label: `Expiration Required = ${join(
            filter.expirationRequiredFilterOptions.map(
              (expirationRequiredFilterOptions) =>
                expirationRequiredFilterOptions?.label,
            ),
            ", ",
          )}`,
        },
      ];
    setTokens([...temp]);
  }, [filter]);
  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 6 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => {
            setFilter({
              ...filter,
              filterCategoryName: "",
            });
            setSelectedProperty(detail.selectedOption);
          }}
          options={[
            { label: "Name", value: "name" },
            { label: "State Required", value: "stateRequired" },
            { label: "Expiration Required", value: "expirationRequired" },
          ]}
        />
        <div>
          {selectedProperty.value === "name" && (
            <TextFilter
              filteringPlaceholder="Find Category"
              filteringText={filter.filterCategoryName}
              onChange={({ detail }) =>
                setFilter({
                  ...filter,
                  filterCategoryName: detail.filteringText,
                })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "stateRequired" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.stateRequiredFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  stateRequiredFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={stateRequiredArray.map((state) => ({
                label: state.label,
                value: state.value,
              }))}
              placeholder="Choose State Required"
            />
          )}
          {selectedProperty.value === "expirationRequired" && (
            <Multiselect
              expandToViewport
              hideTokens
              selectedOptions={filter.expirationRequiredFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  expirationRequiredFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={expirationRequiredArray.map((expiration) => ({
                label: expiration.label,
                value: expiration.value,
              }))}
              placeholder="Choose Expiration Required"
            />
          )}
        </div>
      </Grid>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("State Required")
          ) {
            setFilter({
              ...filter,
              stateRequiredFilterOptions: [],
            });
          } else if (
            !!tokens &&
            tokens?.[itemIndex]?.label?.startsWith("Expiration Required")
          ) {
            setFilter({
              ...filter,
              expirationRequiredFilterOptions: [],
            });
          }
          setTokens([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
        }}
        items={tokens}
      />
    </SpaceBetween>
  );
}
