import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCredentialPackageDocumentsMutation,
  useDeleteCredentialPackageMutation,
  useGetAllCredentialPackageDocumentsQuery,
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import FormSection from "../../../components/FormSection";
import { useEffect, useState } from "react";
import { AllDocumentResponse } from "../../../redux/api/document/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import CredentialPackageAddDocumentsModal from "../modals/credentialpackage-add-documents-modal";
import DeleteAlertModal from "../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import {
  PACKAGE_STATUS,
  PACKAGE_TYPE,
  alertReminderOptions,
} from "../../../helpers/constants";
import CredentialPackageFormsList from "./credentialpackage-forms-list";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import { FormProvider, useForm } from "react-hook-form";
import { CredentialPackageRequest } from "../../../redux/api/credentialpackages/types";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import RHFFloatingSelect from "../../../components/RHF/RHFFloatingSelect";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { useGetAllProvidersQuery } from "../../../redux/api/provider/provider";
import { useCollection } from "@cloudscape-design/collection-hooks";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function CredentialPackageDetails({ setModalName }: PropsFromDispatch) {
  const { id: credentialPackageId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<CredentialPackageRequest>();
  const { data: credentialPackage, fulfilledTimeStamp } =
    useGetCredentialPackageQuery(credentialPackageId ?? skipToken);
  const [selectedDocuments, setSelectedDocuments] = useState<
    AllDocumentResponse[]
  >([]);
  const [selectedForms, setSelectedForms] = useState<DigitalForm[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePackage] = useDeleteCredentialPackageMutation();
  const [updateCredentialPackage] = useUpdateCredentialPackageMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false),
  );
  const { data: packageType = [] } = useGetAllPackageTypesQuery();
  const { data: template = [] } = useGetAllCredentialTemplatesQuery();
  const { data: packageStatus = [] } = useGetAllPackageStatusQuery();
  const { data: provider = [] } = useGetAllProvidersQuery();
  const onSubmit = async (data: CredentialPackageRequest) => {
    if (!!credentialPackageId) {
      await notifyWrapper({
        promise: updateCredentialPackage({
          id: credentialPackageId,
          ...data,
        }),
        resourceName: "Credential Package",
        actionName: "Update",
      });
    }
  };
  useEffect(() => {
    if (credentialPackage) {
      methods.setValue("name", credentialPackage?.name);
      methods.setValue("description", credentialPackage?.description);
      !!credentialPackage?.packageType?.id &&
        methods.setValue("packageTypeId", credentialPackage?.packageType?.id);
      !!credentialPackage?.packageStatus?.id &&
        methods.setValue(
          "packageStatusId",
          credentialPackage?.packageStatus?.id,
        );
      methods.setValue("providerId", credentialPackage?.provider?.id);
      methods.setValue("templateId", credentialPackage?.credentialTemplate?.id);
      methods.setValue("submittedDate", credentialPackage?.submittedDate);
      methods.setValue(
        "recredentialingDate",
        credentialPackage?.recredentialingDate,
      );
      methods.setValue("followupDate", credentialPackage?.followupDate);
    }
  }, [fulfilledTimeStamp, methods, credentialPackage]);

  return (
    <SpaceBetween size={"s"}>
      <BreadcrumbGroup
        items={[
          {
            text: `${t("credentialPackages.packageDetails.header.breadcrumb.text")}`,
            href: `/credentialpackages`,
          },
          {
            text:
              credentialPackage?.name ??
              `${t("credentialPackages.packageDetails.header.breadcrumb.currentPage")}`,
            href: "#",
          },
        ]}
      />
      <SpaceBetween size={"l"}>
        <Container>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormSection
                expandedSection
                defaultExpanded
                header={<Header>{credentialPackage?.name}</Header>}
                actions={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="primary"
                        formAction="submit"
                        disabled={!hasPermission}
                      >
                        {t(
                          "credentialPackages.packageDetails.header.details.actions.edit",
                        )}
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
              >
                <SpaceBetween direction="vertical" size="m">
                  <FormSection columns={4}>
                    <RHFSelect
                      disabled
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.provider",
                      )}
                      name={"providerId"}
                      stretch={false}
                      options={provider.map((temp) => ({
                        label: temp.firstName + " " + temp.lastName,
                        value: "" + temp.id,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFSelect
                      disabled
                      name="templateId"
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.credentialTemplate",
                      )}
                      options={template.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.name",
                      )}
                      name="name"
                      info="test"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="packageTypeId"
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.packageType.label",
                      )}
                      options={packageType.map((type) => ({
                        label: type.name,
                        value: "" + type.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: `${t("credentialPackages.packageDetails.header.details.fields.packageType.actions.modify")}`,
                        onClick: () => {
                          setModalName(PACKAGE_TYPE);
                        },
                      }}
                    />
                    <RHFFloatingSelect
                      name="packageStatusId"
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.packageStatus.label",
                      )}
                      options={packageStatus.map((status) => ({
                        label: status.name,
                        value: "" + status.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: `${t("credentialPackages.packageDetails.header.details.fields.packageStatus.actions.modify")}`,
                        onClick: () => {
                          setModalName(PACKAGE_STATUS);
                        },
                      }}
                    />
                    <RHFDatePicker
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.submittedDate",
                      )}
                      name={"submittedDate"}
                      stretch={false}
                    />
                    <RHFDatePicker
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.recredentialingDate",
                      )}
                      name={"recredentialingDate"}
                      stretch={false}
                    />

                    <RHFSelect
                      label={t(
                        "credentialPackages.packageDetails.header.details.fields.followupDate",
                      )}
                      name={"followupDate"}
                      stretch={false}
                      options={alertReminderOptions}
                    />
                  </FormSection>
                </SpaceBetween>
              </FormSection>
            </form>
          </FormProvider>
        </Container>
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (credentialPackageId) {
              OpenDeleteModal(false);
              await notifyWrapper({
                promise: deletePackage({
                  credentialPackageId: credentialPackageId,
                }),
                resourceName: "package",
                actionName: "delete",
              });
              navigate(-1);
            }
          }}
          closeModal={() => OpenDeleteModal(false)}
          header={t("credentialPackages.delete.header.label")}
          content={
            <>
              <Box>{t("credentialPackages.delete.header.content.label")}</Box>
              <Box variant="awsui-key-label">{credentialPackage?.name}</Box>
            </>
          }
          description={t(
            "credentialPackages.delete.header.content.description",
          )}
        />
        <CredentialPackageFormsList
          forms={credentialPackage?.digitalForm ?? []}
          selectedForms={selectedForms}
          setSelectForms={setSelectedForms}
        />
        <DocumentList
          documents={credentialPackage?.document ?? []}
          selectedDocuments={selectedDocuments}
          setSelectDocuments={setSelectedDocuments}
        />
      </SpaceBetween>
    </SpaceBetween>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CredentialPackageDetails);

function DocumentList({
  selectedDocuments,
  setSelectDocuments,
}: {
  documents: AllDocumentResponse[];
  selectedDocuments: AllDocumentResponse[];
  setSelectDocuments: (selectedDocuments: AllDocumentResponse[]) => void;
}) {
  const { id: credentialPackageId } = useParams();
  const { t } = useTranslation();
  const {
    data: documentsData = [],
    isFetching,
    isLoading,
  } = useGetAllCredentialPackageDocumentsQuery(
    credentialPackageId ? { credentialPackageId } : skipToken,
  );

  const { items, collectionProps } = useCollection(documentsData, {
    propertyFiltering: {
      filteringProperties: [],
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });

  const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] =
    useState<boolean>(false);

  const [deleteCredentialPackageDocuments] =
    useDeleteCredentialPackageDocumentsMutation();

  return (
    <>
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: (
              <div>
                {t(
                  "credentialPackages.packageDetails.header.documentList.table.header.fields.name",
                )}
              </div>
            ),
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "category",
            header: <div>Category</div>,
            cell: (item) => <div>{item?.category?.name || "-"}</div>,
            sortingField: "category.name",
            sortingComparator: (a, b) =>
              (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
          },
        ]}
        {...collectionProps}
        items={items}
        selectedItems={selectedDocuments ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectDocuments(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedDocuments;
          if (!detail.item) return;
          if (temp?.includes(detail.item))
            setSelectDocuments(temp.filter((item) => item !== detail.item));
          else setSelectDocuments([detail.item, ...temp]);
        }}
        selectionType="multi"
        loadingText="Loading..."
        loading={isLoading || isFetching}
        stickyHeader
        header={
          <Header
            variant="h3"
            counter={`(${documentsData?.length})`}
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  onClick={() => {
                    if (!selectedDocuments || !credentialPackageId) return;
                    const documentIds = selectedDocuments.map(
                      (document) => document.id + "",
                    );
                    deleteCredentialPackageDocuments({
                      credentialPackageId: credentialPackageId,
                      documents: documentIds,
                    });
                  }}
                >
                  {t(
                    "credentialPackages.packageDetails.header.documentList.table.header.actions.remove",
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setIsAddDocumentModalVisible(true);
                  }}
                >
                  {t(
                    "credentialPackages.packageDetails.header.documentList.table.header.actions.addDocument",
                  )}
                </Button>
              </SpaceBetween>
            }
          >
            {t(
              "credentialPackages.packageDetails.header.documentList.table.header.label",
            )}
          </Header>
        }
      />
      <CredentialPackageAddDocumentsModal
        closeModal={() => {
          setIsAddDocumentModalVisible(false);
        }}
        visible={isAddDocumentModalVisible}
      />
    </>
  );
}
