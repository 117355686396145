import {
  BreadcrumbGroup,
  Container,
  Wizard,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import "./add-workflow.css";
import InnerAppLayout from "../../components/InnerAppLayout";
import { useTranslation } from "react-i18next";
import {
  WorkFlowTaskRequest,
  WorkflowRequest,
} from "../../redux/api/workflow/types";
import {
  useAddWorkflowMutation,
  useGetAllWorkflowTemplateQuery,
} from "../../redux/api/workflow/workflow";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepThree from "./step-three";
import StepFour from "./step-four";
import ReviewWorkflow from "./review-workflow-step";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
dayjs.extend(dayjsPluginUTC);

export default function AddWorkflow() {
  const { t } = useTranslation();
  const methods = useForm<WorkflowRequest>();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addWorkflow] = useAddWorkflowMutation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [workflowTasks, setWorkflowTasks] = useState<WorkFlowTaskRequest[]>([]);

  const onSubmit = async (data: WorkflowRequest) => {
    const temp = { ...data, tasks: workflowTasks };
    await notifyWrapper({
      promise: addWorkflow(temp),
      resourceName: "workflow",
      actionName: "create",
    });
    navigate(-1);
  };

  const { data: workflowTemplates = [] } = useGetAllWorkflowTemplateQuery();
  const { data: providers = [] } = useGetAllProvidersQuery();
  const [
    providerId,
    workflowTemplateId,
    adminId,
    facilityId,
    startDate,
    dueDate,
  ] = methods.watch([
    "providerId",
    "workflowTemplateId",
    "adminId",
    "facilityId",
    "startDate",
    "dueDate",
  ]);

  useEffect(() => {
    if (!!workflowTemplateId) {
      const workflowTemplate = workflowTemplates?.find(
        (workflowTemplate) =>
          workflowTemplate?.id + "" === workflowTemplateId + "",
      );
      if (!!workflowTemplate && !!workflowTemplate?.tasks) {
        const temp: WorkFlowTaskRequest[] = workflowTemplate?.tasks?.map(
          (workflowTemplateTask) => ({
            name: workflowTemplateTask?.name,
            description: workflowTemplateTask?.description,
            taskStatusId: workflowTemplateTask?.taskStatus?.id,
            taskTypeId: workflowTemplateTask?.taskType?.id,
            facilityId: "",
            adminId: "",
            providerId: "",
            workflowId: "",
            startDate: startDate,
            dueDate: undefined,
          }),
        );
        setWorkflowTasks([...temp]);
      }
    }
  }, [workflowTemplateId, workflowTemplates, startDate]);

  useEffect(() => {
    const assignedProvider = providers?.find(
      (provider) => provider?.id + "" === providerId + "",
    );
    const temp = workflowTemplates?.find(
      (workflowTemplate) =>
        workflowTemplate?.id + "" === workflowTemplateId + "",
    );

    if (!!assignedProvider && !!temp) {
      const workflowName =
        assignedProvider?.firstName +
        " " +
        assignedProvider?.lastName +
        "_" +
        temp?.name;

      methods.setValue("name", workflowName);
    }
  }, [methods, workflowTemplateId, providerId, providers, workflowTemplates]);

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `${t(`workflow.addWorkflow.header.breadcrumb.text`)}`,
                href: `/workflows`,
              },
              {
                text: `${t(`workflow.addWorkflow.header.breadcrumb.currentPage`)}`,
                href: "#",
              },
            ]}
          />
        </>
      }
      content={
        <Container>
          <FormProvider {...methods}>
            <Wizard
              data-cssid="wizard"
              i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                  `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) =>
                  `Skip to ${step.title}`,
                navigationAriaLabel: "Steps",
                cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                submitButton: "Save Workflow",
                optional: "optional",
              }}
              onSubmit={async (event) => {
                const name = methods.getValues("name");
                if (!!name) methods.handleSubmit(onSubmit)();
              }}
              onCancel={() => {
                navigate(-1);
              }}
              onNavigate={({ detail }) => {
                setActiveStepIndex(detail.requestedStepIndex);
              }}
              activeStepIndex={activeStepIndex}
              allowSkipTo
              steps={[
                {
                  title: "Details",
                  content: <StepOne />,
                },
                {
                  title: "Start and Due Dates",
                  content: <StepThree />,
                  isOptional: true,
                },
                {
                  title: "Add Tasks",
                  content: (
                    <StepFour
                      workflowTasks={workflowTasks}
                      setWorkflowTasks={setWorkflowTasks}
                    />
                  ),
                },
                {
                  title: "Review and save",
                  content: (
                    <ReviewWorkflow
                      setActiveStepIndex={setActiveStepIndex}
                      workflowTasks={workflowTasks}
                    />
                  ),
                },
              ]}
            />
          </FormProvider>
        </Container>
      }
    />
  );
}
