import {
  BreadcrumbGroup,
  Button,
  ButtonDropdown,
  Container,
  Form,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DocumentMetaData from "../../components/DocumentMetaData";
import {
  useGetDocumentQuery,
  useGetDocumentVersionQuery,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import InnerAppLayout from "../../components/InnerAppLayout";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { FormProvider, useForm } from "react-hook-form";
import useIsLoading from "../../hooks/useIsLoading";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import useNotify from "../../hooks/useNotify";
import { v4 as uuidv4 } from "uuid";
import { fileAxios } from "../../context/axios";
import { captureException } from "@sentry/react";
import DocumentActivies from "./versions/document-activities";
import { getFileUrl } from "../../config";
import DocumentVersions from "./versions/document-versions";
import Divider from "@mui/material/Divider";
import ChangeTimeZone from "../../components/Timezone";

type FormInputType = {
  name: string;
  expirationDate?: string | Date;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
  isVersioned: boolean;
};

export default function ViewDocumentVersion() {
  const navigate = useNavigate();
  const { providerId, documentId, versionId } = useParams();
  const { t } = useTranslation();

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const {
    data: documentVersion,
    fulfilledTimeStamp,
    isLoading,
  } = useGetDocumentVersionQuery(
    documentId && providerId && versionId
      ? { documentId, providerId, versionId }
      : skipToken,
  );

  const methods = useForm<FormInputType>();

  const documentName = methods.watch("name");

  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );

  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: documentVersion?.name,
      alertDays: documentVersion?.alertDays,
      expirationDate: documentVersion?.expirationDate,
      notes: documentVersion?.notes,
      categoryId: documentVersion?.category?.id,
    });
  }, [document, fulfilledTimeStamp, methods]);

  useIsLoading(isLoading);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("providerSafe.content.editDocument.header.breadcrumb.text")}`,
                href: "/manageprovider",
              },
              {
                text: displayName,
                href: `/manageprovider/${providerId}`,
              },
              {
                text: `${t("providerSafe.content.editDocument.header.breadcrumb.nextPage")}`,
                href: `/safe?providerId=${providerId}`,
              },
              {
                text: !!documentVersion?.createdDate
                  ? `Version(${new Date(
                      documentVersion?.createdDate,
                    ).toLocaleString()})`
                  : "Version",
                href: "#",
              },
            ]}
          />
        }
        rightPanel={
          <AttachmentViewer
            attachment={documentVersion?.attachment}
            onFileChange={undefined}
          />
        }
        content={
          <FormProvider {...methods}>
            <Form>
              <Container
                header={
                  <SpaceBetween size="xs">
                    <Header
                      actions={
                        <Button
                          formAction="none"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          View current
                        </Button>
                      }
                    >
                      {documentName}
                    </Header>
                    <Divider />
                  </SpaceBetween>
                }
              >
                <div>
                  <DocumentMetaData disabled={true} />
                </div>
              </Container>
            </Form>
          </FormProvider>
        }
      />
    </>
  );
}
