// react
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// components
// layout
import DashboardLayout from "../layout/dashboard-layout";
import { PATH_AFTER_LOGIN, PATH_AUTH, PATH_DASHBOARD } from "./paths";
import ProviderSafe from "../providersafe";
import AddDocument from "../providersafe/document/add-document";
import EditDocument from "../providersafe/document/edit-document";
import AuthGuard from "../gaurds/AuthGaurd";
import Support from "../support";
import Profile from "../profile";
import OrganizationSettings from "../organization";
import ManageAdminTable from "../manageadmin/index";
import ManageProviderTable from "../manageprovider/index";
import ManageProfileSections from "../manageprofile";
import ConfigureSection from "../manageprofile/configure-section";
import Digitalforms from "../digitalform/digitalforms";
import SignUpForm from "../auth/signup/signup";
import ManageFacilities from "../managefacility";
import CustomFacilityEditRecord from "../managefacility/modify-facility-record/custom-facility-edit-record";
import CustomFacilityAddRecord from "../managefacility/modify-facility-record/custom-facility-add-record";
import Roles from "../roles";
import RoleDetails from "../roles/manage-roles/role-details";
import PermissionGaurd from "../gaurds/PermissionGaurd";
import OrgBasicDetailsEdit from "../organization/org-basic-details-edit";
import { useEffect } from "react";
import ResetPasswordForm from "../auth/reset-password";
import RequestReset from "../auth/reset-password/request-reset";
import Contracts from "../contracts";
import AddContract from "../contracts/modify-contract/add-contract";
import EditContract from "../contracts/modify-contract/edit-contract";
import ContractType from "../contracts/contracttype";
import ProviderDetail from "../provider/provider-profile/provider-details";
import EditPersonalInfo from "../provider/provider-profile/sections/modify-sections/edit-personal-info";
import EditProfessionalInfo from "../provider/provider-profile/sections/modify-sections/edit-professional-info";
import EditEducation from "../provider/provider-profile/sections/modify-sections/edit-education";
import EditDisclousres from "../provider/provider-profile/sections/modify-sections/edit-disclosures";
import EditAffiliation from "../provider/provider-profile/sections/modify-sections/edit-affiliation";
import EditDea from "../provider/provider-profile/sections/modify-sections/edit-dea";
import EditLiabilityInsurance from "../provider/provider-profile/sections/modify-sections/edit-liabilityinsurance";
import EditLicense from "../provider/provider-profile/sections/modify-sections/edit-license";
import EditPeerReference from "../provider/provider-profile/sections/modify-sections/edit-peer-reference";
import EditSpecialtyInfo from "../provider/provider-profile/sections/modify-sections/edit-specialty-info";
import EditThirdPartyLogin from "../provider/provider-profile/sections/modify-sections/edit-third-party-login";
import EditWorkHistory from "../provider/provider-profile/sections/modify-sections/edit-work-history";
import Privileges from "../privileges";
import EditPrivilege from "../privileges/modify/edit-privilege";
import PrivilegeTypeTable from "../privileges/privilegesType";
import AddPrivilegeType from "../privileges/privilegesType/modify/add-privilegetype";
import EditPrivilegeType from "../privileges/privilegesType/modify/edit-privilegetype";
import AddPrivileges from "../privileges/modify/add-privileges";
import EditDigitalForm from "../digitalform/digitalforms/modify/editDigitalForm";
import AddDigitalForm from "../digitalform/digitalforms/modify/addDigitalForm";
import SignatureRequestsTable from "../signature-requests";
import SignatureDetails from "../signature-requests/signature-detail";
import CredentailPackages from "../credentialpackages";
import CredentailTemplates from "../credentialpackages/templates";
import AddPayerEnrollment from "../payerenrollment/modify/add-payer-enrollment";
import AddCredentailTemplates from "../credentialpackages/modify/add-credentialtemplate";
import AddCredentialPackage from "../credentialpackages/packages/modify/add-package";
import AddPayer from "../payers/modify/add-payer";
import EditPayer from "../payers/modify/edit-payer";
import PayersTable from "../payers";
import PayerTable from "../payerenrollment";
import Dashboard from "../dashboard";
import ReportsList from "../reports";
import ExpiredDocuments from "../reports/expired-documents";
import ExpiringDocuments from "../reports/expiring-documents";
import EditPayerEnrollment from "../payerenrollment/modify/edit-payer-enrollments";
import DigitalformTemplateDesigner from "../template-designer/wrappers/digitalform-template-designer";
import AddFacility from "../managefacility/modify/add-facility";
import EditFacility from "../managefacility/modify/edit-facility";
import DigitalformRequestSignature from "../signature-requests/wrappers/digitalform-signature-request-wrapper";
import ProviderSafeDetails from "../providersafe/provider-safe-details";
import AddPSV from "../providersafe/psv/add-psv";
import CredentialPackageDetails from "../credentialpackages/packages/modify/credential-package-details";
import CredentialTemplateDetails from "../credentialpackages/modify/credentialtemplate-details";
import AddManageProviderCsvUpload from "../manageprovider/modals/add-manageprovidercsvupload";
import { TestPdftronViewer } from "../components/PdftronViewerWrappers/test-pdftron-viewer";
import AddEnrolledpayers from "../provider/provider-profile/sections/modify-sections/add-enrolledpayers";
import EditEnrolledpayers from "../provider/provider-profile/sections/modify-sections/edit-enrolledpayers";
import CredentialPackageSignatureRequest from "../signature-requests/wrappers/credential-package-signature-request-wrapper";
import Managelicensetype from "../managelicensetype";
import AddLicenseType from "../managelicensetype/add-licensetype";
import EditLicenseType from "../managelicensetype/edit-licensetype";
import ManageCategory from "../managecategory";
import AddCategory from "../managecategory/add-category";
import EditCategory from "../managecategory/edit-category";
import Workflows from "../workflow";
import AddWorkflow from "../workflow/add-workflow/add-workflow";
import Tasks from "../workflow/tasks";
import AddTask from "../workflow/tasks/add-task";
import EditTask from "../workflow/tasks/edit-task";
import EditWorkflow from "../workflow/edit-workflow";
import AddWorkflowTemplate from "../workflow/templates/add-workflow-template";
import WorkflowsTemplate from "../workflow/templates";
import EditWorkflowTemplate from "../workflow/templates/edit-workflow-template";
import AddWorkflowtask from "../workflow/workflowtasks/add-workflowtask";
import { captureException } from "@sentry/react";
import BulkUpload from "../providersafe/document/BulkUpload";
import EditWorkflowtask from "../workflow/workflowtasks/edit-workflowtask";
import DocumentVerionsTable from "../providersafe/document/versions";
import AllDocumentsReport from "../reports/all-documents-report";
import PayerEnrollmentReport from "../reports/payer-enrollments-report";
import ProviderRoasterReport from "../reports/provider-roster";
import ProviderAffiliationReport from "../reports/provider-affiliation-report";
import ViewDocumentVersion from "../providersafe/document/view-documet-version";
import PayerGroupTable from "../payergroup";
import AddPayerGroup from "../payergroup/modify/add-payer-group";
import EditPayerGroup from "../payergroup/modify/edit-payer-group";
import AddInvitation from "../manageadmin/invitation/add-invitation";
import EditMember from "../manageadmin/manageadmin-details/edit-member";
import { SigningDocument } from "../token-based/signing-document";
import SigningSuccess from "../components/results/signature/signing-success";
import SigningFailed from "../components/results/signature/signing-failed";
import AddFacilityGroup from "../facilitygroup/modify/add-facility-group";
import EditFacilityGroup from "../facilitygroup/modify/edit-facility-group";
import FacilityGroupTable from "../facilitygroup";
import ManageAffiliations from "../affiliation";
import AddAffiliation from "../affiliation/modify/add-affiliation";
import IncredableLogin from "../incredable/login";

export const InvalidPathRoute = () => {
  const location = useLocation();
  useEffect(() => {
    captureException(`Invalid path: ${location.pathname}`);
    console.error(`Invalid path: ${location.pathname}`);
  }, [location.pathname]);

  return <Navigate to={PATH_DASHBOARD.safe.list} replace />;
};

function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "dashboard",
          element: <Dashboard />,
          index: true,
        },
        {
          path: "safe",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafe />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":providerId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafe />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/addnew",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/bulkupload",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <BulkUpload />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafeDetails />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId/psv/:psvId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafeDetails />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId/psv/add",
              element: <AddPSV />,
            },
            {
              path: ":providerId/documents/:documentId/edit",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId/version/:versionId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ViewDocumentVersion />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId/versions",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <DocumentVerionsTable />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "affiliations",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ManageAffiliations />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "editaffiliation/:affiliationId",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddAffiliation />
                </PermissionGaurd>
              ),
            },
            {
              path: "addaffiliation",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddAffiliation />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.manage.categories.list,
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ManageCategory />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <EditCategory />
                </PermissionGaurd>
              ),
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <AddCategory />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "manageadmin",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="member.view">
                  <ManageAdminTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":manageadminId",
              element: (
                <PermissionGaurd permissionKey="member.view">
                  <ManageAdminTable />
                </PermissionGaurd>
              ),
            },
            {
              path: "add/:manageadminId",
              element: (
                <PermissionGaurd permissionKey="member.edit">
                  <AddInvitation />
                </PermissionGaurd>
              ),
            },
            {
              path: "editmember/:manageadminId",
              element: (
                <PermissionGaurd permissionKey="member.edit">
                  <EditMember />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "workflows",
          children: [
            {
              element: <Workflows />,
              index: true,
            },
            {
              path: "add",
              element: <AddWorkflow />,
            },
            {
              path: ":workflowId",
              element: <EditWorkflow />,
            },
          ],
        },
        {
          path: "workflows/:workflowId/addtask",
          element: <AddWorkflowtask />,
        },
        {
          path: "workflows/:workflowId/task/:taskId",
          element: <EditWorkflowtask />,
        },
        {
          path: PATH_DASHBOARD.manage.workflowTemplates.list,
          children: [
            {
              element: <WorkflowsTemplate />,
              index: true,
            },
            {
              path: "add",
              element: <AddWorkflowTemplate />,
            },
            {
              path: ":templateId",
              element: <EditWorkflowTemplate />,
            },
          ],
        },
        {
          path: "tasks",
          children: [
            {
              element: <Tasks />,
              index: true,
            },
            {
              path: "add",
              element: <AddTask />,
            },
            {
              path: ":taskId",
              element: <EditTask />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.reports.list,
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="reports.view">
                  <ReportsList />
                </PermissionGaurd>
              ),
              index: true,
            },
          ],
        },
        {
          path: "expireddocuments",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ExpiredDocuments />
            </PermissionGaurd>
          ),
        },
        {
          path: "expiringdocuments",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ExpiringDocuments />
            </PermissionGaurd>
          ),
        },
        {
          path: "alldocuments",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <AllDocumentsReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "enrollmentreport",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <PayerEnrollmentReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "provideraffiliation",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ProviderAffiliationReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "providerroatser",
          element: (
            <PermissionGaurd permissionKey="reports.view">
              <ProviderRoasterReport />
            </PermissionGaurd>
          ),
        },
        {
          path: "manageprovider",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ManageProviderTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addCsvUpload",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <AddManageProviderCsvUpload />
                </PermissionGaurd>
              ),
            },

            {
              path: ":providerId",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="provider.view">
                      <ManageProviderTable />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "profile",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetail />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "editpersonalinfo",
                      element: <EditPersonalInfo />,
                    },
                    {
                      path: "editprofessionalinfo",
                      element: <EditProfessionalInfo />,
                    },
                    {
                      path: "editeducation/:educationId",
                      element: <EditEducation />,
                    },
                    {
                      path: "editeducation",
                      element: <EditEducation />,
                    },
                    {
                      path: "editdisclousres",
                      element: <EditDisclousres />,
                    },
                    {
                      path: "editdea/:deaId",
                      element: <EditDea />,
                    },
                    {
                      path: "editdea",
                      element: <EditDea />,
                    },
                    {
                      path: "editaffiliation/:affiliationId",
                      element: <EditAffiliation />,
                    },
                    {
                      path: "editaffiliation",
                      element: <EditAffiliation />,
                    },
                    {
                      path: "editliabilityinsurance/:liabilityinsuranceId",
                      element: <EditLiabilityInsurance />,
                    },
                    {
                      path: "editliabilityinsurance",
                      element: <EditLiabilityInsurance />,
                    },
                    {
                      path: "editlicense/:licenseId",
                      element: <EditLicense />,
                    },
                    {
                      path: "editlicense",
                      element: <EditLicense />,
                    },
                    {
                      path: "editpeerreference/:peerreferenceId",
                      element: <EditPeerReference />,
                    },
                    {
                      path: "editpeerreference",
                      element: <EditPeerReference />,
                    },
                    {
                      path: "editspecialtyinfo/:specialtyinfoId",
                      element: <EditSpecialtyInfo />,
                    },
                    {
                      path: "editspecialtyinfo",
                      element: <EditSpecialtyInfo />,
                    },
                    {
                      path: "editthirdpartylogin/:thirdpartyloginId",
                      element: <EditThirdPartyLogin />,
                    },
                    {
                      path: "editthirdpartylogin",
                      element: <EditThirdPartyLogin />,
                    },
                    {
                      path: "editworkhistory/:workhistoryId",
                      element: <EditWorkHistory />,
                    },
                    {
                      path: "editworkhistory",
                      element: <EditWorkHistory />,
                    },
                    {
                      path: "addenrolledpayers",
                      element: <AddEnrolledpayers />,
                    },
                    {
                      path: "editenrolledpayers/:enrollmentId",
                      element: <EditEnrolledpayers />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "managefacilities",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <ManageFacilities />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="facility.edit">
                  <EditFacility />
                </PermissionGaurd>
              ),
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <AddFacility />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id/profile/:recordId",
              element: <CustomFacilityEditRecord />,
            },
            {
              path: ":id/add/:sectionId",
              element: <CustomFacilityAddRecord />,
            },
          ],
        },
        {
          path: "managesection",
          children: [
            {
              element: <ManageProfileSections />,
              index: true,
            },
            {
              path: ":id",
              element: <ConfigureSection />,
            },
          ],
        },
        {
          path: "support",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="support.all">
                  <Support />
                </PermissionGaurd>
              ),
              index: true,
            },
          ],
        },
        {
          path: "org",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="organization_settings.view">
                  <OrganizationSettings />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "details/edit",
              element: (
                <PermissionGaurd permissionKey="organization_settings.edit">
                  <OrgBasicDetailsEdit />
                </PermissionGaurd>
              ),
            },
            {
              path: "settings/edit",
              element: (
                <PermissionGaurd permissionKey="organization_settings.edit">
                  <OrgBasicDetailsEdit />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "settings/profile",
          children: [
            {
              element: <Profile />,
              index: true,
            },
          ],
        },
        {
          path: "settings/roles",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="roles.view">
                  <Roles />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="roles.view">
                  <RoleDetails />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "privilegetype",
          children: [
            {
              element: <PrivilegeTypeTable />,
              index: true,
            },
            {
              path: "add",
              element: <AddPrivilegeType />,
            },
            {
              path: ":id",
              element: <EditPrivilegeType />,
            },
          ],
        },
        {
          path: "privileges",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="privileges.view">
                  <Privileges />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="privileges.view">
                  <AddPrivileges />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="privileges.view">
                  <EditPrivilege />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.payerenrollment.list,
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayerTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id/addenrollment",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <AddPayerEnrollment />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id/editenrollment/:enrollmentId",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <EditPayerEnrollment />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayerTable />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "payers",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayersTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <AddPayer />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <EditPayer />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "payergroup",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <PayerGroupTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <AddPayerGroup />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="payer.view">
                  <EditPayerGroup />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "facilitygroup",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <FacilityGroupTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <AddFacilityGroup />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="facility.edit">
                  <EditFacilityGroup />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "contract",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="contract.view">
                  <Contracts />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="contract.view">
                  <AddContract />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="contract.view">
                  <EditContract />
                </PermissionGaurd>
              ),
            },
          ],
        },

        {
          path: "contracttypes",
          element: <ContractType />,
          children: [
            {
              path: ":id",
              element: <ContractType />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.credential.packages.list,
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <CredentailPackages />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <AddCredentialPackage />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="credentialpackage.view">
                      <CredentialPackageDetails />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "requestsignature",
                  element: (
                    <PermissionGaurd permissionKey="signature.view">
                      <CredentialPackageSignatureRequest />
                    </PermissionGaurd>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: PATH_DASHBOARD.credential.templates.list,
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <CredentailTemplates />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addnew",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <AddCredentailTemplates />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="credentialpackage.view">
                  <CredentialTemplateDetails />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.manage.licensetype.list,
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <Managelicensetype />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddLicenseType />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <EditLicenseType />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "digitalforms",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="readyforms.view">
                  <Digitalforms />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="readyforms.edit">
                      <EditDigitalForm />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "templatedesigner",
                  element: <DigitalformTemplateDesigner />,
                },
                {
                  path: "requestsignature",
                  element: (
                    <PermissionGaurd permissionKey="signature.view">
                      <DigitalformRequestSignature />
                    </PermissionGaurd>
                  ),
                },
              ],
            },
            {
              path: "addNew",
              element: (
                <PermissionGaurd permissionKey="readyforms.view">
                  <AddDigitalForm />,
                </PermissionGaurd>
              ),
            },
          ],
        },

        {
          path: "esignatures",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="signature.view">
                  <SignatureRequestsTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="signature.view">
                  <SignatureDetails />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "testviewer",
          element: <TestPdftronViewer />,
        },
        {
          path: "*",
          element: <InvalidPathRoute />,
        },
      ],
    },
    {
      path: PATH_AUTH.login,
      element: <Navigate to={PATH_DASHBOARD.dashboard.list} />,
    },
    {
      path: "incredable/login",
      element: <IncredableLogin />,
    },
    {
      path: PATH_AUTH.register,
      element: <SignUpForm />,
    },
    {
      path: PATH_AUTH.resetPassword,
      element: <ResetPasswordForm />,
    },
    {
      path: PATH_AUTH.requestReset,
      element: <RequestReset />,
    },
    {
      path: "signing",
      children: [
        { element: <SigningDocument />, index: true },
        { path: "error", element: <SigningFailed /> },
        { path: "success", element: <SigningSuccess /> },
      ],
    },
  ]);
}

export default Router;
