import {
  Table,
  TableProps,
  Box,
  Header,
  Button,
  ButtonDropdown,
  Pagination,
  Select,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import StyledRouterLink from "../components/styled-route-link";

import { useNavigate } from "react-router";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../components/LoadingScreen";
import { PATH_DASHBOARD } from "../routes/paths";
import ChangeTimeZone from "../components/Timezone";
import {
  useDeleteWorkflowMutation,
  useGetAllWorkflowStatusQuery,
  usePatchWorkflowMutation,
} from "../redux/api/workflow/workflow";
import { WorkflowMetadata } from "../redux/api/workflow/types";
import {
  TablePreferences,
  WORKFLOW_CONTENT_DISPLAY_OPTIONS,
  WORKFLOW_TABLE_DEFAULT_PREFERENCES,
} from "../common/table-config";
import { useLocalStorage } from "../common/localStorage";
import useWorkflowFilter from "../hooks/useWorkflowFilter";
import WorkflowFilters from "../components/filter/workflow-filters";
import { useTranslation } from "react-i18next";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { captureException } from "@sentry/react";
import { findLast } from "lodash";

export default function Workflows() {
  const { t } = useTranslation();
  const [deleteWorkflow] = useDeleteWorkflowMutation();
  const { data: workflowStatuses = [] } = useGetAllWorkflowStatusQuery();

  const [patchWorkflow] = usePatchWorkflowMutation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const {
    filteredWorkflow,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useWorkflowFilter();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Workflow-Table-Preferences",
    WORKFLOW_TABLE_DEFAULT_PREFERENCES,
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredWorkflow,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: (
          <TableEmptyState
            resourceName={t(`workflow.table.header.label`)}
            action={() => {
              navigate(`add`);
            }}
          />
        ),
      },
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkflowMetadata>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const workflowStatusOptions: OptionDefinition[] = useMemo(() => {
    if (!workflowStatuses) return [];
    return workflowStatuses?.map((status) => ({
      label: status.name,
      value: "" + status.id,
    }));
  }, [workflowStatuses]);

  const tableProps: TableProps<WorkflowMetadata> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredWorkflow.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedWorkflow}
                  items={[
                    {
                      id: "edit_form",
                      text: `${t(`workflow.table.header.actions.workflowEdit`)}`,
                    },
                    {
                      id: "delete_form",
                      text: `${t(`workflow.table.header.actions.workflowDelete`)}`,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedWorkflow) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      if (!!selectedWorkflow?.id) {
                        navigate(
                          PATH_DASHBOARD.manage.workflows.edit(
                            selectedWorkflow?.id,
                          ),
                        );
                      }
                    }
                  }}
                >
                  {t(`workflow.table.header.actions.workflowActions`)}
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate(`add`);
                  }}
                  variant="primary"
                  data-test="add_workflow"
                >
                  {t(`workflow.table.header.actions.addWorkflow`)}
                </Button>
              </span>
            </div>
          }
        >
          {t(`workflow.table.header.label`)}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      filter: <WorkflowFilters filter={filter} setFilter={setFilter} />,
      preferences: (
        <TablePreferences
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={WORKFLOW_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedWorkflow(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedWorkflow(onRowClickDetail.detail.item);
      },
      selectedItems: !!selectedWorkflow ? [selectedWorkflow] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>{t(`workflow.table.fields.id`)}</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>{t(`workflow.table.fields.name`)}</div>,
          cell: (item) => (
            <StyledRouterLink
              className={"edit_link"}
              to={`${item.id}`}
              label={item?.name}
            />
          ),
          sortingField: "name",
          sortingComparator: (a, b) =>
            (a?.name ?? "").localeCompare(b?.name ?? ""),
        },
        {
          id: "workflowStatusId",
          header: <span>{t(`workflow.table.fields.workflowStatus`)}</span>,
          cell: (item) => <span>{item?.workFlowStatus?.name}</span>,
          editConfig: {
            editingCell: (item, { currentValue, setValue }) => {
              return (
                <Select
                  selectedOption={
                    findLast(
                      workflowStatusOptions,
                      (option) =>
                        option?.value + "" ===
                        (currentValue ?? item?.workFlowStatus?.id + ""),
                    ) ?? null
                  }
                  expandToViewport
                  onChange={({ detail }) => {
                    if (!!detail?.selectedOption?.value)
                      setValue(detail.selectedOption.value);
                  }}
                  options={workflowStatusOptions}
                />
              );
            },
          },
          sortingField: "workFlowStatus.name",
          sortingComparator: (a, b) =>
            (a?.workFlowStatus?.name ?? "").localeCompare(
              b?.workFlowStatus?.name ?? "",
            ),
        },
        {
          id: "workflowTemplate",
          header: <div>{t(`workflow.table.fields.workflowTemplate`)}</div>,
          cell: (item) => <span>{item?.workflowTemplate?.name}</span>,
          sortingField: "workflowTemplate.name",
          sortingComparator: (a, b) =>
            (a?.workflowTemplate?.name ?? "").localeCompare(
              b?.workflowTemplate?.name ?? "",
            ),
        },
        {
          id: "admin",
          header: <div>{t(`workflow.table.fields.assignedTo`)}</div>,
          cell: (item) => (
            <span>
              {!!item?.admin
                ? `${item?.admin?.firstName} ${item?.admin?.lastName}`
                : "-"}
            </span>
          ),
          sortingField: "admin",
          sortingComparator: (a, b) =>
            (a?.admin?.firstName ?? "").localeCompare(b?.admin?.lastName ?? ""),
        },
        {
          id: "provider",
          header: <div>{t(`workflow.table.fields.provider`)}</div>,
          cell: (item) => (
            <span>
              {!!item?.provider
                ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                : "-"}
            </span>
          ),
          sortingField: "provider",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.lastName ?? "",
            ),
        },
        {
          id: "startDate",
          header: <div>{t(`workflow.table.fields.startDate`)}</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.startDate) ?? "-"}</span>,
          sortingField: "startDate",
        },
        {
          id: "dueDate",
          header: <div>{t(`workflow.table.fields.dueDate`)}</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.dueDate) ?? "-"}</span>,
          sortingField: "dueData",
        },
        {
          id: "completedDate",
          header: <div>{t(`workflow.table.fields.completedDate`)}</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.completedDate) ?? "-"}</span>
          ),
          sortingField: "completedDate",
        },
        {
          id: "description",
          header: <div>{t(`workflow.table.fields.description`)}</div>,
          cell: (item) => <span>{item?.notes}</span>,
          sortingField: "notes",
          sortingComparator: (a, b) =>
            (a?.notes ?? "").localeCompare(b?.notes ?? ""),
        },
        {
          id: "createdDate",
          header: <div>{t(`workflow.table.fields.createdDate`)}</div>,
          cell: (item) => <span>{ChangeTimeZone(item?.createdDate)}</span>,
          sortingField: "createdDate",
        },
      ],
      submitEdit: async (item, column, newValue) => {
        try {
          const columnId = column?.id;
          if (!columnId || !newValue) return;
          const temp = newValue as string;
          await notifyWrapper({
            promise: patchWorkflow({
              id: item?.id,
              request: { [columnId]: temp },
            }),
            resourceName: "Workflow",
            actionName: "update",
          });
        } catch (error) {
          captureException(error);
        }
      },
    };
  }, [items, selectedWorkflow, tablePreferences]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!selectedWorkflow?.id)
            await notifyWrapper({
              promise: deleteWorkflow({ id: selectedWorkflow?.id }),
              resourceName: "Workflow",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t(`workflow.delete.header.label`)}
        content={
          <>
            <Box>{t(`workflow.delete.header.content.label`)}</Box>
            <Box variant="awsui-key-label">{selectedWorkflow?.name}</Box>
          </>
        }
        description={t(`workflow.delete.header.content.description`)}
      />
    </div>
  );
}
