import {
  Box,
  Container,
  Header,
  SpaceBetween,
  Pagination,
  CollectionPreferencesProps,
  CollectionPreferences,
} from "@cloudscape-design/components";
import { Divider } from "antd";
import { useGetAllDocumentActivitiesQuery } from "../../../redux/api/document/document";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import * as awsui from "@cloudscape-design/design-tokens";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./document-activities.css";
import ChangeTimeZone from "../../../components/Timezone";
import React from "react";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { usePageIndex } from "../../../hooks/usePageIndex";
import useIsLoading from "../../../hooks/useIsLoading";

export default function DocumentActivies() {
  const { providerId, documentId } = useParams();

  const [preferences, setPreferences] = React.useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { data: documentHistory, isLoading } = useGetAllDocumentActivitiesQuery(
    !!documentId && !!providerId ? { providerId, documentId } : skipToken,
  );

  const { paginationProps } = useCollection(documentHistory?.activities ?? [], {
    pagination: {
      pageSize: preferences?.pageSize,
      defaultPage: 1,
    },
  });

  usePageIndex(String(paginationProps.currentPageIndex));
  const startIndex =
    (paginationProps.currentPageIndex - 1) * (preferences.pageSize as number);
  const endIndex = startIndex + (preferences.pageSize as number);
  useIsLoading(isLoading);
  return (
    <div style={{ height: `calc(100vh - 210px)`, overflowY: "auto" }}>
      <Container header={<Header>Audit Logs</Header>}>
        <SpaceBetween size="m" direction="vertical">
          <SpaceBetween direction="horizontal" alignItems="center" size="s">
            <Pagination {...paginationProps} />
            <CollectionPreferences
              onConfirm={({ detail }) => {
                if (!!detail) {
                  setPreferences(detail);
                }
              }}
              preferences={preferences}
              pageSizePreference={{
                options: [
                  { value: 10, label: "10 items" },
                  { value: 30, label: "30 items" },
                  { value: 50, label: "50 items" },
                ],
              }}
            />
          </SpaceBetween>
          <SpaceBetween size="m">
            {!documentHistory?.document &&
              !!documentHistory?.activities &&
              documentHistory?.activities?.length === 0 &&
              "No Audit logs Found"}
            {!!documentHistory?.document && (
              <div>
                <Divider
                  dashed
                  style={{
                    borderColor: awsui.colorBorderDividerDefault,
                    borderWidth: "1px 0 0",
                    margin: "4px 0",
                  }}
                />
                <Box variant="p">
                  Created by{" "}
                  <b>
                    {documentHistory?.document?.user?.firstName}{" "}
                    {documentHistory?.document?.user?.lastName}
                  </b>{" "}
                  on{" "}
                  <b>
                    {!!documentHistory?.document?.createdDate
                      ? new Date(
                          documentHistory?.document?.createdDate,
                        ).toLocaleString()
                      : "-"}
                  </b>
                </Box>
                <Divider
                  dashed
                  style={{
                    borderColor: awsui.colorBorderDividerDefault,
                    borderWidth: "1px 0 0",
                    margin: "4px 0",
                  }}
                />
                {!!documentHistory?.document?.name && (
                  <Box variant="p">
                    Name: <i>{documentHistory?.document?.name} </i>
                  </Box>
                )}

                {!!documentHistory?.document?.attachment && (
                  <Box variant="p">
                    File:{" "}
                    <i>{documentHistory?.document?.attachment?.name ?? "-"} </i>
                  </Box>
                )}
                {!!documentHistory?.document?.category && (
                  <Box variant="p">
                    Category:{" "}
                    <i>{documentHistory?.document?.category?.name ?? "-"}</i>
                  </Box>
                )}
                {!!documentHistory?.document?.expirationDate && (
                  <Box variant="p">
                    Expiration Date:{" "}
                    <i>
                      {ChangeTimeZone(
                        documentHistory?.document?.expirationDate,
                      ) ?? "-"}
                    </i>
                  </Box>
                )}
                {!!documentHistory?.document?.alertDays && (
                  <Box variant="p">
                    Expiration Alert Reminder:{" "}
                    <i>
                      {!!documentHistory?.document?.alertDays
                        ? `${documentHistory?.document?.alertDays} days`
                        : "-"}
                    </i>
                  </Box>
                )}
                <Divider
                  dashed
                  style={{
                    borderColor: awsui.colorBorderDividerDefault,
                    borderWidth: "1px 0 0",
                    margin: "12px 0",
                  }}
                />
              </div>
            )}
            {!!documentHistory &&
              !!documentHistory?.activities &&
              !!documentHistory?.activities?.length &&
              documentHistory?.activities
                .slice(startIndex, endIndex)
                .map((documentActivity) => {
                  return (
                    <div>
                      <Divider
                        dashed
                        style={{
                          borderColor: awsui.colorBorderDividerDefault,
                          borderWidth: "1px 0 0",
                          margin: "4px 0",
                        }}
                      />
                      <Box variant="p">
                        Modified by{" "}
                        <b>
                          {documentActivity?.user?.firstName}{" "}
                          {documentActivity?.user?.lastName}
                        </b>{" "}
                        on{" "}
                        <b>
                          {!!documentActivity?.createdDate
                            ? new Date(
                                documentActivity?.createdDate,
                              ).toLocaleString()
                            : "-"}
                        </b>
                      </Box>
                      <Divider
                        dashed
                        style={{
                          borderColor: awsui.colorBorderDividerDefault,
                          borderWidth: "1px 0 0",
                          margin: "4px 0",
                        }}
                      />
                      {!!documentActivity?.name && (
                        <Box variant="p">
                          Name:{" "}
                          <i>
                            {documentActivity?.name.oldValue}{" "}
                            <ArrowRightOutlined
                              style={{
                                fontSize: "12px",
                                color: awsui.colorChartsThresholdNeutral,
                                margin: "0 4px",
                              }}
                            />{" "}
                            {documentActivity?.name.newValue}
                          </i>
                        </Box>
                      )}
                      {!!documentActivity?.attachment && (
                        <Box variant="p">
                          File:{" "}
                          <i>
                            {!!documentActivity?.attachment.oldValue
                              ? documentActivity?.attachment.oldValue
                              : "None"}{" "}
                            <ArrowRightOutlined
                              style={{
                                fontSize: "12px",
                                color: awsui.colorChartsThresholdNeutral,
                                margin: "0 4px",
                              }}
                            />{" "}
                            {!!documentActivity?.attachment.newValue
                              ? documentActivity?.attachment.newValue
                              : "None"}
                          </i>
                        </Box>
                      )}
                      {!!documentActivity?.notes && (
                        <Box variant="p">
                          Notes:{" "}
                          <i>
                            {documentActivity?.notes.oldValue}{" "}
                            <ArrowRightOutlined
                              style={{
                                fontSize: "12px",
                                color: awsui.colorChartsThresholdNeutral,
                                margin: "0 4px",
                              }}
                            />{" "}
                            {documentActivity?.notes.newValue}
                          </i>
                        </Box>
                      )}
                      {!!documentActivity?.category && (
                        <Box variant="p">
                          Category:{" "}
                          <i>
                            {!!documentActivity?.category.oldValue
                              ? documentActivity?.category.oldValue
                              : "None"}{" "}
                            <ArrowRightOutlined
                              style={{
                                fontSize: "12px",
                                color: awsui.colorChartsThresholdNeutral,
                                margin: "0 4px",
                              }}
                            />{" "}
                            {!!documentActivity?.category.newValue
                              ? documentActivity?.category.newValue
                              : "None"}
                          </i>
                        </Box>
                      )}
                      {!!documentActivity?.expirationDate && (
                        <Box variant="p">
                          Expiration Date:{" "}
                          <i>
                            {ChangeTimeZone(
                              documentActivity?.expirationDate.oldValue,
                            ) ?? "None"}{" "}
                            <ArrowRightOutlined
                              style={{
                                fontSize: "12px",
                                color: awsui.colorChartsThresholdNeutral,
                                margin: "0 4px",
                              }}
                            />{" "}
                            {ChangeTimeZone(
                              documentActivity?.expirationDate.newValue,
                            ) ?? "None"}{" "}
                          </i>
                        </Box>
                      )}
                      {!!documentActivity?.alertDays && (
                        <Box variant="p">
                          Expiration Alert Reminder:{" "}
                          <i>
                            {!!documentActivity?.alertDays.oldValue &&
                            documentActivity?.alertDays.oldValue !== "0"
                              ? `${documentActivity?.alertDays.oldValue} days`
                              : "None"}{" "}
                            <ArrowRightOutlined
                              style={{
                                fontSize: "12px",
                                color: awsui.colorChartsThresholdNeutral,
                                margin: "0 4px",
                              }}
                            />{" "}
                            {!!documentActivity?.alertDays.newValue
                              ? `${documentActivity?.alertDays.newValue} days`
                              : "None"}{" "}
                          </i>
                        </Box>
                      )}
                      <Divider
                        dashed
                        style={{
                          borderColor: awsui.colorBorderDividerDefault,
                          borderWidth: "1px 0 0",
                          margin: "12px 0",
                        }}
                      />
                    </div>
                  );
                })}
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </div>
  );
}
