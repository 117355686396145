import { api } from "..";
import {
  AllDocumentResponse,
  Document,
  DocumentHistory,
  DocumentRequest,
  DocumentVersion,
  Psv,
  PsvRequest,
} from "./types";

const documentAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDocument: build.query<AllDocumentResponse[], void>({
      query: () => `document/all`,
      providesTags: ["Document"],
    }),
    getDocument: build.query<
      Document,
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}`,
      providesTags: (result, error, { documentId }) => [
        { type: "Document", id: documentId },
      ],
    }),

    getAllDocumentVersions: build.query<
      DocumentVersion[],
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}/versions`,
      providesTags: ["Document"],
    }),
    getDocumentVersion: build.query<
      DocumentVersion,
      { providerId: string; documentId: string; versionId: string }
    >({
      query: ({ providerId, documentId, versionId }) =>
        `provider/${providerId}/document/${documentId}/versions/${versionId}`,
      providesTags: ["Document"],
    }),
    getAllDocumentActivities: build.query<
      DocumentHistory,
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}/activities`,
      providesTags: ["Document"],
    }),

    getAllDocumentPsv: build.query<Psv[], { documentId: string }>({
      query: ({ documentId }) => `document/${documentId}/psv/all`,
      providesTags: ["Document"],
    }),

    getDocumentPsv: build.query<Psv, { documentId: String; psvId: String }>({
      query: ({ documentId, psvId }) => `document/${documentId}/psv/${psvId}`,
      providesTags: ["Document"],
    }),
    deleteDocument: build.mutation<
      void,
      { providerId: string; documentId: string }
    >({
      query({ documentId, providerId }) {
        return {
          url: `provider/${providerId}/document/${documentId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Provider" }],
    }),
    saveDocument: build.mutation<
      Document,
      { providerId: string; document: DocumentRequest }
    >({
      query({ providerId, document }) {
        return {
          url: `provider/${providerId}/document`,
          method: "POST",
          body: {
            ...document,
            attachment:
              !!document.file && document.file?.size > 0
                ? {
                    name: document.file?.name,
                    size: document.file?.size,
                    contentType: document.file?.type,
                  }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Document" },
        { type: "Provider" },
        { type: "Provider", id: args.providerId },
      ],
    }),
    updateDocument: build.mutation<
      Document,
      {
        providerId: string;
        documentId: string;
        document: DocumentRequest;
        isVersioned: boolean;
      }
    >({
      query({ providerId, documentId, document, isVersioned = false }) {
        return {
          url: `provider/${providerId}/document/${documentId}?isVersioned=${isVersioned}`,
          method: "PUT",
          body: {
            ...document,
            attachment:
              !!document.file && document.file?.size > 0
                ? {
                    name: document.file?.name,
                    size: document.file?.size,
                    contentType: document.file?.type,
                  }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Document" },
        { type: "Provider" },
        { type: "Provider", id: args.providerId },
      ],
    }),
    shareDocument: build.mutation<
      void,
      { providerId: string; documentId: string; email: string }
    >({
      query({ providerId, documentId, email }) {
        return {
          url: `provider/${providerId}/document/${documentId}/share`,
          method: "POST",
          body: {
            toAddress: email,
          },
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Document" },
        { type: "Provider" },
        { type: "Provider", id: args.providerId },
      ],
    }),
    savePsv: build.mutation<Psv, PsvRequest>({
      query({
        documentId,
        sourceType,
        primarySource,
        status,
        expiration,
        alertDays,
        file,
      }) {
        const formData = new FormData();
        if (sourceType) formData.append("sourceType", sourceType);
        if (primarySource) formData.append("primarySource", primarySource);
        if (status) formData.append("status", status);
        if (expiration) formData.append("expiration", expiration);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (file) formData.append("file", file);
        return {
          url: `document/${documentId}/psv/upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    updatePSV: build.mutation<Psv, PsvRequest>({
      query: ({
        documentId,
        id,
        sourceType,
        primarySource,
        status,
        expiration,
        alertDays,
        file,
      }) => {
        const formData = new FormData();
        if (sourceType) formData.append("sourceType", sourceType);
        if (primarySource) formData.append("primarySource", primarySource);
        if (status) formData.append("status", status);
        if (expiration) formData.append("expiration", expiration);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (file) formData.append("file", file);
        return {
          url: `document/${documentId}/psv/${id}/upload`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Document", id: arg.id },
        { type: "Document" },
      ],
    }),
    deletePsv: build.mutation<void, { documentId: string; psvId: string }>({
      query({ documentId, psvId }) {
        return {
          url: `document/${documentId}/psv/${psvId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    saveVerfication: build.mutation<
      void,
      { documentId: string; selectedOptionValue: string; license_no: string }
    >({
      query: ({ documentId, selectedOptionValue, ...body }) => {
        return {
          url: `document/${documentId}/psv/auto?taskName=${selectedOptionValue}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Document"],
    }),
  }),
});

export const {
  useGetAllDocumentActivitiesQuery,
  useGetAllDocumentVersionsQuery,
  useGetDocumentVersionQuery,
  useGetAllDocumentQuery,
  useDeleteDocumentMutation,
  useGetDocumentQuery,
  useSaveDocumentMutation,
  useShareDocumentMutation,
  useUpdateDocumentMutation,
  useGetAllDocumentPsvQuery,
  useSavePsvMutation,
  useUpdatePSVMutation,
  useGetDocumentPsvQuery,
  useDeletePsvMutation,
  useSaveVerficationMutation,
} = documentAPI;
